import * as XLSX from "xlsx";
import {AgencyFileDatum} from "./types";

function cleanString(data?: any): string {
  return data?.toString().trim() || ''
}

function toNumber(numberLike?: string | number) {
  if (typeof numberLike === 'number') return numberLike;
  return Number(cleanString(numberLike) || 0);
}


export default async function parseGGFile(file: Blob | File): Promise<AgencyFileDatum[]> {
  const parsed = await parseFileData(file);
  return parsed
    .map((raw) => {
      const agency = 'GG'
      const isJ = !!raw['Checkout Date']
      const agencyCode = isJ ? cleanString(raw['Reference']) : cleanString(raw['Reservation Code']);
      const tour = isJ ? '' : cleanString(raw['Product']);
      const option = isJ ? '' : cleanString(raw['Option']);
      const date = isJ ? cleanString(raw['Checkout Date']) : formatDate(cleanString(raw['Date']));
      const adult = isJ ? 0 : toNumber(raw['Adult']);
      const senior = isJ ? 0 : toNumber(raw['Senior']);
      const student = isJ ? 0 : toNumber(raw['Student (with ID)']);
      const eu = isJ ? 0 : toNumber(raw['EU Citizens (with ID)']);
      const euStudent = isJ ? 0 : toNumber(raw['Student EU Citizens (with ID)']);
      const military = isJ ? 0 : toNumber(raw['Military (with ID)']);
      const youth = isJ ? 0 : toNumber(raw['Youth']);
      const kid = isJ ? 0 : toNumber(raw['Children']);
      const infant = isJ ? 0 : toNumber(raw['Infant']);
      const people = adult + senior + student + eu + euStudent + military + youth + kid + infant;
      const price = isJ ? toNumber(raw['Travelled']) : parsePrice(raw['Net Price']);
      return ({
        tour,
        option,
        date,
        agency,
        agencyCode,
        people,
        price,
        unitPrice: price / people,
      });
    })
}


const parseFileData = async (file: Blob | File) => {
  const binary = await file.arrayBuffer()
  const workbook = XLSX.read(binary, {type: "array", raw: true})
  const sheets = workbook.Sheets
  const sheetNames = workbook.SheetNames

  const firstSheet = sheets[sheetNames[0]]
  return XLSX.utils.sheet_to_json(firstSheet) as any[];
}


function formatDate(ddmmyyyy: string) {
  const matches = /(\d{2})\/(\d{2})\/(\d{4})/gi.exec(ddmmyyyy);
  const yyyy = matches?.[3] ?? '';
  const mm = matches?.[2] ?? '';
  const dd = matches?.[1] ?? '';
  return `${yyyy}-${mm}-${dd}`;
}


function parsePrice(priceUSD: string) {
  const matches = /[\d.]+ USD/gi.exec(priceUSD);
  const numbers = matches?.[1] ?? '0';
  return Number(numbers);
}