import { Box, Typography, styled } from "@mui/material"

function InfoMessage({ message }: { message: any }) {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                columnGap: "12px",
                my: 1,
            }}
        >
            <Typography
                sx={{
                    flex: 2,
                    color: "#828282",
                    whiteSpace: "nowrap",
                    // overflow: 'hidden',
                    // textOverflow: 'ellipsis',
                    textAlign: "center",
                }}
            >
                {message}
            </Typography>
        </Box>
    )
}

const Border = styled(Box)(({ theme }) => ({
    flex: 1,
    borderTop: "1px solid #E5E5E5",
}))
export default InfoMessage
