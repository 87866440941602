import { Avatar } from "@mui/material"
import { red, blue } from "@mui/material/colors"
import { IOperator } from "../../../types/chat"

interface Props {
    user: IOperator
}

export default function UserAvatar({ user }: Props) {
    return (
        <Avatar
            sx={{
                bgcolor: (user.area?.at(0)?.toUpperCase() ?? "S") === "S" ? red["500"] : blue["500"],
            }}
        >
            {user.area?.at(0)?.toUpperCase() ?? "S"}
        </Avatar>
    )
}
