import React, {useState} from 'react';

import Box from '@mui/material/Box';
import Chip from "@mui/material/Chip";
import {
    DataGridPro, GridColDef
} from "@mui/x-data-grid-pro";

import {Agency} from '../../models/Agency';
import {useRead} from "../../hooks/realtime";

import AgencyModal from "../../partials/AgencyModal";
import CreateAgencyModal from "../../partials/CreateAgencyModal";
import {GridEventListener} from "@mui/x-data-grid/models/events";
import Button from "@mui/material/Button";
import FiberNewIcon from "@mui/icons-material/FiberNew";


import { useDemoData } from '@mui/x-data-grid-generator';


type AgencyRow = {
    id: string,
    code: string,
    name: string,
    domain: string,
    cancelRegex: string,
    reservationRegex: string,
    reviewRegex: string,
    updateRegex: string,
    ignoreRegex: string
}

export default function () {
    const [agencyId, setAgencyId] = useState<string | null>(null);
    const [loadKey, setLoadKey] = useState<string>(Date.now() + '');
    const [newAgency, setNewAgency] = useState<boolean>(false);
    const {
        data: agencies,
        setData,
        update,
        save,
        loading
    } = useRead<{ [key: string]: Agency }>('agency', loadKey);

    const handleClick: GridEventListener<'rowClick'> = (row, event, context) => {
        setAgencyId(row.id as string);
    }

    const agencyRows = Object.values(agencies ?? {}).map<AgencyRow>((agency, idx) => {
        return {
            id: agency.key,
            code: agency.code,
            name: agency.name,
            domain: agency.domain,
            reservationRegex: agency.categoryRegex?.reservation ?? '',
            cancelRegex: agency.categoryRegex?.cancel ?? '',
            reviewRegex: agency.categoryRegex?.review ?? '',
            updateRegex: agency.categoryRegex?.update ?? '',
            ignoreRegex: agency.categoryRegex?.ignore ?? ''
        }
    })


    const agencyColumns: GridColDef[] = [
        {
            field: 'code',
            headerName: 'Code',
            minWidth: 50,
            align: 'center',
            headerAlign: 'center',
            editable: true,
            renderCell: (params) => {
                return <Chip variant={'outlined'} color={'primary'} label={params.row.code}/>
            }
        },
        {
            field: 'name',
            headerName: 'Name',
            minWidth: 200,
            align: 'center',
            headerAlign: 'center',
            editable: true
        },
        {
            field: 'domain',
            headerName: 'Domain',
            minWidth: 300,
            align: 'center',
            headerAlign: 'center',
            editable: true
        },
        {
            field: 'reservationRegex',
            headerName: 'Reservation Subject',
            minWidth: 400,
            align: 'center',
            headerAlign: 'center',
            editable: true
        },
        {
            field: 'updateRegex',
            headerName: 'Update Subject',
            minWidth: 400,
            align: 'center',
            headerAlign: 'center',
            editable: true
        },
        {
            field: 'cancelRegex',
            headerName: 'Cancel Subject',
            minWidth: 400,
            align: 'center',
            headerAlign: 'center',
            editable: true
        },
        {
            field: 'reviewRegex',
            headerName: 'Review Subject',
            minWidth: 400,
            align: 'center',
            headerAlign: 'center',
            editable: true
        },
        {
            field: 'ignoreRegex',
            headerName: 'Ignore Subject',
            minWidth: 400,
            align: 'center',
            headerAlign: 'center',
            editable: true
        },
    ]


    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                }}
            >
                <Box sx={{
                    position: 'fixed',
                    display: 'inline-flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    bottom: 0,
                    left: '50%',
                    transform: 'translate(-50%, -1em)',
                    zIndex: 999,
                    backgroundColor: 'white',
                    borderRadius: 5,
                    boxShadow: 2,
                    py: 2,
                    px: 3
                }}>
                    <Button
                        fullWidth
                        variant={'text'}
                        size={'large'}
                        startIcon={<FiberNewIcon/>}
                        onClick={() => setNewAgency(true)}
                    >
                        새 에이전시
                    </Button>
                </Box>
                <DataGridPro
                    columns={agencyColumns}
                    rows={agencyRows}
                    onRowClick={handleClick}
                    // processRowUpdate={async (nr, or) => {
                    //     const categoryRegex = {
                    //         cancel: nr.cancelRegex,
                    //         update: nr.updateRegex,
                    //         reservation: nr.reservationRegex,
                    //         review: nr.reviewRegex,
                    //         ignore: nr.ignoreRegex
                    //     }
                    //
                    //     const isValidCategoryRegex = Object.values(categoryRegex).filter((v) => !!v).length > 0;
                    //
                    //     try{
                    //         await save({
                    //             [nr.id]: {
                    //                 categoryRegex: isValidCategoryRegex ? categoryRegex : null,
                    //                 code:nr.code,
                    //                 domain:nr.domain,
                    //                 key:nr.id,
                    //                 id:nr.id,
                    //                 name:nr.name
                    //             }
                    //         })
                    //         return nr;
                    //     }catch(e){
                    //         return or
                    //     }
                    // }}
                    // onProcessRowUpdateError={console.error}
                />
            </Box>
            {
                agencyId
                    ? <AgencyModal
                        id={agencyId}
                        onClose={(update) => {
                            if (update) setLoadKey(Date.now() + '')
                            setAgencyId(null);
                        }}
                    />
                    : null
            }
            {
                newAgency
                    ? <CreateAgencyModal
                        onClose={(update) => {
                            if (update) setLoadKey(Date.now() + '')
                            setNewAgency(false);
                        }}
                    />
                    : null
            }
        </>
    )
}