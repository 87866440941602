import React, {useEffect, useRef} from "react";

interface MapProps {
  lat: number;
  lng: number;
  zoom?: number;
  onClick?: (lat: number, lng: number, address: string) => void; // onClick 콜백을 prop으로 추가
}

const GoogleMap: React.FC<MapProps> = ({lat, lng, zoom = 18, onClick}) => {
  const mapRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (mapRef.current && window.google) {
      const map = new window.google.maps.Map(mapRef.current, {
        center: {lat, lng},
        zoom,
        mapId: 'ef9d70a263b9a5f2'
      });

      const marker = new window.google.maps.marker.AdvancedMarkerElement({
        position: {lat, lng},
        map,
      });
      map.addListener("click", (event: google.maps.MapMouseEvent) => {
        if (event.latLng) {
          const clickedLat = event.latLng.lat();
          const clickedLng = event.latLng.lng();
          const geocoder = new window.google.maps.Geocoder();
          geocoder.geocode({location: event.latLng})
              .then((response) => {
                const address = response.results[0]?.formatted_address;
                onClick?.(clickedLat, clickedLng, address); // 클릭된 위치의 위도와 경도를 콜백으로 전달
              })
              .catch((e)=>{
                console.error(e);
                onClick?.(clickedLat, clickedLng, '');
              })
          ;
        }
      });

    }
  }, [lat, lng]);

  return <div ref={mapRef} style={{width: "100%", height: "400px"}}/>;
};

export default GoogleMap;