import * as XLSX from "xlsx"
export const convertDataFromExel = async <T>(file: File, options?: any): Promise<T[]> => {
    const binary = await file.arrayBuffer()
    const workbook = XLSX.read(binary, { type: "array" })
    const sheets = workbook.Sheets
    const sheetNames = workbook.SheetNames

    const firstSheet = sheets[sheetNames[0]]
    return XLSX.utils.sheet_to_json(firstSheet, {
        ...options,
    })
}
