import * as XLSX from "xlsx";
import {
  AgencyFileDatum,
  KintFileDatum,
  KKdayAggregation,
  KlookAggregation,
  KlookFileDatum,
  TrazyAggregation
} from "./types";


class KlookDatum {
  rows: KlookFileDatum[];
  refunded: KlookFileDatum[];
  compensation: KlookFileDatum[];
  reservation: KlookFileDatum[];
  representativeRow: KlookFileDatum;

  constructor(rows: KlookFileDatum[]) {
    this.rows = rows;
    this.refunded = rows.filter(({type}) => type.toLowerCase().includes('refund'));
    this.compensation = rows.filter(({type}) => type.toLowerCase().includes('compen'));
    this.reservation = rows.filter(({type}) => !type.toLowerCase().includes('refund') && !type.toLowerCase().includes('compen'));
    this.representativeRow = this.reservation[0] ?? this.rows[0];
  }

  get reservationAdult(): number {
    return this.reservation.filter(({unit}) => !unit.toLowerCase().includes('child')).map(({number}) => number).reduce((a, b) => a + b, 0);
  }

  get refundAdult(): number {
    return this.refunded.filter(({unit}) => !unit.toLowerCase().includes('child')).map(({number}) => number).reduce((a, b) => a + b, 0);
  }

  get adult(): number {
    return this.reservationAdult - this.refundAdult;
  }


  get reservationKid(): number {
    return this.reservation.filter(({unit}) => unit.toLowerCase().includes('child')).map(({number}) => number).reduce((a, b) => a + b, 0);
  }

  get refundKid(): number {
    return this.refunded.filter(({unit}) => unit.toLowerCase().includes('child')).map(({number}) => number).reduce((a, b) => a + b, 0);
  }

  get kid(): number {
    return this.reservationKid - this.refundKid;
  }

  get people(): number {
    return this.adult + this.kid;
  }

  get price(): number {
    return this.rows.map(r => r.originalPrice + r.discount).reduce((a, b) => a + b, 0);
  }

  toAgencyFileDatum(): AgencyFileDatum {
    const people = this.adult + this.kid;
    return ({
      tour: this.representativeRow.tour,
      option: this.representativeRow.option,
      date: this.representativeRow.date,
      agency: 'L',
      agencyCode: this.representativeRow.agencyCode,
      people: people,
      price: this.price,
      unitPrice:this.price/ people,
    })
  }

}


function cleanString(data?: any): string {
  return data?.toString().trim() || ''
}

function toNumber(numberLike?: string | number) {
  if (typeof numberLike === 'number') return numberLike;
  return Number(cleanString(numberLike) || 0);
}


export default async function parseKlookFile(file: Blob | File): Promise<AgencyFileDatum[]> {
  const parsed = await parseFileData(file);
  const rows = parsed.map((raw) => {
    const agency = 'L'
    const agencyCode = cleanString(raw['Booking Reference ID']);
    const sub = cleanString(raw['Sub voucher code']);
    const type = cleanString(raw['Settlement type']);
    const date = cleanString(raw['Settlement time'].split(' ').at(0) ?? '');
    const tour = cleanString(raw['Activity Name']);
    const option = cleanString(raw['Package Name']);
    const number = toNumber(raw['Num of Units']);
    const unit = cleanString(raw['Unit name']);
    const originalPrice = toNumber(raw['Original price']);
    const total = toNumber(raw['Total']);
    const discount = toNumber(raw['Discount undertaken by merchant\n']);
    return ({
      type,
      sub,
      tour,
      option,
      date,
      agency,
      agencyCode,
      unit,
      number,
      originalPrice,
      total,
      discount
    });
  })

  const klookAggregationByAgencyCode = rows.reduce((result, row) => {
    if (!(row.agencyCode in result)) {
      result[row.agencyCode] = []
    }
    result[row.agencyCode].push(row);
    return result;
  }, {} as KlookAggregation);

  return Object.entries(klookAggregationByAgencyCode)
    .map(([code, rows]) => {
      if (rows.length === 1) return [code, rows];
      const refunded = rows.filter(({type}) => type.toLowerCase().includes('refund'))
      const others = rows.filter(({type}) => !type.toLowerCase().includes('refund'));
      const refundedTotalOriginalPrice = refunded.map((row) => row.originalPrice).reduce((a, b) => a + b, 0)
      const othersTotalOriginalPrice = others.map((row) => row.originalPrice).reduce((a, b) => a + b, 0)
      if (refundedTotalOriginalPrice + othersTotalOriginalPrice === 0) return [code, []];
      return [code, rows];
    })
    .filter(([_, rows]) => rows.length > 0)
    .map(([key, rows]) => new KlookDatum(rows as KlookFileDatum[]).toAgencyFileDatum());

}

const parseFileData = async (file: Blob | File) => {
  const binary = await file.arrayBuffer()
  const workbook = XLSX.read(binary, {type: "array"})
  const sheets = workbook.Sheets
  const sheetNames = workbook.SheetNames

  const firstSheet = sheets[sheetNames[0]]
  const data = XLSX.utils.sheet_to_json(firstSheet) as any[];

  return data.map((row, idx, array) => {
    if (row['Booking Reference ID']) {
      return row;
    }
    let lastValid = null;
    for (let i = 1; i < idx + 1 && (!lastValid || !lastValid['Booking Reference ID']); i++) {
      lastValid = array[idx - i];
    }
    return {...lastValid, ...pickValues(row, ['Sub voucher code', 'Unit name', 'Unit price', 'Num of Units', 'Discount undertaken by merchant\n', 'Original price'])};
  });
}


function pickValues<T extends Object>(obj: T, properties: string[]): Partial<T> {
  return Object.fromEntries(Object.entries(obj).filter(([property]) => properties.includes(property))) as Partial<T>;
}