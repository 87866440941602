import {Box, Button, Card, Tab, Tabs, TextField, Typography} from "@mui/material"
import TabPanel from "../../components/ui/TabPanel"
import {useState} from "react"
import {Space} from "../../components/ui/Space"
import {useForm} from "react-hook-form"
import {DatePicker} from "@mui/x-date-pickers/DatePicker"
import dayjs, {Dayjs} from "dayjs"
import {getDocs, or, query, Timestamp, updateDoc, where} from "firebase/firestore"
import {collectionWithBaseFireStore, getCurrentUser, Query} from "../../hooks/firebase"
import ChatTable from "../../components/monitor/ChatTable"
import {useAuth} from "../../hooks/auth";

const CHAT_CATEGORIES = Object.freeze({
    all: 0,
    client: 1,
    clientGuide: 2,
    clientOperator: 3,
    // clientPromotion: 4,
})

// export type Type = "all" | "tour" | "client" | "group" | "personal" | "team" | "client-guide" | "client-operator" | "cs-promotion"
export type Type = "all" | "client" | "client-guide" | "client-operator"
export type SortField = "createdAt" | "updatedAt" | "date" | "participants"
export type SortOrder = "asc" | "desc"

const ALL = 'ALL'
const AREA_TABS = [ALL, 'Seoul', 'Busan', 'Tokyo']


function Monitor() {
    const [chatCategory, setChatCategory] = useState<number>(CHAT_CATEGORIES.all)
    const [order, setOrder] = useState<{
        field: SortField
        type: SortOrder
    }>({
        field: "createdAt",
        type: "asc",
    })

    const [date, setDate] = useState<Dayjs | null>(dayjs())
    const [queries, setQueries] = useState<Query[]>([])
    const [areaTab, setAreaTab] = useState<string>(ALL);
    const [keywords, setKeywords] = useState<string>('');

    const areaQueries = areaTab === ALL ? [] : [["tour.productId", ">", areaTab], ["tour.productId", "<", areaTab + '\uf8ff'],]

    const handleChangeAreaTab = (_: any, value: string) => {
        setAreaTab(value);
    }

    const {handleSubmit, register, reset} = useForm({
        defaultValues: {
            text: "",
        },
    })
    const dateQuery = date
        ? [["date", "==", Timestamp.fromDate(new Date(date.format("YYYY-MM-DD"))) as any]]
        : ([] as any)


    const textProps = register("text");


    function onSubmit(data: any) {
        const {text} = data
        if (!text) {
            setQueries([])
            return
        }
        setQueries([["search", "array-contains", text.toLowerCase()]])
        reset()
    }


    function handleTabChange(_: any, value: number) {
        setChatCategory(value)
        setQueries([])
        reset()
    }


    return (
        <Box
            component={"form"}
            sx={{
                px: 8,
            }}
            onSubmit={handleSubmit(onSubmit)}
        >
            <Box mb={2}>
                <Tabs onChange={handleChangeAreaTab} value={areaTab}>
                    {
                        AREA_TABS.map((t) => {
                            return <Tab value={t} label={t} key={t} id={t}/>
                        })
                    }
                </Tabs>
            </Box>
            <Card
                sx={{
                    p: 2,
                }}
            >
                <Box sx={{display: "flex", justifyContent: "space-between"}}>
                    <Tabs onChange={handleTabChange} value={chatCategory} role="tablist">
                        {Object.entries(CHAT_CATEGORIES).map(([key, value]) => {
                            return <Tab key={key} label={
                                key === "clientGuide"
                                    ? "CLIENT-GUIDE"
                                    : key === "clientOperator"
                                        ? "CLIENT-OPERATOR"
                                        : key === 'clientPromotion'
                                            ? "CLIENT-PROMOTION"
                                            : key
                            } value={value}/>
                        })}
                    </Tabs>
                    <Box sx={{display: "flex", alignItems: "center", gap: "0.4rem"}}>
                        <Button
                            variant="contained"
                            color="secondary"
                            sx={{height: "100%", color: "#fff"}}
                            onClick={() => {
                                setDate(null)
                                onSubmit({text: ""})
                                reset()
                            }}
                        >
                            전체 검색
                        </Button>
                        <DatePicker
                            value={date}
                            defaultValue={dayjs()}
                            format="YYYY/MM/DD"
                            onChange={(date) => {
                                date && setDate(date)
                            }}
                        />
                    </Box>
                </Box>
                <Space height={16}/>
                <Box
                    sx={{
                        display: "flex",
                        columnGap: 2,
                    }}
                >
                    <TextField fullWidth {...textProps}/>
                    <Button variant="contained" type="submit">
                        Submit
                    </Button>
                </Box>
                <Typography variant="body2" sx={{}}>
                    ※ 한글/영문 2,3 글자로 가이드/상품 검색 가능, 그 외 고객명, 투어명, 가이드명 띄어쓰기 단위로 검색 가능)
                </Typography>
            </Card>
            <Space height={16}/>
            <TabPanel index={CHAT_CATEGORIES.all} value={chatCategory}>
                <ChatTable
                    visible={chatCategory === CHAT_CATEGORIES.all}
                    queries={[...queries, ...areaQueries, ["category", "in", ["CLIENT", "CLIENT-GUIDE", "CLIENT-OPERATOR"]], ...dateQuery]}
                    order={order}
                    setOrder={setOrder}
                    setQueries={setQueries}
                />
            </TabPanel>
            <TabPanel index={CHAT_CATEGORIES.client} value={chatCategory}>
                <ChatTable
                    visible={chatCategory === CHAT_CATEGORIES.client}
                    queries={[...queries, ...areaQueries, ["category", "==", "CLIENT"], ...dateQuery]}
                    order={order}
                    setOrder={setOrder}
                    setQueries={setQueries}
                />
            </TabPanel>
            <TabPanel index={CHAT_CATEGORIES.clientGuide} value={chatCategory}>
                <ChatTable
                    visible={chatCategory === CHAT_CATEGORIES.clientGuide}
                    queries={[...queries, ...areaQueries, ["category", "==", "CLIENT-GUIDE"], ...dateQuery]}
                    order={order}
                    setOrder={setOrder}
                    setQueries={setQueries}
                />
            </TabPanel>
            <TabPanel index={CHAT_CATEGORIES.clientOperator} value={chatCategory}>
                <ChatTable
                    visible={chatCategory === CHAT_CATEGORIES.clientOperator}
                    queries={[...queries, ["category", "==", "CLIENT-OPERATOR"], ...areaQueries, ...dateQuery]}
                    order={order}
                    setOrder={setOrder}
                    setQueries={setQueries}
                />
            </TabPanel>
        </Box>
    )
}

export default Monitor
