import Box from "@mui/material/Box"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import { SyntheticEvent, useState } from "react"
// import Bus from "./bus"
import Bike from "./bike"
import DoubleCheck from "./DoubleCheck"
import Bus from "./NewBus"
import {getCurrentUser} from "../../hooks/firebase";
import {useAuth} from "../../hooks/auth";

const TAB_LIST = ["더블 체크", "배차", "레일 바이크"]

function Helper() {
    const [value, setValue] = useState("1")

    const handleChange = (event: SyntheticEvent, newValue: string) => {
        setValue(newValue)
    }


    const auth = useAuth();
    if (!auth.auth || auth.auth.level > 1) {
        return null;
    }

    return (
        <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList onChange={handleChange}>
                        {TAB_LIST.map((tab, index) => (
                            <Tab label={tab} value={(index + 1).toString()} />
                        ))}
                    </TabList>
                </Box>
                <TabPanel value="1">
                    <DoubleCheck />
                </TabPanel>
                <TabPanel value="2">
                    <Bus />
                </TabPanel>
                <TabPanel value="3">
                    <Bike />
                </TabPanel>
            </TabContext>
        </Box>
    )
}

export default Helper
