import React, {useState} from 'react';

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import {GridRowSelectionModel} from "@mui/x-data-grid-pro";

import {DateRange, DateRangePicker, SingleInputDateRangeField} from "@mui/x-date-pickers-pro";
import dayjs, {Dayjs} from "dayjs";


import {useQueryDocs} from "../../hooks/firestore";
import {useRead} from "../../hooks/realtime";
import {Reservation, ReservationProp} from "../../models/Reservation";
import {Product} from "../../models/Product";

import DateTabs from "../../components/DateTabs";
import {Doughnut, Bar, Line} from 'react-chartjs-2';
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    ChartData,
    TooltipItem,
    PointElement,
    LineElement,
} from 'chart.js';
import CardHeader from "@mui/material/CardHeader";
import {getCurrentUser} from "../../hooks/firebase";
import {useAuth} from "../../hooks/auth";


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    ArcElement,
    Tooltip,
    Legend
);

export default function () {
    const [dateRange, setDateRange] = React.useState<DateRange<Dayjs>>([dayjs(), dayjs().add(1, 'months')]);
    const [timeseriesRange, setTimeseriesRange] = React.useState<DateRange<Dayjs>>([dayjs().subtract(1, 'months'), dayjs()]);
    const [tempDateRange, setTempDateRange] = React.useState<DateRange<Dayjs>>([...dateRange]);
    const [tempTimeseriesRange, setTempTimeSeriesRange] = React.useState<DateRange<Dayjs>>([...timeseriesRange]);
    const formatDateRanges = dateRange.map(djs => djs?.format('YYYY-MM-DD'));
    const [loadKey, setLoadKey] = useState<string>(Date.now() + '');


    const {
        data: allReservations, setData: setReservations,
        loading, error
    } = useQueryDocs<Reservation>('reservation', [['date', ">=", formatDateRanges[0]!], ['date', '<=', formatDateRanges[1]!]], formatDateRanges.join('-') + loadKey);
    const {
        data: products,
    } = useRead<{ [productId: string]: Product }>('/product');
    const reservations = allReservations.filter(r => !r.canceledAt && !r.noShow);
    const existProducts = new Set(reservations.map(({productId}) => productId));
    const productOptions = Object.values(products ?? {})
        .map((product) => ({
            id: product.id,
            // name: product.name
            name: product.id.replace(/_.+_/gi, '_').toUpperCase()
        }))
        .filter(({id}) => existProducts.has(id))
        .sort((a, b) => a.name > b.name ? 1 : -1);
    const [selectedProducts, setSelectedProducts] = useState<{ id: string, name: string }[]>([])
    const selectedProductIds = selectedProducts.length > 0 ? selectedProducts.map(({id}) => id) : productOptions.map(({id}) => id);
    const selectedProductNames = selectedProducts.length > 0 ? selectedProducts.map(({name}) => name) : productOptions.map(({name}) => name);


    const selectedReservations = reservations.filter((r) =>
        (selectedProductIds.length === 0 || selectedProductIds.includes(r.productId))
    );

    const aggregated = selectedProductIds.length > 0 && reservations.length > 0 ? Object.entries(aggregateBy(selectedProductIds, 'reservedAt', selectedReservations, (strDate: string) => dayjs(strDate).format('YYYY-MM-DD')))
        : null;


    const stacked: [string, [string, Reservation[][]][]][] =
        aggregated !== null
            ? new Array((dayjs(timeseriesRange[1]?.format('YYYY-MM-DD') ?? new Date()).diff(dayjs(timeseriesRange[0]?.format('YYYY-MM-DD') ?? new Date()), 'days') ?? 0))
                .fill(timeseriesRange[0]?.format('YYYY-MM-DD') ?? new Date()).map((d, idx) => dayjs(d).add(idx, 'day'))
                .map((d) => [d.format('YYYY-MM-DD'), aggregated.filter(([date]) => new Date(date) < d.add(1,'day').toDate())])
            : []


    const dataReservation = {
        labels: stacked.map(([d, dateProductReservations]) => `${d}(${dateProductReservations.reduce((result, [_, productReservations]) => result + productReservations.map(reservations => reservations.length).reduce((a, b) => a + b), 0).toLocaleString()}건)`) as string[],
        datasets:
            selectedProductNames.map((name, idx) => {
                const reservationCountData = stacked.map(([_, dateProductReservations]) => dateProductReservations.reduce((result, [_, productReservations]) => result + productReservations[idx].length, 0));
                return (
                    {
                        label: name,
                        data: reservationCountData,
                        backgroundColor: [
                            'rgba(153, 102, 255, 1)',   // Original Soft Purple
                            'rgba(103, 132, 255, 1)',   // Modified Soft Blue
                            'rgba(54, 162, 235, 1)',   // Original Sky Blue
                            'rgba(54, 192, 235, 1)',   // Lighter Sky Blue
                            'rgba(75, 192, 192, 1)',   // Original Cyanish Green
                            'rgba(75, 202, 142, 1)',   // Modified Soft Green
                            'rgba(175, 206, 86, 1)',   // Modified Lime Green
                            'rgba(255, 206, 86, 1)',   // Original Soft Yellow
                            'rgba(255, 176, 76, 1)',   // Modified Soft Orange
                            'rgba(255, 159, 64, 1)',   // Original Orange
                            'rgba(255, 129, 102, 1)',  // Modified Soft Red
                            'rgba(255, 99, 132, 1)'    // Original Pinkish Red
                        ][idx % 12],
                    }
                );
            })
    }

    const combinedReservations = {
        labels: stacked.map(([d, dateProductReservations]) => `${d}(${dateProductReservations.reduce((result, [_, productReservations]) => result + productReservations.map(reservations => reservations.length).reduce((a, b) => a + b), 0).toLocaleString()}건/${dateProductReservations.reduce((result, [_, productReservations]) => result + productReservations.map(reservations => reservations.length > 0 ? reservations.map(reservation => reservation.adult + reservation.kid + reservation.infant).reduce((a, b) => a + b) : 0).reduce((a, b) => a + b), 0).toLocaleString()}명)`) as string[],
        datasets:
            selectedProductNames.map((name, idx) => {
                const reservationCountData = stacked.map(([_, dateProductReservations]) => dateProductReservations.reduce((result, [_, productReservations]) => result + productReservations[idx].length, 0));
                return (
                    {
                        label: `${name}`,
                        data: reservationCountData,
                        backgroundColor: [
                            'rgba(153, 102, 255, 1)',   // Original Soft Purple
                            'rgba(103, 132, 255, 1)',   // Modified Soft Blue
                            'rgba(54, 162, 235, 1)',   // Original Sky Blue
                            'rgba(54, 192, 235, 1)',   // Lighter Sky Blue
                            'rgba(75, 192, 192, 1)',   // Original Cyanish Green
                            'rgba(75, 202, 142, 1)',   // Modified Soft Green
                            'rgba(175, 206, 86, 1)',   // Modified Lime Green
                            'rgba(255, 206, 86, 1)',   // Original Soft Yellow
                            'rgba(255, 176, 76, 1)',   // Modified Soft Orange
                            'rgba(255, 159, 64, 1)',   // Original Orange
                            'rgba(255, 129, 102, 1)',  // Modified Soft Red
                            'rgba(255, 99, 132, 1)'    // Original Pinkish Red
                        ][idx % 12],
                        stack: 'y-axis-1',
                        yAxisID: 'y-axis-1',
                    }
                );
            })
                .concat(
                    selectedProductNames.map
                    ((name, idx) => (
                        {
                            label: `${name}-인원 수`,
                            data: stacked.map(([_, dateProductReservations]) => dateProductReservations.reduce((result, [_, productReservations]) => result + productReservations[idx].reduce((rslt, r) => rslt + r.adult + r.kid + r.infant, 0), 0)),
                            backgroundColor: [
                                'rgba(153, 102, 255, 1)',   // Original Soft Purple
                                'rgba(103, 132, 255, 1)',   // Modified Soft Blue
                                'rgba(54, 162, 235, 1)',   // Original Sky Blue
                                'rgba(54, 192, 235, 1)',   // Lighter Sky Blue
                                'rgba(75, 192, 192, 1)',   // Original Cyanish Green
                                'rgba(75, 202, 142, 1)',   // Modified Soft Green
                                'rgba(175, 206, 86, 1)',   // Modified Lime Green
                                'rgba(255, 206, 86, 1)',   // Original Soft Yellow
                                'rgba(255, 176, 76, 1)',   // Modified Soft Orange
                                'rgba(255, 159, 64, 1)',   // Original Orange
                                'rgba(255, 129, 102, 1)',  // Modified Soft Red
                                'rgba(255, 99, 132, 1)'    // Original Pinkish Red
                            ][idx % 12],
                            stack: 'y-axis-2',
                            yAxisID: 'y-axis-2',
                        }
                    ))
                )
    }

    const dataPeople = {
        labels: stacked.map(([d, dateProductReservations]) => `${d}(${dateProductReservations.reduce((result, [_, productReservations]) => result + productReservations.map(reservations => reservations.length > 0 ? reservations.map(reservation => reservation.adult + reservation.kid + reservation.infant).reduce((a, b) => a + b) : 0).reduce((a, b) => a + b), 0).toLocaleString()}명)`) as string[],
        datasets:
            selectedProductNames.map
            ((name, idx) => (
                {
                    label: name,
                    data: stacked.map(([_, dateProductReservations]) => dateProductReservations.reduce((result, [_, productReservations]) => result + productReservations[idx].reduce((rslt, r) => rslt + r.adult + r.kid + r.infant, 0), 0)),
                    backgroundColor: [
                        'rgba(153, 102, 255, 1)',   // Original Soft Purple
                        'rgba(103, 132, 255, 1)',   // Modified Soft Blue
                        'rgba(54, 162, 235, 1)',   // Original Sky Blue
                        'rgba(54, 192, 235, 1)',   // Lighter Sky Blue
                        'rgba(75, 192, 192, 1)',   // Original Cyanish Green
                        'rgba(75, 202, 142, 1)',   // Modified Soft Green
                        'rgba(175, 206, 86, 1)',   // Modified Lime Green
                        'rgba(255, 206, 86, 1)',   // Original Soft Yellow
                        'rgba(255, 176, 76, 1)',   // Modified Soft Orange
                        'rgba(255, 159, 64, 1)',   // Original Orange
                        'rgba(255, 129, 102, 1)',  // Modified Soft Red
                        'rgba(255, 99, 132, 1)'    // Original Pinkish Red
                    ][idx % 12],
                }
            ))
    }

    const handleDateRange = (dr: DateRange<Dayjs>) => {
        setTempDateRange(dr);
    }

    const handleTimeseriesRange = (dr: DateRange<Dayjs>) => {
        setTempTimeSeriesRange(dr);
    }

    const handleConfirmDateRange = () => {
        setDateRange(tempDateRange);
        setTimeseriesRange(tempTimeseriesRange);
    }


    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                px: 8
            }}
        >
            <Card>
                <CardContent sx={(theme) => ({
                    display: 'flex',
                    justifyContent: 'space-between',
                    color: theme.palette.text.primary,
                    padding: '8px !important',
                    gap: 2,
                })}
                >
                    <Box
                        sx={{
                            flex: 1
                        }}
                    >
                        <Autocomplete
                            multiple
                            fullWidth
                            options={productOptions}
                            getOptionLabel={(option) => option.name}
                            value={selectedProducts}
                            onChange={(_, selected) => setSelectedProducts(selected)}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            sx={{backgroundColor: 'white'}}
                            renderInput={(params) => <TextField {...params} label={'상품'}/>}
                        />
                    </Box>
                    <Box
                        display={'flex'}
                        gap={1}
                    >
                        <DateRangePickerValue onChange={handleDateRange} dateRange={tempDateRange} label={'투어일'}/>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            gap: 1,
                        }}
                    >
                        <DateRangePickerValue onChange={handleTimeseriesRange} dateRange={tempTimeseriesRange}
                                              label={'예약일'}/>

                        {
                            loading ?
                                <CircularProgress/>
                                : <Button onClick={handleConfirmDateRange} variant={'contained'}
                                          disabled={
                                              timeseriesRange.map(d => d?.format('YYYY-MM-DD')).join() === tempTimeseriesRange.map(d => d?.format('YYYY-MM-DD')).join()
                                              && dateRange.map(d => d?.format('YYYY-MM-DD')).join() === tempDateRange.map(d => d?.format('YYYY-MM-DD')).join()
                                          }
                                >
                                    Confirm
                                </Button>

                        }

                    </Box>
                </CardContent>
            </Card>
            <Card>
                <CardHeader title={'종합'}/>
                <CardContent
                    sx={(theme) => ({
                        display: 'flex',
                        justifyContent: 'space-between',
                        color: theme.palette.text.primary,
                        padding: '8px !important',
                        height: '80vh'
                    })}
                >
                    <Bar data={combinedReservations}
                         options={{

                             plugins: {
                                 legend: {
                                     position: 'top', // legend position to 'left'
                                     align: 'start', // start alignment (top for vertical, left for horizontal)
                                     labels: {
                                         filter: function (item, chart) {
                                             return !item.text.includes("인원");
                                         }
                                     },
                                     onClick(e, legendItem) {
                                         const selected = productOptions.find((p) => p.name === legendItem.text);
                                         if (selected) setSelectedProducts([selected])
                                     }
                                 },

                                 // tooltip: {
                                 //     callbacks: {
                                 //         label: (context: TooltipItem<'bar'>) => {
                                 //             const label = context.label!;
                                 //             const value = context.parsed as unknown as number;
                                 //             console.log(value);
                                 //             const dataset = context.dataset!.data as number[];
                                 //             const total = dataset.reduce((prev, curr) => prev + curr, 0);
                                 //             const percentage = Math.round((value / total) * 100);
                                 //
                                 //             return `${label}: ${percentage}%`;
                                 //         },
                                 //     },
                                 // },
                             },

                             responsive: true,
                             maintainAspectRatio: false,
                             interaction: {
                                 intersect: false,
                             },
                             scales: {
                                 x: {
                                     stacked: true,
                                 },
                                 'y-axis-1': {
                                     position: 'left',
                                     stacked: true
                                 },
                                 'y-axis-2': {
                                     position: 'right',
                                     stacked: true,
                                     grid: {
                                         drawOnChartArea: false
                                     }
                                 }
                             }
                         }}/>

                </CardContent>
            </Card>

            <Card>
                <CardHeader title={'예약 수'}/>
                <CardContent
                    sx={(theme) => ({
                        display: 'flex',
                        justifyContent: 'space-between',
                        color: theme.palette.text.primary,
                        padding: '8px !important',
                        height: '80vh'
                    })}
                >
                    <Bar data={dataReservation}
                         options={{
                             plugins: {
                                 legend: {
                                     position: 'top', // legend position to 'left'
                                     align: 'start', //
                                 }
                             },
                             responsive: true,
                             maintainAspectRatio: false,
                             scales: {
                                 x: {stacked: true},
                                 y: {stacked: true}
                             },
                         }}/>

                </CardContent>
            </Card>
            <Card>
                <CardHeader title={'인원 수'}/>
                <CardContent
                    sx={(theme) => ({
                        display: 'flex',
                        justifyContent: 'space-between',
                        color: theme.palette.text.primary,
                        padding: '8px !important',
                        height: '80vh'
                    })}
                >
                    <Bar data={dataPeople} options={{
                        plugins: {
                            legend: {
                                position: 'top', // legend position to 'left'
                                align: 'start', //
                            }
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                            x: {stacked: true},
                            y: {stacked: true}
                        },
                    }}/>

                </CardContent>
            </Card>
        </Box>
    )
}


function DateRangePickerValue(props: { dateRange: DateRange<Dayjs>, onChange: (dr: DateRange<Dayjs>) => void, label?: string }) {
    const {dateRange: value, onChange: setValue, label} = props;
    return (
        <DateRangePicker
            format={'YY-MM-DD'}
            slots={{field: SingleInputDateRangeField}}
            value={value}
            label={label ?? ''}
            onChange={(newValue) => setValue(newValue)}
        />
    );
}

function aggregateBy(productIds: string[], propName: ReservationProp, reservations: Reservation[], mapPropValue: (a: any) => string | number = f => f): { [propValue: string | number]: Reservation[][] } {
    return reservations.reduce((result, reservation,_idx) => {
        const propValue = mapPropValue(Number.isNaN(Number(reservation[propName])) ? reservation[propName] as string : Number(reservation[propName]));
        if (!propValue) {
            return result;
        }
        if (!result[propValue]) result[propValue] = (new Array(productIds.length)).fill(null).map(() => []);
        const idx = productIds.indexOf(reservation.productId);
        result[propValue][idx].push(reservation);
        return result;
    }, {} as { [propValue: string | number]: Reservation[][] });
}
