import React, {useContext, useEffect, useLayoutEffect, useState} from "react"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import Icon from "@mui/material/Icon"
import ReservationIcon from "@mui/icons-material/ConfirmationNumberOutlined"
import PeopleIcon from "@mui/icons-material/PeopleOutline"
import CancelIcon from "@mui/icons-material/Cancel"
import CircleIcon from "@mui/icons-material/Circle"
import CircularProgress from "@mui/material/CircularProgress"
import {Team, Tour} from "../models/Operation"
import {callFunction, readRealtime} from "../hooks/firebase"
import {AuthContext} from "../hooks/auth"
import ChatIcon from "@mui/icons-material/Chat"
import {ArrowDropUp, ArrowDropDown, ThreeP, ModeComment} from "@mui/icons-material";
const OPERATOR = Object.freeze([
    {
        id: "QSkWA94jvqSkfnKXBj37GyplSyG2",
        name: "써니",
        nameEn: "Sunny",
        type: "Operator",
    },
    {
        id: "SX1njRz4BbevBd6Exb9Z07U7F5D2",
        name: "박호영",
        nameEn: "Dylan",
        type: "Operator",
    },
    {
        id: "Spt1OZhXCvMLKmjGpFLQldoTQk22",
        name: "이영훈",
        nameEn: "Younghun",
        type: "Operator",
    },
    {
        id: "Vermu2kOJeNZiqEm6JBPd8yul6N2",
        name: "박영규",
        nameEn: "Rupert",
        type: "Operator",
    },
    {
        id: "hut7yktlwbbBPTUrKZo2zlsT3YQ2",
        name: "정혜민",
        nameEn: "Christine",
        type: "Operator",
    },
])

export default function (props: {
    highlight?: boolean | null
    date: string
    tour: Tour
    team: Team
    idx: number
    onClick: (team: Team) => void
    onDelete: (team: Team) => void
    hideReservation?: boolean
}) {
    const [chatId, setChatId] = useState<string | null>(null)
    const [loadingCreateChat, setLoadingCreateChat] = useState(false)
    const {auth: currentUser} = useContext(AuthContext) as any

    const {highlight, team, idx, onClick, onDelete} = props
    const {date, tour} = props

    const {product, productId} = tour

    const hideReservation = props.hideReservation ?? false
    const guides = (team.guides ?? []).map(({name}) => name).join(", ")
    const reservations = Object.values(team.reservations ?? {})
    const people = reservations.reduce(
        (people, reservation) => people + reservation.adult + reservation.kid + reservation.infant,
        0
    )
    const handleClick = () => onClick(team)
    const handleDeleteTeam = () => onDelete(team)
    const hasMemo =
        (typeof team.memo === "string" && team.memo.length > 0) ||
        (team.memo && Object.values(team.memo).filter((v) => v && (v as string).length > 0).length > 0)
    const hasOfficeMemo =
        typeof team.memo === "string" && team.memo.length > 0 || (typeof team.memo === 'object' && team.memo?.office && typeof team.memo?.office === 'string' && team.memo?.office.length > 0)
    const hasGuideMemo =
        typeof team.memo === 'object' && Object.keys(team.memo).filter(k => {
            const memoObject = team.memo as { [key: string]: string };
            return k.toUpperCase() !== 'OFFICE' && memoObject[k] && typeof memoObject[k] === 'string' && memoObject[k].length > 0
        }).length > 0
    /**
     * 1. operator + guide uids 파싱
     * 2. 1.에서 파싱한 uids로 채팅 존재 유무 확인 후 있다면 chatId를 가져옴
     * 3. state 관리를 해줘야함 { teamId: chatId } 형태로 저장
     */
    useEffect(() => {
    }, [])
    return (
        <Box
            sx={(theme) => ({
                width: "100%",
                height: "100%",
                border: `solid ${highlight ? 2 : 1}px ${
                    !team.id
                        ? theme.palette.secondary.main
                        : highlight
                            ? theme.palette.error.main
                            : theme.palette.divider
                }`,
                borderRadius: 2,
                color: "text.secondary",
                backgroundColor: "background.default",
                py: 1,
                px: 2,
            })}
            onClick={(e) => {
                // e.preventDefault()
                e.stopPropagation()
                handleClick()
            }}
            onMouseDown={(e) => {
                // e.preventDefault()
                e.stopPropagation()
            }}
        >
            {reservations.length === 0 && guides.length === 0 ? (
                <Box
                    sx={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <IconButton
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            handleDeleteTeam()
                        }}
                        onMouseDown={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                        }}
                    >
                        <CancelIcon/>
                    </IconButton>
                </Box>
            ) : (
                <Box
                    sx={(theme) => ({
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    })}
                >
                    <Box flex={1} overflow={"hidden"}>
                        <Typography
                            noWrap
                            sx={{
                                display: "inline-flex",
                                alignItems: "center",
                                justifyContent: "start",
                                gap: "4px",
                            }}
                            variant={"subtitle1"}
                            color={"text.disabled"}
                            textAlign={"start"}
                            // 챗 부르기
                            // onClick={async (e) => {
                            //     e.stopPropagation()
                            //     e.preventDefault()
                            //     if (!currentUser || !team.guides || !Array.isArray(team.guides)) return
                            //     if (window.confirm("채팅방을 여시겠습니까?")) {
                            //         async function recallChat() {
                            //             setLoadingCreateChat(true)
                            //             if (chatId) return chatId
                            //             const chat = (await callFunction("recallChat", {
                            //                 category: "TEAM",
                            //                 cId: `${date}:${productId}:${team.id}`,
                            //                 title: `${date}:${product}:${idx + 1}`,
                            //                 participants: [
                            //                     ...(team.guides?.map((guide: any) => ({
                            //                         name: guide.name,
                            //                         id: guide.id,
                            //                         nameEn: guide?.nameEn ?? "",
                            //                         type: "Guide",
                            //                     })) ?? []),
                            //                     ...OPERATOR,
                            //                     {
                            //                         name: currentUser!.name,
                            //                         id: currentUser?.id,
                            //                         nameEn: currentUser?.nameEn,
                            //                         type: "Operator",
                            //                     },
                            //                 ],
                            //                 tour: {
                            //                     date: date,
                            //                     productId: productId,
                            //                     team: team.id,
                            //                 },
                            //             })) as {
                            //                 id: string
                            //                 [key: string]: any
                            //             }
                            //             if (chat && chat.id) {
                            //                 setChatId(chat.id)
                            //             }
                            //             return chat.id
                            //         }
                            //
                            //         recallChat()
                            //             .then((chatId) => {
                            //                 try {
                            //                     if (!chatId) return alert("적절한 채팅방을 찾을 수 없습니다.")
                            //                     const newWin = window.open(
                            //                         `/chat/rooms/${chatId}`,
                            //                         "_blank",
                            //                         "width=375,height=700"
                            //                     )
                            //                     newWin?.focus()
                            //                 } catch (e) {
                            //                     alert("채팅방을 열 수 없습니다.")
                            //                 }
                            //             })
                            //             .finally(() => {
                            //                 setLoadingCreateChat(false)
                            //             })
                            //     }
                            // }}
                        >
                            {/*{hasMemo ? (*/}
                            {/*    <CircleIcon*/}
                            {/*        sx={{*/}
                            {/*            width: "0.8rem",*/}
                            {/*            height: "0.8rem",*/}
                            {/*        }}*/}
                            {/*        fontSize={"small"}*/}
                            {/*    />*/}
                            {/*) : null}*/}
                            {hasGuideMemo ? (
                                <ThreeP/>
                            ) : null}
                            {hasOfficeMemo ? (
                                <ModeComment/>
                            ) : null}
                            Team {idx + 1}{" "}
                            {/*챗 부르기 아이콘*/}
                            {/*{loadingCreateChat ? (*/}
                            {/*    <CircularProgress size={18} sx={{ width: "18px", height: "18px", opacity: 0.8 }} />*/}
                            {/*) : (*/}
                            {/*    <ChatIcon sx={{ width: "18px", opacity: 0.8 }} />*/}
                            {/*)}*/}
                        </Typography>
                        <Typography noWrap textOverflow={"ellipsis"} variant={"subtitle1"} textAlign={"start"}>
                            ({team.guides?.length}){guides}
                        </Typography>
                    </Box>
                    <Box flex={0}>
                        {!hideReservation ? (
                            <Typography noWrap variant={"subtitle1"} color={"text.disabled"} textAlign={"end"}>
                                <Icon>
                                    <ReservationIcon/>
                                </Icon>{" "}
                                {reservations.length}
                            </Typography>
                        ) : null}

                        <Typography noWrap variant={"subtitle1"} color={"text.disabled"} textAlign={"end"}>
                            <Icon>
                                <PeopleIcon/>
                            </Icon>{" "}
                            {people}
                        </Typography>
                    </Box>
                </Box>
            )}
        </Box>
    )
}
