import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import useMail from "../hooks/useMail";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";


export default function MailPreview(props: { messageId: string }) {
    const {messageId} = props;
    const {data, loading} = useMail(messageId)
    return loading
        ? <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '80vh'
        }}>
            <CircularProgress/>
        </Box>
        : data
            ? (
                <Box>
                    <Box
                        display={'flex'}
                        justifyContent={'space-between'}
                        my={2}
                    >
                        <Typography variant={'h6'}>
                            {data.subject}
                        </Typography>
                        <Typography variant={'h6'}>
                            {new Date(data.date?._seconds * 1000).toLocaleString()}
                        </Typography>
                    </Box>
                    <Divider/>
                    <Box
                        my={2}
                    >
                        <div dangerouslySetInnerHTML={{__html:
                                data.html.replace(/<style>[\s\S]+?<\/style>/g, '')
                                // data.html
                                || data.text}}/>
                    </Box>
                </Box>
            )
            : null
}