export const ANNOUNCE_INFO_MESSAGE =
`——————————————
오피스 연락처 01096508388
★ 투어코스/탑승인원/탑승지/탑승시간 전달
★ 투어 전날 저녁 21시까지 채팅 웹 입장 안 하신 손님들께 WhatsApp/Line/Viber 등으로 연락
★ Email 없는 에이전시(T, VI, TE, CV) 같은 경우 손님 연락처 추가하여 직접 연락
★ 동역사 손님 동대문 아닌 ⭐동대문역사문화공원역⭐ 강조, 하루 전 연락 불가 손님 투어 시작 후 연락처 확보 후 채팅 웹 입장 유도
★ 롯데면세점 쿠폰 배부 필수 [회사 단체 번호: 23179785]
——————————————
● 차량 내 음식물 반입 금지 요청
● 기사님 픽업지/시간/투어명/투어 방문 순서 ⭐반드시⭐ 안내
● 기사님 존칭 사용/매너 유지
● 투어 종료 시 손님 개인 소지품 분실 주의 안내 
——————————————
☑ 출근 사진 업로딩 필수, 누락 시 지각비 차감
☑ 투어 시작과 투어 종료 단톡방에 ⭐반드시⭐ 보고
☑ 투어 당일 기상 시 "투어준비중" 단톡방에 보고 
——————————————
※ 예장 주차장 주의사항
※ 버스는 반드시 주차라인에 정차
※ 본인 깃발 번호 사용 필수
※ 버스로 이동 시 반드시 바닥에 표시된 ⭐하얀색 안전선⭐ 따라서 이동, 주차장 가로지르기 금지, 급할 시 버스기사 출입문 이용\n
`
export const TOURS: { [key: string]: string } = {
    "남설": "남이섬 + 설악산",
    "남아": "남이섬 + 아침고요수목원",
    "남쁘아": "남이섬 + 쁘띠프랑스 + 아침고요수목원",
    "레남쁘": "레일바이크 + 남이섬 + 쁘띠프랑스",
    "레남아": "레일바이크 + 남이섬 + 아침고요수목원",
    "쁘남레아": "쁘띠프랑스 + 남이섬 + 레일바이크 + 아침고요수목원",
    "설낙": "설악산 + 낙산사",
    "알파카": "알파카월드 + 산토리니카페 + 제이든가든",
    "휘남레": "휘바핀라드 + 남이섬 + 레일바이크",

    "남쁘": "남이섬 + 쁘띠프랑스",
    "에버": "에버랜드",
    "남셔": "남이섬셔틀",
    "포천딸기": "포천아트벨리 + 딸기농장 + 허브아일랜드",
    "포천사과": "포천아트벨리 + 사과농장 + 허브아일랜드",
    "남이엘리시안": "남이섬 + 강촌 엘리시안",
    "엘리시안": "강촌 엘리시안",
    "지산": "지산리조트",
    "남이비발디": "남이섬 + 비발디파크",
    '비주스': '비발디파크 + 하남 스타필드 + 주렁주렁 하남점',
    '휘닉스평창': '평창 휘닉스파크',
    "딸남아": "딸기농장 + 남이섬 + 아침고요수목원",
    "딸남레": "딸기농장 + 남이섬 + 레일바이크",
    "딸남레아": "레일바이크 + 남이섬 + 딸기농장 + 아침고요수목원",

    '딸썰어': '딸기농장 + 가평 썰매장 + 어비계곡',
    '딸남어': '딸기농장 + 남이섬 +  어비계곡',

    '알레남아': '알파카월드 + 레일바이크 + 남이섬 + 아침고요수목원',
    '알딸남아': '알파카월드 + 딸기농장 + 남이섬 + 아침고요수목원',
    '딸알프스': '딸기농장 + 청양알프스',

    '광양전주(서울)': '광양 매화마을 + 전주 한옥마을',
    '서울진해': '진해 여좌천 + 진해 경화역 벚꽃길',
    '태안딸기': '태안 튤립축제 + 딸기농장',

    '오대산': '오대산',
    '설악산단풍': '설악산 소공원',
    '대둔산': '대둔산 케이블카',
    '내장산': '내장산 케이블카',

    '레고레일': '레고랜드 + 레일바이크',
    '레고남이': '레고랜드 + 남이섬',

    '파주DMZ': '파주 임진각 + 캠프그리브스 + 오두산 통일전망대',
    '파주 DMZ': '파주 임진각 + 캠프그리브스 + 오두산 통일전망대',
    '파주(땅굴)DMZ': '파주 임진각',
    '파주(땅굴) DMZ': '파주 임진각',
    '김포DMZ': '애기봉평화생태공원 + 김포 대명포구',
    '철원DMZ': '철원 고석정 + DMZ두루미평화타운 + 백마고지 + 노동당사',
    '철원 DMZ': '철원 고석정 + DMZ두루미평화타운 + 백마고지 + 노동당사',
    '김포 DMZ': '애기봉평화생태공원 + 김포 대명포구',
    '겹벚꽃': '-------',

    '벚꽃랜덤': '------',
    '광장시장': '광장시장',

    '알남': '알파카월드 + 남이섬',
    '알남레': '알파카월드 + 남이섬 + 레일바이크',
    '알남아': '알파카월드 +  남이섬 + 아침고요수목원',
    '알레고': '알파카월드 + 레고랜드',
    '알남삼': '알파카월드 + 남이섬 + 삼악산 케이블카',

    '어알삼레아': '어비계곡 + 알파카월드 + 삼악산 케이블카 + 레일바이크 + 아침고요수목원',

    '화구스': '화담숲 + 구하우스 + 더스테이힐링파크',

    '수원화성': '용인 한국민속촌 + 수원 화성 + 수원 남문시장',

    '야간민속촌': '용인 한국민속촌 + 수원 화성',

    '양평': '양평 양떼목장 + 더 그림 카페 + 두물머리',

    '전주': '전주 한옥마을',

    'BTS': '용인 대장금파크 + 유정식당 + 휴가 카페 + 노들섬',

    '알루산': '알파카월드 + 횡성 루지 + 뮤지엄 산',
    '알출산': '알파카월드 + 소금산 출렁다리 + 뮤지엄 산',
    '레고랜드': '레고랜드',
    '화남레': '화담숲 + 남이섬 + 레일바이크',
    '알출루': '알파카월드 + 소금산 출렁다리 + 횡성 루지',
    '벚꽃랜덤(서울)': '랜덤 스팟1 + 랜덤 스팟2 + 랜덤 스팟3',
    '겹벚꽃(서울)': '랜덤 스팟1 + 랜덤 스팟2 + 랜덤 스팟3',
    '블랙핑크': 'YG 엔터테인먼트 + 더세임카페 + 매종 키티버니포니 + 내맘대로폰케이스 홍대점 + 아이헤이트먼데이',
    '이태원클래스': '장가 본사 + 남산오르미 + 남산공원 백범광장 + 수아네집 & 이태원 단밤포차 2호점 + 해방촌거리 & 녹사평육교 + 이태원 단밤포차 1호점',
    '서울더버스커스': '광화문광장 + 경복궁 + 북촌 한옥마을 + 한지문화산업센터',
    'MBC 스튜디오': 'MBC',
    'MBC 스튜디오(드라마 리허설)': 'MBC 리허설',
    '특공대(서울)': '청계천 + 광화문광장 + 인사동 + 광장시장 + DDP + 성수동 + 잠실 + 코엑스 별마당 도서관 + 노량진수산물도매시장 + 여의도 한강공원 + 연남동',
    '파주(캠프그리브즈) DMZ': '파주 임진각 + 캠프그리브스 + 오두산 통일전망대',
    '화주스':'비발디파크 + 하남 스타필드 + 주렁주렁 하남점',
    'Private(서울)':'Private 서울',
}

export const PICKUP_TIMES: {
    [key: string]: number
} = {
    "광양전주(서울)": 600,
    서울진해: 600,
    태안딸기: 730.1,

    오대산: 630,
    설악산단풍: 630,
    대둔산: 630,
    내장산: 630,

    알남: 730.1,
    알루산: 730.1,
    알출산: 730.1,
    알레남아: 730.1,
    알딸남아: 730.1,
    딸알프스: 730.1,
    알남삼: 730.1,
    어알삼레아: 700,

    남설: 730.1,
    알레고: 730.1,

    화구스: 800,
    전주: 800,
    BTS: 800,
    딸썰어: 800,
    딸남어: 800,

    레남아: 800,
    쁘남레아: 800,
    알남아: 730.1,
    딸남레아: 800,

    남쁘아: 800,
    딸남아: 800,

    남아: 900,

    레남쁘: 800,
    설낙: 800,
    알파카: 800,
    휘남레: 800,

    알남레: 730.1,

    레고레일: 800,
    레고남이: 800,

    남쁘: 900,
    에버: 900,
    남셔: 900,

    포천딸기: 900,
    포천사과: 900,

    딸남레: 800,

    벚꽃랜덤: 800,
    겹벚꽃: 800,

    파주DMZ: 720,
    "파주(땅굴)DMZ": 630.1,
    "파주(땅굴) DMZ": 630.1,

    수원화성: 900,
    야간민속촌: 1400,
    양평: 900,

    광장시장: 1700,

    남이엘리시안: 800,
    엘리시안: 800,
    남이비발디: 700,
    비주스: 700,
    휘닉스평창: 700,
    지산: 800,

    '레고랜드': 900,
    김포DMZ: 800.1,
    철원DMZ: 800,
    '철원 DMZ': 800,
    '김포 DMZ': 800.1,
    '화남레': 730.1,
    '알출루': 730.1,
    '벚꽃랜덤(서울)': 800,
    '겹벚꽃(서울)': 820,
    '블랙핑크': 910,

    '이태원클래스': 1430,
    '서울더버스커스': 920,
    'MBC 스튜디오': 9999,
    'MBC 스튜디오(드라마 리허설)': 9999,
    'Private(서울)': 9999,
    'VIP TOUR (P)': 9999,
    'VIP TOUR (A)': 9999,
    'VIP TOUR (B)': 9999,
    'VIP TOUR (C)': 9999,
    '특공대(서울)': 900.9,
    '파주(캠프그리브즈)':720,
    '화주스':800,
}

export const PICKUP_TIMES_WINTER: { [key: string]: number } = {
    광양구례: 600,
    진해: 600,
    남비: 700,
    남설: 730,
    레남쁘: 800,
    설낙: 800,
    알파카: 800,
    휘남레: 800,
    남엘: 800,
    엘리시안: 800,
    비발디: 800,
    지산: 800,
    딸남레: 800,
    남쁘: 900,
    에버: 900,
    남셔: 900.1,
    레남아: 1000,
    쁘남레아: 1000,
    딸남레아: 1000,
    남쁘아: 1100,
    딸남아: 1100,
    남아: 1230,
    포천딸기: 1330,

    알남레: 730.1,
    전주: 800,
    BTS: 800,
}

export const DEPARTURES: { [key: number]: string[] } = {
    600: ["05:30 홍대입구역 4번 출구", "06:00 명동역 2번 출구", "06:10 동대문역사문화공원역 10번 출구"],
    630: ["06:00 홍대입구역 4번 출구", "06:30 명동역 2번 출구", "06:40 동대문역사문화공원역 10번 출구"],
    630.1: ["06:20 동대문역사문화공원역 10번 출구", "06:30 남산예장버스환승주차장", "07:00 홍대입구역 3번 출구"],
    700: ["06:30 홍대입구역 4번 출구", "07:00 남산예장버스환승주차장", "07:10 동대문역사문화공원역 10번 출구"],
    700.1: ["06:20 홍대입구역 4번 출구", "07:00 남산예장버스환승주차장", "07:10 동대문역사문화공원역 10번 출구"],
    720: ["07:10 동대문역사문화공원역 10번 출구", "07:20 남산예장버스환승주차장", "08:00 홍대입구역 3번 출구"],
    730: ["07:00 홍대입구역 4번 출구", "07:30 명동역 2번 출구", "07:40 동대문역사문화공원역 10번 출구"],
    730.1: ["07:00 홍대입구역 4번 출구", "07:30 남산예장버스환승주차장", "07:40 동대문역사문화공원역 10번 출구"],
    800: ["07:20 홍대입구역 4번 출구", "08:00 남산예장버스환승주차장", "08:10 동대문역사문화공원역 10번 출구"],
    800.1: ["07:40 동대문역사문화공원역 10번 출구", "08:00 남산예장버스환승주차장", "08:30 홍대입구역 3번 출구"],
    820: ['08:10 동대문역사문화공원역 10번 출구', '08:20 남산예장버스환승주차장', '09:00 홍대입구역 3번 출구'],
    900: ["08:20 홍대입구역 4번 출구", "09:00 남산예장버스환승주차장 ", "09:10 동대문역사문화공원역 10번 출구"],
    900.1: ["08:20 홍대입구역 4번 출구", "09:00 명동역 2번 출구", "09:10 동대문역사문화공원역 10번 출구"],
    900.9: ["08:20 홍대입구역 4번 출구", "09:00 남산예장버스환승주차장 ", ""],
    910: ['09:00 동대문역사문화공원역 10번 출구', '09:10 남산예장버스환승주차장', '09:50 홍대입구역 3번 출구'],
    920: ['09:20 광화문역 7번 출구', '09:20 광화문역 7번 출구', '09:20 광화문역 7번 출구'],
    1000: ["09:20 홍대입구역 4번 출구", "10:00 남산예장버스환승주차장 ", "10:10 동대문역사문화공원역 10번 출구"],
    1100: ["10:20 홍대입구역 4번 출구", "11:00 남산예장버스환승주차장 ", "11:10 동대문역사문화공원역 10번 출구"],
    1230: ["11:50 홍대입구역 4번 출구", "12:30 남산예장버스환승주차장 ", "12:40 동대문역사문화공원역 10번 출구"],
    1330: ["12:50 홍대입구역 4번 출구", "13:30 남산예장버스환승주차장 ", "13:40 동대문역사문화공원역 10번 출구"],
    1400: ["13:20 홍대입구역 4번 출구", "14:00 남산예장버스환승주차장 ", "14:10 동대문역사문화공원역 10번 출구"],
    1430: ['14:30 명동역 4번 출구', '14:30 명동역 4번 출구', '14:30 명동역 4번 출구'],
    1700: ["17:00 종로5가역 8번 출구", "17:00 종로5가역 8번 출구", "17:00 종로5가역 8번 출구"],
    9999: ['99:99 픽업 정보 없음', '99:99 픽업 정보 없음', '99:99 픽업 정보 없음'],
}

export const PLACE_PRIORITIES: { [key: string]: number } = {
    홍출: 0,
    명출: 1,
    동출: 2,
    홍: 0,
    명: 1,
    동: 2,
}

export const PLACE_PRIORITIES_IN_REVERSE: { [key: string]: number } = {
    동출: 0,
    명출: 1,
    홍출: 2,
    동: 0,
    명: 1,
    홍: 2,
}

export const IGNORE_DISPATCH = ['광장시장', '이태원클래스', '서울더버스커스', 'MBC 스튜디오', 'MBC 스튜디오(드라마 리허설)'];
export const IGNORE_ANNOUNCE = ['MBC 스튜디오', 'MBC 스튜디오(드라마 리허설)'];
export const TOUR_REVERSE = ["파주DMZ", "파주(땅굴)DMZ",'파주(캠프그리브즈)', "김포DMZ", "파주 DMZ", "파주(땅굴) DMZ", "김포 DMZ", "겹벚꽃", "블랙핑크"];
