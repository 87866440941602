import * as XLSX from "xlsx";
import {AgencyFileDatum} from "./types";

function cleanString(data?: any): string {
  return data?.toString().trim() || ''
}

function toNumber(numberLike?: string | number) {
  if (typeof numberLike === 'number') return numberLike;
  return Number(cleanString(numberLike) || 0);
}


export default async function parsePelago(file: Blob | File):Promise<AgencyFileDatum[]> {
  const parsed = await parseFileData(file);
  return parsed
    .map((raw) => {
    const agency = 'PG'
    const agencyCode = cleanString(raw['Booking ID']);
    const tour = cleanString(raw['Product Name']);
    const option = cleanString(raw['Product Option'])
    const date = formatDate(cleanString(raw['Activity Date']));
    const people = parseQuantity(raw['Units']);
    const price = toNumber(raw['Amount Payable']);

    return ({
      tour,
      option,
      date,
      agency,
      agencyCode,
      people,
      price,
      unitPrice:price/people,
    });
  })
}


const parseFileData = async (file: Blob | File) => {
  const binary = await file.arrayBuffer()
  const workbook = XLSX.read(binary, {type: "array", raw: true})
  const sheets = workbook.Sheets
  const sheetNames = workbook.SheetNames

  const firstSheet = sheets[sheetNames[0]]
  return XLSX.utils.sheet_to_json(firstSheet,{range:3}) as any[];
}


function formatDate(yyyymmmdd: string) {
  const yyyy = yyyymmmdd.slice(0, 4);
  const mm = yyyymmmdd.slice(4, 6);
  const dd = yyyymmmdd.slice(6, 8);
  return `${yyyy}-${mm}-${dd}`;
}

/**
 * "3 x Adult
 * 2 x Child
 * "
 * 1
 * Person (3 yrs~) 1
 * @param quantity
 */

function parseQuantity(quantity: string) {
  const lines = quantity.split(/\n?/gi);
  const numbers = lines.map(l => toNumber(/(\d+)/gi.exec(l)?.[1] ?? '0'));
  return numbers.reduce((a, b) => a + b);
}