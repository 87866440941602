import * as XLSX from "xlsx";
import {AgencyFileDatum} from "./types";

function cleanString(data?: any): string {
  return data?.toString().trim() || ''
}

function toNumber(numberLike?: string | number) {
  if (typeof numberLike === 'number') return numberLike;
  return Number(cleanString(numberLike) || 0);
}


export default async function parseBeMyGuestFile(file: Blob | File):Promise<AgencyFileDatum[]> {
  const parsed = await parseFileData(file);
  return parsed
    .filter(raw=>!!raw['Booking Code'])
    .map((raw) => {
    const agency = 'BMG'
    const agencyCode = cleanString(raw['Booking Code']);
    const tour = cleanString(raw['Product Name']);
    const option = '';
    const date = cleanString(raw['Travel Date']);
    const people = toNumber(raw['Total Pax #']);
    const price = toNumber(raw[' Revenue']);

    return ({
      tour,
      option,
      date,
      agency,
      agencyCode,
      people,
      price,
      unitPrice:price/people,
    });
  })
}


const parseFileData = async (file: Blob | File) => {
  const binary = await file.arrayBuffer()
  const workbook = XLSX.read(binary, {type: "array", raw: true})
  const sheets = workbook.Sheets
  const sheetNames = workbook.SheetNames

  const firstSheet = sheets[sheetNames[0]]
  return XLSX.utils.sheet_to_json(firstSheet,{range:1}) as any[];
}
