import Box from '@mui/material/Box';
import CircularProgress from "@mui/material/CircularProgress";


export default function Loading(){

    return(
        <Box
            sx={{
                minWidth:'100vw',
                minHeight:'100vh',
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}
        >
            <CircularProgress
                size={72}
            />
        </Box>
    )
}