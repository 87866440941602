import { Grid, Typography } from "@mui/material"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import { useNavigate, useParams } from "react-router-dom"
import GroupIcon from "@mui/icons-material/Group"
import { useContext, useEffect, useState } from "react"
import { IParticipant } from "../../../types/chat"
import { getDocData } from "../../../hooks/chat/firebase"
import { DrawerContext } from "../../../context/drawer"

export default function ChatHeader() {
    const [participants, setParticipants] = useState<IParticipant[]>([])
    const [category, setCategory] = useState<string | null>(null)
    const [title, setTitle] = useState<string>("")

    const navigate = useNavigate()
    const { chatId } = useParams() as { chatId: string }

    const { state: drawerState, dispatch: dispatchDrawer } = useContext(DrawerContext)

    useEffect(() => {
        async function getParticipants() {
            const { participants, title, category } = (await getDocData(["chats", chatId])) as any
            setParticipants(Object.values(participants))
            setTitle(title)
            setCategory(category)
        }

        getParticipants()
    }, [drawerState, chatId])
    return (
        <Grid
            container
            sx={{
                position: "sticky",
                top: 0,
                background: "#fff",
                zIndex: 1,
                py: 1.25,
                px: 2.5,
            }}
        >
            <Grid item xs={1} display="flex" alignItems="center">
                <ChevronLeftIcon
                    sx={{
                        width: "32px",
                        height: "32px",
                    }}
                    onClick={() => navigate("/chat/rooms")}
                />
            </Grid>
            <Grid
                item
                xs={10}
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Typography
                    variant="h6"
                    sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    {title}
                </Typography>
            </Grid>
            <Grid item xs={1} alignItems="center" display="flex" justifyContent="flex-end">
                <GroupIcon
                    sx={{
                        cursor: "pointer",
                    }}
                    onClick={() => {
                        dispatchDrawer({
                            type: "OPEN",
                            payload: {
                                view: "ADD_PARTICIPANT",
                                anchor: "right",
                                data: { participants: participants, category },
                            },
                        })
                    }}
                />
            </Grid>
        </Grid>
    )
}
