import React, {useState} from 'react';

import Box from '@mui/material/Box';

import {
  DataGridPro,
  GridToolbar,
  FilterColumnsArgs,
  GetColumnForNewFilterArgs,
  GridColDef, GridActionsCellItem,
} from '@mui/x-data-grid-pro';

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import UserModal from "../../partials/UserModal";
import {useAuth} from "../../hooks/auth";
import {useListen, useListenPropValueStartAt, useRead, useReadPropValueStartAt} from "../../hooks/realtime";
import {callFunction, removeRealtime, updateRealtime} from "../../hooks/firebase";
import {User} from '../../models/User';
import Icon from '@mui/material/Icon';
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOffOutlined";
import NoShowIcon from "@mui/icons-material/NoAccounts";
import NoShowOutlinedIcon from "@mui/icons-material/NoAccountsOutlined";
import TourIcon from "@mui/icons-material/Tour";
import TourOutlinedIcon from "@mui/icons-material/TourOutlined";
import DriverIcon from "@mui/icons-material/DriveEta";
import DriverOutlinedIcon from "@mui/icons-material/DriveEtaOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutline";
import AgentIcon from "@mui/icons-material/SupportAgent";
import AgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import {GridEventListener} from "@mui/x-data-grid/models/events";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Modal from '@mui/material/Modal';
import {Operation} from "../../models/Operation";
import {downloadObjectAsJson} from "../../utils/utils";

export default function () {
  const [tab, setTab] = useState<number>(0);
  const {user, auth} = useAuth();
  const [loadKey, setLoadKey] = useState<string>(Date.now() + '');
  const [modal, setModal] = useState<boolean>(false);
  const {
    data: users,
    setData,
    loading
  } = useReadPropValueStartAt<User>('user', 'level', (auth?.level ?? 999) - 1, loadKey);
  const [userId, setUserId] = useState<string | null>(null);


  const userList = Object.values(users ?? {})
      .filter(user => {
        return tab === 6
            ? user.waiting
            : tab === 1
                ? !user.waiting && user.area.toLowerCase() === 'seoul'
                : tab === 2
                    ? !user.waiting && user.area.toLowerCase() === 'busan'
                    : tab === 3
                        ? !user.waiting && user.area.toLowerCase() === 'tokyo'
                        : tab === 4
                            ? !user.waiting && user.on
                            : tab === 5
                                ? !user.waiting && !user.on
                                : true
      })
      .map((user) => ({
        id: user.id,
        on: user.on,
        area: user.area,
        operator: user.level <= 1.25,
        cs: user.level === 1.25,
        name: user.name,
        nameEn: user.nameEn,
        email: user.email,
        english: user.english,
        chinese: user.chinese,
        japanese: user.japanese,
        korean: user.korean,
        languages: user.languages,
        tel: user.tel,
        license: user.license,
        driver: user.driver,
        level: user.level,
      }))

  const handleChangeBuilder = (uid: string, prop: string) => (_: React.MouseEvent | React.ChangeEvent<HTMLInputElement>, value: any) => {
    const ref = `/user/${uid}`;
    setData((users) => {
      if (!users) return users;
      const updates = {[prop]: value};
      updateRealtime(ref, updates)
          .catch(console.error)
      return {...users, [uid]: {...users[uid], ...updates}};
    })
  }

  const guideColumns: GridColDef[] = [
    {
      field: 'area',
      headerName: 'Area',
      minWidth: 100,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return <Chip variant={'outlined'} color={
          params.row.area === 'Busan' ? 'secondary'
            : params.row.area === 'Tokyo' ? 'warning'
            : 'primary'
        }
                     label={params.row.area}/>
      }
    },
    {field: 'name', headerName: 'Name', minWidth: 100, align: 'center', headerAlign: 'center', editable: true,},
    {
      field: 'nameEn',
      headerName: 'Name(En)',
      minWidth: 100,
      align: 'center',
      headerAlign: 'center',
      editable: true,
    },
    {field: 'email', headerName: 'Email', minWidth: 300, align: 'center', headerAlign: 'center'},
    {field: 'tel', headerName: 'Tel', minWidth: 200, align: 'center', headerAlign: 'center'},
    {
      field: 'english', headerName: 'English', minWidth: 100, align: 'center', headerAlign: 'center',
      renderCell: (params) => {
        return params.row.english
            ? <Icon color={'primary'}><CheckCircleIcon/></Icon>
            : <Icon color={'disabled'}><CheckCircleOutlinedIcon/></Icon>
      }
    },
    {
      field: 'chinese', headerName: 'Chinese', minWidth: 100, align: 'center', headerAlign: 'center',
      renderCell: (params) => {
        return params.row.chinese
            ? <Icon color={'primary'}><CheckCircleIcon/></Icon>
            : <Icon color={'disabled'}><CheckCircleOutlinedIcon/></Icon>
      }
    },
    {
      field: 'korean', headerName: 'Korean', minWidth: 100, align: 'center', headerAlign: 'center',
      renderCell: (params) => {
        return params.row.korean
            ? <Icon color={'primary'}><CheckCircleIcon/></Icon>
            : <Icon color={'disabled'}><CheckCircleOutlinedIcon/></Icon>
      }
    },
    {
      field: 'japanese', headerName: 'Japanese', minWidth: 100, align: 'center', headerAlign: 'center',
      renderCell: (params) => {
        return params.row.japanese
            ? <Icon color={'primary'}><CheckCircleIcon/></Icon>
            : <Icon color={'disabled'}><CheckCircleOutlinedIcon/></Icon>
      }
    },
    {field: 'languages', headerName: 'Other Languages', minWidth: 200, align: 'center', headerAlign: 'center'},
    {
      field: 'license', headerName: 'Tourist Guide', minWidth: 100, align: 'center', headerAlign: 'center',
      renderCell: (params) => {
        return params.row.license
            ? <Icon color={'primary'}><TourIcon/></Icon>
            : <Icon color={'disabled'}><TourOutlinedIcon/></Icon>
      }
    },
    {
      field: 'driver',
      headerName: 'Driver',
      minWidth: 50,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return params.row.driver
            ? <Icon color={params.row.level === 2.5 ? 'error' : 'primary'}><DriverIcon/></Icon>
            : <Icon color={'disabled'}><DriverOutlinedIcon/></Icon>
      }
    },
    {
      field: 'on',
      type: 'actions',
      headerName: 'On',
      minWidth: 50,
      align: 'center',
      headerAlign: 'center',
      getActions: (params) => [
        <GridActionsCellItem
            icon={params.row.on ? <ToggleOnIcon color={'primary'} fontSize={'large'}/> :
                <ToggleOffIcon fontSize={'large'}/>}
            onClick={(e) => {
              handleChangeBuilder(params.row.id, 'on')(e, !params.row.on)
            }}
            label={'star'}
        />
      ]
    },

    {
      field: 'operator',
      headerName: 'Operator',
      minWidth: 100,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return params.row.operator
            ? <Icon color={params.row.cs ? 'error' : 'primary'}><AgentIcon/></Icon>
            : <Icon color={'disabled'}><AgentOutlinedIcon/></Icon>
      }
    },
  ];

  const handleClick: GridEventListener<'rowClick'> = (row, event, context) => {
    setUserId((row as unknown as User).id)
  }

  return (
      <>
        <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
        >
          <Stack
              flexDirection={'row'}
              justifyContent={'space-between'}
          >
            <Tabs value={tab} onChange={(_, tab) => setTab(tab)}>
              <Tab label={'All'}/>
              <Tab label={'Seoul'}/>
              <Tab label={'Busan'}/>
              <Tab label={'Tokyo'}/>
              <Tab label={'On'}/>
              <Tab label={'Off'}/>
              <Tab label={'Awaiting'}/>
            </Tabs>
          </Stack>
          <DataGridPro
              columns={guideColumns}
              rows={userList}
              onRowClick={handleClick}
          />
        </Box>
        {
          userId
              ? (
                  <UserModal
                      id={userId}
                      onClose={(update) => {
                        setUserId(null);
                        if (update) {
                          setLoadKey(Date.now() + '');
                        }
                      }}

                  />
              )
              : null
        }
        {
          modal
              ? <Modal open={modal}>
                <div/>
              </Modal>
              : null
        }
      </>
  )
}