import {
    query,
    orderBy,
    startAfter,
    startAt,
    endBefore,
    endAt,
    limit,
    limitToLast,
    getDocs,
    getCountFromServer,
    WhereFilterOp,
    where,
    Query as FirestoreQuery,
} from "firebase/firestore"
import {useState, useLayoutEffect, useEffect, MouseEvent} from "react"
import {
    collectionWithBaseFireStoreMultiPath,
    listenWhereFireStore,
    Query,
    queryFireStore,
    readFireStore,
    updateFireStore,
} from "../firebase"
import {add} from "@dnd-kit/utilities";

export function useDoc<S>(collection: string, id: string) {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState<S | null>(null)
    const [updated, setUpdated] = useState<S | null>(null)
    const update = (key: string, value: any) => {
        setUpdated({...(updated ?? {}), [key]: value} as S)
    }

    const updates = (values: { [key: string]: any }) => {
        setUpdated({...(updated ?? {}), ...values} as S)
    }
    const save = async (updates?: { [key: string]: any }) => {
        if (updates) {
            setData((data) => {
                if (!data) return data
                return {...data, ...updates}
            })
        }
        setLoading(true)
        await updateFireStore(collection, id, updates ? updates : updated)
        setLoading(false)
    }

    useEffect(() => {
        readFireStore<S>(collection, id)
            .then((s) => {
                setLoading(false)
                setData(s)
            })
            .catch((e) => console.error(e))
    }, [])

    return {
        data: updated ? {...data, ...updated} : data,
        loading,
        original: data,
        update,
        updates,
        save,
        setData,
    }
}

export function useQueryDocs<S>(collection: string, queries: Query[], key: string) {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [data, setData] = useState<S[]>([])
    useEffect(() => {
        setLoading(true)
        queryFireStore<S>(collection, queries)
            .then((s) => {
                setLoading(false)
                setData(s)
            })
            .catch((e) => {
                setError(e)
                console.error(e)
            })
    }, [key])

    return {
        data,
        loading,
        setData,
        error,
    }
}

export function useQueryListen<S>(collection: string, queries: Query[], key: string) {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState<Error | null>(null)
    const [data, setData] = useState<S[]>([])
    useEffect(() => {
        setLoading(true);
        const unsubscribe = listenWhereFireStore(
            collection,
            queries,
            (s) => {
                setLoading(false)
                const data = s.docs.map<S>((d) => d.data() as S)
                setData(data)
            },
            (e) => {
                setError(e)
                console.error(e)
            }
        )
        return () => {
            unsubscribe()
        }
    }, [key])

    return {
        data,
        loading,
        setData,
        error,
    }
}

// order 변경 , tourdate, createdat, updatedat
export function useQueryPagination<S>({
                                          paths,
                                          count = 10,
                                          queries = [],
                                          order,
                                          pause = true,
                                      }: {
    pause: boolean
    paths: string[]
    count: number
    queries: Query[]
    order: {
        field: string
        type: "asc" | "desc"
    }
}) {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState<S[]>([])
    const [docs, setDocs] = useState<any[]>([])
    const [page, setPage] = useState(0)
    const [total, setTotal] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(count)
    const wheres = queries
        .filter((q) => q.length === 3)
        .map(([property, operator, value]: any) => where(property, operator as WhereFilterOp, value))

    const additionalOrderBy =
        Array.from(new Set(queries.filter((q) => q[0] !== order.field).map(q => q[0])))
            .sort((a, b) => a === 'tour.productId' ? -1 : 0)
            .map((q) => orderBy(q))


    useEffect(() => {
        if (pause) return;
        (async () => {
            const ref = collectionWithBaseFireStoreMultiPath(paths)
            const q = query(ref, ...additionalOrderBy, orderBy(order.field, order.type), ...wheres, limit(rowsPerPage))
            const t = query(ref, ...additionalOrderBy, orderBy(order.field, order.type), ...wheres)
            await getData(q)

            const total = await getCountFromServer(t)
            setTotal(total.data().count)
            setPage(0)
        })().catch(console.error)
    }, [rowsPerPage, pause, JSON.stringify(order), JSON.stringify(queries)])

    async function nextPage(last: S | any) {
        const ref = collectionWithBaseFireStoreMultiPath(paths)
        const q = query(
            ref,
            ...additionalOrderBy,
            orderBy(order.field, order.type),
            startAfter(last),
            ...wheres,
            limit(rowsPerPage)
        )
        setPage((prev) => prev + 1)
        await getData(q)
    }

    async function prevPage(first: S | any) {
        const ref = collectionWithBaseFireStoreMultiPath(paths)
        const q = query(
            ref,
            ...additionalOrderBy,
            orderBy(order.field, order.type),
            endBefore(first),
            ...wheres,
            limitToLast(rowsPerPage)
        )
        setPage((prev) => {
            if (prev === 0) return prev
            return prev - 1
        })
        await getData(q)
    }

    async function getData(q: FirestoreQuery) {
        setLoading(true)
        const snapshot = await getDocs(q)
        const data = snapshot.docs.map((doc) => doc.data()) as S[]
        setDocs(snapshot.docs);
        setData(data)
        setLoading(false)
    }

    function handlePagination(event: MouseEvent<HTMLButtonElement, any> | null, newPage: number) {
        if (newPage > page) {
            nextPage(docs[data.length - 1])
        } else {
            prevPage(docs[0])
        }
    }

    function handleRowsPerPage(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setRowsPerPage(parseInt(event.target.value, 10))
    }

    return {
        loading,
        nextPage,
        prevPage,
        data,
        page,
        total,
        rowsPerPage,
        handlePagination,
        handleRowsPerPage,
    }
}
