import React, {useCallback, useEffect} from "react"
import {LicenseInfo} from "@mui/x-license-pro"

//router
import {createBrowserRouter, Navigate, Outlet, RouterProvider} from "react-router-dom"

// mui theme
import DefaultLayout from "./layouts/default"

//pages
import Main from "./pages/main"
import Reservation from "./pages/reservation2"
import Statics from "./pages/statics2"
import Statics3 from "./pages/aggregation"
import Operation from "./pages/operation2"
import Settings from "./pages/settings"
import Balance from "./pages/balance"
import Log from "./pages/log";
import ChatHome from "./pages/chat/Home"
import Chats from "./pages/chat/Chats"
import Chat from "./pages/chat/Chat"
import Monitor from "./pages/monitor"
import CS from "./pages/cs";
import Helper from "./pages/helper"
import Loading from "./pages/loading";

// css
import "./global.css"
import {catchMessage} from "./hooks/firebase"
import {closeSnackbar, useSnackbar} from "notistack"
import Button from "@mui/material/Button"
import {DrawerView} from "./context/drawer"
import {ChatContextProvider} from "./context/chat"
import {useAuth} from "./hooks/auth";
import Reconciliation from "./pages/reconciliation";


LicenseInfo.setLicenseKey(
    "317b392a3e4674c2f3007ab1cd66eb24Tz05MDE1OCxFPTE3NDcxMDQ5NjUwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
)

const superRouter = createBrowserRouter([
    {
        path: "/",
        element: <DefaultLayout/>,
        children: [
            {
                path: "/",
                element: <Main/>,
            },
            {
                path: "/reservation",
                element: <Reservation/>,
            },
            {
                path: "/statics",
                element: <Statics/>,
            },
            {
                path: "/aggregation",
                element: <Statics3/>,
            },
            {
                path: "/operation",
                element: <Operation/>,
            },
            {
                path: "/balance",
                element: <Balance/>,
            },
            {
                path: "/reconciliation",
                element: <Reconciliation/>,
            },
            {
                path: "/settings",
                element: <Settings/>,
            },
            {
                path: "/monitor",
                element: <Monitor/>,
            },
            {
                path: "/log",
                element: <Log/>,
            },
            {
                path: "/helper",
                element: <Helper/>,
            },
            {
                path: '/cs',
                element: <CS/>,
            }
        ],
    },
    {
        path: "/chat",
        element: (
            <>
                <Outlet/>
                <DrawerView/>
            </>
        ),
        children: [
            {
                path: "",
                element: <ChatHome/>,
            },
            {
                path: "rooms",
                element: <Chats/>,
            },
            {
                path: "rooms/:chatId",
                element: (
                    <ChatContextProvider>
                        <Chat/>
                    </ChatContextProvider>
                ),
            },
        ],
    },
    {
        path: '*',
        element: <Navigate to="/"/>
    },
])

const baseRouter = createBrowserRouter([
    {
        path: "/",
        element: <DefaultLayout/>,
        children: [
            {
                path: "/",
                element: <Main/>,
            },
            {
                path: "/reservation",
                element: <Reservation/>,
            },
            {
                path: "/statics",
                element: <Statics/>,
            },
            {
                path: "/aggregation",
                element: <Statics3/>,
            },
            {
                path: "/operation",
                element: <Operation/>,
            },
            {
                path: "/balance",
                element: <Balance/>,
            },
            {
                path: "/reconciliation",
                element: <Reconciliation/>,
            },
            {
                path: "/settings",
                element: <Settings/>,
            },
            {
                path: "/monitor",
                element: <Monitor/>,
            },
            {
                path: "/helper",
                element: <Helper/>,
            },
            {
                path: '/cs',
                element: <CS/>,
            }
        ],
    },
    {
        path: "/chat",
        element: (
            <>
                <Outlet/>
                <DrawerView/>
            </>
        ),
        children: [
            {
                path: "",
                element: <ChatHome/>,
            },
            {
                path: "rooms",
                element: <Chats/>,
            },
            {
                path: "rooms/:chatId",
                element: (
                    <ChatContextProvider>
                        <Chat/>
                    </ChatContextProvider>
                ),
            },
        ],
    },
    {
        path: '*',
        element: <Navigate to="/"/>
    },
])

const csRouter = createBrowserRouter([
    {
        path: "/",
        element: <DefaultLayout/>,
        children: [
            {
                path: "/",
                element: <Main/>,
            },
            {
                path: "/reservation",
                element: <Reservation/>,
            },
            {
                path: "/operation",
                element: <Operation/>,
            },
            {
                path: "/monitor",
                element: <Monitor/>,
            },
            {
                path: "/balance",
                element: <Balance/>,
            },
            {
                path: '/cs',
                element: <CS/>,
            }
        ],
    },
    {
        path: "/chat",
        element: (
            <>
                <Outlet/>
                <DrawerView/>
            </>
        ),
        children: [
            {
                path: "",
                element: <ChatHome/>,
            },
            {
                path: "rooms",
                element: <Chats/>,
            },
            {
                path: "rooms/:chatId",
                element: (
                    <ChatContextProvider>
                        <Chat/>
                    </ChatContextProvider>
                ),
            },
        ],
    },
    {
        path: '*',
        element: <Navigate to="/"/>
    }
])

const promotionRouter = createBrowserRouter([
    {
        path: '/',
        element: <CS/>,
    },
    {
        path: "/chat",
        element: (
            <>
                <Outlet/>
                <DrawerView/>
            </>
        ),
        children: [
            {
                path: "",
                element: <ChatHome/>,
            },
            {
                path: "rooms",
                element: <Chats/>,
            },
            {
                path: "rooms/:chatId",
                element: (
                    <ChatContextProvider>
                        <Chat/>
                    </ChatContextProvider>
                ),
            },
        ],
    },
    {
        path: '*',
        element: <Navigate to="/"/>
    }
])

const balanceRouter = createBrowserRouter([
    {
        path: '/',
        element: <DefaultLayout/>,
        children:[
            {
                path:'/',
                element: <Reconciliation/>,
            },
            {
                path: '/balance',
                element: <Balance/>,
            },
        ]

    },
    {
        path: "/chat",
        element: (
            <>
                <Outlet/>
                <DrawerView/>
            </>
        ),
        children: [
            {
                path: "",
                element: <ChatHome/>,
            },
            {
                path: "rooms",
                element: <Chats/>,
            },
            {
                path: "rooms/:chatId",
                element: (
                    <ChatContextProvider>
                        <Chat/>
                    </ChatContextProvider>
                ),
            },
        ],
    },
    {
        path: '*',
        element: <Navigate to="/"/>
    }
])

const noneRouter = createBrowserRouter([
    {
        path: '*',
        element: <Loading/>,
    },
])

const chatWindowName = "operator_chat"

function App() {

    const {user, auth} = useAuth()
    const isPromotion = user?.email?.includes('cs@ktourstory.com') || user?.email?.includes('support@ktourstory.com');
    const level = auth?.level ?? 99;
    const isLoading = level === 99;
    const isCs = level === 1.25;
    const isSuper = level < 1;
    const isBalance  = user?.email?.match(/ktsacc\d{1,2}@ktourstory\.com/);

    const {enqueueSnackbar} = useSnackbar()
    // background message navigate
    const chatNotificationClickHandler = useCallback(
        (event: any) => {
            if (event.data.action === "chat-notification-clicked") {
                window.focus()
                window.location.href = event.data.url
            }
        },
        [window]
    )
    useEffect(() => {
        if (window && window.navigator && navigator.serviceWorker) {
            window.navigator.serviceWorker.addEventListener("message", chatNotificationClickHandler)
        }
        return () => {
            if (window && window.navigator && navigator.serviceWorker) {
                window.navigator.serviceWorker.removeEventListener("message", chatNotificationClickHandler)
            }
        }
    }, [])

    // foreground message
    useEffect(() => {
        ;(async () => {
            const unsub = await catchMessage((message, chatId) => {
                return enqueueSnackbar(message, {
                    className: "message-snackbar",
                    action: (snackbarId) => {
                        return (
                            <>
                                <Button
                                    variant="text"
                                    onClick={(event: any) => {
                                        closeSnackbar(snackbarId)

                                        const chatWindow = window.open(
                                            `/chat/rooms/${chatId}`,
                                            chatWindowName,
                                            "width=375,height=700"
                                        )
                                        if (chatWindow) chatWindow.focus()
                                    }}
                                >
                                    Go to Chat
                                </Button>
                                <Button variant="text" color="error" onClick={() => closeSnackbar(snackbarId)}>
                                    Dismiss
                                </Button>
                            </>
                        )
                    },
                })
            })

            return async () => {
                if (unsub) await unsub()
            }
        })()
    }, [])

    return <RouterProvider router={
        isPromotion
            ? promotionRouter
            : isBalance
                ? balanceRouter
                : isCs
                    ? csRouter
                    : isSuper
                        ? superRouter
                        : isLoading
                            ? noneRouter
                            : baseRouter
    }/>
}


export default App
