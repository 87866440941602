import React from "react"
import {Box, Button} from "@mui/material"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import {file} from "googleapis/build/src/apis/file";
import {ButtonProps} from "@mui/material/Button";

function FileUploadButton({onChange, multiple, disabled, id, title, variant, startIcon}: React.InputHTMLAttributes<HTMLInputElement> & {id?:string, title?:string, variant?:ButtonProps['variant'], startIcon?: ButtonProps['startIcon']}) {
  const [fileName, setFileName] = React.useState("")
  const [fileNames, setFileNames] = React.useState<string[]>([])

  const handleFileChange = (event: any) => {
    if (!onChange) return
    if (event.target.files?.[0]) {
      const name = event.target.files.length > 1 ? [...event.target.files].map(({name}) => name).join(', ') : event.target.files[0].name
      setFileName(name)
      setFileNames([...event.target.files].map(({name}) => name))
      onChange(event)
    }
  }


  return (
    <Box
      style={{
        display: "flex",
      }}
    >
      <Button
        disabled={disabled}
        variant={variant ?? "contained"}
        component="label"
        tabIndex={-1}
        style={{
          minWidth: "max-content",
        }}
        startIcon={startIcon ?? <CloudUploadIcon/>}
      >
        {title ?? 'Upload'}
        <input
          onChange={handleFileChange}
          style={{
            clip: "rect(0 0 0 0)",
            clipPath: "inset(50%)",
            height: 1,
            overflow: "hidden",
            position: "absolute",
            bottom: 0,
            left: 0,
            whiteSpace: "nowrap",
            width: 1,
          }}
          accept=".xlsx,.csv"
          type="file"
          id={id ?? title ?? "file"}
          multiple={multiple}
        />
      </Button>
      <label
        htmlFor="file"
        style={{
          display: "flex",
          alignItems: "center",
          padding: "0 10px",
        }}
      >
        {fileNames.length > 1
          ? (fileNames[0].length > 8 ? fileNames[0].substring(0, 8) + "..." + fileNames[0].split(".").pop() : fileNames[0]) + `외 ${fileNames.length - 1}개`
          : fileName
            ? fileName.length > 8
              ? fileName.substring(0, 8) + "..." + fileName.split(".").pop()
              : fileName
            : "파일 업로드"}
      </label>
    </Box>
  )
}

export default FileUploadButton
