import MuiTabs from "@mui/material/Tabs"
import { PropsWithChildren, SyntheticEvent } from "react"

export default function Tabs({
    children,
    value,
    onChange,
}: PropsWithChildren & {
    value: number
    onChange: (event: SyntheticEvent, newValue: number) => void
}) {
    return (
        <MuiTabs
            value={value}
            onChange={onChange}
            TabIndicatorProps={{ style: { backgroundColor: "transparent" } }}
            sx={{
                "& .MuiTabs-flexContainer": {
                    justifyContent: "center",
                    fontSize: "14px",
                    fontWeight: 600,
                },
                "& .MuiTab-textColorPrimary": {
                    textTransform: "none",
                    color: "#E0E0E0",
                },
                "& .Mui-selected": {
                    color: "#2A2F37 !important",
                },
            }}
        >
            {children}
        </MuiTabs>
    )
}
