import React from 'react';

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Modal from "@mui/material/Modal";

import MailPreview from "./MailPreview";

import {Exception} from "../models/Exception";
import {logAction, removeRealtime} from "../hooks/firebase";
import {useListen} from "../hooks/realtime";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MailIcon from "@mui/icons-material/Mail";
import dayjs from "dayjs";

const HIGH = 0;
const MOD = 1;
const MID = 2;
const LOW = 3;
const CLASS_LABELS = ['오류', '주의', '확인', '일반']
const CLASS_COLORS: Array<'error' | 'warning' | 'info' | 'success'> = ['error', 'warning', 'info', 'success']

function checkClassLevel(type: string) {
    return type.includes('FAIL') || type.includes('ERROR') ? HIGH : type.includes('UNKNOWN') ? MOD : MID;
}

export default function (props: { exceptionId: string, onClose: () => void }) {
    const {exceptionId, onClose} = props;
    const {data: exception,save} = useListen<Exception>(`/exception/${exceptionId}`);
    // const handleDelete = () => {
    //     removeRealtime(`/exception/${exceptionId}`).catch((e) => console.error(e));
    //     onClose();
    // }

    const messageLink = `https://mail.google.com/mail/u/${exception?.reference?.emailAddress ? window.localStorage.getItem(exception?.reference?.emailAddress) ?? '0' : '0'}/#inbox/${exception?.reference?.messageId}`;

    const handleDelete = () => {
        // if (!(window?.confirm("예약을 취소하시겠습니까?"))) return;
        save({
            deletedDate: dayjs().format('YYYY-MM-DD'),
            deletedAt: dayjs().toDate().toString()
        })
            .then(() => {
                logAction('EXCEPTION', 'DELETE EXCEPTION', exceptionId,
                    `DELETE EXCEPTION${exceptionId}(${exception?.title}, ${exception?.description})`
                    , exception ?? {},
                    {exception: JSON.parse(JSON.stringify(exception ?? {}))}
                );
                onClose();
            })
            .catch(console.error)
    }

    return (
        <Modal
            open={!!exception}
            onClose={onClose}
        >
            <Box
                sx={(theme) => ({
                    width: '80vw',
                    maxHeight: '80vh',
                    position: 'relative' as 'relative',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                })}
            >

                <Box sx={{
                    position: 'fixed',
                    display: 'inline-flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    bottom: 0,
                    left: '50%',
                    transform: 'translate(-50%, 125%)',
                    zIndex: 999,
                    backgroundColor: 'white',
                    borderRadius: 5,
                    boxShadow: 2,
                    py: 1,
                    px: 2
                }}>

                    <IconButton
                        component={'a'}
                        href={messageLink}
                        target={"_blank"}
                    >
                        <MailIcon/>
                    </IconButton>
                    <IconButton onClick={handleDelete}>
                        <DeleteIcon/>
                    </IconButton>
                </Box>

                <Paper
                    sx={(theme) => ({
                        padding: '32px 24px',
                    })}
                >
                    <Box
                        sx={{
                            maxHeight: '80vh',
                            overflowY: 'auto',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 2,
                            }}
                        >
                            <Chip label={CLASS_LABELS[checkClassLevel(exception?.type ?? '')]}
                                  color={CLASS_COLORS[checkClassLevel(exception?.type ?? '')]}
                                  variant={'outlined'}/>

                            <Typography variant={'h4'}>
                                {exception?.title}
                            </Typography>
                        </Box>
                        {/*<Box my={2}>*/}
                        {/*    <Typography variant={'h6'}>*/}
                        {/*        {exception?.description}*/}
                        {/*    </Typography>*/}
                        {/*</Box>*/}
                        {
                            exception?.reference?.messageId
                                ? (
                                    <Box my={2}>
                                        <MailPreview messageId={exception.reference.messageId}/>
                                    </Box>
                                )
                                : null
                        }
                        <Divider/>
                        <Box my={2}>
                            <Typography variant={'caption'} sx={{whiteSpace: 'pre-wrap'}}>
                                {JSON.stringify(exception, null, 2)}
                            </Typography>
                        </Box>

                        <Box
                            sx={{
                                my: 2,
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center'
                            }}
                        >
                            <TextField
                                fullWidth
                                disabled
                                defaultValue={messageLink}
                            />
                        </Box>
                    </Box>
                </Paper>
            </Box>
        </Modal>
    )
}