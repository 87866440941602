import * as XLSX from "xlsx"

const REVERSE: string[] = ["외도", "통영", "에덴", "부산광양구례", "부산진해", "부산야간진해", "파주DMZ", "김포DMZ"]
const SKI_TOUR: string[] = ["엘리시안", "지산", "남이비발디", "남이엘리시안", "에덴"]
const TOUR_START: { [key: string]: number } = {
    서울광양구례: 6,
    서울진해: 6,
    알남: 7.5,
    태안딸기: 7.5,
    남설: 7.5,
    남쁘아: 8,
    레남쁘: 8,
    레남아: 8,
    쁘남레아: 8,
    설낙: 8,
    알파카: 8,
    휘남레: 8,
    알남레: 8,
    알남아: 8,
    레고레일: 8,
    레고남이: 8,
    서울벚꽃랜덤: 8,
    겹벚꽃: 8,
    BTS: 8,
    양평: 8,
    수원화성: 8,
    남쁘: 9,
    남아: 9,
    에버: 9,
    남셔: 9,
    포천딸기: 9,
    포천사과: 9,
    딸남아: 8,
    딸남레: 8,
    딸남레아: 8,
    파주DMZ: 7.2,
    김포DMZ: 8.01,
    철원DMZ: 8,
    태감송해: 9.2,
    부산벚꽃랜덤: 8.4,
    경주벚꽃: 8.4,
    경주: 10.1,
    외도: 8.2,
    통영: 9.01,
    요트: 9.2,
    부산진해: 8.5,
    부산광양구례: 9.01,
    부산야간진해: 13,
    감천미포: 9.02,
    시티미포: 9.02,
    PV: 8,
    레고티켓: 8,
}
const TOUR_START_WINTER: { [key: string]: number } = {
    남설: 7.5,
    남아: 12.5,
    남쁘아: 11,
    레남쁘: 8,
    레남아: 10,
    쁘남레아: 10,
    설낙: 8,
    알파카: 8,
    휘남레: 8,
    남쁘: 9,
    에버: 9,
    남셔: 9,
    포천딸기: 13.5,
    남이엘리시안: 8,
    엘리시안: 8,
    지산: 8,
    남이비발디: 7,
    딸남아: 11,
    딸남레: 8,
    딸남레아: 10,
    태감송해: 9.2,
    경주: 9.2,
    외도: 8.2,
    통영: 9.01,
    에덴: 8.4,
    부산광양구례: 9.01,
    서울광양구례: 6,
    PV: 8,
}
const PICKUP_TIME: { [key: number]: [string, string, string] } = {
    6: ["05:30", "06:00", "06:10"],
    7: ["06:30", "07:00", "07:10"],
    7.01: ["06:20", "07:00", "07:10"],
    7.2: ["07:10", "07:20", "08:00"],
    7.5: ["07:00", "07:30", "07:40"],
    8: ["07:20", "08:00", "08:10"],
    8.01: ["07:40", "08:00", "08:30"],
    9: ["08:20", "09:00", "09:10"],
    10: ["09:20", "10:00", "10:10"],
    11: ["10:20", "11:00", "11:10"],
    12.5: ["11:50", "12:30", "12:40"],
    13.5: ["12:50", "13:30", "13:40"],
    8.2: ["07:40", "08:20", "08:40"],
    8.4: ["08:20", "08:40", "09:20"],
    8.5: ["08:00", "08:50", "09:20"],
    9.01: ["08:20", "09:00", "09:20"],
    9.02: ["08:40", "09:00", "09:40"],
    9.2: ["09:00", "09:20", "10:00"],
    9.4: ["09:20", "09:40", "10:20"],
    10.1: ["09:50", "10:10", "10:50"],
    13: ["12:30", "13:00", "13:20"],
}

export const parseKintReservationFile = async (file: any) => {
    let parsedData = await parseFileData(file)
    const klook: any = {}
    const kkday: any = {}
    const trazy: any = {}
    const viator: any = {}
    const klookDuplicated: any = []
    const kkdayDuplicated: any = []
    const trazyDuplicated: any = []
    const viatorDuplicated: any = []
    parsedData.forEach((data) => {
        let bookingId = data["Agency Code"]?.toString().trim() || ""
        let agencyCode = data["Agency"]?.toString().trim() || ""
        let people = parseInt(data["People"])
        let lang = data["Language"]?.toString().trim() || ""
        let option = data["Option"]?.toString().trim() || ""
        let pickupPlace = data["Pickup"]?.toString().trim() || ""
        let date = data["Date"]?.toString().trim() || ""
        let stroller = data["stroller"]?.toString().trim() || ""
        let phone = data["Tel"]?.toString().trim() || ""

        let tour = data["Product"]?.trim() ?? data["Product"]

        let option_no = option.replace(/[^0-9]/gi, "")

        let pickup = ""

        if (option.includes("PV BIKE")) {
            option = "PV5000"
        } else if (option.includes("MONEY")) {
            option = "T-MONEY" + option_no
        } else if (option.includes("PAINTERS")) {
            option = "페인터즈" + option_no
        } else if (option.includes("SKI 강습 + LIFT (1 Time)")) {
            option = "SKI 강습 + LIFT (1 Time)" + option_no
        } else if (option.includes("SNOWBOARD 강습 + LIFT (1 Time)")) {
            option = "SNOWBOARD 강습 + LIFT (1 Time)" + option_no
        } else if (option.includes("강습 + MOVING + LIFT")) {
            option = "강습 + MOVING + LIFT" + option_no
        } else if (option.includes("강습 + MOVING")) {
            option = "강습 + MOVING" + option_no
        } else if (option.includes("강습 + LIFT")) {
            option = "강습 + LIFT" + option_no
        } else if (option.includes("강습")) {
            option = "강습" + option_no
        } else if (option.includes("썰매")) {
            option = "썰매" + option_no
        } else if (option.includes("관광")) {
            option = "관광" + option_no
        } else {
            option = ""
        }

        if (lang === "Chinese") {
            lang = "중국어"
        } else if (lang === "English") {
            lang = "영어"
        } else if (lang === "Korean") {
            lang = "한국어"
        } else if (lang === "Japanese") {
            lang = "일본어"
        } else if (lang.includes("Chinese") && lang.includes("English")) {
            lang = "중국어,영어"
        }

        if (stroller.includes("O")) {
            stroller = "유모차"
        }

        if (agencyCode === "L") {
            if (klook[bookingId]) {
                klookDuplicated.push(bookingId)
            }
            klook[bookingId] = { tour, date, pickupPlace, memo: "memo_original", people, extra: option, lang, stroller }
        } else if (agencyCode === "KK") {
            if (kkday[bookingId]) {
                kkdayDuplicated.push(bookingId)
            }
            kkday[bookingId] = { tour, date, pickupPlace, memo: "memo", people, extra: option, lang, stroller }
        } else if (agencyCode === "T") {
            if (trazy[bookingId]) {
                trazyDuplicated.push(bookingId)
            }
            trazy[bookingId] = { tour, people, pickup: pickupPlace, extra: option, lang, }
        } else if (agencyCode === "VI") {
            if (viator[bookingId]) {
                viatorDuplicated.push(bookingId)
            }
            viator[bookingId] = { tour, people, pickup: pickupPlace, extra: option, phone, lang }
        }
    })

    return {
        klook,
        kkday,
        viator,
        trazy,
        klookDuplicated,
        kkdayDuplicated,
        viatorDuplicated,
        trazyDuplicated,
    }
}
const parseFileData = async (file: any) => {
    const binary = await file.arrayBuffer()
    const workbook = XLSX.read(binary, { type: "array" })
    const sheets = workbook.Sheets
    const sheetNames = workbook.SheetNames

    const firstSheet = sheets[sheetNames[0]]
    const data = XLSX.utils.sheet_to_json(firstSheet) as any[]
    return data
}
