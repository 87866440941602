import {TempReservation, TempReservationCancel, TempReservationUpdate} from "./Reservation";


export const EXCEPTION_REALTIME_REF = 'exception';

export const enum EXCEPTION_CATEGORY {
    MAIL_EXCEPTION = 'MAIL_EXCEPTION',
    RESERVATION_EXCEPTION = 'RESERVATION_EXCEPTION',
    SYSTEM_EXCEPTION = 'SYSTEM_EXCEPTION',
    OTHER_EXCEPTION = 'OTHER_EXCEPTION'
}

export const enum MAIL_EXCEPTION {
    UNKNOWN_MAIL = 'UNKNOWN_MAIL',
    UNKNOWN_ADDRESS = 'UNKNOWN_ADDRESS',
    UNKNOWN_SUBJECT = 'UNKNOWN_SUBJECT',
    UNKNOWN_CONTENT_TYPE = 'UNKNOWN_CONTENT_TYPE',
    FAIL_HANDLE_MAIL = 'FAIL_HANDLE_MAIL',
    OTHER_MAIL = 'OTHER_MAIL',
    UPDATE_RESERVATION = 'UPDATE_RESERVATION',
    REVIEW_RESERVATION = 'REVIEW_RESERVATION',
    CANCEL_RESERVATION = 'CANCEL_RESERVATION',
    INQUIRY_RESERVATION = 'INQUIRY_RESERVATION',
    MANUAL_RESERVATION = 'MANUAL_RESERVATION'
}

export const enum RESERVATION_EXCEPTION {
    UNKNOWN_PRODUCT = 'UNKNOWN_PRODUCT',
    UNKNOWN_PICKUP = 'UNKNOWN_PICKUP',
    UNKNOWN_OPTION = 'UNKNOWN_OPTION',
    UNACCEPTABLE_RESERVATION = 'UNACCEPTABLE_RESERVATION',
    MANUAL_RESERVATION = 'MANUAL_RESERVATION',
    KKDAY = 'KKDAY',
    PRIVATE_TOUR = 'PRIVATE_TOUR',
    FAIL_HANDLE_RESERVATION = 'FAIL_HANDLE_RESERVATION',
}

export const enum SYSTEM_EXCEPTION {
    FAIL_HANDLE_MAIL = 'FAIL_HANDLE_MAIL',
    FAIL_HANDLE_RESERVATION = 'FAIL_HANDLE_RESERVATION',
    NETWORK_ERROR = 'NETWORK_ERROR',
    API_ERROR = 'API_ERROR',

}

export const enum OTHER_EXCEPTION {
    UNKNOWN_EXCEPTION = 'UNKNOWN_EXCEPTION',
    EXTERNAL_REPORTED_EXCEPTION = 'EXTERNAL_REPORTED_EXCEPTION'

}

export type Exception = {
    id: string,
    type: string,
    title: string,
    description: string,
    reference?: ExceptionReference,
    createdAt: string,
    deletedDate?:string // 'YYYY-MM-DD'
    deletedAt?:string // 'YYYY-MM-DD HH:mm:ss'
}
export type ExceptionReference =
    {
        error?: { name: string, message: string, stack?: string },
        reservation?: TempReservation | TempReservationUpdate | TempReservationCancel,
        messageId?: string,
        historyId?: string,
        emailAddress?: string,
        mail?: {
            subject: string,
            from: string,
        },
        other?: { [key: string]: any }
    }
