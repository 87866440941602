import {Modal, Paper, TableBody, TableCell, TableRow, TextField, Typography} from "@mui/material"
import {Table, TableContainer, TableHead} from "@mui/material"
import {Box} from "@mui/system"
import React, {useEffect} from "react"
import {
    ANNOUNCE_INFO_MESSAGE,
    DEPARTURES,
    PICKUP_TIMES,
    PICKUP_TIMES_WINTER,
    PLACE_PRIORITIES,
    TOURS,
    TOUR_REVERSE,
    IGNORE_ANNOUNCE,
    IGNORE_DISPATCH, PLACE_PRIORITIES_IN_REVERSE,
} from "./constants/bus"
import FileUploadButton from "./FileUploadButton"
import {convertDataFromExel} from "./utils"
import DeleteIcon from "@mui/icons-material/Delete"
import {Button} from "@mui/material"
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import {readRealtime} from "../../hooks/firebase";
import {User} from "../../models/User";
import {useRead} from "../../hooks/realtime";
import {Product} from "../../models/Product";
import Switch from "@mui/material/Switch";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";

export interface IBusExecl {
    Date: string
    "Reservation Date": number
    "Reservation Time": number
    ID: string
    Agency: string
    "Agency Code": string
    Area: string
    Guide?: string | string[]
    Product: string
    Team: number
    Pickup: string
    name: string
    Nationality: string
    Language: string
    People: number
    Adult: number
    Kid: number
    Infant: number
    stroller: string
    Email: string
    Tel: string
    Messenger: string
    Option?: string
}

export interface IBus {
    title: string
    productName: string
    vehicle: string,
    vehicleType: string,
    driver: string,
    guides: string[]
    pickupPlaces: string[]
    totalPeople: number
    team: number
}

const removeUnusedData = (data: IBusExecl[], conditions: { [key: string]: string }): IBusExecl[] => {
    return data.filter((d: { [key: string]: any }) => {
        return Object.entries(conditions).every(([key, value]: any) => d[key] !== value)
    })
}

const convertProductName = (data: IBusExecl[], productNameToChange: { [key: string]: string }) => {
    return data.map((d) => {
        const key = d["Product"].trim()
        return {
            ...d,
            Product: productNameToChange[key] ? productNameToChange[key] : key,
        }
    })
}

const splitedGuides = (data: IBusExecl[]) =>
    data.map((d) => ({...d, Guide: ((d["Guide"] as string | undefined) ?? "미정").split(",").map((d) => d.trim())}))

const groupBy = (data: any[], fields: string[]) => {
    return data.reduce((acc: any, cur) => {
        const key = fields.map((f) => cur[f]).join("-")
        if (!acc[key])
            acc[key] = fields.reduce(
                (obj: any, field: string) => {
                    return {
                        ...obj,
                        [field]: cur[field] as any,
                    }
                },
                {People: 0}
            )

        acc[key].People += cur["People"]
        return acc
    }, {})
}

function Bus() {
    const [dispatchText, setDispatchText] = React.useState<string>("")
    const [drivingDispatchText, setDirvingDispatchText] = React.useState<string>("")
    const [privateDispatchText, setPrivateDispatchText] = React.useState<string>("")
    const [announceText, setAnnounceText] = React.useState<string>("")
    const [products, setProducts] = React.useState<{ [key: string]: any[] }>({})
    const [date, setDate] = React.useState<string>('');
    const [winter, setWinter] = React.useState<boolean>(false)
    const [openModal, setOpenModal] = React.useState<boolean>(false)

    const {data: users} = useRead<{ [uid: string]: User }>('/user');
    const {data: tourProducts} = useRead<{ [pid: string]: Product }>('/product');

    const tourNameProductMap = new Map(Object.values(tourProducts ?? {}).map((p) => [p.name, {
        tour: p.chat?.name.ko ?? p.name,
        pickups: Object.values(p.chat?.pickup ?? {}).filter(a => a.use).sort((a, b) => {
            return (a.order ?? 0) - (b.order ?? 0);
        }).map(pickup => `${winter ? pickup.winterTime : pickup.time} ${pickup.ko}`),
    }]));


    const filteredUsers = Object.values(users ?? {}).filter((user) => user.on)
    const getUsers = (name: string) => filteredUsers.filter((user) => user.name === name);
    const getUserNames = (name: string) => {
        if (name === '미정') return '미정';
        const matched = getUsers(name);
        if (matched.length === 0) return name;
        return matched.map((user) => `${user.name}(${user.nameEn})`).join('/')
    };
    const getUserNameWithNumber = (name: string) => {
        if (name === '미정') return '미정';
        const matched = getUsers(name);
        if (matched.length === 0) return name;
        return matched.map((user) => `${user.name}(${user.tel})`).join('/');
    }
    const removeDuplicatedDataInArr = (arr: string[]) => {
        const set = new Set(arr)

        return Array.from(set)
    }
    const getPickupPlace = (productName: string, pickupPlaceEn: string) => {
        let result = "NONE"
        if (productName === "광장시장") result = "종로"
        if (productName === "서울더버스커스") result = "광화문"
        else {
            switch (pickupPlaceEn) {
                case "Hongdae": {
                    result = "홍"
                    break
                }
                case "Myungdong": {
                    result = "명"
                    break
                }
                case "Dongdaemoon": {
                    result = "동"
                    break
                }
            }
        }

        return result
    }

    const initialBusData = (data: IBusExecl[]) => {
        let parsedData = data
        parsedData = removeUnusedData(parsedData, {
            Area: "Busan",
        })

        parsedData = removeUnusedData(parsedData, {
            Area: "Tokyo",
        })


        parsedData = convertProductName(parsedData, {
            // 남이섬셔틀: "남셔",
            // "화구스(EG)": "화구스",
            "수원화성투어(EG)": "수원화성",
            "양평투어(EG)": "화구스(EG)",
        })

        parsedData = splitedGuides(parsedData)

        // const groupedByProdTeamGuidePickup = groupBy(parsedData, ["Product", "Team", "Guide", "Pickup"])
        const groupedByProdTeamGuide = groupBy(parsedData, ["Product", "Team", "Guide"])
        const productCounts = Object.values(groupedByProdTeamGuide).reduce(
            (acc: { [Product: string]: number }, cur: any) => {
                acc[cur["Product"]] = (acc[cur["Product"]] || 0) + 1
                return acc
            },
            {}
        )

        // const uniqueProducts = Object.keys(productCounts).filter((product) => productCounts[product] === 1)

        const products = Object.entries(productCounts).reduce(
            (
                acc: {
                    [productName: string]: IBus[]
                },
                [productName, count]
            ) => ({
                ...acc,
                [productName]: Array.from({length: count}, (_: unknown, team: number) => ({
                    productName,
                    title: tourNameProductMap.get(productName)?.tour ?? TOURS[productName],
                    vehicle: "",
                    vehicleType: '',
                    driver: '',
                    guides: [],
                    team: team + 1,
                    pickupPlaces: [],
                    totalPeople: 0,
                })),
            }),
            {}
        )

        const dates: string[] = [];
        parsedData.forEach((product) => {
            if (!dates.includes(product.Date)) dates.push(product.Date);
            const productName = product.Product
            const pickupPlaceEn = product.Pickup
            const team = product.Team - 1 // if team is 1 then array index is 0
            const guides = product.Guide
            const people = product.People


            const prevProductData = products[productName][team]
            const pickupPlace = getPickupPlace(productName, pickupPlaceEn)
            const totalPeople = prevProductData.totalPeople + people

            const productInfo = tourNameProductMap.get(productName);

            const [vehicle, vehicleType, driver] = IGNORE_DISPATCH.includes(productName) ? ['', '', '\n']
                : totalPeople <= 7 ? ["스타리아", '스타리아', '']
                    : totalPeople <= 15 ? ['카운티', '카운티', '기사님']
                        : ['45인승', '버스', '기사님']


            const pickupPlaces = removeDuplicatedDataInArr([...prevProductData.pickupPlaces, pickupPlace])
                .sort((a, b) => {
                    const findPickupIndex = (pickup: string): number => {
                        const idx = productInfo?.pickups.findIndex((pWithTime: string) => {
                            const p = pWithTime.split(' ')[1] ?? '';
                            if (pickup === '명') return p.startsWith(pickup) || p.startsWith('남')
                            return p.startsWith(pickup);
                        })

                        if (!idx) {
                            if (TOUR_REVERSE.includes(productName)) {
                                return PLACE_PRIORITIES_IN_REVERSE[pickup]
                            }
                            return PLACE_PRIORITIES[pickup]
                        }

                        return idx;
                    }
                    return findPickupIndex(a) - findPickupIndex(b);
                });


            products[productName][team] = {
                ...prevProductData,
                vehicle,
                vehicleType,
                driver,
                guides: removeDuplicatedDataInArr([...(guides as string[]), ...prevProductData.guides]),
                pickupPlaces: pickupPlaces, // 최신
                totalPeople,
            }
        })

        setProducts(products)
        setDate(dates[0] ?? '');
    }

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return

        const parsedData = await convertDataFromExel<IBusExecl>(event.target.files[0])
        initialBusData(parsedData)
    }

    const handleInputChange = async (event: React.ChangeEvent<HTMLInputElement>, productName: string, team: number) => {
        if (!event.target) return
        const targetProducts = products[productName]
        const teamIndex = targetProducts.findIndex((t: any) => {
            return t.team === team
        });
        const value = event.target.value

        switch (event.target.name) {
            case "guides": {
                targetProducts[teamIndex] = {
                    ...targetProducts[teamIndex],
                    guides: value.split(","),
                }
                break
            }
            case "pickup": {
                let pickupPlaces: string[] = [];
                switch (value) {
                    case '홍':
                        pickupPlaces = ['홍', '명', '동'];
                        break;
                    case '명':
                        pickupPlaces = ['명', '동'];
                        break;
                    case '동':
                        if (TOUR_REVERSE.includes(productName)) {
                            pickupPlaces = ['동', '명', '홍'];
                        } else {
                            pickupPlaces = ['동'];
                        }
                        break;
                    default:
                        pickupPlaces = [value]
                }
                targetProducts[teamIndex] = {
                    ...targetProducts[teamIndex],
                    pickupPlaces,
                }
                break
            }
            case "people": {
                const totalPeople = parseInt(value);
                const [vehicle, vehicleType, driver] = IGNORE_DISPATCH.includes(productName) ? ['', '', '\n']
                    : totalPeople <= 7 ? ["스타리아", '스타리아', '']
                        : totalPeople <= 15 ? ['카운티', '카운티', '기사님']
                            : ['45인승', '버스', '기사님'];

                targetProducts[teamIndex] = {
                    ...targetProducts[teamIndex],
                    totalPeople,
                    vehicle,
                    vehicleType,
                    driver
                }
                break;
            }

            case "vehicle": {
                const [vehicleType, driver] =
                    value === '스타리아' ? ['스타리아', '']
                        : value === '카운티' ? ['카운티', '기사님']
                            : value === '45인승' ? ['버스', '기사님']
                                : value === '쏠라티' ? ['쏠라티', '']
                                    : [value, '']

                targetProducts[teamIndex] = {
                    ...targetProducts[teamIndex],
                    vehicle: value,
                    vehicleType,
                    driver
                }
                break;
            }
        }

        setProducts((prev) => ({
            ...prev,
            [productName]: targetProducts,
        }))
    }

    const checkPickup = (tour: string, pickup: string, winter: boolean) => {
        const productInfo = tourNameProductMap.get(tour);
        const legacyProductInfo = checkPickupLegacy(tour, pickup, winter);
        // {key:'', pickups:[]}
        if (!productInfo || !productInfo.tour || productInfo.pickups.length < 1) return legacyProductInfo;
        const wholePickupList = productInfo.pickups.map(p => p.split(/\d{1,2}:\d{1,2}/).map(s => s.trim()).at(-1) ?? '')
        const matchedPickupIndex = wholePickupList.findIndex((p: string) => {
            if (pickup === '명') return p.startsWith(pickup) || p.startsWith('남')
            return p.startsWith(pickup);
        })
        if(tour==='보령머드') console.log(wholePickupList);
        if (matchedPickupIndex < 0) return legacyProductInfo;
        const matchedPickupList = productInfo.pickups.slice(matchedPickupIndex);
        const representativePickup = matchedPickupList.length < 3 ? productInfo.pickups[0] : productInfo.pickups[1];
        const key = representativePickup.split(' ')?.[0] ?? null

        if (!key) return legacyProductInfo;
        return {
            key,
            pickups: (matchedPickupList?.length > 0) ? matchedPickupList : ['99:99 픽업 정보 없음'],
            wholePickups: (!!productInfo.pickups && productInfo.pickups.length > 0) ? productInfo.pickups : ['99:99 픽업 정보 없음'],
        }
    }

    const checkPickupLegacy = (tour: string, pickup: string, winter: boolean) => {
        const key = winter ? PICKUP_TIMES_WINTER[tour] : PICKUP_TIMES[tour] ?? tour
        let pickups: string[] = ["99:99 픽업 정보 없음"]
        let wholePickups = DEPARTURES[key] && DEPARTURES[key].length > 0 ? DEPARTURES[key] : ['99:99 픽업 정보 없음']

        try {
            switch (pickup) {
                case "홍": {
                    pickups = DEPARTURES[key]
                    if (!winter && TOUR_REVERSE.includes(tour)) {
                        pickups = [DEPARTURES[key][2]]
                    }
                    break
                }
                case "명": {
                    pickups = [DEPARTURES[key][1], DEPARTURES[key][2]]
                    break
                }
                case "동": {
                    pickups = [DEPARTURES[key][2]]
                    if (!winter && TOUR_REVERSE.includes(tour)) {
                        pickups = DEPARTURES[key]
                    }
                    break
                }
                case "종로": {
                    pickups = [DEPARTURES[key][1]]
                    break
                }

                case "광화문": {
                    pickups = [DEPARTURES[key][1]]
                    break
                }

                default:
                    pickups = ["99:99 픽업 정보 없음"]
            }
        } catch (e) {
            pickups = ['99:99 픽업 정보 없음 - 에러']
        }

        return {
            key,
            pickups: pickups ? pickups : ["99:99 픽업 정보 없음"],
            wholePickups
        }
    }

    const parseAnnounceText = () => {
        // const timeFlags: { [key: string]: any[] } = Object.fromEntries(Object.keys(DEPARTURES).map((k) => [k, []]))
        const timeFlags: { [key: string]: any[] } = {};
        const sortedDataByProduct = Object.entries(products)
            .filter(([product]) => !IGNORE_ANNOUNCE.includes(product))
            .sort(([aProduct, aValue]: any, [bProduct, bValue]: any) => {
                const aTime = Number(checkPickup(aValue[0].productName, aValue[0].pickupPlaces[0], winter).pickups[0].split(' ')[0].replace(":", ""));
                const bTime = Number(checkPickup(bValue[0].productName, bValue[0].pickupPlaces[0], winter).pickups[0].split(' ')[0].replace(":", ""));
                return aTime - bTime
                // winter ? PICKUP_TIMES_WINTER[bValue[0].productName] : PICKUP_TIMES[bValue[0].productName]
                // return aTime < bTime ? -1 : 0;
            })

        sortedDataByProduct.forEach(([productName, datas]) => {
            datas.forEach((data) => {
                const pickupPlace = data.pickupPlaces[0]
                const {wholePickups, pickups} = checkPickup(productName, pickupPlace, winter)
                const flagKey = (wholePickups[1] ?? wholePickups[0] ?? '99:99').split(' ')[0];
                let driver = data.driver;
                let vehicleType = data.vehicleType;
                let pickup = data.pickupPlaces[0]

                if (!timeFlags[flagKey]) timeFlags[flagKey] = [];
                timeFlags[flagKey].push({
                    ...data,
                    vehicleType,
                    driver,
                    pickups,
                    wholePickups,
                })
            })
        })
        const DAY = ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'];
        const DATE = `${(new Date(date)).getMonth() + 1}월 ${(new Date(date).getDate())}일 ${DAY[new Date(date).getDay()]}`;
        const BLOCK = "--------------------------------\n"
        const sortedTimeFlags = Object.entries(timeFlags)
            .map<[string, any[]]>(([flag, datas]) => {
                return [flag, datas.filter(d => !d.productName.toLowerCase().startsWith('private') && !d.productName.toLowerCase().startsWith('vip'))]
            })
            .filter(([flag, datas]) => datas.length)
            .sort((a, b) => {
                return a[0] < b[0] ? -1 : 0;
            })


        const sortedByPickupTime = Object.values(timeFlags)
            .filter((data) => data.length)
            .flat()
            .sort((a: any, b: any) => {
                if (a.productName.toLowerCase().includes('private') || a.productName.toLowerCase().includes('vip')) {
                    return Number.POSITIVE_INFINITY;
                }
                const aPickTime = Number(a.pickups[0].split(" ")[0].replace(":", ""))
                const bPickTime = Number(b.pickups[0].split(" ")[0].replace(":", ""))
                return aPickTime - bPickTime
            })
            .map((d, idx, array) => {
                const grouped = array.filter((t) => t.productName === d.productName);
                const indexInAnnounce = grouped.findIndex(g => g.team === d.team)
                d.indexInAnnounce = indexInAnnounce;
                d.hasMore = grouped.length > 1;
                return d;
            })

        let prevTime = 0
        const productAnnouncement = sortedByPickupTime
            .map((data, idx, array) => {
                let result = ""
                const splited = data.pickups[0]?.split(" ") ?? ["", ""];
                const curTime = Number(splited[0].replace(":", ""))
                if (prevTime !== curTime) {
                    result += BLOCK
                    prevTime = curTime
                } else {
                    result += '\n'
                }
                result += `${data.productName}${data.hasMore ? data.indexInAnnounce + 1 : ''} - ${[`@${data.guides[0]}`, data.pickups[0], data.vehicleType, data.driver].filter(s => !!s).join(`\n`)}`
                return result;
            })
            .join('\n')

        const flagAnnouncement =
            sortedTimeFlags
                .map(([flag, datas]) => {
                    const time = flag ??
                        Math.floor(Number(flag) / 100)
                            .toString()
                            .padStart(2, "0") +
                        ":" +
                        (Math.floor(Number(flag)) % 100).toString().padStart(2, "0")
                    return (
                        `${time} flag\n` +
                        datas
                            .map((data: any, index: number) => {
                                return `${index + 1 + (time === '07:30' ? 20 : 0)}.${data.productName}${data.indexInAnnounce + 1} - ${getUserNames(data.guides[0])} - ${data.pickupPlaces[0]}`
                            })
                            .join("\n")
                    )
                })
                .join("\n\n")
        const result = `${productAnnouncement}\n${ANNOUNCE_INFO_MESSAGE}\n${flagAnnouncement}`
        setAnnounceText(`${DATE}\n` + result)
    }

    const parseDispatchText = () => {
        const sortedDataByProduct = Object.entries(products)
            .filter(([product]) => !IGNORE_DISPATCH.includes(product))
            .sort(([aProduct, aValue]: any, [bProduct, bValue]: any) => {
                const aTime = Number(checkPickup(aValue[0].productName, aValue[0].pickupPlaces[0], winter).wholePickups[0].split(' ')[0].replace(":", ""));
                const bTime = Number(checkPickup(bValue[0].productName, bValue[0].pickupPlaces[0], winter).wholePickups[0].split(' ')[0].replace(":", ""));
                const compare = aTime - bTime;
                if (compare === 0) return aValue.productName > bValue.productName ? 1 : 0
                return compare;
            })

        const makeText = (data: IBus, idx: number, unique: boolean) => {
            return `${idx + 1}. ${data.title}${unique ? "" : data.team} (${
                data.totalPeople + data.guides.length
            }명)\n${checkPickup(data.productName, data.pickupPlaces[0], winter).pickups?.join("\n") ?? ''}\n${data.vehicle}\n가이드: ${getUserNameWithNumber(data.guides[0])}`
        }

        let idx = 0
        const result = sortedDataByProduct
            .filter(([_, datas]) =>
                datas.filter((data) => !data.productName.toLowerCase().startsWith('private') && !data.productName.toLowerCase().startsWith('vip') && data.productName !== "광장시장" && data.vehicle !== '쏠라티' && data.vehicle !== '스타리아').length > 0
            )
            .map(([productName, datas]) => datas.map((data) => !data.productName.toLowerCase().startsWith('private') && !data.productName.toLowerCase().startsWith('vip') && data.productName !== "광장시장" && data.vehicle !== '쏠라티' && data.vehicle !== '스타리아' ? makeText(data, idx++, datas.length === 1) : ""))
            .flat(1)
            .filter((s) => !!s)
            .join("\n\n\n")


        const DAY = ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'];
        const DATE = `${(new Date(date)).getMonth() + 1}월 ${(new Date(date).getDate())}일 ${DAY[new Date(date).getDay()]}`;

        setDispatchText(`${DATE}\n\n` + result)
    }

    const parsePrivateDispatchText = () => {
        const sortedDataByProduct = Object.entries(products)
            .filter(([product]) => !IGNORE_DISPATCH.includes(product))
            .sort(([aProduct, aValue]: any, [bProduct, bValue]: any) => {

                const aTime = Number(checkPickup(aValue[0].productName, aValue[0].pickupPlaces[0], winter).wholePickups[0].split(' ')[0].replace(":", ""));
                const bTime = Number(checkPickup(bValue[0].productName, bValue[0].pickupPlaces[0], winter).wholePickups[0].split(' ')[0].replace(":", ""));
                const compare = aTime - bTime;
                if (compare === 0) return aValue.productName > bValue.productName ? 1 : 0
                return compare;

                // const aTime = Number(checkPickup(aValue[0].productName, aValue[0].pickupPlaces[0], winter).pickups[0].split(' ')[0].replace(":", ""));
                // const bTime = Number(checkPickup(bValue[0].productName, bValue[0].pickupPlaces[0], winter).pickups[0].split(' ')[0].replace(":", ""));
                // return aTime - bTime
            })

        const makeText = (data: IBus, idx: number, unique: boolean) => {
            return `${idx + 1}. ${data.title}${unique ? "" : data.team} (${data.totalPeople + data.guides.length}명)\n${data.vehicle}\n가이드: ${getUserNameWithNumber(data.guides[0])}`
        }

        let idx = 0
        const result = sortedDataByProduct
            .filter(([_, datas]) =>
                datas.filter((data) => data.productName.toLowerCase().startsWith('private') || data.productName.toLowerCase().startsWith('vip')).length > 0
            )
            .map(([productName, datas]) => datas.map((data) => data.productName.toLowerCase().startsWith('private') || data.productName.toLowerCase().startsWith('vip') ? makeText(data, idx++, datas.length === 1) : ""))
            .flat(1)
            .filter((s) => !!s)
            .join("\n\n\n")

        setPrivateDispatchText(result)
    }

    const parseDrivingDispatchText = () => {

        const sortedDataByProduct = Object.entries(products)
            .filter(([product]) => !IGNORE_DISPATCH.includes(product))
            .sort(([aProduct, aValue]: any, [bProduct, bValue]: any) => {
                const aTime = Number(checkPickup(aValue[0].productName, aValue[0].pickupPlaces[0], winter).wholePickups[0].split(' ')[0].replace(":", ""));
                const bTime = Number(checkPickup(bValue[0].productName, bValue[0].pickupPlaces[0], winter).wholePickups[0].split(' ')[0].replace(":", ""));
                const compare = aTime - bTime;
                if (compare === 0) return aValue.productName > bValue.productName ? 1 : 0
                return compare;
            })

        const makeText = (data: IBus, idx: number, unique: boolean) => {
            const bufferZero = (numb: number | string) => {
                return ('0' + numb).slice(-2);
            }
            const modifyHours = (pickup: string) => {
                const splited = pickup.split(' ');
                if (splited.length < 2) return pickup;
                const [hours, min] = splited.shift()!.split(':')
                const time = new Date()
                const parseHour = Number(min) < 15 ? Number(hours) - 1 : Number(hours);
                const parseMin = Number(min) < 15 ? 60 - (15 - Number(min)) : Number(min) - 15;
                time.setHours(parseHour, parseMin);
                if (hours === '99') return `${pickup}`;
                return `${bufferZero(time.getHours())}:${bufferZero(time.getMinutes())} ${splited.join(' ')}`
            }

            return `${idx + 1}. ${data.title}${unique ? "" : data.team} (${data.totalPeople + data.guides.length
            }명)\n${checkPickup(data.productName, data.pickupPlaces[0], winter).pickups.map(modifyHours)?.join("\n") ?? ''}\n${data.vehicle}\n가이드: ${getUserNameWithNumber(data.guides[0])}`
        }

        let idx = 0
        const result = sortedDataByProduct
            .filter(([_, datas]) =>
                datas.filter((data) => (data.productName !== '광장시장' && !data.productName.toLowerCase().startsWith('private') && !data.productName.toLowerCase().startsWith('vip')) && (data.vehicle === '스타리아' || data.vehicle === '쏠라티')).length > 0
            )
            .map(([productName, datas]) => datas.map((data) => (data.productName !== '광장시장' && !data.productName.toLowerCase().startsWith('private') && !data.productName.toLowerCase().startsWith('vip')) && (data.vehicle === '스타리아' || data.vehicle === '쏠라티') ? makeText(data, idx++, datas.length === 1) : ""))
            .flat(1)
            .filter((s) => !!s)
            .join("\n\n\n")

        setDirvingDispatchText(result)
    }


    useEffect(() => {
        if (!Object.keys(products).length) return
        // const unknowns = Object.keys(products).filter((p) => !PICKUP_TIMES[p])
        // if (unknowns.length > 0) {
        //     alert(`${unknowns.join(", ")}의 정보를 확인할 수 없습니다.`)
        //     return
        // }인
        parseDispatchText()
        parseDrivingDispatchText()
        parsePrivateDispatchText()
        parseAnnounceText()
    }, [products])


    return (
        <Box display="flex" flexDirection="column" gap={4}>
            <FileUploadButton onChange={handleFileChange}/>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TableContainer component={Paper}>
                        <FormControlLabel
                            sx={{m: 2}}
                            control={
                                <Switch checked={winter} onChange={(_, value) => {
                                    setWinter(value)
                                }} name="winter"/>
                            }
                            label="Winter"
                        />
                        <Button variant="contained" sx={{float: "right", margin: 1.5}} onClick={() => setOpenModal(true)}>
                            Add
                        </Button>
                        <Table sx={{width: "100%"}} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">No</TableCell>
                                    <TableCell align="center">Product Name</TableCell>
                                    <TableCell align="center">Team</TableCell>
                                    <TableCell align="center">Guide</TableCell>
                                    <TableCell align="center">Pickup Place</TableCell>
                                    <TableCell align="center">People</TableCell>
                                    <TableCell align="center">Vehicle</TableCell>
                                    <TableCell align="center">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.values(products)
                                    .flat()
                                    .sort((aValue, bValue) => {
                                        const aTime = Number(checkPickup(aValue.productName, aValue.pickupPlaces[0], winter).wholePickups[0].split(' ')[0].replace(":", ""));
                                        const bTime = Number(checkPickup(bValue.productName, bValue.pickupPlaces[0], winter).wholePickups[0].split(' ')[0].replace(":", ""));
                                        const compare = aTime - bTime;
                                        if (compare === 0) return aValue.productName > bValue.productName ? 1 : 0
                                        return compare;
                                    })
                                    .map(({productName, team, guides, pickupPlaces, totalPeople, vehicle,}, index) => {
                                        // const product = products[productName][team - 1]
                                        // const _team = team
                                        // const _guides = guides
                                        // const _pickupPlaces = product?.pickupPlaces ?? pickupPlaces
                                        // const _totalPeople = product?.totalPeople ?? totalPeople
                                        // const _vehicle = product?.vehicle ?? ''
                                        return (
                                            <TableRow
                                                sx={{
                                                    "&:last-child td, &:last-child th": {border: 0},
                                                }}
                                            >
                                                <TableCell component="th" scope="row" align="center">
                                                    {index + 1}
                                                </TableCell>
                                                <TableCell
                                                    align="center">
                                                    {productName}({checkPickup(productName, pickupPlaces[0], winter).wholePickups[0].split(' ')[0]})
                                                </TableCell>
                                                <TableCell align="center">{team}</TableCell>
                                                <TableCell>
                                                    <TextField
                                                        fullWidth
                                                        name="guides"
                                                        value={guides?.join(",") ?? ''}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                            handleInputChange(e, productName, team)
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        fullWidth
                                                        select
                                                        name="pickup"
                                                        value={pickupPlaces[0]}
                                                        SelectProps={{
                                                            native: true,
                                                        }}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                            handleInputChange(e, productName, team)
                                                        }
                                                    >
                                                        {['홍', '명', '동', '종로', '광화문'].map(p=>(<option key={p} value={p}>{p}</option>))}
                                                    </TextField>
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        fullWidth
                                                        name="people"
                                                        type="number"
                                                        value={totalPeople}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                            handleInputChange(e, productName, team)
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell
                                                    sx={{
                                                        minWidth:'120px'
                                                    }}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        select
                                                        name="vehicle"
                                                        type="text"
                                                        SelectProps={{
                                                            native:true
                                                        }}
                                                        value={vehicle}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                            handleInputChange(e, productName, team)
                                                        }
                                                    >
                                                        {['45인승', '카운티', '쏠라티', '스타리아'].map(p=>(<option key={p} value={p}>{p}</option>))}
                                                    </TextField>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <DeleteIcon
                                                        onClick={() => {
                                                            setProducts((prev) => {
                                                                const newProducts = {...prev}
                                                                newProducts[productName] = newProducts[productName].filter(
                                                                    (p, i) => p.team !== team
                                                                )
                                                                if (!newProducts[productName].length)
                                                                    delete newProducts[productName]
                                                                return newProducts
                                                            })
                                                        }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={3}>
                    <Stack direction={'column'}>

                        <Box flex="1">
                            <Typography variant="h6">배차용 문구</Typography>

                            <TextField
                                id="filled-multiline-flexible"
                                label="Multiline"
                                multiline
                                rows={30}
                                fullWidth
                                variant="filled"
                                value={dispatchText}
                            />
                        </Box>

                        <Box flex="1">
                            <Typography variant="h6">Driving Tour 문구</Typography>

                            <TextField
                                id="filled-multiline-flexible"
                                label="Multiline"
                                multiline
                                rows={30}
                                fullWidth
                                variant="filled"
                                value={drivingDispatchText}
                            />
                        </Box>

                    </Stack>
                </Grid>
                <Grid item xs={3}>
                    <Stack direction={'column'}>
                        <Box flex="1">
                            <Typography variant="h6">공지용 문구</Typography>
                            <TextField
                                id="filled-multiline-flexible"
                                label="Multiline"
                                multiline
                                rows={30}
                                fullWidth
                                variant="filled"
                                value={announceText}
                            />
                        </Box>
                        <Box flex="1">
                            <Typography variant="h6">Private 문구</Typography>
                            <TextField
                                id="filled-multiline-flexible"
                                label="Multiline"
                                multiline
                                rows={30}
                                fullWidth
                                variant="filled"
                                value={privateDispatchText}
                            />
                        </Box>
                    </Stack>
                </Grid>
            </Grid>
            <Modal open={openModal} onClose={() => setOpenModal(false)}>
                <Box
                    sx={{
                        position: "absolute" as "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 800,
                        bgcolor: "background.paper",
                        border: "2px solid #000",
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Add Tour
                    </Typography>
                    <form
                        onSubmit={(e: any) => {
                            e.preventDefault()
                            const form = e.target
                            const title = form.title.value
                            const productName = form.productName.value
                            const guides = form.guides.value.split(",")
                            const pickupPlaces = form.pickupPlaces.value.split(",")
                            const team = Number(form.team.value)
                            const totalPeople = Number(form.totalPeople.value)
                            const vehicle = form.vehicle.value

                            if (!Object.keys(products).length) {
                                alert("파일을 먼저 업로드해주세요")
                            } else if (!Object.keys(PICKUP_TIMES).includes(e.target.productName.value)) {
                                alert("지원하지 않는 투어입니다.")
                            } else if (products[productName]?.find((p: any) => p.team === team)) {
                                alert("이미 존재하는 팀입니다.")
                            } else if (
                                !title ||
                                !productName ||
                                guides.length === 0 ||
                                pickupPlaces.length === 0 ||
                                !vehicle
                            ) {
                                alert("모든 항목을 입력해주세요")
                            } else if (team < 1 || totalPeople < 0) {
                                alert("팀과 인원은 1 이상이어야 합니다.")
                            } else {
                                const data = {
                                    title,
                                    productName,
                                    guides,
                                    pickupPlaces,
                                    team,
                                    totalPeople,
                                    vehicle,
                                }

                                if (!products[productName]) {
                                    setProducts((prev) => ({
                                        ...prev,
                                        [productName]: [data],
                                    }))
                                } else {
                                    setProducts((prev) => ({
                                        ...prev,
                                        [productName]: [...prev[productName], data],
                                    }))
                                }
                            }

                            setOpenModal(false)
                        }}
                        style={{
                            marginTop: "12px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "24px",
                        }}
                    >
                        <TextField fullWidth name="title" label="프로덕트" placeholder="남이섬 + 쁘띠프랑스"/>
                        <TextField fullWidth name="productName" label="프로덕트 별칭" placeholder="남쁘"/>
                        <TextField fullWidth name="guides" label="가이드(구분자: ',')" placeholder="홍길동,홍길동"/>
                        <TextField
                            fullWidth
                            name="pickupPlaces"
                            label="픽업 장소(구분자: ',')"
                            placeholder="홍출,명출,동출"
                        />
                        <TextField fullWidth name="team" label="팀" type="number" placeholder="1" defaultValue={1}/>
                        <TextField
                            fullWidth
                            name="totalPeople"
                            label="인원"
                            type="number"
                            placeholder="0"
                            defaultValue={0}
                        />
                        <TextField fullWidth name="vehicle" label="차량" placeholder="카운티"/>
                        <Button variant="contained" type="submit">
                            추가
                        </Button>
                    </form>
                </Box>
            </Modal>
        </Box>
    )
}

export default Bus

const NUMBERS: { [name: string]: string } = {
    강주영: "01082074779",
    강진혁: "01049334195",
    고예형: "01080829417",
    권윤희: "01083883608",
    김락형: "01048220628",
    김리희: "01085282758",
    김민준: "01099475188",
    김수연: "01072979594",
    김장원: "01099829505",
    김진형: "01050493911",
    김한나: "01039069607",
    김홍범: "01022490705",
    남다은: "01055516507",
    레이첼: "01034971006",
    레지나: "01071887935",
    박지순: "01032845466",
    박호영: "01057721861",
    설지예: "01063009336",
    셰리: "01034493991",
    소시아: "01034919606",
    신천선: "01053732046",
    안대영: "01038368104",
    안서하: "01030329578",
    유종호: "01091668001",
    유해영: "01066822309",
    이미라: "01039762578",
    이민희: "01030760229",
    이상하: "01049630773",
    이영훈: "01099469596",
    이인수: "01046111708",
    이지강: "01099168101",
    이지영: "01072805596",
    이현장: "01097066660",
    잘래: "01030659596",
    정규창: "01092819670",
    정대훈: "01042110297",
    조국: "01098328818",
    조한진: "01051451855",
    최신영: "01098940430",
    하윤주: "01054180862",
    한빙: "01024359529",
    한수호: "01082338830",
    황세호: "01020453309",
    맹승연: "01025722396",
    오금상: "01028111935",
    장유미: "01092331111",
    박지호: "01025260901",
    김보미: "01041573725",
    양수영: "01020736569",
    이창석: "01022787383",
    김민정J: "01046453020",
    김민정H: "01036044626",
    김희영: "01074340348",
    장순주: "01055293321",
    케빈: "01050891740",
    최유선: "01099345440",
    오선화: "01094099273",
    강승모: "01051476678",
    김수빈: "01068543691",
    정인환: "01029527242",
    문지원: "01056601664",
    이국형: "01043995385",
    김미정: "01053401465",
    안희환: "01053403328",
    진현주: "01056126768",
    김민규: "01058240441",
    김병곤: "01057599931",
    김상호: "01092881133",
    곽전호: "01079346589",
    조철: "01086436494",
    김송이: "01077930923",
    변상혁: "01056145186",
    임자혜: "01066615764",
    박정원: "01086539629",
    반월명: "01042252014",
    김태준: "01023459538",
    양용수: "01062249771",
    김세명: "01055688458",
    정나연: "01056486720",
    이미령: "01073770250",
    김민수: "01026488048",
    장원영: "01098671309",
    이은지: "01083114702",
    김대희: "01083657575",
    오태균: "01084494251",
    화시어: "01031055945",
    앨리스: "01082861967",
    이은상: "01094677567",
    성동현: "01037036503",
    차수림: "01064004145",
    김상은: "01087734924",
    이규희: "01079345120",
    오관엽: "01095365023",
    김욱중: "01026481080",
    정혜민: "01059153425",
    헤이시: "01025090827",
    엘사: "01028400881",
    써니: "01083870560",
    정종갑: "01086896465",
    전용민: "01095930978",
    이선엽: "01071018246",
    주체리: "01042954562",
    강규림: "01097225638",
    문수명: "01053727093",
    유영희: "01096533588",
    곽예나: "01097821491",
    정문건: "01089799989",
    진신혜: "01023065722",
    이소영: "01027544572",
    오하연: "01099837743",
    권시연: "01054738835",
    김기리: "01064000120",
    한결: "01073470725",
    김정용: "01053089106",
    김승주: "01047290959",
    임수아: "01098139537",
    이정인: "01067685162",
    이현구: "01072280802",
    박주은: "01075438898",
    엄재찬: "01025822748",
    김재봉: "01046289103",
    이한빛: "01072310140",
    허연재: "01031803557",
    포가함: "01068799691",
    김향자: "01089640757",
    이창준: "01053260928",
    최련희: "01083911033",
    진정한: "01066800803",
    풍초요: "01022385401",
    이준호: "01076673675",
    이녹이: "01066495881",
    백주환: "01082891247",
    조준범: "01033093786",
    진은하: "01055499482",
    한연화: "01093096133",
    켈리: "01021864963",
    이지원: "01026534807",
    이상혁: "01044258145",
    신호영: "01075311016",
    이성재: "01096972254",
    변부길: "01083099209",
    임선희: "01031414308",
    한선미: "01086287006",
    왕유이: "01051070458",
    이서윤: "01085748997",
    김익환: "01027290702",
    주경의: "01096709602",
    이후관: "01024607413",
    정수정: "01073589214",
    강다은: "01095068933",
    김성: "01076394135",
    손현지: "01076414121",
    김주현: "01089230792",
    김태영: "01083608998",
    김서현: "01029943738",
    오영희: "01053219801",
    권호중: "01055706878",
    신영준: "01029220843",
    최우행: "01092755506",
    장준: "01037979278",
    류호상: "01025087665",
    윤진용: "01098389144",
    권선희: "01027881104",
    강남민: "01094709836",
    정화연: "01056624423",
    김지훈: "01027769337",
    전하연: "01031787052",
    윤미숙: "01042258629",
    김광혁: "01051379887",
    윤의준: "01085344385",
    김세련: "01037210068",
    한병옥: '01021236165',
    박문령: '01066878815',
    김종국: '01045735278',
    이병석: '01039225198',
    허정화: '01038524900',
    이재원: '01042487545'
}