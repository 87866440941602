import {
    Box,
    Card,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from "@mui/material"
import { IChatMeta } from "../../types/chat"
import { lazy, SetStateAction, Dispatch, useState } from "react"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import { SortField, SortOrder } from "../../pages/monitor"
import { useQueryPagination } from "../../hooks/firestore"
import { Query } from "../../hooks/firebase"

interface Props {
    visible: boolean
    queries: Query[]
    order: { field: SortField; type: SortOrder }
    setOrder: Dispatch<SetStateAction<{ field: SortField; type: SortOrder }>>
    setQueries: Dispatch<SetStateAction<Query[]>>
}

const ChatDetailRow = lazy(() => import("./ChatDetailRow"))

function ChatTable({ visible = false, queries, order, setOrder, setQueries }: Props) {
    const {
        data: chats,
        loading,
        total,
        page,
        rowsPerPage,
        handlePagination,
        handleRowsPerPage,
    } = useQueryPagination<IChatMeta>({
        pause: !visible,
        paths: ["chats"],
        count: 25,
        queries,
        order,
    })

    function handleSort(field: SortField) {
        setOrder((prev) => ({
            field,
            type: prev.type === "asc" ? "desc" : "asc",
        }))
        if (field === "date") {
            setQueries([["category", "in", ["CLIENT", "TEAM", "TOUR"]]])
        }
    }


    console.log(chats.map((c)=>c.id).join('|'))

    if (loading)
        return (
            <CircularProgress
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                }}
            />
        )
    return (
        <TableContainer component={Card}>
            <Table aria-label="collapsibnle table">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell align="center">Category</TableCell>
                        <TableCell align="center">Title</TableCell>
                        <TableCell align="center">Guide</TableCell>
                        <TableCell align="center">
                            <Box
                                sx={{
                                    width: "fit-content",
                                    margin: "0 auto",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                Participants(참여, 메일확인 / 전체)
                            </Box>
                        </TableCell>
                        <TableCell>
                            메세지 수
                        </TableCell>
                        <TableCell>
                            활성 정도
                        </TableCell>
                        <TableCell align="center">
                            <Box
                                onClick={() => handleSort("date")}
                                sx={{
                                    width: "fit-content",
                                    margin: "0 auto",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                Tour Date
                                <ArrowUpwardIcon
                                    sx={{
                                        transform: `rotate(${
                                            order.field === "date" && order.type === "desc" ? 180 : 0
                                        }deg)`,
                                        transition: "transform 0.2s ease-in-out",
                                    }}
                                />
                            </Box>
                        </TableCell>
                        <TableCell align="center">
                            <Box
                                onClick={() => handleSort("createdAt")}
                                sx={{
                                    width: "fit-content",
                                    margin: "0 auto",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                CreatedAt
                                <ArrowUpwardIcon
                                    sx={{
                                        transform: `rotate(${
                                            order.field === "createdAt" && order.type === "desc" ? 180 : 0
                                        }deg)`,
                                        transition: "transform 0.2s ease-in-out",
                                    }}
                                />
                            </Box>
                        </TableCell>
                        <TableCell align="center">
                            <Box
                                onClick={() => handleSort("updatedAt")}
                                sx={{
                                    width: "fit-content",
                                    margin: "0 auto",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                UpdatedAt
                                <ArrowUpwardIcon
                                    sx={{
                                        transform: `rotate(${
                                            order.field === "updatedAt" && order.type === "desc" ? 180 : 0
                                        }deg)`,
                                        transition: "transform 0.2s ease-in-out",
                                    }}
                                />
                            </Box>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {chats.map((row) => (
                        <ChatDetailRow key={row.id} chatMeta={row} />
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                component="div"
                count={total}
                page={page}
                onPageChange={handlePagination}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleRowsPerPage}
            />
        </TableContainer>
    )
}

export default ChatTable
