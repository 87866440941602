// @flow
import * as React from "react"
import { Box, styled } from "@mui/material"
import InputBase from "@mui/material/InputBase"
import SearchIcon from "@mui/icons-material/Search"
import { KeyboardEvent, useState } from "react"
import CancelIcon from "@mui/icons-material/Cancel"

type Props = {
    handleSearch: (name: string) => void
}

export function UserSearch({ handleSearch }: Props) {
    const [username, setUsername] = useState("")

    async function handleKeyDown(event: KeyboardEvent<HTMLInputElement>) {
        ;(event.code === "Enter" || event.keyCode === 13) && (await handleSearch(username))
    }
    return (
        <SearchContainer>
            <SearchIcon
                onClick={() => handleSearch(username)}
                sx={{
                    color: "#4F4F4F",
                    width: "24px",
                    height: "24px",
                }}
            />
            <InputBase
                sx={{
                    flex: 1,
                    marginLeft: "2px",
                    fontSize: "16px",
                }}
                placeholder="Search Operator"
                inputProps={{
                    "aria-label": "search",
                    sx: {
                        "&::placeholder": {
                            color: "#828282",
                        },
                    },
                }}
                onKeyDown={handleKeyDown}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
            />
            {username && (
                <CancelIcon
                    sx={{
                        fill: "#BDBDBD",
                        width: "24px",
                        height: "24px",
                    }}
                    onClick={() => setUsername("")}
                />
            )}
        </SearchContainer>
    )
}

const SearchContainer = styled(Box)`
    display: flex;
    align-items: center;

    padding: 10px 20px;

    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);

    border-radius: 40px;

    max-height: 44px;
`
