import { Modal } from "@mui/material"

interface Props {
    open: boolean
    onClose: () => void
    fileUrl: string
}
export default function ImagePreviewer({ open, fileUrl, onClose }: Props) {
    return (
        <Modal
            open={open}
            onClose={onClose}
            sx={{
                background: "rgba(0, 0, 0, 0.6)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <img
                style={{
                    maxHeight: "80%",
                    maxWidth: "80%",
                    objectFit: "contain",
                }}
                src={fileUrl}
                alt=""
            />
        </Modal>
    )
}
