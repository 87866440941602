import {useState, useLayoutEffect, useEffect} from 'react';
import {
    listenRealtime,
    listenWithPropValue,
    listenWithPropValueStartAt,
    readRealtime,
    readWithPropValueStartAt,
    listenWithPropValueEndAt,
    updateFireStore,
    updateRealtime,
    readWithPropValueStartEndAt,
    readWithKeyStartAt,
    readWithKeyStartEndAt, setRealtime
} from '../firebase';


export function useListen<S>(path: string) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<S | null>(null);
    useEffect(() => {
        const unsubscribeAuthChange = listenRealtime<S>(path, (data) => {
            setLoading(false);
            setData(data);
        });

        return () => {
            unsubscribeAuthChange();
        };
    }, [path]);


    const save = async (updates: { [key: string]: any }) => {
        setLoading(true);
        try {
            await updateRealtime(path, updates)
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    return {
        data, loading, setData, save
    }
}

export function useListenPropValue<S>(path: string, prop: string, value: any) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const [data, setData] = useState<{ [id: string]: S } | null>(null);
    useEffect(() => {
        const unsubscribeAuthChange = listenWithPropValue<S>(path, prop, value, (data) => {
            setLoading(false);
            setData(data);
        }, (e) => {
            setError(e);
        });

        return () => {
            unsubscribeAuthChange();
        };
    }, [path, prop, value]);

    return {
        data,
        error,
        loading,
        setData
    }
}

export function useListenPropValueStartAt<S>(path: string, prop: string, value: any) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const [data, setData] = useState<{ [id: string]: S } | null>(null);
    useEffect(() => {
        const unsubscribeAuthChange = listenWithPropValueStartAt<S>(path, prop, value, (data) => {
            setLoading(false);
            setData(data);
        }, (e) => {
            setError(e);
        });

        return () => {
            unsubscribeAuthChange();
        };
    }, [path, prop, value]);

    return {
        data,
        error,
        loading,
        setData
    }
}

export function useListenPropValueEndAt<S>(path: string, prop: string, value: any) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const [data, setData] = useState<{ [id: string]: S } | null>(null);
    useEffect(() => {
        const unsubscribeAuthChange = listenWithPropValueEndAt<S>(path, prop, value, (data) => {
                setLoading(false);
                setData(data);
            },
            (e) => {
                setError(e);
            });

        return () => {
            unsubscribeAuthChange();
        };
    }, [path, prop, value]);

    return {
        data,
        error,
        loading,
        setData
    }
}

export function useRead<S>(path: string, loadKey?:string) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<S | null>(null);
    const [updated, setUpdated] = useState<S | null>(null);
    const update = (key: string, value: any) => {

        setUpdated({...(updated ?? {}), [key]: value} as S);
    }
    const updates = (values: { [key: string]: any }) => {
        setUpdated({...(updated ?? {}), ...values} as S);
    }
    const save = async (updates?: { [key: string]: any }) => {
        setLoading(true);
        try {
            const saved = {...updated, ...(updates ?? {})};
            await updateRealtime(path, saved)
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
        if (updates) {
            setData((data) => {
                if (!data) return data;
                return {...data, ...updated, ...updates};
            });
        }
    };
    const put = async (updates: any) => {
        setLoading(true);
        try {
            await setRealtime(path, updates)
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        readRealtime<S>(path)
            .then((data) => {
                setLoading(false);
                setData(data);
            });
    }, [path, loadKey]);

    return {
        data: updated ? {...data, ...updated} : data,
        loading,
        original: data,
        updated,
        update,
        updates,
        save,
        put,
        setData,
        setLoading,
    }
}

export function useReadPropValueStartAt<S>(path: string, prop: string, value: any, key?: any) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const [data, setData] = useState<{ [id: string]: S } | null>(null);
    useEffect(() => {
        readWithPropValueStartAt<S>(path, prop, value)
            .then((data) => {
                setLoading(false);
                setData(data);
            });
    }, [path, prop, value, key]);

    return {
        data,
        error,
        loading,
        setData
    }
}
export function useReadPropValueStartEndAt<S>(path: string, prop: string, start: any, end:any, key?: any) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const [data, setData] = useState<{ [id: string]: S } | null>(null);
    useEffect(() => {
        readWithPropValueStartEndAt<S>(path, prop, start, end)
            .then((data) => {
                setLoading(false);
                setData(data);
            });
    }, [path, prop, start, end, key]);

    const save = async (updates: { [key: string]: any }) => {
        if (updates) {
            setData((data) => {
                if (!data) return data;
                return {...data, ...updates};
            });
        }
        setLoading(true);
        try {
            await updateRealtime(path, updates)
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };


    return {
        data,
        save,
        error,
        loading,
        setData
    }
}
export function useReadKeyStartAt<S>(path: string, startKey: any, reloadKey?: any) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const [data, setData] = useState<{ [id: string]: S } | null>(null);
    useEffect(() => {
        readWithKeyStartAt<S>(path, startKey)
            .then((data) => {
                setLoading(false);
                setData(data);
            });
    }, [path, startKey, reloadKey]);

    return {
        data,
        error,
        loading,
        setData
    }
}
export function useReadKeyStartAtEndAt<S>(path: string, startKey: any, endKey:any, reloadKey?: any) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const [data, setData] = useState<{ [id: string]: S } | null>(null);
    useEffect(() => {
        readWithKeyStartEndAt<S>(path,startKey,endKey )
            .then((data) => {
                setLoading(false);
                setData(data);
            });
    }, [path, startKey, endKey, reloadKey]);

    const save = async (updates: { [key: string]: any }) => {
        if (updates) {
            setData((data) => {
                if (!data) return data;
                return {...data, ...updates};
            });
        }
        setLoading(true);
        try {
            await updateRealtime(path, updates)
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };


    return {
        data,
        save,
        error,
        loading,
        setData
    }
}