import React from 'react';
import dayjs from "dayjs";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import CircleIcon from '@mui/icons-material/Circle';


export default function (props: {
    date: Date | null,
    length?: number,
    backward?: boolean,
    onChange: (date: Date) => void
}) {
    const {date, length = 10, onChange, backward = false} = props;
    const today = dayjs(dayjs().format('YYYY-MM-DD 00:00'));
    const todayFormat = dayjs().format('MM/DD');
    const dateFormat = dayjs(date).format('MM/DD');
    const dateList = new Array(length).fill(today).map((today, idx) => (
        backward
            ? today.subtract(idx, 'day')
            : today.add(idx - 1, 'day')
    ).format('MM/DD'))
    const tabFocus = dateList.findIndex((df) => df === dateFormat);

    return (
        <>
            <Tabs value={tabFocus} onChange={(e, idx) => {
                const selectedDate = backward
                    ? today.subtract(idx, 'day').toDate()
                    : today.add(idx - 1, 'day').toDate()

                onChange(selectedDate);
            }}>
                {dateList.map((df) => {
                    return <Tab label={df}/>
                })}
            </Tabs>
        </>
    )
}