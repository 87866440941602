import React, {ChangeEvent, useState} from 'react';


import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';


import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from '@mui/material/IconButton';
import Switch from "@mui/material/Switch";
import Checkbox from '@mui/material/Checkbox';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from "@mui/material/CardContent";

import CircularProgress from '@mui/material/CircularProgress';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';

import {useRead} from '../hooks/realtime';
import {logAction, removeRealtime, uploadFileToStorage} from "../hooks/firebase";
import {Product, ProductOption} from "../models/Product";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from '@mui/icons-material/Close';
import PlusIcon from '@mui/icons-material/PlusOne';
import UploadIcon from '@mui/icons-material/Upload';
import {v4 as uuidv4} from "uuid";
import {Areas, Area, Pickup} from "../models/Pickup";
import Autocomplete from "@mui/material/Autocomplete";
import {Agency} from "../models/Agency";
import CardActions from "@mui/material/CardActions";
import ReplayIcon from "@mui/icons-material/Replay";
import GoogleMap from "../components/GoogleMap";


export default function (props: { id: string, onClose: (update: boolean) => void }) {
  const {id, onClose} = props;
  const ref = `product/${id}`;
  const {
    data: product,
    original: originalProduct,
    update,
    save,
    put,
    setData,
    loading,
    setLoading
  } = useRead<Product>(ref);
  const {data: areas} = useRead<Areas>(`pickup`);
  const {data: agencies} = useRead<{ [id: string]: Agency }>('agency');
  const area = areas?.[product?.area?.toLowerCase() ?? ''];

  const [tab, setTab] = useState<string>('product');


  const handleDelete = () => {
    if (product?.deletedAt) {
      if (!(window?.confirm("완전히 삭제하시겠습니까?"))) return;
      setLoading(true);
      removeRealtime(ref)
          .then(() => {
            logAction('PRODUCT', 'DELETE PRODUCT', id, `Delete product permanently: ${id})`, product);
            setLoading(false);
            onClose(true);
          })
          .catch(console.error)
      return;
    } else {
      if (!(window?.confirm("삭제하시겠습니까?"))) return;
      setLoading(true);
      save({'deletedAt': Date.now()})
          .then(() => {
            logAction('PRODUCT', 'DELETE PRODUCT', id, `Delete product: ${id})`, product);
            setLoading(false);
            onClose(true);
          })
          .catch(console.error)
      // removeRealtime(ref)
    }
  }

  const handleRecover = () => {
    setLoading(true);
    save({'deletedAt': null})
        .then(() => {
          logAction('PRODUCT', 'RECOVER PRODUCT', id, `Recover product: ${id})`, product);
          setLoading(false);
          onClose(true);
        })
        .catch(console.error)
    // removeRealtime(ref)
  }

  const handleSave = () => {
    const filteredPossibles = (product?.possibles ?? []).filter(v => !!v);
    const adjustedOptions = (((product?.option?.length ?? 0) > 0 && !product?.option?.find((o) => o.option.toLowerCase() === 'ignore'))
            ? [...(product?.option ?? []), {
              option: 'Ignore',
              possibles: []
            }]
            : (product?.option ?? [])
    )
        .sort((a, b) => a.option.toLowerCase() === 'ignore' ? 1 : 0);

    const updates = {
      possibles: filteredPossibles,
      option: adjustedOptions
    }

    const additionalChangeList: string[] = [];
    const isPossibleChanged = JSON.stringify(updates.possibles) !== JSON.stringify(product?.possibles);
    const isOptionChanged = JSON.stringify(updates.option) !== JSON.stringify(product?.option ?? []);


    if (isPossibleChanged) additionalChangeList.push('[possible]')
    if (isOptionChanged) additionalChangeList.push('[option]')
    const additionalChange = additionalChangeList.join('\r\n')

    const diff = Object.entries(originalProduct ?? {})
        .filter((entry: [string, any]) => JSON.stringify(entry[1]) !== JSON.stringify((product as any)?.[entry[0]]))
        .map((entry) => `[${entry[0]}, ${JSON.stringify(entry[1])} => ${JSON.stringify((product as any)?.[entry[0]])}]`).join('\r\n')
    const value = {
      from: JSON.parse(JSON.stringify(originalProduct)),
      to: JSON.parse(JSON.stringify({...product, ...updates}))
    };

    save(updates)
        .then((saved) => {
          logAction('PRODUCT', 'UPDATE PRODUCT', id, `Update Product:${id}\r\n${diff}\r\n${additionalChange}`, value)
          onClose(true);
        })
  }


  const handleChangeBuilder = (prop: string) => (_: any, value: any) => {
    update(prop, value);
  }
  const handleTargetChangeBuilder = (prop: string, middleware: (value: any) => any = f => f) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = middleware(e.target.value);
    update(prop, value);
  }

  return (
      <Modal
          open
          onClose={onClose}
      >
        <Box
            sx={(theme) => ({
              width: '80vw',
              maxHeight: '80vh',
              position: 'relative' as 'relative',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            })}
        >


          <Box sx={{
            position: 'fixed',
            display: 'inline-flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            bottom: 0,
            left: '50%',
            transform: 'translate(-50%, 125%)',
            zIndex: 999,
            backgroundColor: 'white',
            borderRadius: 5,
            boxShadow: 2,
            py: 1,
            px: 2
          }}>

            {
              loading
                  ? <CircularProgress color={"primary"}/>
                  : <>
                    <Switch checked={product?.status === 'ON'}
                            onChange={(e, value) => handleChangeBuilder('status')(e, value ? 'ON' : 'STOP')}/>
                    <IconButton onClick={handleSave}>
                      <EditIcon/>
                    </IconButton>
                    {
                      product?.deletedAt
                          ?
                          <IconButton onClick={handleRecover}>
                            <ReplayIcon/>
                          </IconButton>
                          : null
                    }
                    <IconButton onClick={handleDelete}>
                      <DeleteIcon/>
                    </IconButton>
                  </>
            }

          </Box>

          <Paper
              sx={(theme) => ({
                padding: '32px 24px',
              })}
          >
            <Grid
                container
                spacing={2}
            >
              <Grid
                  item
                  xs={12}
                  sx={{
                    position: 'relative',
                  }}
              >
                <Box
                    sx={{
                      position: 'relative',
                      maxHeight: '75vh',
                      overflowY: 'auto',
                    }}
                >

                  <TabContext value={tab}>
                    <Box>
                      <TabList onChange={(e, tabValue) => {
                        setTab(tabValue)
                      }}>
                        <Tab
                            label={'Product'}
                            value={'product'}
                        />
                        <Tab
                            label={'DoubleCheck'}
                            value={'doublecheck'}
                        />
                        <Tab
                            label={'Chat'}
                            value={'chat'}
                        />
                      </TabList>
                    </Box>
                    <Box sx={(theme) => ({
                      borderRadius: 3,
                      backgroundColor: theme.palette.background.default,
                      p: 4
                    })}>
                      <TabPanel value={'product'}>
                        <Grid
                            container
                            spacing={4}
                            component={'form'}
                        >
                          <Grid
                              item
                              xs={2}
                          >
                            <Typography fontWeight={'bold'}>
                              Area
                            </Typography>
                          </Grid>
                          <Grid
                              item
                              xs={4}
                          >
                            <FormControl>
                              <RadioGroup
                                  row
                                  name="position"
                                  value={product?.area ?? ''}
                                  onChange={handleChangeBuilder('area')}
                              >
                                <FormControlLabel
                                    value="Seoul"
                                    control={<Radio/>}
                                    label="Seoul"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    value="Busan"
                                    control={<Radio/>}
                                    label="Busan"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    value="Tokyo"
                                    control={<Radio/>}
                                    label="Tokyo"
                                    labelPlacement="end"
                                />
                              </RadioGroup>
                            </FormControl>

                          </Grid>
                          <Grid
                              item
                              xs={2}
                          >
                            <Typography fontWeight={'bold'}>
                              Winter
                            </Typography>
                          </Grid>
                          <Grid
                              item
                              xs={4}
                          >

                            <FormControlLabel control={<Switch/>} label={'winter'} checked={product?.winter ?? false}
                                              onChange={(e, value) => {
                                                handleChangeBuilder('winter')(e, value);
                                              }}/>
                          </Grid>

                          <Grid
                              item
                              xs={12}
                          >
                            <Divider/>
                          </Grid>

                          <Grid
                              item
                              xs={2}
                          >
                            <Typography fontWeight={'bold'}>
                              Info
                            </Typography>
                          </Grid>
                          <Grid
                              item
                              xs={5}
                          >
                            <TextField
                                fullWidth
                                label={'Name'}
                                value={product?.name ?? ''}
                                onChange={handleTargetChangeBuilder('name')}
                                InputLabelProps={{shrink: true}}
                                sx={{backgroundColor: 'white'}}
                            />

                          </Grid>

                          <Grid
                              item
                              xs={5}
                          >
                            <TextField
                                fullWidth
                                label={'Category'}
                                value={product?.category ?? ''}
                                onChange={handleTargetChangeBuilder('category')}
                                InputLabelProps={{shrink: true}}
                                sx={{backgroundColor: 'white'}}
                            />
                          </Grid>
                          <Grid
                              item
                              xs={2}
                          >
                            <Typography fontWeight={'bold'}>
                              Conditions
                            </Typography>
                          </Grid>


                          <Grid
                              item
                              xs={4}
                          >
                            <ToggleButtonGroup
                                fullWidth
                                style={{
                                  backgroundColor: 'white'
                                }}
                                value={product?.availableDays.map((on, idx) => on ? idx : false) ?? []}
                                onChange={
                                  (e, value) => {
                                    const values = product?.availableDays.map((on, idx) => (value as number[]).includes(idx));
                                    handleChangeBuilder('availableDays')(e, values);
                                  }
                                }
                            >
                              <ToggleButton value={0} color={'primary'} size={'large'}>
                                월
                              </ToggleButton>
                              <ToggleButton value={1} color={'primary'} size={'large'}>
                                화
                              </ToggleButton>
                              <ToggleButton value={2} color={'primary'} size={'large'}>
                                수
                              </ToggleButton>
                              <ToggleButton value={3} color={'primary'} size={'large'}>
                                목
                              </ToggleButton>
                              <ToggleButton value={4} color={'primary'} size={'large'}>
                                금
                              </ToggleButton>
                              <ToggleButton value={5} color={'primary'} size={'large'}>
                                토
                              </ToggleButton>
                              <ToggleButton value={6} color={'primary'} size={'large'}>
                                일
                              </ToggleButton>
                            </ToggleButtonGroup>
                          </Grid>

                          <Grid
                              item
                              xs={2}
                          >
                            <TextField
                                fullWidth
                                label={'Departure Time'}
                                value={product?.departureTime ?? '0900'}
                                onChange={handleTargetChangeBuilder('departureTime')}
                                InputLabelProps={{shrink: true}}
                                sx={{backgroundColor: 'white'}}
                            />
                          </Grid>
                          <Grid
                              item
                              xs={2}
                          >
                            <TextField
                                fullWidth
                                label={'Winter Departure Time'}
                                value={product?.departureTimeWinter ?? '0900'}
                                onChange={handleTargetChangeBuilder('departureTimeWinter')}
                                InputLabelProps={{shrink: true}}
                                sx={{backgroundColor: 'white'}}
                            />
                          </Grid>
                          <Grid
                              item
                              xs={2}
                          >
                            <TextField
                                fullWidth
                                label={'Time limit'}
                                value={product?.availableBefore ?? ''}
                                onChange={handleTargetChangeBuilder('availableBefore')}
                                InputLabelProps={{shrink: true}}
                                sx={{backgroundColor: 'white'}}
                            />
                          </Grid>
                          <Grid
                              item
                              xs={12}
                          >
                            <Divider/>
                          </Grid>
                          <Grid
                              item
                              xs={2}
                          >
                            <Typography fontWeight={'bold'}>
                              Memo
                            </Typography>
                          </Grid>
                          <Grid
                              item
                              xs={10}
                          >
                            <TextField
                                fullWidth
                                minRows={5}
                                sx={{
                                  backgroundColor: 'white'
                                }}
                                value={product?.memo}
                                onChange={handleTargetChangeBuilder('memo')}
                            />
                          </Grid>
                          <Grid
                              item
                              xs={12}
                          >
                            <Divider/>
                          </Grid>
                          <Grid
                              item
                              xs={2}
                          >
                            <Typography fontWeight={'bold'}>
                              Possibles
                            </Typography>
                          </Grid>
                          <Grid
                              item
                              xs={10}
                          >
                            <Stack
                                gap={1}
                            >
                              {
                                (product?.possibles ?? []).map((p, i, array) => (
                                    <TextField
                                        key={'possibles' + i}
                                        fullWidth
                                        style={{
                                          backgroundColor: 'white'
                                        }}
                                        value={p}
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          const newArray = [...array]
                                          newArray[i] = value;
                                          handleChangeBuilder('possibles')(e, newArray);
                                        }}
                                    />
                                ))
                              }
                              <Button
                                  fullWidth
                                  style={{
                                    backgroundColor: 'white',
                                  }}
                                  variant={'outlined'}
                                  color={'primary'}
                                  onClick={(e) => {
                                    const newArray = [...(product?.possibles ?? []), ''];
                                    handleChangeBuilder('possibles')(e, newArray);
                                  }}
                              >
                                <PlusIcon/>
                              </Button>
                            </Stack>
                          </Grid>

                          <Grid
                              item
                              xs={12}
                          >
                            <Divider/>
                          </Grid>

                          <Grid
                              item
                              xs={2}
                          >

                            <Typography fontWeight={'bold'}>
                              Options
                            </Typography>
                          </Grid>
                          <Grid
                              item
                              xs={10}
                          >
                            <Grid
                                container
                                spacing={2}
                            >
                              {
                                (product?.option ?? []).map((option, idx, options) => {
                                  return (
                                      <Grid
                                          item
                                          key={'option' + idx}
                                          xs={12}
                                          sm={6}
                                      >
                                        <Card
                                        >
                                          <CardHeader
                                              disableTypography
                                              action={
                                                <IconButton
                                                    onClick={(e) => {
                                                      const newOptions = [...options]
                                                      newOptions.splice(idx, 1);
                                                      handleChangeBuilder('option')(e, newOptions)
                                                    }}
                                                >
                                                  <CloseIcon/>
                                                </IconButton>
                                              }
                                              title={
                                                <TextField
                                                    fullWidth
                                                    variant={'standard'}
                                                    value={option.option}
                                                    onChange={(e) => {
                                                      const value = e.target.value;
                                                      const newOption = {
                                                        ...option,
                                                        option: value
                                                      }
                                                      const newOptions = [...options]
                                                      newOptions[idx] = newOption;
                                                      handleChangeBuilder('option')(e, newOptions)
                                                    }}
                                                />
                                              }
                                          />

                                          <CardContent>

                                            <Stack
                                                gap={1}
                                            >
                                              {
                                                (option.possibles ?? []).map((p, i, array) => (
                                                    <TextField
                                                        key={'possibles' + i}
                                                        fullWidth
                                                        style={{
                                                          backgroundColor: 'white'
                                                        }}
                                                        value={p}
                                                        onChange={(e) => {
                                                          const value = e.target.value;
                                                          const newArray = [...array]
                                                          newArray[i] = value;
                                                          const newOption = {
                                                            ...option,
                                                            possibles: newArray
                                                          }
                                                          const newOptions = [...options]
                                                          newOptions[idx] = newOption;
                                                          handleChangeBuilder('option')(e, newOptions);
                                                        }}
                                                    />
                                                ))
                                              }
                                              <Button
                                                  fullWidth
                                                  style={{
                                                    backgroundColor: 'white',
                                                  }}
                                                  variant={'outlined'}
                                                  color={'primary'}
                                                  onClick={(e) => {
                                                    const newArray = [...(option.possibles ?? []), ''];
                                                    const newOption = {
                                                      ...option,
                                                      possibles: newArray
                                                    }
                                                    const newOptions = [...options]
                                                    newOptions[idx] = newOption;
                                                    handleChangeBuilder('option')(e, newOptions);
                                                  }}
                                              >
                                                <PlusIcon/>
                                              </Button>
                                            </Stack>
                                          </CardContent>

                                        </Card>
                                      </Grid>
                                  )
                                })
                              }
                              <Grid
                                  item
                                  xs={12}
                              >

                                <Button
                                    fullWidth
                                    style={{
                                      backgroundColor: 'white',
                                    }}
                                    variant={'outlined'}
                                    color={'primary'}
                                    onClick={(e) => {
                                      const newArray = [...(product?.option ?? []), {
                                        option: '',
                                        possibles: []
                                      }];
                                      handleChangeBuilder('option')(e, newArray);
                                    }}
                                >
                                  <PlusIcon/>
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </TabPanel>
                      <TabPanel value={'doublecheck'}>
                        <DoubleCheckInner product={product} agencies={agencies ?? {}}
                                          doubleCheck={product?.doubleCheck ?? {}}
                                          onChange={handleChangeBuilder('doubleCheck')}/>
                      </TabPanel>
                      <TabPanel value={'chat'}>
                        <ChatInner chat={product?.chat} name={product?.name} option={product?.option} area={area}
                                   onChange={handleChangeBuilder('chat')}/>
                      </TabPanel>
                    </Box>
                  </TabContext>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Modal>
  )
}

function DoubleCheckInner(props: {
  agencies: { [id: string]: Agency },
  product: (Product | null),
  doubleCheck: { [id: string]: { agency: string, productPossible: string, optionPossible?: string, option?: string } },
  onChange: (_: any, updated: { [id: string]: any }) => void
}) {
  const {product, agencies, doubleCheck, onChange} = props;
  const agencyOptions = Object.values(agencies).map((a) => ({id: a.code, name: a.name}));
  const handleOnDeleteBuilder = (id: string) => (_: any) => {
    const updatable = doubleCheck;
    delete updatable[id];
    onChange(_, updatable)
  }
  const handleOnChangeBuilder = (id: string) => (_: any, change: {
    agency: string,
    productPossible: string,
    optionPossible?: string,
    option?: string
  }) => {
    onChange(_, {...doubleCheck, [id]: change});
  }
  const handleAddNew = (_: any) => {
    onChange(_, {
      ...doubleCheck,
      [Date.now() + '']: {agency: "L", productPossible: '', optionPossible: '', option: ''}
    });
  }
  return (
      <Box display={'flex'} flexDirection={'column'} gap={2}>
        <Button fullWidth onClick={handleAddNew}>
          Add New
        </Button>
        {
          Object.entries(doubleCheck)
              .sort(([aKey], [bKey]) => aKey > bKey ? -1 : 0)
              .map(([id, {agency, productPossible, optionPossible, option}]) => {
                return (
                    <Card>
                      <CardActions>
                        <IconButton sx={{flex: 0}} onClick={handleOnDeleteBuilder(id)}>
                          <DeleteIcon/>
                        </IconButton>
                      </CardActions>
                      <CardContent component={Grid} key={id} container gap={1}>
                        <Grid item xs={12}>
                          <Autocomplete
                              options={agencyOptions}
                              getOptionLabel={(option) => `${option.id}(${option.name})`}
                              value={agencyOptions.find(({id}) => id === agency)}
                              onChange={(_, agencyOption) => {
                                if (agencyOption) {
                                  handleOnChangeBuilder(id)(_, {
                                    agency: agencyOption.id,
                                    productPossible,
                                    optionPossible,
                                    option
                                  });
                                }
                              }}
                              sx={{backgroundColor: 'white'}}
                              renderInput={
                                (params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        label={'Agency'}
                                        InputLabelProps={{shrink: true}}
                                    />
                                )
                              }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                              fullWidth
                              label={'Product possible'}
                              value={productPossible}
                              onChange={(e,) => {
                                const value = e.target.value
                                handleOnChangeBuilder(id)(e, {agency, productPossible: value, optionPossible, option});
                              }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                              fullWidth
                              label={'Option possible'}
                              value={optionPossible}
                              onChange={(e,) => {
                                const value = e.target.value
                                handleOnChangeBuilder(id)(e, {agency, optionPossible: value, productPossible, option});
                              }}
                          />
                        </Grid>
                        {
                          ((product?.option?.filter(o => o.option.toLowerCase() !== 'ignore').length ?? 0) > 0)
                              ?
                              <Grid item xs={12}>
                                <Autocomplete
                                    fullWidth
                                    options={product?.option?.map((o) => ({
                                      name: o.option.toLowerCase() === 'ignore' ? 'No Option' : o.option,
                                      id: o.option
                                    })) ?? []}
                                    getOptionLabel={(option) => `${option.name}`}
                                    value={{name: option ?? 'No Option', id: option ?? 'Ignore'}}
                                    onChange={(_, option) => {
                                      if (option) {
                                        handleOnChangeBuilder(id)(_, {
                                          agency,
                                          productPossible,
                                          optionPossible,
                                          option: option.id
                                        });
                                      }
                                    }}
                                    sx={{backgroundColor: 'white'}}
                                    renderInput={
                                      (params) => (
                                          <TextField
                                              {...params}
                                              fullWidth
                                              label={'Option'}
                                              InputLabelProps={{shrink: true}}
                                          />
                                      )
                                    }
                                />
                              </Grid>
                              : null
                        }
                      </CardContent>
                    </Card>
                )
              })
        }
      </Box>
  )
}


function ChatInner(props: {
  chat: { [propName: string]: any } | undefined,
  name: string | undefined,
  option: ProductOption[] | undefined,
  area: Area | undefined,
  onChange: (_: any, updated: { [propName: string]: any }) => void
}) {
  let {chat, option, area, name, onChange} = props;
  const [uploading, setUploading] = useState<boolean>(false);

  if (!chat) {
    chat = {
      name: {en: '', cn: '', ko: ''},
      message: '',
      option: {},
      pickup: {}
    }
  }
  if (!chat.option) {
    chat.option = {};
  }

  if (!chat.pickup) {
    chat.pickup = {};
  }


  const options = option
      ? option
          .filter((o) => o.option.toLowerCase() !== 'ignore')
          .map((op) => {
            const exist = Object.values(chat?.option ?? {}).find((cOp: any) => cOp.option === op.option);
            if (exist) return exist;
            return ({option: op.option, en: '', cn: ''})
          })
      : []
  const pickups = area
      ? Object.values(area)
          .map((pk) => {
            const exist = Object.values(chat?.pickup ?? {}).find((cPk: any) => cPk.place === pk.place);
            if (exist) return exist;
            return ({place: pk.place, en: '', cn: '', latLang: [], cnImage: '', enImage: '', use: false, order: 99})
          })
      : []

  const handleChangeValueBuilder = (propName: string) => (_: any, v: any) => {
    const propNames = propName.split(/[./]/);  //['title', 'en'];
    const updateObject = {...chat};
    const lastPropName = propNames.pop() as string;
    let cursor = updateObject;
    for (let pn of propNames) {
      cursor = cursor[pn] = {...cursor[pn]};

    }
    cursor[lastPropName] = v;
    onChange(_, updateObject);
  }

  const handleChangeEventTargetBuilder = (propName: string, middleware: (value: any) => any = f => f) => {
    const handleChangeValue = handleChangeValueBuilder(propName);
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = middleware(e.target.value ?? '');
      handleChangeValue(e, value);
    }
  }


  const changeFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files instanceof FileList && e.target.files.length > 0) {
      const file = e.target.files.item(e.target.files.length - 1);
      if (!file) return alert('참조된 파일이 없습니다.');
      if (!file.type.includes('ima')) alert('이미지 파일이 아닙니다.');
      setUploading(true);
      uploadFileToStorage(`public/${uuidv4()}`, file)
          .then((url) => {
            handleChangeValueBuilder(`image`)(e, url);
          })
          .finally(() => {
            setUploading(false);
          })
    }
  }


  return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant={'h5'}>
            {name}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography fontWeight={'bold'}>
            Name
          </Typography>
        </Grid>

        <Grid item xs={10}>
          <TextField
              fullWidth
              label={'Korean-full'}
              value={chat?.name?.ko ?? ''}
              onChange={handleChangeEventTargetBuilder('name.ko')}
          />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={10}>
          <TextField
              fullWidth
              label={'English'}
              value={chat?.name?.en ?? ''}
              onChange={handleChangeEventTargetBuilder('name.en')}
          />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={10}>
          <TextField
              fullWidth
              label={'Chinese'}
              value={chat?.name?.cn ?? ''}
              onChange={handleChangeEventTargetBuilder('name.cn')}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider/>
        </Grid>
        <Grid item xs={2}>
          <Typography fontWeight={'bold'}>
            Options
          </Typography>
        </Grid>
        <Grid item xs={10}>
          {
            options.length > 0
                ?
                (options as { option: string, en: string, cn: string }[]).map((o, idx) => (
                    <InnerChatOption
                        option={o}
                        onChange={handleChangeValueBuilder(`option.${o.option.replace(/[.\/\\]/gi, '')}`)}
                    />
                ))
                : <Typography fontWeight={'bold'}>NONE</Typography>
          }
        </Grid>
        <Grid item xs={12}>
          <Divider/>
        </Grid>
        <Grid item xs={2}>
          <Typography fontWeight={'bold'}>
            Message
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <TextField
              multiline
              fullWidth
              value={chat?.message ?? ''}
              label={'regular'}
              onChange={handleChangeEventTargetBuilder(`message`)}
          />
        </Grid>
        <Grid item xs={2}>
        </Grid>
        <Grid item xs={10}>
          <TextField
              multiline
              fullWidth
              value={chat?.messageWinter ?? ''}
              label={'winter'}
              onChange={handleChangeEventTargetBuilder(`messageWinter`)}
          />
        </Grid>

        <Grid item xs={12}>
          <Divider/>
        </Grid>

        <Grid item xs={2}>
          <Typography fontWeight={'bold'}>
            Image
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'stretch',
                mb: 2,
                gap: 1,
              }}
          >
            <TextField
                sx={{flex: 1}}
                fullWidth
                value={chat?.image ?? ''}
                onChange={handleChangeEventTargetBuilder(`image`)}
            />
            <Button
                component={'label'}
                variant={'contained'}
                disabled={uploading}
            >
              <UploadIcon/>
              <input
                  type="file"
                  id="file"
                  style={{display: "none"}}
                  accept={"image/*"}
                  onChange={changeFile}
              />
            </Button>
          </Box>
          {
            uploading
                ?
                <Box
                    sx={{
                      height: '20vh',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                >
                  <CircularProgress/>
                </Box>
                :
                chat?.image
                    ? (
                        <img
                            style={{maxHeight: '50vh'}}
                            src={chat?.image ?? ''}
                        />
                    ) : null
          }
        </Grid>
        <Grid item xs={12}>
          <Divider/>
        </Grid>
        <Grid item xs={2}>
          <Typography fontWeight={'bold'}>
            Pickups
          </Typography>
        </Grid>
        <Grid item xs={10}>
          {
            pickups.length > 0
                ?
                (pickups as {
                  order: number,
                  use: boolean,
                  place: string,
                  time: string,
                  winterTime: string,
                  ko: string,
                  en: string,
                  cn: string,
                  latLang: string[],
                  cnImage: string,
                  enImage: string,
                }[])
                    .sort((a, b) => {
                      const aUse = a.use ?? false;
                      const bUse = b.use ?? false;
                      const aOrder = a.order ?? Number.POSITIVE_INFINITY;
                      const bOrder = b.order ?? Number.POSITIVE_INFINITY;
                      return aUse ? aOrder - bOrder : 1;
                    })
                    .map((p, idx) => (
                        <InnerChatPickup
                            pickup={p}
                            onChange={handleChangeValueBuilder(`pickup.${p.place.replace(/[.\/\\]/gi, '')}`)}
                        />
                    ))
                : <Typography fontWeight={'bold'}>NONE</Typography>
          }
        </Grid>

      </Grid>
  )
}

function InnerChatOption(props: {
  option: { option: string, cn: string, en: string },
  onChange: (e: any, option: { option: string, cn: string, en: string }) => void
}) {

  const {option, onChange} = props;

  return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>
            {option.option}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
              fullWidth
              label={'English'}
              value={option.en ?? ''}
              onChange={(e) => {
                const value = e.target.value;
                onChange(e, {...option, en: value});
              }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
              fullWidth
              label={'Chinese'}
              value={option.cn ?? ''}
              onChange={(e) => {
                const value = e.target.value;
                onChange(e, {...option, cn: value});
              }}
          />
        </Grid>
        <Grid item xs={12}/>
      </Grid>
  )
}

function InnerChatPickup(props: {
  pickup: {
    order: number,
    use: boolean,
    place: string,
    time: string,
    winterTime: string,
    ko: string,
    en: string,
    cn: string,
    latLang: string[],
    cnImage: string,
    enImage: string,
    lat?: number,
    lng?: number
  },
  onChange: (e: any, pickup: {
    order: number,
    use: boolean,
    place: string,
    time: string,
    winterTime: string,
    ko: string,
    en: string,
    cn: string,
    latLang: string[],
    cnImage: string,
    enImage: string,
    lat?: number,
    lng?: number
  }) => void
}) {
  const [uploading, setUploading] = useState<boolean>(false);
  const {pickup, onChange} = props;
  const changeFileBuilder = (propName: 'enImage' | 'cnImage') => (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files instanceof FileList && e.target.files.length > 0) {
      setUploading(true);
      const file = e.target.files.item(e.target.files.length - 1);
      if (!file) return alert('참조된 파일이 없습니다.');
      if (!file.type.includes('ima')) alert('이미지 파일이 아닙니다.');

      uploadFileToStorage(`public/${uuidv4()}`, file)
          .then((url) => {
            onChange(e, {...pickup, [propName]: url});
          })
          .catch(e => {
            alert('파일 업로드에 실패했습니다.')
          })
          .finally(() => {
            setUploading(false);
          })
    }
  }
  return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControlLabel control={<Switch/>} label={pickup.place} checked={pickup.use ?? false}
                            onChange={(e, value) => {
                              onChange(e, {...pickup, use: value});
                            }}/>
          <TextField
              variant={'standard'}
              type={'number'}
              value={pickup.order}
              onChange={(e) => {
                const value = e.target.value;
                const number = Number.parseInt(value);
                onChange(e, {...pickup, order: number});
              }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
              fullWidth
              label={'Korean Name'}
              value={pickup.ko ?? ''}
              onChange={(e) => {
                const value = e.target.value;
                onChange(e, {...pickup, ko: value});
              }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
              fullWidth
              label={'English Name'}
              value={pickup.en ?? ''}
              onChange={(e) => {
                const value = e.target.value;
                onChange(e, {...pickup, en: value});
              }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
              fullWidth
              label={'Chinese Name'}
              value={pickup.cn ?? ''}
              onChange={(e) => {
                const value = e.target.value;
                onChange(e, {...pickup, cn: value});
              }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
              fullWidth
              label={'Time'}
              value={pickup.time ?? ''}
              onChange={(e) => {
                const value = e.target.value;
                onChange(e, {...pickup, time: value});
              }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
              fullWidth
              label={'Winter Time'}
              value={pickup.winterTime ?? ''}
              onChange={(e) => {
                const value = e.target.value;
                onChange(e, {...pickup, winterTime: value});
              }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
              fullWidth
              type={'number'}
              label={'Lat'}
              value={pickup.lat ?? ''}
              onChange={(e) => {
                const value = Number(e.target.value);
                onChange(e, {...pickup, lat: value});
              }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
              fullWidth
              type={'number'}
              label={'lng'}
              value={pickup.lng ?? ''}
              onChange={(e) => {
                const value = Number(e.target.value);
                onChange(e, {...pickup, lng: value});
              }}
          />
        </Grid>
        <Grid item xs={12}>
          <GoogleMap lng={pickup.lng ?? 0} lat={pickup.lat ?? 0} onClick={(lat, lng) => {
            onChange(null, {...pickup, lat, lng});
          }}/>
        </Grid>
        <Grid item xs={6} display={'none'}>
          <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'stretch',
                mb: 1,
                gap: 1,
              }}
          >
            <TextField
                sx={{flex: 1}}
                fullWidth
                value={pickup?.enImage ?? ''}
                onChange={(e) => {
                  const value = e.target.value;
                  onChange(e, {...pickup, enImage: value});
                }}
            />
            <Button
                component={'label'}
                variant={'contained'}
                disabled={uploading}
            >
              <UploadIcon/>
              <input
                  type="file"
                  id="file"
                  style={{display: "none"}}
                  accept={"image/*"}
                  onChange={changeFileBuilder('enImage')}
              />
            </Button>
          </Box>
          {
            uploading
                ? (
                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10vh'}}>
                      <CircularProgress/>
                    </Box>
                )
                : (
                    <img
                        src={pickup.enImage}
                        width={'100%'}
                    />
                )
          }
        </Grid>
        <Grid item xs={6} display={'none'}>
          <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'stretch',
                mb: 1,
                gap: 1,
              }}
          >
            <TextField
                sx={{flex: 1}}
                fullWidth
                value={pickup?.cnImage ?? ''}
                onChange={(e) => {
                  const value = e.target.value;
                  onChange(e, {...pickup, cnImage: value});
                }}
            />
            <Button
                component={'label'}
                variant={'contained'}
                disabled={uploading}
            >
              <UploadIcon/>
              <input
                  type="file"
                  id="file"
                  style={{display: "none"}}
                  accept={"image/*"}
                  onChange={changeFileBuilder('cnImage')}
              />
            </Button>
          </Box>
          {
            uploading
                ? (
                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10vh'}}>
                      <CircularProgress/>
                    </Box>
                )
                : (
                    <img
                        src={pickup.cnImage}
                        width={'100%'}
                    />
                )
          }
        </Grid>
        <Grid item xs={12}/>
      </Grid>
  )
}