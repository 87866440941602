import { createContext, PropsWithChildren, useContext, useEffect, useReducer } from "react"
import { Drawer } from "@mui/material"
import { useLocation } from "react-router-dom"
import AddParticipant from "../../components/chat/drawer/AddParticipant"

export const DrawerContext = createContext<any>(null)

export const DrawerContextProvider = ({ children }: PropsWithChildren) => {
    const INITIAL_STATE = {
        open: false,
        view: null,
        anchor: "bottom",
        data: null,
    }

    const drawerReducer = (state: any, action: any) => {
        switch (action.type) {
            case "OPEN":
                return {
                    open: true,
                    view: action.payload.view,
                    anchor: action.payload.anchor,
                    data: action.payload.data,
                }
            case "CLOSE":
                return {
                    ...state,
                    open: false,
                }
            default:
                return state
        }
    }

    const [state, dispatch] = useReducer(drawerReducer, INITIAL_STATE)

    return (
        <DrawerContext.Provider
            value={{
                state,
                dispatch,
            }}
        >
            {children}
        </DrawerContext.Provider>
    )
}

export const DrawerView = () => {
    const { state, dispatch } = useContext(DrawerContext)
    const location = useLocation()
    useEffect(() => {
        dispatch({ type: "CLOSE" })
    }, [location.pathname])

    function getView() {
        switch (state.view) {
            case "ADD_PARTICIPANT":
                const { participants, category } = state.data
                return <AddParticipant participants={participants} category={category} />
            default:
                return <></>
        }
    }

    return (
        <Drawer
            anchor={state.anchor}
            open={state.open}
            onClose={() =>
                dispatch({
                    type: "CLOSE",
                })
            }
        >
            {getView()}
        </Drawer>
    )
}
