import * as XLSX from "xlsx";
import {AgencyFileDatum} from "./types";

function cleanString(data?: any): string {
  return data?.toString().trim() || ''
}

function toNumber(numberLike?: string | number) {
  if (typeof numberLike === 'number') return numberLike;
  return Number(cleanString(numberLike) || 0);
}


export default async function parseTrazyFile(file: Blob | File):Promise<AgencyFileDatum[]> {
  const parsed = await parseFileData(file);
  return parsed
    .filter(raw=>!!raw['Reservation Code'])
    .map((raw) => {
    const agency = 'T'
    const agencyCode = cleanString(raw['Reservation Code']);
    const tour = cleanString(raw['Option Title']);
    const date = formatDate(cleanString(raw['Reserve Date']));
    const people = parseQuantity(raw['Quantity']);
    const price = toNumber(raw['Net Price']);

    return ({
      tour,
      option:'',
      date,
      agency,
      agencyCode,
      people,
      price,
      unitPrice:price/people,
    });
  })
}


const parseFileData = async (file: Blob | File) => {
  const binary = await file.arrayBuffer()
  const workbook = XLSX.read(binary, {type: "array", raw: true})
  const sheets = workbook.Sheets
  const sheetNames = workbook.SheetNames

  const firstSheet = sheets[sheetNames[0]]
  return XLSX.utils.sheet_to_json(firstSheet,{range:11}) as any[];
}


function formatDate(yyyymmmdd: string) {
  const yyyy = yyyymmmdd.slice(0, 4);
  const mm = yyyymmmdd.slice(4, 6);
  const dd = yyyymmmdd.slice(6, 8);
  return `${yyyy}-${mm}-${dd}`;
}

/**
 * Adult (13 yrs~) 5, Child (3~12 yrs) 1
 * 1
 * Person (3 yrs~) 1
 * @param quantity
 */

function parseQuantity(quantity: string) {
  const lines = quantity.split(/,\r?\n?/gi);
  const numbers = lines.map(l => toNumber(l?.trim()?.split(' ')?.at(-1) ?? '0'));
  return numbers.reduce((a, b) => a + b);
}