import Box from "@mui/material/Box"
import Tab from "@mui/material/Tab"
import { useContext, useEffect, useState } from "react"
import ParticipantList from "../../components/chat/home/ParticipantList"
import { AuthContext } from "../../hooks/auth"
import { readRealtime } from "../../hooks/firebase"
import { IUser } from "../../types/chat"
import Footer from "../../components/chat/layout/Footer"
import { UserSearch } from "../../components/chat/home/UserSearch"
import Tabs from "../../components/ui/Tabs"
import TabPanelUser from "../../components/chat/home/TabPanelUser"

const TAB_PANEL = {
    guide: 0,
    operator: 1,
}

export default function Home() {
    const [participants, setParticipants] = useState<IUser[]>([])
    const [guiders, setGuiders] = useState<IUser[]>([])
    const [operators, setOperators] = useState<IUser[]>([])
    const [oldGuiders, setOldGuiders] = useState<IUser[]>([])
    const [oldOperators, setOldOperators] = useState<IUser[]>([])

    const [tabValue, setTabValue] = useState(TAB_PANEL.guide)

    const { operator } = useContext(AuthContext)

    useEffect(() => {
        if (!operator) return
        ;(async () => {
            const users = await readRealtime("user")
            const filteredUsers = Object.values(users as object)
                .filter((user: any) => user.id !== operator.id && user.on)
                .map((user) => ({
                    ...user,
                    raw: JSON.parse(user.raw),
                }))

            const operators = filteredUsers.filter((user) => user.level <= 1)
            const guiders = filteredUsers.filter((user) => user.level > 1)

            setGuiders(guiders)
            setOperators(operators)
            setOldGuiders(guiders)
            setOldOperators(operators)
        })()
    }, [operator])

    const handleChangeTab = (_: any, newValue: number) => {
        setTabValue(newValue)
    }

    function handleSetParticipants(user: IUser) {
        const isExist = participants.find((participant) => participant.id === user.id)
        if (isExist) {
            const filteredParticipants = participants.filter((participant) => participant.id !== user.id)
            setParticipants(filteredParticipants)
            return
        }
        setParticipants((prev) => [...prev, user])
    }

    const handleSearchUser = (searchKeyword: string) => {
        if (searchKeyword === "") {
            setGuiders(oldGuiders)
            setOperators(oldOperators)
            return
        }
        const regex = new RegExp(searchKeyword, "gi")

        if (tabValue === TAB_PANEL.guide) {
            const filteredGuiders = oldGuiders.filter((guider) => {
                return guider?.name?.match(regex) || guider?.nameEn?.match(regex) || guider?.tel?.match(regex)
            })
            setGuiders(filteredGuiders)
        } else {
            const filteredOperators = oldOperators.filter((operator) => {
                return operator?.name?.match(regex) || operator?.nameEn?.match(regex) || operator?.tel?.match(regex)
            })
            setOperators(filteredOperators)
        }
    }

    if (!operator) return null
    return (
        <>
            <Box
                sx={{
                    position: "sticky",
                    top: 0,
                    width: "100%",
                    backgroundColor: "white",
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    px: 4,
                    py: 4,
                    zIndex: 1,
                }}
            >
                <UserSearch handleSearch={handleSearchUser} />
                <Tabs value={tabValue} onChange={handleChangeTab}>
                    <Tab label="Guide" />
                    <Tab label="Operator" />
                </Tabs>
            </Box>
            <Box px={4} pb={12}>
                <TabPanelUser
                    index={0}
                    value={tabValue}
                    participants={participants}
                    users={guiders}
                    handleToggleSelectUser={handleSetParticipants}
                />
                <TabPanelUser
                    index={1}
                    value={tabValue}
                    participants={participants}
                    users={operators}
                    handleToggleSelectUser={handleSetParticipants}
                />
            </Box>
            <Box
                sx={{
                    position: "fixed",
                    bottom: 0,
                    width: "100%",
                }}
            >
                <ParticipantList participants={participants} onClick={handleSetParticipants} />
                <Footer />
            </Box>
        </>
    )
}
