import React, {useMemo, useState} from 'react';

import Box from '@mui/material/Box';
import ButtonBase from "@mui/material/ButtonBase";
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import InputBase from '@mui/material/InputBase';
import Stack from '@mui/material/Stack';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";


import {useQueryDocs, useQueryListen} from "../../hooks/firestore";
import {Reservation} from "../../models/Reservation";


import dayjs, {Dayjs} from 'dayjs';
import {DateRange, DateRangePicker, SingleInputDateRangeField} from '@mui/x-date-pickers-pro';
import {
    DataGridPro,
    GridToolbar,
    GridToolbarQuickFilter,
    FilterColumnsArgs,
    GetColumnForNewFilterArgs,
    GridColDef,
    GridActionsCellItem,
    GridHeaderFilterCellProps,
    useGridApiContext,
    getDefaultGridFilterModel,
    useGridSelector, gridFilterModelSelector, GridRowSelectionModel,
    getGridStringOperators,
    getGridNumericOperators,
    getGridSingleSelectOperators,
} from '@mui/x-data-grid-pro';
import {GridEventListener} from "@mui/x-data-grid/models/events";

import StarIcon from "@mui/icons-material/Star";
import StarOutlinedIcon from "@mui/icons-material/StarOutline";
import NoShowIcon from "@mui/icons-material/NoAccounts";
import NoShowOutlinedIcon from "@mui/icons-material/NoAccountsOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutline";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import FiberNewIcon from '@mui/icons-material/FiberNew';
import ReservationModal from "../../partials/ReservationModal";
import {updateFireStore, updateRealtime} from "../../hooks/firebase";
import CreateReservationModal from "../../partials/CreateReservationModal";
import {useRead} from "../../hooks/realtime";
import DateTabs from "../../components/DateTabs";
import {Product} from "../../models/Product";
import {Pickup} from "../../models/Pickup";
import {capitalize, InputLabel, Select, SelectChangeEvent} from "@mui/material";

import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    ChartData,
    TooltipItem,
    PointElement,
    LineElement,
} from 'chart.js';
import {Doughnut, Bar, Line} from 'react-chartjs-2';
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import Icon from '@mui/material/Icon';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";


import ReservationIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import PeopleIcon from '@mui/icons-material/PeopleOutline';
import {useAuth} from "../../hooks/auth";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    ArcElement,
    Tooltip,
    Legend
);

type ReservationRow = {
    id: string,
    memo: string,
    date: string,
    product: string,
    pickupPlace: string,
    people: string,
    option: string,
    clientName: string,
    nationality: string,
    language: string,
    agency: string,
    agencyCode: string,
    star: boolean,
    check: boolean,
    noShow: boolean,
    canceledAt: string | undefined,
};

type ReservationRows = {
    total: ReservationRow[],
    all: ReservationRow[],
    present: ReservationRow[],
    noShow: ReservationRow[],
    star: ReservationRow[],
    noStar: ReservationRow[],
    check: ReservationRow[],
    cancel: ReservationRow[],
}

type ReservationAggregation = {
    count: number,
    people: number,
    reservations: Reservation[]
}


type Products = { [productKey: string]: Product }

function DateRangePickerValue(props: { dateRange: DateRange<Dayjs>, onChange: (dr: DateRange<Dayjs>) => void }) {
    const {dateRange: value, onChange: setValue} = props;
    return (

        <DateRangePicker
            format={'YY-MM-DD'}
            slots={{field: SingleInputDateRangeField}}
            value={value}
            onChange={(newValue) => setValue(newValue)}
        />
    );
}

type ReservationCategoryType = 'all' | 'present' | 'noShow' | 'cancel'

function ReservationCalendar(props: { productOrders: string[], dateCount: { [product: string]: DayCount }, dateRange: [Dayjs | null, Dayjs | null] }) {
    const {productOrders, dateRange, dateCount} = props;
    const length = (dateRange[1]?.diff(dayjs(dateRange[0]?.format('YYYY-MM-DD')), 'day') ?? 0);
    const days = Array(length >= 0 ? length + 1 : 0).fill(dateRange[0] || dayjs()).map((day, idx) => {
        return dayjs(day).add(idx, 'days').format('YYYY-MM-DD');
    });

    const dayByDay = (dn: number) => {
        switch (dn) {
            case 1:
                return '월';
            case 2:
                return '화';
            case 3:
                return '수';
            case 4:
                return '목';
            case 5:
                return '금';
            case 6:
                return '토';
            case 0:
                return '일';
            default:
                return dn;
        }
    }

    const columns: GridColDef[] = productOrders.map((pName) => {
        return ({field: pName, headerName: pName, minWidth: 150, align: 'center', headerAlign: 'center'});
    });
    const columns2: GridColDef[] = days.map((day) => {
        return ({
            field: day,
            headerName: `${day}(${dayByDay(new Date(day).getDay())})`,
            minWidth: 150,
            align: 'center',
            headerAlign: 'center'
        });
    });

    columns.unshift({field: 'date', headerName: 'DATE', minWidth: 150, align: 'center', headerAlign: 'center'})
    columns2.unshift({field: 'product', headerName: 'PRODUCT', minWidth: 150, align: 'center', headerAlign: 'center'})


    const rows = days.map((day) => {
        return Object.fromEntries([['id', day], ['date', `${day}(${dayByDay(new Date(day).getDay())})`], ...productOrders.map(pName => [pName, dateCount[pName][day]])]);
    });
    const rows2 = productOrders.map((pName) => {
        return Object.fromEntries([['id', pName], ['product', pName], ...days.map(day => [day, dateCount[pName][day]])]);
    });

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: '일별 투어 인원 수',
            },
        },
    };

    const labels = days;

    const data = {
        labels,
        datasets: productOrders.map(pName => ({
            label: pName,
            data: days.map((day) => dateCount[pName][day]),
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1
        }))
    };
    return (
        <Stack gap={2}>
            <Line options={options} data={data}/>
            <Box
                height={'80vh'}
            >
                <DataGridPro columns={columns} rows={rows} slots={{toolbar:GridToolbar}}/>
            </Box>
            <Box
                height={'80vh'}
            >
                <DataGridPro columns={columns2} rows={rows2} slots={{toolbar:GridToolbar}}/>
            </Box>
        </Stack>
    )
}

function ReservationCharts(props: { reservations: Reservation[], products: Products, dateRange: [Dayjs | null, Dayjs | null] }) {
    const [tab, setTab] = useState<ReservationCategoryType>('all');
    const allReservations = reservationStatics(props.reservations);
    const categorizedReservations = reservationCategorize(props.reservations, props.products);
    const nationalityReservations = categorizeReservationByProp(props.reservations, 'nationality');
    const agencyReservations = categorizeReservationByProp(props.reservations, 'agency');
    const dateByProductReservations = dayByDayReservationByProduct(props.reservations);
    const productOrders = Object.entries(dateByProductReservations.productCount).sort(([_, a], [__, b]) => b - a).map(([name]) => name);
    const chartData = [
        agencyReservations,
        nationalityReservations,
    ];
    const handleSetTab = (e: any, tab: any) => {
        setTab(tab)
    }

    const {data: pickups} = useRead<{ [areaName: string]: any }>('/pickup');
    const pickupOrder = Object.entries(pickups ?? {}).reduce((result, [areaName, pickups]) => {
        const capitalizedAreaName = areaName.charAt(0).toUpperCase() + areaName.slice(1);
        result[capitalizedAreaName] = Object.keys(pickups);
        return result;
    }, {} as { [areaName: string]: string[] });

    return (
        <Stack gap={2}>
            <Box>
                <Stack flexDirection={'row'} justifyContent={'stretch'}>
                    {
                        Object.entries(pickupOrder).map(([areaName, pickups]) => {
                            const labels = pickups.map((p) => `${p}(${categorizedReservations?.place?.[areaName]?.[p]?.[tab].counts ?? 0}/${categorizedReservations?.place?.[areaName]?.[p]?.[tab].people ?? 0})`)
                            const countsData = pickups.map((p) => categorizedReservations?.place?.[areaName]?.[p]?.[tab].counts ?? 0);
                            const peopleData = pickups.map((p) => categorizedReservations?.place?.[areaName]?.[p]?.[tab].people ?? 0);
                            const data = {
                                labels: labels,
                                datasets: [
                                    {
                                        label: 'Reservations',
                                        data: countsData,
                                        backgroundColor: [
                                            'rgba(255, 99, 132, 0.2)',
                                            'rgba(54, 162, 235, 0.2)',
                                            'rgba(255, 206, 86, 0.2)',
                                            'rgba(75, 192, 192, 0.2)',
                                            'rgba(153, 102, 255, 0.2)',
                                            'rgba(255, 159, 64, 0.2)',
                                        ],
                                        borderColor: [
                                            'rgba(255, 99, 132, 1)',
                                            'rgba(54, 162, 235, 1)',
                                            'rgba(255, 206, 86, 1)',
                                            'rgba(75, 192, 192, 1)',
                                            'rgba(153, 102, 255, 1)',
                                            'rgba(255, 159, 64, 1)',
                                        ],
                                        borderWidth: 1,
                                    },
                                    {
                                        label: 'People',
                                        data: peopleData,
                                        backgroundColor: [
                                            'rgba(255, 99, 132, 0.2)',
                                            'rgba(54, 162, 235, 0.2)',
                                            'rgba(255, 206, 86, 0.2)',
                                            'rgba(75, 192, 192, 0.2)',
                                            'rgba(153, 102, 255, 0.2)',
                                            'rgba(255, 159, 64, 0.2)',
                                        ],
                                        borderColor: [
                                            'rgba(255, 99, 132, 1)',
                                            'rgba(54, 162, 235, 1)',
                                            'rgba(255, 206, 86, 1)',
                                            'rgba(75, 192, 192, 1)',
                                            'rgba(153, 102, 255, 1)',
                                            'rgba(255, 159, 64, 1)',
                                        ],
                                        borderWidth: 1,
                                    },
                                ],
                            };

                            return (
                                <Box
                                    sx={{
                                        flex: 1,
                                        width: '25%',
                                        display: "flex",
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        position: 'relative',
                                        my: 2,
                                    }}
                                >
                                    <Doughnut
                                        data={data}
                                        options={{
                                            plugins: {
                                                legend: {
                                                    position: 'left', // legend position to 'left'
                                                    align: 'start', // start alignment (top for vertical, left for horizontal)
                                                    labels: {
                                                        boxWidth: 20,
                                                        padding: 10
                                                    }
                                                },
                                                tooltip: {
                                                    callbacks: {
                                                        label: (context: TooltipItem<'doughnut'>) => {
                                                            const label = context.label!;
                                                            const value = context.parsed as number;
                                                            const dataset = context.dataset!.data as number[];
                                                            const total = dataset.reduce((prev, curr) => prev + curr, 0);
                                                            const percentage = Math.round((value / total) * 100);

                                                            return `${label}: ${percentage}%`;
                                                        },
                                                    },
                                                },
                                            },
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            position: 'absolute',
                                            top: 0,
                                            transform: 'translate(-50%, 0)',
                                            textAlign: 'center',
                                        }}
                                        variant={'caption'}
                                    >
                                        {capitalize(areaName)}<br/>
                                        {countsData.reduce((a, b) => a + b)}/{peopleData.reduce((a, b) => a + b)}
                                    </Typography>
                                </Box>
                            )
                        })
                    }
                </Stack>
                <Stack flexDirection={'row'} justifyContent={'stretch'}>

                    {
                        chartData.map((dataSet: ReservationByProp) => {
                            const props = Object.entries(dataSet).sort(([__, {count: countA}], [_, {count: countB}]) => countB.all.reservation - countA.all.reservation).map(([p]) => p);
                            const labels = props.map((p) => `${p}(${dataSet[p].count[tab].reservation ?? 0}/${dataSet[p].count[tab].people ?? 0})`)
                            const countsData = props.map((p) => dataSet[p].count[tab].reservation ?? 0);
                            const peopleData = props.map((p) => dataSet[p].count[tab].people ?? 0);
                            const data = {
                                labels: labels,
                                datasets: [
                                    {
                                        label: 'Reservations',
                                        data: countsData,
                                        backgroundColor: [
                                            'rgba(255, 99, 132, 0.2)',
                                            'rgba(54, 162, 235, 0.2)',
                                            'rgba(255, 206, 86, 0.2)',
                                            'rgba(75, 192, 192, 0.2)',
                                            'rgba(153, 102, 255, 0.2)',
                                            'rgba(255, 159, 64, 0.2)',
                                        ],
                                        borderColor: [
                                            'rgba(255, 99, 132, 1)',
                                            'rgba(54, 162, 235, 1)',
                                            'rgba(255, 206, 86, 1)',
                                            'rgba(75, 192, 192, 1)',
                                            'rgba(153, 102, 255, 1)',
                                            'rgba(255, 159, 64, 1)',
                                        ],
                                        borderWidth: 1,
                                    },
                                    {
                                        label: 'People',
                                        data: peopleData,
                                        backgroundColor: [
                                            'rgba(255, 99, 132, 0.2)',
                                            'rgba(54, 162, 235, 0.2)',
                                            'rgba(255, 206, 86, 0.2)',
                                            'rgba(75, 192, 192, 0.2)',
                                            'rgba(153, 102, 255, 0.2)',
                                            'rgba(255, 159, 64, 0.2)',
                                        ],
                                        borderColor: [
                                            'rgba(255, 99, 132, 1)',
                                            'rgba(54, 162, 235, 1)',
                                            'rgba(255, 206, 86, 1)',
                                            'rgba(75, 192, 192, 1)',
                                            'rgba(153, 102, 255, 1)',
                                            'rgba(255, 159, 64, 1)',
                                        ],
                                        borderWidth: 1,
                                    },
                                ],
                            };

                            return (
                                <Box
                                    sx={{
                                        width: '25%',
                                        flex: 1,
                                        display: "flex",
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        position: 'relative',
                                        my: 2,
                                    }}
                                >
                                    <Doughnut
                                        data={data}
                                        options={{
                                            plugins: {
                                                legend: {
                                                    position: 'left', // legend position to 'left'
                                                    align: 'start', // start alignment (top for vertical, left for horizontal)
                                                    labels: {
                                                        boxWidth: 20,
                                                        padding: 10
                                                    }
                                                },
                                                tooltip: {
                                                    callbacks: {
                                                        label: (context: TooltipItem<'doughnut'>) => {
                                                            const label = context.label!;
                                                            const value = context.parsed as number;
                                                            const dataset = context.dataset!.data as number[];
                                                            const total = dataset.reduce((prev, curr) => prev + curr, 0);
                                                            const percentage = Math.round((value / total) * 100);

                                                            return `${label}: ${percentage}%`;
                                                        },
                                                    },
                                                },
                                            },
                                        }}
                                    />
                                </Box>
                            )
                        })
                    }
                </Stack>
            </Box>
            <Stack
                gap={2}
                flexDirection={'row'}
            >
                {

                    Object.entries(pickupOrder).map(([areaName, pickups]) => {
                        const data = Object.entries(categorizedReservations.product)
                            .filter(([productName, {area, ...statics}]) => area === areaName)
                            .map(([productName, {area, ...statics}]) => ([productName, statics]))
                            .reduce((result, [productName, statics]) => {
                                result.labels.push(productName as string);
                                if (tab === 'all' || tab === 'present')
                                    result.datasets[0].data.push((statics as { present: any, noShow: any, cancel: any }).present.counts)
                                if (tab === 'all' || tab === 'noShow')
                                    result.datasets[1].data.push((tab === 'all' ? -1 : 1) * (statics as { present: any, noShow: any, cancel: any }).noShow.counts)
                                if (tab === 'all' || tab === 'cancel')
                                    result.datasets[2].data.push((tab === 'all' ? -1 : 1) * (statics as { present: any, noShow: any, cancel: any }).cancel.counts)
                                return result;
                            }, {
                                labels: [] as string[],
                                datasets: [
                                    {
                                        label: 'Present',
                                        data: [] as number[],
                                        backgroundColor: 'rgb(255, 99, 132)',
                                    },
                                    {
                                        label: 'No Show',
                                        data: [] as number[],
                                        backgroundColor: 'rgb(75, 192, 192)',
                                    },
                                    {
                                        label: 'Cancel',
                                        data: [] as number[],
                                        backgroundColor: 'rgb(53, 162, 235)',
                                    },
                                ]
                            });
                        if (tab === 'present')
                            data.datasets.splice(1, 2)
                        if (tab === 'noShow') {
                            data.datasets.splice(0, 1)
                            data.datasets.splice(1, 1)
                        }
                        if (tab === 'cancel')
                            data.datasets.splice(0, 2)
                        return (
                            <Box
                                sx={{
                                    width: '50%',
                                    height: 700,
                                    display: "flex",
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <Bar data={data} options={{
                                    responsive: true,
                                    maintainAspectRatio: false,
                                    scales: {
                                        x: {stacked: true},
                                        y: {stacked: true}
                                    },
                                }}/>
                            </Box>
                        )
                    })
                }
            </Stack>

            <ReservationCalendar productOrders={productOrders} dateCount={dateByProductReservations[tab]}
                                 dateRange={props.dateRange}/>
        </Stack>
    )
}


function reservationCategorize(reservations: Reservation[], products: Products) {
    const categorized: {
        place: {
            [area: string]: {
                [pickup: string]: Reservation[]
            }
        },
        product: {
            [product: string]: {
                area: string,
                reservations: Reservation[]
            }
        }
    } = {place: {}, product: {}};

    reservations.reduce((result, reservation) => {
        const product: Product = products[reservation.productId];
        const productName = product?.name ?? reservation.product;
        const area = product?.area ?? 'Seoul';
        const pickup = reservation.pickupPlace;

        if (!result.product[productName]) result.product[productName] = {area, reservations: []};
        if (!result.place[area]) result.place[area] = {};
        if (!result.place[area][pickup]) result.place[area][pickup] = [];

        result.product[productName].reservations.push(reservation);
        result.place[area][pickup].push(reservation);

        return result;
    }, categorized);

    const toStatics = (categorized: { [k: string]: Reservation[] }) => Object.fromEntries(Object.entries(categorized).map(([product, reservations]) => [product, reservationStatics(reservations)]))
    return {
        product: {
            ...Object.fromEntries(Object.entries(categorized.product).map(([productName, {
                area,
                reservations
            }]) => [productName, {area, ...reservationStatics(reservations)}]))
        },
        place: {
            ...Object.fromEntries(Object.entries(categorized.place).map(([area, pickupReservations]) => [area, toStatics(pickupReservations)]))
        }
    }
}

type ReservationByProp = {
    [key: string]: {
        reservations: Reservation[],
        count: {
            [key in ReservationCategoryType]: {
                reservation: number,
                people: number,
            }
        }
        product: {
            [product: string]: {
                [key in ReservationCategoryType]: {
                    reservation: number,
                    people: number,
                }
            }
        }
    }
}

type DayCount = {
    [date: string]: number
}

function dayByDayReservationByProduct(reservations: Reservation[]) {
    return reservations.reduce((result, reservation) => {
        const date = reservation.date;
        const product = reservation.product;
        const people = reservation.adult + reservation.kid + reservation.infant;
        if (!result.all[product]) result.all[product] = {}
        if (!result.present[product]) result.present[product] = {}
        if (!result.cancel[product]) result.cancel[product] = {}
        if (!result.noShow[product]) result.noShow[product] = {}
        if (!result.all[product][date]) result.all[product][date] = 0
        if (!result.present[product][date]) result.present[product][date] = 0
        if (!result.cancel[product][date]) result.cancel[product][date] = 0
        if (!result.noShow[product][date]) result.noShow[product][date] = 0
        if (!result.productCount[product]) result.productCount[product] = 0;

        result.productCount[product] += people;
        result.all[product][date] += people;

        if (reservation.canceledAt) {
            result.cancel[product][date] += people;
        } else if (reservation.noShow) {
            result.noShow[product][date] += people;
        } else if (!reservation.noShow) {
            result.present[product][date] += people;
        }

        return result;
    }, ({
        productCount: {},
        all: {},
        present: {},
        cancel: {},
        noShow: {}
    } as { productCount: { [productName: string]: number }, all: { [product: string]: DayCount }, present: { [product: string]: DayCount }, cancel: { [product: string]: DayCount }, noShow: { [product: string]: DayCount } }))


}

function categorizeReservationByProp(reservations: Reservation[], propName: 'nationality' | 'agency') {
    return reservations.reduce((result, reservation) => {
        const prop = reservation[propName] ?? 'NONE'
        if (!result[prop]) {
            result[prop] = {
                reservations: [],
                count: {
                    all: {
                        reservation: 0,
                        people: 0
                    },
                    present: {
                        reservation: 0,
                        people: 0
                    },
                    cancel: {
                        reservation: 0,
                        people: 0
                    },
                    noShow: {
                        reservation: 0,
                        people: 0
                    },
                },
                product: {}
            }
        }
        if (!result[prop].product[reservation.product]) {
            result[prop].product[reservation.product] = {
                all: {
                    reservation: 0,
                    people: 0
                },
                present: {
                    reservation: 0,
                    people: 0
                },
                cancel: {
                    reservation: 0,
                    people: 0
                },
                noShow: {
                    reservation: 0,
                    people: 0
                },
            }
        }

        const cursor = result[prop];
        cursor.reservations.push(reservation);

        cursor.count.all.reservation += 1;
        cursor.count.all.people += (reservation.adult + reservation.kid + reservation.infant)
        cursor.product[reservation.product].all.reservation += 1;
        cursor.product[reservation.product].all.people += (reservation.adult + reservation.kid + reservation.infant)

        if (reservation.canceledAt) {
            cursor.count.cancel.reservation += 1;
            cursor.count.cancel.people += (reservation.adult + reservation.kid + reservation.infant)
            cursor.product[reservation.product].cancel.reservation += 1;
            cursor.product[reservation.product].cancel.people += (reservation.adult + reservation.kid + reservation.infant)
        } else if (reservation.noShow) {
            cursor.count.noShow.reservation += 1;
            cursor.count.noShow.people += (reservation.adult + reservation.kid + reservation.infant)
            cursor.product[reservation.product].noShow.reservation += 1;
            cursor.product[reservation.product].noShow.people += (reservation.adult + reservation.kid + reservation.infant)
        } else {
            cursor.count.present.reservation += 1;
            cursor.count.present.people += (reservation.adult + reservation.kid + reservation.infant)
            cursor.product[reservation.product].present.reservation += 1;
            cursor.product[reservation.product].present.people += (reservation.adult + reservation.kid + reservation.infant)
        }

        return result;
    }, {} as ReservationByProp)
}

function reservationStatics(reservations: Reservation[]) {
    return {
        all: countWithFilter(reservations, () => true),
        present: countWithFilter(reservations, (r) => !r.canceledAt && !r.noShow),
        cancel: countWithFilter(reservations, (r) => !!r.canceledAt),
        noShow: countWithFilter(reservations, (r) => !r.canceledAt && r.noShow),
    }
}


function countWithFilter(reservations: Reservation[], filter: (reservation: Reservation) => boolean) {
    const filtered = reservations.filter(filter);
    return {
        counts: filtered.length,
        people: filtered.reduce((a, b) => (a + b.adult + b.kid + b.infant), 0),
        reservations: filtered,
    }
}


const getDefaultFilter = (field: string) => ({field, operator: 'isAnyOf'});

const filterGen = (productNames: string[]) => function AdminFilter(props: GridHeaderFilterCellProps) {
    const {colDef} = props;
    const apiRef = useGridApiContext();
    const filterModel = useGridSelector(apiRef, gridFilterModelSelector);
    const currentFieldFilters = React.useMemo(
        () => filterModel.items?.filter(({field}) => field === colDef.field),
        [colDef.field, filterModel.items],
    );


    const handleChange = React.useCallback(
        (event: SelectChangeEvent) => {
            if (!event.target.value) {
                if (currentFieldFilters[0]) {
                    apiRef.current.deleteFilterItem(currentFieldFilters[0]);
                }
                return;
            }
            apiRef.current.upsertFilterItem({
                ...(currentFieldFilters[0] ?? getDefaultFilter(colDef.field)),
                value: event.target.value,
            });
        },
        [apiRef, colDef.field, currentFieldFilters],
    );

    const value = currentFieldFilters[0]?.value ?? [];
    const label = 'is any of';

    return (
        <FormControl variant="standard" sx={{m: 1, minWidth: 120}} fullWidth>
            <InputLabel id="select-is-admin-label">Select</InputLabel>
            <Select
                labelId="select-is-admin-label"
                id="select-is-admin"
                multiple
                value={value}
                onChange={handleChange}
                label={'Select'}
            >
                {
                    productNames.map(p => (
                        <MenuItem key={p} value={p}>{p}</MenuItem>
                    ))
                }

            </Select>
        </FormControl>
    );
}


export default function () {
    const [dateRange, setDateRange] = React.useState<DateRange<Dayjs>>([dayjs(), dayjs()]);
    const [tempDateRange, setTempDateRange] = React.useState<DateRange<Dayjs>>([dayjs(), dayjs()]);
    const formatDateRanges = dateRange.map(djs => djs?.format('YYYY-MM-DD'));
    const [reservationId, setReservationId] = useState<string | null>(null);
    const [newReservation, setNewReservation] = useState<boolean>(false);
    const [copyReservation, setCopyReservation] = useState<Reservation | null>(null);
    const [loadKey, setLoadKey] = useState<string>(Date.now() + '');
    const [tab, setTab] = useState<number>(0);
    const {
        data: _reservations, setData: setReservations, loading
    } =
        (dateRange[0]?.diff(dateRange[1], 'months') ?? 0) > 6
            ?
            useQueryDocs<Reservation>('reservation', [['date', ">=", formatDateRanges[0]!], ['date', '<=', formatDateRanges[1]!]], formatDateRanges.join('-') + loadKey)
            :
            useQueryListen<Reservation>('reservation', [['date', ">=", formatDateRanges[0]!], ['date', '<=', formatDateRanges[1]!]], formatDateRanges.join('-') + loadKey);

    const {
        data: products,
    } = useRead<{ [productId: string]: Product }>('/product');
    const {
        data: pickups,
    } = useRead<{ [area: string]: { [pickupName: string]: Pickup } }>('/pickup');
    const {
        data: notes,
        setData: setNotes,
    } = useRead<string[]>(`note/${dateRange[0]?.format('YYYY-MM-DD')}`);


    const reservations =
        _reservations.filter(({agency})=> agency !== 'KTOS')
            .map((reservation)=> {
                const product = products?.[reservation.productId];
                reservation.product = product?.name ?? reservation.product;
                return reservation
            })

    const aggregation = aggregateReservations(reservations ?? [], products ?? {});

    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);


    const handleDateRange = (dr: DateRange<Dayjs>) => {
        setTempDateRange(dr);
    }

    const handleConfirmDateRange = () => {
        setDateRange(tempDateRange);
    }

    const handleClick: GridEventListener<'rowClick'> = (row, event, context) => {
        setReservationId((row as unknown as Reservation).id)
    }


    const handleToggleBuilder = (id: string, property: 'check' | 'noShow' | 'star') => () => {

        const toggledReservation = reservations.find((reservation) => reservation.id === id);
        if (!toggledReservation) return reservations;

        toggledReservation[property] = !toggledReservation[property];

        reservations[reservations.findIndex((reservation) => reservation.id === id)] = toggledReservation
        setReservations([...reservations]);

        const update = {[property]: toggledReservation[property]};
        updateFireStore('reservation', toggledReservation.id, update).catch(console.error);

    }


    const actions: GridColDef[] = [

        {
            field: 'star',
            type: 'actions',
            headerName: 'Star',
            minWidth: 30,
            align: 'center',
            headerAlign: 'center',
            getActions: (params) => [
                <GridActionsCellItem
                    icon={params.row.star ? <StarIcon color={'primary'} fontSize={'large'}/> :
                        <StarOutlinedIcon fontSize={'large'}/>}
                    onClick={handleToggleBuilder(params.id + '', 'star')}
                    label={'star'}
                />
            ]
        },
        {
            field: 'noShow',
            type: 'actions',
            headerName: 'No Show',
            minWidth: 30,
            align: 'center',
            headerAlign: 'center',
            getActions: (params) => [
                <GridActionsCellItem
                    icon={params.row.noShow ? <NoShowIcon color={"primary"} fontSize={'large'}/> :
                        <NoShowOutlinedIcon fontSize={'large'}/>}
                    onClick={handleToggleBuilder(params.id + '', 'noShow')}
                    label={'noShow'}
                />
            ]
        },
        {
            field: 'check',
            type: 'actions',
            headerName: 'Check',
            minWidth: 30,
            align: 'center',
            headerAlign: 'center',
            getActions: (params) => [
                <GridActionsCellItem
                    icon={params.row.check ? <CheckCircleIcon color={"primary"} fontSize={'large'}/> :
                        <CheckCircleOutlinedIcon fontSize={'large'}/>}
                    onClick={handleToggleBuilder(params.id + '', 'check')}
                    label={'check'}
                />
            ]
        },
    ]
    const reservationRows =
        tab === 0
            ? aggregation.present
            : tab === 1
                ? aggregation.check
                : tab === 2
                    ? aggregation.star
                    : tab === 3
                        ? aggregation.noStar
                        : tab === 4
                            ? aggregation.noShow
                            : tab === 5
                                ? aggregation.cancel
                                : tab === 6
                                    ? aggregation.all
                                    : aggregation.total;

    const productNames = reservationRows.reduce((s, r) => {
        s.add(r.product);
        return s;
    }, new Set<string>());
    const selectedReservations = reservations.filter((r) => rowSelectionModel.includes(r.id));
    const ProductFilter = filterGen([...productNames.values()].sort((a, b) => a > b ? 1 : -1));
    const defaultColumns: GridColDef[] = [
        {field: 'memo', headerName: 'MEMO', minWidth: 300, align: 'center', headerAlign: 'center'},
        {field: 'date', headerName: 'DATE', minWidth: 100, align: 'center', headerAlign: 'center'},
        {
            field: 'product',
            type: 'singleSelect',
            headerName: 'PRODUCT',
            minWidth: 200,
            align: 'center',
            headerAlign: 'center',
            renderHeaderFilter: (params) => <ProductFilter {...params}/>
        },
        {field: 'pickupPlace', headerName: 'PICKUP', minWidth: 100, align: 'center', headerAlign: 'center'},
        {field: 'people', headerName: 'PEOPLE', minWidth: 100, align: 'center', headerAlign: 'center'},
        {field: 'option', headerName: 'OPTION', minWidth: 200, align: 'center', headerAlign: 'center'},
        {field: 'clientName', headerName: 'NAME', minWidth: 200, align: 'center', headerAlign: 'center'},
        {field: 'nationality', headerName: 'NATIONALITY', minWidth: 100, align: 'center', headerAlign: 'center'},
        {field: 'language', headerName: 'LANGUAGE', minWidth: 100, align: 'center', headerAlign: 'center'},
        {field: 'agency', headerName: 'AGENCY', minWidth: 50, align: 'center', headerAlign: 'center'},
        {field: 'agencyCode', headerName: 'AGENCY CODE', minWidth: 50, align: 'center', headerAlign: 'center'},
    ];

    const cancelColumn: GridColDef[] = [
        {field: 'memo', headerName: 'MEMO', minWidth: 300, align: 'center', headerAlign: 'center'},
        {field: 'date', headerName: 'DATE', minWidth: 100, align: 'center', headerAlign: 'center'},
        {field: 'canceledAt', headerName: 'CANCEL', minWidth: 100, align: 'center', headerAlign: 'center'},
        {
            field: 'product', headerName: 'PRODUCT', minWidth: 200, align: 'center', headerAlign: 'center',
            renderHeaderFilter: (params) => <ProductFilter {...params}/>
        },
        {field: 'pickupPlace', headerName: 'PICKUP', minWidth: 100, align: 'center', headerAlign: 'center'},
        {field: 'people', headerName: 'PEOPLE', minWidth: 100, align: 'center', headerAlign: 'center'},
        {field: 'option', headerName: 'OPTION', minWidth: 200, align: 'center', headerAlign: 'center'},
        {field: 'clientName', headerName: 'NAME', minWidth: 200, align: 'center', headerAlign: 'center'},
        {field: 'nationality', headerName: 'NATIONALITY', minWidth: 100, align: 'center', headerAlign: 'center'},
        {field: 'language', headerName: 'LANGUAGE', minWidth: 100, align: 'center', headerAlign: 'center'},
        {field: 'agency', headerName: 'AGENCY', minWidth: 50, align: 'center', headerAlign: 'center'},
        {field: 'agencyCode', headerName: 'AGENCY CODE', minWidth: 50, align: 'center', headerAlign: 'center'},
    ];
    const allColumn: GridColDef[] = [
        ...actions,
        {field: 'memo', headerName: 'MEMO', minWidth: 300, align: 'center', headerAlign: 'center'},
        {field: 'date', headerName: 'DATE', minWidth: 100, align: 'center', headerAlign: 'center'},
        {field: 'canceledAt', headerName: 'Cancel', minWidth: 100, align: 'center', headerAlign: 'center'},
        {
            field: 'product', headerName: 'PRODUCT', minWidth: 200, align: 'center', headerAlign: 'center',
            renderHeaderFilter: (params) => <ProductFilter {...params}/>
        },
        {field: 'pickupPlace', headerName: 'PICKUP', minWidth: 100, align: 'center', headerAlign: 'center'},
        {field: 'people', headerName: 'PEOPLE', minWidth: 100, align: 'center', headerAlign: 'center'},
        {field: 'option', headerName: 'OPTION', minWidth: 200, align: 'center', headerAlign: 'center'},
        {field: 'clientName', headerName: 'NAME', minWidth: 200, align: 'center', headerAlign: 'center'},
        {field: 'nationality', headerName: 'NATIONALITY', minWidth: 100, align: 'center', headerAlign: 'center'},
        {field: 'language', headerName: 'LANGUAGE', minWidth: 100, align: 'center', headerAlign: 'center'},
        {field: 'agency', headerName: 'AGENCY', minWidth: 50, align: 'center', headerAlign: 'center'},
        {field: 'agencyCode', headerName: 'AGENCY CODE', minWidth: 50, align: 'center', headerAlign: 'center'},
    ];


    const columns =
        tab === 5 ?
            cancelColumn :
            tab === 6 ?
                allColumn :
                defaultColumns


    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    px: 8
                }}
            >
                {
                    rowSelectionModel.length > 0
                        ? (
                            <Box sx={{
                                position: 'fixed',
                                display: 'inline-flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                bottom: 0,
                                left: '50%',
                                transform: 'translate(-50%, -1em)',
                                zIndex: 999,
                                backgroundColor: 'white',
                                borderRadius: 5,
                                boxShadow: 2,
                                py: 2,
                                px: 3
                            }}>


                                <ButtonBase
                                    component={Stack}
                                    flexDirection={'row'}
                                    gap={2}
                                    onClick={() => {
                                        setRowSelectionModel([])
                                    }}
                                >
                                    <Box
                                        color={'primary.main'}
                                        display={'flex'}
                                        alignItems={'center'}
                                        gap={1}
                                    >
                                        <Icon>
                                            <ReservationIcon/>
                                        </Icon>
                                        <Typography variant={'h6'} color={'inherit'}>
                                            {selectedReservations.reduce((result, a) => result + 1, 0).toLocaleString()}
                                        </Typography>
                                    </Box>
                                    <Box
                                        color={'accentPrimary.main'}
                                        display={'flex'}
                                        alignItems={'center'}
                                        gap={1}
                                    >
                                        <Icon>
                                            <PeopleIcon/>
                                        </Icon>
                                        <Typography variant={'h6'} color={'inherit'}>
                                            {selectedReservations.reduce((result, a) => result + a.adult + a.kid + a.infant, 0).toLocaleString()}
                                        </Typography>
                                    </Box>
                                </ButtonBase>

                            </Box>
                        )
                        : null
                }
                <Card
                    sx={{
                        display: 'inline-block',
                        flex: 0
                    }}
                >
                    <CardContent sx={(theme) => ({
                        display: 'flex',
                        justifyContent: 'space-between',
                        color: theme.palette.text.primary,
                        padding: '8px !important',
                    })}>
                        <DateTabs date={tempDateRange[0]?.toDate() ?? null} onChange={(date) => {
                            setTempDateRange([dayjs(date), dayjs(date)])
                            setDateRange([dayjs(date), dayjs(date)])
                        }}/>
                        <Box
                            display={'flex'}
                            gap={1}
                        >
                            <DateRangePickerValue onChange={handleDateRange} dateRange={tempDateRange}/>
                            {
                                loading ?
                                    <CircularProgress/>
                                    : <Button onClick={handleConfirmDateRange} variant={'contained'}
                                              disabled={dateRange.map(d => d?.format('YYYY-MM-DD')).join() === tempDateRange.map(d => d?.format('YYYY-MM-DD')).join()}>
                                        Confirm
                                    </Button>

                            }

                        </Box>
                    </CardContent>
                </Card>


                <Card>
                    <CardContent>
                        <Tabs onChange={(_, value) => setTab(value)} value={tab}>
                            <Tab label={`Present(${aggregation.present.length})`} id={'tab-present'}/>
                            <Tab label={`Check(${aggregation.check.length})`} id={'tab-check'}/>
                            <Tab label={`Star(${aggregation.star.length})`} id={'tab-star'}/>
                            <Tab label={`No Star(${aggregation.noStar.length})`} id={'tab-star'}/>
                            <Tab label={`No Show(${aggregation.noShow.length})`} id={'tab-noshow'}/>
                            <Tab label={`Cancel(${aggregation.cancel.length})`} id={'tab-cancel'}/>
                            <Tab label={`All(${aggregation.all.length})`} id={'tab-all'}/>
                            <Tab label={`Total(${aggregation.total.length})`} id={'tab-total'}/>
                        </Tabs>
                        <Box
                            sx={{
                                height: '80vh',
                                mt: 2,
                            }}
                        >
                            <DataGridPro
                                slots={{
                                    toolbar: (...props) => (
                                        <Box sx={{
                                            width: '100%',
                                            display: 'flex',
                                            alignItems: 'flex-end',
                                            py: 2,
                                            px: 2
                                        }}>
                                            <GridToolbarQuickFilter
                                                {...props}
                                                fullWidth
                                            />
                                        </Box>
                                    )
                                }}
                                rows={reservationRows}
                                columns={columns}
                                // onRowClick={handleClick}
                                checkboxSelection
                                onRowSelectionModelChange={(newRowSelectionModel) => {
                                    setRowSelectionModel(newRowSelectionModel);
                                }}
                                rowSelectionModel={rowSelectionModel}
                                unstable_headerFilters
                            />
                        </Box>
                    </CardContent>
                </Card>
                <Card>
                    <CardContent>
                        <ReservationCharts reservations={selectedReservations} products={products ?? {}}
                                           dateRange={dateRange}/>
                    </CardContent>
                </Card>
            </Box>
            {
                reservationId
                    ? <ReservationModal
                        reservationId={reservationId}
                        onCopy={(reservation) => {
                            setCopyReservation(reservation)
                        }}
                        onClose={(update) => {
                            setReservationId(null);
                            if (update) {
                                setLoadKey(Date.now() + '');
                            }
                        }}
                    />
                    : null
            }
        </>
    )
}

function aggregateReservations(reservations: Reservation[], products: Products = {}) {
    return reservations.reduce((result, reservation, idx) => {
            const people = reservation.adult + reservation.kid + reservation.infant;
            const row = {
                id: reservation.id,
                memo: reservation.memo,
                date: reservation.date,
                product: products[reservation.productId]?.name ?? reservation.product,
                pickupPlace: reservation.pickupPlace,
                people: `${people}(${reservation.adult}/${reservation.kid}/${reservation.infant})`,
                option: reservation.option && reservation.option.length > 0 ? reservation.option.map((option) => `${option.option}(${option.people})`).join(', ') : '',
                clientName: reservation.clientName,
                nationality: reservation.nationality,
                language: (reservation?.language ?? 'English').split(/- /g)[0].replace(/english/gi, 'EN').replace(/chinese/gi, 'CN').replace(/japanese/gi, 'JP').replace(/korean/gi, 'KO'),
                agency: reservation.agency,
                agencyCode: reservation.agencyCode,
                star: reservation.star,
                noShow: reservation.noShow,
                check: reservation.check,
                canceledAt: reservation.canceledAt ? dayjs(reservation.canceledAt).format('YYYY-MM-DD') : undefined,
            };

            result.total.push(row);

            if (!reservation.canceledAt) {
                result.all.push(row);
            }
            if (!reservation.noShow && !reservation.canceledAt) {
                result.present.push(row)
            }
            if (reservation.noShow && !reservation.canceledAt) {
                result.noShow.push(row)
            }
            if (reservation.star && !reservation.canceledAt) {
                result.star.push(row)
            }
            if (!reservation.star && !reservation.canceledAt) {
                result.noStar.push(row)
            }
            if (reservation.check && !reservation.canceledAt) {
                result.check.push(row)
            }
            if (reservation.canceledAt) {
                result.cancel.push(row)
            }
            return result;
        },
        {
            total: [],
            all: [],
            present: [],
            star: [],
            noStar: [],
            check: [],
            noShow: [],
            cancel: [],
        } as ReservationRows
    )
}

