import { Card, CardActions, CardHeader } from "@mui/material"

import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"

import UserAvatar from "./UserAvatar"
import { AuthContext } from "../../../hooks/auth"
import { useContext } from "react"
interface Props {
    user: any
    onClick?: () => void
    isSelected: boolean
}

export default function User({ user, onClick, isSelected }: Props) {
    const { operator } = useContext(AuthContext)
    const authed = operator as { level: number } | null

    return (
        <Card
            sx={{
                padding: "16px",
                alignItems: "center",
                gap: "12px",
                borderRadius: "16px",
                background: isSelected ? "#F7F8F9" : "#fff",
                border: "1px solid #e0e0e0",
                boxShadow: "none",
            }}
        >
            <CardHeader
                avatar={<UserAvatar user={user} />}
                title={
                    <Typography variant={"h6"}>
                        {user.name}({user.nameEn})
                    </Typography>
                }
                subheader={user.email}
                sx={{
                    "& .MuiCardHeader-content": {
                        overflow: "hidden",
                    },
                    "& .MuiCardHeader-subheader": {
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                    },
                }}
            />
            <CardActions
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 2,
                }}
            >
                <Button fullWidth disabled={isSelected} variant={"outlined"} color={"secondary"} onClick={onClick}>
                    Chat
                </Button>
                {(authed?.level ?? 99) <= 1 ? (
                    <Button
                        fullWidth
                        component={"a"}
                        disabled={isSelected || !user.tel}
                        variant={"outlined"}
                        href={`tel:${user.tel?.replaceAll(/[^\d+-]*/gi, "")}`}
                    >
                        Tel
                    </Button>
                ) : null}
            </CardActions>
        </Card>
    )
}
