import React, {useEffect, useState} from 'react';

import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

import Chip from '@mui/material/Chip';
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import {CircularProgress, Collapse, IconButtonProps, InputLabel, styled, TextField} from "@mui/material";
import {useListen} from "../../hooks/realtime";
import {Exception, EXCEPTION_REALTIME_REF, SYSTEM_EXCEPTION} from "../../models/Exception";
import {database, getCurrentUser, removeRealtime} from "../../hooks/firebase";
import {useFunction} from "../../hooks/function";
import {Reservation} from "../../models/Reservation";
import ReservationModal from "../../partials/ReservationModal";
import ExceptionModal from "../../partials/ExceptionModal";
import {
    DataGridPro,
    GridActionsCellItem,
    GridColDef,
    GridNoRowsOverlay,
    GridRowSelectionModel
} from "@mui/x-data-grid-pro";
import StarIcon from "@mui/icons-material/Star";
import StarOutlinedIcon from "@mui/icons-material/StarOutline";
import NoShowIcon from "@mui/icons-material/NoAccounts";
import NoShowOutlinedIcon from "@mui/icons-material/NoAccountsOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {GridEventListener} from "@mui/x-data-grid/models/events";
import Stack from "@mui/material/Stack";
import ButtonBase from "@mui/material/ButtonBase";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateReservationModal from "../../partials/CreateReservationModal";
import {useAuth} from "../../hooks/auth";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {equalTo, orderByChild, ref, query, onValue} from "firebase/database";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import {DateRange, DateRangePicker, SingleInputDateRangeField} from "@mui/x-date-pickers-pro";
import dayjs, {Dayjs} from "dayjs";
import DateTabs from "../../components/DateTabs";

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const {expand, ...other} = props;
    return <IconButton {...other} />;
})(({theme, expand}) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const HeadCell = styled(TableCell)(({theme}) => ({
    backgroundColor: theme.palette.background.default,
    textAlign: 'center',
    fontWeight: 'medium',
    minWidth: '200px'
}));

const BodyCell = styled(TableCell)(({theme}) => ({
    textAlign: 'center'
}));

const HIGH = 0;
const MOD = 1;
const MID = 2;
const LOW = 3;
const CLASS_LABELS = ['오류', '주의', '확인', '일반']
const CLASS_COLORS: Array<'error' | 'warning' | 'info' | 'success'> = ['error', 'warning', 'info', 'success']

function checkClassLevel(type: string) {
    return type.includes('FAIL') || type.includes('ERROR') ? HIGH : type.includes('UNKNOWN') ? MOD : MID;
}


function DateRangePickerValue(props: { dateRange: DateRange<Dayjs>, onChange: (dr: DateRange<Dayjs>) => void }) {
    const {dateRange: value, onChange: setValue} = props;
    return (

        <DateRangePicker
            format={'YY-MM-DD'}
            slots={{field: SingleInputDateRangeField}}
            value={value}
            onChange={(newValue) => setValue(newValue)}
        />
    );
}


export default Main;


function Main() {
    const [tab, setTab] = React.useState<string>('VALID');
    const [dateRange, setDateRange] = React.useState<DateRange<Dayjs>>([dayjs(), dayjs()]);
    const [exceptionId, setExceptionId] = React.useState<string | null>(null);
    const [reservation, setReservation] = React.useState<Reservation | null>(null);
    const [copyReservation, setCopyReservation] = useState<Reservation | null>(null);
    const [keywords, setKeywords] = React.useState<string>('');
    // const {data, loading} = useListen<{ [id: string]: Exception }>(EXCEPTION_REALTIME_REF);
    const [data, setData] = useState<{ [id: string]: Exception }>({});
    useEffect(() => {
        setData({});
        const exceptionRef = ref(database, EXCEPTION_REALTIME_REF);
        const queryRef =
            tab === 'DELETED'
                ? query(exceptionRef, orderByChild('deletedDate'), equalTo(dateRange[0]?.format('YYYY-MM-DD') ?? dayjs().format('YYYY-MM-DD')))
                : query(exceptionRef, orderByChild('deletedDate'), equalTo(null))
        const unsub = onValue(queryRef, (snapshot) => {
            const data = snapshot.val();
            setData(data);
        }, (e) => console.error(e))
        return () => {
            unsub()
        }
    }, [tab, dateRange[0]?.format('YYYY-MM-DD')]);


    const {
        data: searchData,
        loading: searchLoading
    } = useFunction<{ result: { reservations: Reservation[] } }>('search', {keywords}, keywords);


    const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);

    const [authed, setAuthed] = useState<{
        'ktourstory@gmail.com': number,
        'info@ktourstory.com': number,
        'cs@ktourstory.com': number
    }>({
        'ktourstory@gmail.com': Number(window.localStorage.getItem('ktourstory@gmail.com') ?? 0),
        'info@ktourstory.com': Number(window.localStorage.getItem('info@ktourstory.com') ?? 1),
        'cs@ktourstory.com': Number(window.localStorage.getItem('cs@ktourstory.com') ?? 2)
    });
    const [expandSettings, setExpandSettings] = useState<boolean>(false);


    const columns: GridColDef[] = [
        {field: 'label', headerName: 'LABEL', minWidth: 50, align: 'center', headerAlign: 'center'},
        {field: 'date', headerName: 'DATE', minWidth: 200, align: 'center', headerAlign: 'center'},
        {field: 'email', headerName: 'EMAIL', minWidth: 50, align: 'center', headerAlign: 'center'},
        {field: 'type', headerName: 'TYPE', minWidth: 300, align: 'center', headerAlign: 'center'},
        {field: 'title', headerName: 'TITLE', minWidth: 500, align: 'center', headerAlign: 'center'},
        {field: 'emailSubject', headerName: 'EMAIL-SUBJECT', minWidth: 300, align: 'center', headerAlign: 'center'},
        {field: 'emailFrom', headerName: 'EMAIL-FROM', minWidth: 300, align: 'center', headerAlign: 'center'}
    ];


    const rows = data
        ? Object.entries<Exception>(data).map(([id, exception]) => {
            return ({
                id,
                label: CLASS_LABELS[checkClassLevel(exception.type)],
                date: (new Date(exception.createdAt)).toLocaleString(),
                type: exception.type,
                title: exception.title,
                email: exception.reference?.emailAddress,
                emailSubject: exception.reference?.mail?.subject,
                emailFrom: exception.reference?.mail?.from
            })
        })
        : [];

    const handleTempDateRange = (dr: DateRange<Dayjs>) => {
        setDateRange(dr);
    }

    const onDeleteSelections = () => {
        Promise.all(rowSelectionModel.map((row) => removeRealtime(`/exception/${row}`).catch((e) => console.error(e))))
            .finally(() => {

            })
    }
    const handleExceptionRowClick: GridEventListener<'rowClick'> = (row, event, context) => {
        setExceptionId((row as unknown as Exception).id)
    }

    const handleSelectRows = (newRowSelectionModel: GridRowSelectionModel) => {
        setRowSelectionModel(newRowSelectionModel);
    }
    const handleEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.code === 'Enter') {
            setKeywords(event.currentTarget.value ?? '');
        }
    }


    return (
        <>
            <Box
                sx={{
                    px: 8,
                    py: 3
                }}
            >
                <Box
                    mb={10}
                >
                    <Box
                        sx={(theme) => ({
                            mb: 4,
                            px: 4,
                            backgroundColor: theme.palette.background.paper,
                            borderRadius: 8
                        })}
                    >
                        {
                            searchLoading
                                ? <CircularProgress/>
                                : <InputBase
                                    fullWidth
                                    autoFocus
                                    placeholder={'4 LETTERS + ENTER'}
                                    onKeyDown={handleEnter}
                                    sx={{
                                        height: '56px'
                                    }}
                                />
                        }
                    </Box>
                    {
                        (searchData?.result?.reservations && searchData.result.reservations.length > 0)
                            ? (
                                <Table>
                                    <TableBody>
                                        {
                                            searchData.result.reservations.map((reservation) => (
                                                <TableRow
                                                    onClick={() => {
                                                        setReservation(reservation)
                                                    }}
                                                >
                                                    <TableCell>
                                                        {reservation.date}
                                                    </TableCell>
                                                    <TableCell>
                                                        {reservation.product}
                                                    </TableCell>
                                                    <TableCell>
                                                        {reservation.clientName}
                                                    </TableCell>
                                                    <TableCell>
                                                        {reservation.messenger}
                                                    </TableCell>
                                                    <TableCell>
                                                        {reservation.email}
                                                    </TableCell>
                                                    <TableCell>
                                                        {reservation.adult + reservation.kid + reservation.infant}
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>

                                </Table>
                            )
                            : null
                    }
                </Box>

                <Card
                    sx={{
                        padding: 4
                    }}
                >

                    <CardContent>
                        <Tabs value={tab} onChange={(_, value) => setTab(value)}>
                            <Tab label={'VALID'} value={'VALID'}/>
                            <Tab label={'DELETED'} value={'DELETED'}/>
                        </Tabs>
                        {
                            tab === 'DELETED'
                                ? (
                                    <Box>
                                        <DateTabs backward date={dateRange[0]?.toDate() ?? new Date()} onChange={(date) => {
                                            setDateRange([dayjs(date), dayjs(date)]);
                                        }}/>
                                    </Box>)
                                : null
                        }
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Typography variant={'caption'}>
                                {rows.length.toLocaleString()} Exceptions
                            </Typography>
                        </Box>
                        <DataGridPro
                            hideFooter
                            checkboxSelection
                            disableRowSelectionOnClick
                            columns={columns}
                            rows={rows}
                            onRowClick={handleExceptionRowClick}
                            rowSelectionModel={rowSelectionModel}
                            onRowSelectionModelChange={handleSelectRows}
                            slots={{noRowsOverlay: Box}}
                        />
                    </CardContent>


                    <CardActions>
                        <ExpandMore
                            expand={expandSettings}
                            onClick={() => {
                                setExpandSettings(v => !v)
                            }}
                            aria-expanded={expandSettings}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon/>
                        </ExpandMore>
                    </CardActions>
                    <Collapse in={expandSettings}>
                        <CardContent>
                            <Stack direction={'row'} gap={2}>
                                <FormControl fullWidth>
                                    <InputLabel id="ktourstory@gmail.com-label">ktourstory@gmail.com</InputLabel>
                                    <Select
                                        type={'number'}
                                        labelId="ktourstory@gmail.com-label"
                                        id="ktourstory@gmail.com"
                                        value={authed['ktourstory@gmail.com']}
                                        label="ktourstory@gmail.com"
                                        onChange={(event,) => {
                                            window.localStorage.setItem('ktourstory@gmail.com', event.target.value.toString())
                                            const numberValue = Number(event.target.value);
                                            setAuthed((exists) => {
                                                return {
                                                    ...exists,
                                                    ['ktourstory@gmail.com']: numberValue
                                                }
                                            })
                                        }}
                                    >
                                        <MenuItem value={0}>0</MenuItem>
                                        <MenuItem value={1}>1</MenuItem>
                                        <MenuItem value={2}>2</MenuItem>
                                        <MenuItem value={3}>3</MenuItem>
                                        <MenuItem value={4}>4</MenuItem>
                                        <MenuItem value={5}>5</MenuItem>
                                        <MenuItem value={6}>6</MenuItem>
                                        <MenuItem value={7}>7</MenuItem>
                                        <MenuItem value={8}>8</MenuItem>
                                        <MenuItem value={9}>9</MenuItem>
                                    </Select>
                                </FormControl>


                                <FormControl fullWidth>
                                    <InputLabel id="info@ktourstory.com-label">info@ktourstory.com</InputLabel>
                                    <Select
                                        type={'number'}
                                        labelId="info@ktourstory.com-label"
                                        id="info@ktourstory.com"
                                        value={authed['info@ktourstory.com']}
                                        label="info@ktourstory.com"
                                        onChange={(event,) => {
                                            window.localStorage.setItem('info@ktourstory.com', event.target.value.toString())
                                            const numberValue = Number(event.target.value);
                                            setAuthed((exists) => {
                                                return {
                                                    ...exists,
                                                    ['info@ktourstory.com']: numberValue
                                                }
                                            })
                                        }}
                                    >
                                        <MenuItem value={0}>0</MenuItem>
                                        <MenuItem value={1}>1</MenuItem>
                                        <MenuItem value={2}>2</MenuItem>
                                        <MenuItem value={3}>3</MenuItem>
                                        <MenuItem value={4}>4</MenuItem>
                                        <MenuItem value={5}>5</MenuItem>
                                        <MenuItem value={6}>6</MenuItem>
                                        <MenuItem value={7}>7</MenuItem>
                                        <MenuItem value={8}>8</MenuItem>
                                        <MenuItem value={9}>9</MenuItem>
                                    </Select>
                                </FormControl>

                                <FormControl fullWidth>
                                    <InputLabel id="cs@ktourstory.com-label">cs@ktourstory.com</InputLabel>
                                    <Select
                                        type={'number'}
                                        labelId="cs@ktourstory.com-label"
                                        id="cs@ktourstory.com"
                                        value={authed['cs@ktourstory.com']}
                                        label="cs@ktourstory.com"
                                        onChange={(event,) => {
                                            window.localStorage.setItem('cs@ktourstory.com', event.target.value.toString())
                                            const numberValue = Number(event.target.value);
                                            setAuthed((exists) => {
                                                return {
                                                    ...exists,
                                                    ['cs@ktourstory.com']: numberValue
                                                }
                                            })
                                        }}
                                    >
                                        <MenuItem value={0}>0</MenuItem>
                                        <MenuItem value={1}>1</MenuItem>
                                        <MenuItem value={2}>2</MenuItem>
                                        <MenuItem value={3}>3</MenuItem>
                                        <MenuItem value={4}>4</MenuItem>
                                        <MenuItem value={5}>5</MenuItem>
                                        <MenuItem value={6}>6</MenuItem>
                                        <MenuItem value={7}>7</MenuItem>
                                        <MenuItem value={8}>8</MenuItem>
                                        <MenuItem value={9}>9</MenuItem>
                                    </Select>
                                </FormControl>


                            </Stack>
                        </CardContent>
                    </Collapse>
                </Card>

            </Box>
            {

                rowSelectionModel.length > 0
                    ?
                    <Box sx={{
                        position: 'fixed',
                        display: 'inline-flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        bottom: 0,
                        left: '50%',
                        transform: 'translate(-50%, -100%)',
                        zIndex: 999,
                        backgroundColor: 'white',
                        borderRadius: 5,
                        boxShadow: 2,
                        py: 2,
                        px: 3
                    }}>

                        <Box>
                            <IconButton onClick={onDeleteSelections} sx={{
                                display: 'flex',
                                alignItems: 'center'
                            }}>

                                <Typography component={'span'} sx={{fontWeight: 'bold', mr: 1}}>
                                    {rowSelectionModel.length}
                                </Typography>
                                <DeleteIcon/>
                            </IconButton>
                        </Box>
                    </Box>
                    : null
            }
            {
                exceptionId
                    ? <ExceptionModal
                        exceptionId={exceptionId}
                        onClose={() => {
                            setExceptionId(null);
                        }}
                    />
                    : null
            }
            {
                reservation
                    ? <ReservationModal
                        onCopy={(reservation) => {
                            setCopyReservation(reservation)
                        }}
                        reservationId={reservation.id}
                        onClose={() => {
                            setReservation(null)
                        }}
                    />
                    : null
            }
            {
                copyReservation
                    ? <CreateReservationModal
                        defaultReservation={copyReservation}
                        onClose={(update) => {
                            setCopyReservation(null);
                        }}
                    />
                    : null
            }
        </>
    );
}
