import * as XLSX from "xlsx";
import {AgencyFileDatum} from "./types";

function cleanString(data?: any): string {
  return data?.toString().trim() || ''
}

function toNumber(numberLike?: string | number) {
  if (typeof numberLike === 'number') return numberLike;
  return Number(cleanString(numberLike) || 0);
}


export default async function parseCreatripFile(file: Blob | File):Promise<AgencyFileDatum[]> {
  const parsed = await parseFileData(file);
  return parsed
    .filter(raw=>!!raw['결제코드/예약코드'])
    .map((raw) => {
    const agency = 'CRE'
    const agencyCode = cleanString(raw['결제코드/예약코드']);
    const tour = cleanString(raw['스팟 이름']);
    const option = cleanString(raw['아이템이름']);
    const date = cleanString(raw['예약일']);
    const people = toNumber(raw['수량']);
    const price = toNumber(raw['총 정산가(KRW)']);

    return ({
      tour,
      option,
      date,
      agency,
      agencyCode,
      people,
      price,
      unitPrice:price/people,
    });
  })
}


const parseFileData = async (file: Blob | File) => {
  const binary = await file.arrayBuffer()
  const workbook = XLSX.read(binary, {type: "array", raw: true})
  const sheets = workbook.Sheets
  const sheetNames = workbook.SheetNames

  const firstSheet = sheets[sheetNames[0]]
  return XLSX.utils.sheet_to_json(firstSheet,{range:1}) as any[];
}
