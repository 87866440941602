import { Avatar, Box, Typography } from "@mui/material"
import Message from "./Message"
import { IMessage } from "../../../types/chat"
import { useContext } from "react"
import { AuthContext } from "../../../hooks/auth"
import { convertSenderName, isInfoMessage } from "../../../utils/chatUtils"
import InfoMessage from "./InfoMessage"

type Props = {
    messages: IMessage[]
}

export function Messages({ messages }: Props) {
    const { operator } = useContext(AuthContext)
    const firstMessage = messages[0]
    const sender = firstMessage.sender
    const isMe = sender.id === operator!.id

    if (isInfoMessage(firstMessage)) {
        return <InfoMessage message={firstMessage.text} />
    }

    return (
        <Box
            sx={{
                display: "flex",
                columnGap: "12px",
                flexDirection: isMe ? "row-reverse" : "row",
                my: 1,
            }}
        >
            {/*Profile */}
            {!isMe && <Avatar alt="user profile image" src={sender.photoURL ?? "/logo512.png"} />}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "8px",
                    maxWidth: "90%",
                }}
            >
                {messages?.map((message: any, index: number) => (
                    <>
                        <Box key={message.id}>
                            {!isMe && index === 0 && (
                                <Typography color="#828282" variant="body1" fontWeight="500">
                                    {convertSenderName(message.sender)}
                                </Typography>
                            )}

                            <Message
                                message={message}
                                isMe={isMe}
                                showTime={index === messages.length - 1}
                                isStart={index === 0}
                            />
                        </Box>
                    </>
                ))}
            </Box>
        </Box>
    )
}
