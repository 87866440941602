import {useState, useLayoutEffect, useEffect} from 'react';
import {callFunction} from "../firebase";


export function useFunction<S>(name:string, callData:unknown, key:string) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<S | null>(null);
    useEffect(() => {
        if(key.length === 0){
            setLoading(false);
        }else{
            setLoading(true);
            callFunction<S>(name, callData)
                .then((s) => {
                    setData(s);
                })
                .catch((e) => console.error(e))
                .finally(()=>{
                    setLoading(false);
                })
        }
    }, [key]);

    return {
        data, loading
    }
}
