import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"
import React from "react"
import FileUploadButton from "../FileUploadButton"
import {parseKkdayReservationFile} from "./kkday"
import {parseKlookReservationFile} from "./klook"
import {parseKintReservationFile} from "./ktourstory"
import {parseTrazyReservationFile} from "./trazy"
import {parseViatorReservationFile} from "./viator"
import DeleteIcon from "@mui/icons-material/Delete"
import {useRead} from "../../../hooks/realtime";
import {Product} from "../../../models/Product";

function DoubleCheck() {
  const {data: tourProducts} = useRead<{ [uid: string]: Product }>('/product');
  const findProductNameAndOption = (productPossible: string, optionPossible?: string): ({
    product: string,
    option?: string
  } | null) => {
    return Object.values(tourProducts ?? {}).reduce((result, p) => {
      if (!p.doubleCheck) return result;
      const foundMatches = Object.values(p.doubleCheck).filter((dc) => dc.productPossible === productPossible && !!dc.optionPossible ? dc.optionPossible === optionPossible : !optionPossible);
      const foundMatch = foundMatches.find((dc) => dc.productPossible === productPossible && dc.optionPossible === optionPossible) ?? foundMatches[0];
      if (!foundMatch) return result;
      return {product: p.name, option: foundMatch.option};
    }, null as ({ product: string, option?: string } | null));
  }
  const [klookTalkList, setKlookTalkList] = React.useState<any>([])
  const [kkdayTalkList, setKkdayTalkList] = React.useState<any>([])
  const [viatorTalkList, setViatorTalkList] = React.useState<any>([])
  const [trazyTalkList, setTrazyTalkList] = React.useState<any>([])
  const [kintReservation, setKintReservation] = React.useState<any>(null)
  const [ids, setIds] = React.useState<any>({
    klook: [],
    kkday: [],
    viator: [],
    trazy: [],
  })
  const [totalBookingKint, setTotalBookingKint] = React.useState<any>({
    klook: 0,
    kkday: 0,
    trazy: 0,
    viator: 0,
  })
  const [klook, setKlook] = React.useState<any>({})
  const [kkday, setKkday] = React.useState<any>({})
  const [trazy, setTrazy] = React.useState<any>({})
  const [viator, setViator] = React.useState<any>({})
  const [kint, setKint] = React.useState<any>({})
  const handleKintReservation = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return
    const file = event.target.files[0]
    const {
      klook,
      kkday,
      viator,
      trazy,
      klookDuplicated,
      kkdayDuplicated,
      viatorDuplicated,
      trazyDuplicated
    } = await parseKintReservationFile(file)

    setIds({
      klook: Object.keys(klook),
      kkday: Object.keys(kkday),
      trazy: Object.keys(trazy),
      viator: Object.keys(viator),
    })

    setKint({
      klook,
      klookDuplicated,
      kkday,
      kkdayDuplicated,
      trazy,
      trazyDuplicated,
      viator,
      viatorDuplicated,
    })
  }

  const handleKlookReservation = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return
    const files = event.target.files
    const {klookNotRegistered, klook, names} = await parseKlookReservationFile(files, findProductNameAndOption)
    setKlook(klook)
  }

  const handleTrazyReservation = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return
    const files = event.target.files
    const {trazy, trazyNotRegistered} = await parseTrazyReservationFile(files, findProductNameAndOption)
    setTrazy(trazy)
  }

  const handleViatorReservation = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return
    const files = event.target.files
    const {viator, viatorNotRegistered} = await parseViatorReservationFile(files, findProductNameAndOption)
    setViator(viator)
  }

  const handleKkdayReservation = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return
    const files = event.target.files
    const {kkday, kkdayNotRegistered} = await parseKkdayReservationFile(files, findProductNameAndOption)
    setKkday(kkday)
  }

  const checkKlook = () => {
    const ids = Object.keys(klook ?? {})

    setTotalBookingKint((prev: any) => ({
      ...prev,
      klook: ids.length,
    }))

    Object.keys(kint.klook).forEach((key) => {
      if (kint.klookDuplicated.includes(key)) {
        setKlookTalkList((prev: any) => [...prev, `[중복예약] ${key} |  ${kint.klook[key].tour}`])
      }
      if (!Object.keys(klook).includes(key)) {
        setKlookTalkList((prev: any) => [...prev, `[KINT [0] KLOOK [X]] > ${key} | ${kint.klook[key].tour}`])
      } else {
        if (kint.klook[key].tour !== klook[key].tour) {
          setKlookTalkList((prev: any) => [
            ...prev,
            `[투어명] > ${key} | < KINT: ${kint.klook[key].tour} > | < KLOOK: ${klook[key].tour} >`,
          ])
        }

        if (kint.klook[key].people !== klook[key].people) {
          setKlookTalkList((prev: any) => [
            ...prev,
            `[명수] > ${key} | < KINT: ${kint.klook[key].people} > | < KLOOK: ${klook[key].people} >`,
          ])
        }

        if (kint.klook[key].extra !== klook[key].extra) {
          setKlookTalkList((prev: any) => [
            ...prev,
            `[옵션] > ${key} | < KINT: ${kint.klook[key].extra} > | < KLOOK: ${klook[key].extra} > `,
          ])
        }

        if (kint.klook[key].stroller !== klook[key].stroller) {
          setKlookTalkList((prev: any) => [
            ...prev,
            `[유모차] > ${key} | < KINT: ${kint.klook[key].stroller} > | < KLOOK: ${klook[key].stroller} > `,
          ])
        }

        if (kint.klook[key].lang !== klook[key].lang) {
          setKlookTalkList((prev: any) => [
            ...prev,
            `[언어] > ${key} | < KINT: ${kint.klook[key].lang} > | < KLOOK: ${klook[key].lang} > `,
          ])
        }
      }
    })

    Object.keys(klook).forEach((key) => {
      if (!Object.keys(kint.klook).includes(key)) {
        setKlookTalkList((prev: any) => [...prev, `[킨트 [X] KLOOK [O]] > ${key} | ${klook[key].tour}`])
      }
    })
  }

  const checkKkday = () => {
    const ids = Object.keys(kkday ?? {})

    setTotalBookingKint((prev: any) => ({
      ...prev,
      kkday: ids.length,
    }))

    Object.keys(kint.kkday).forEach((key) => {
      if (kint.kkdayDuplicated.includes(key)) {
        setKkdayTalkList((prev: any) => [...prev, `[중복예약] > ${key} | ${kint.kkday[key].tour}`])
      }

      if (!Object.keys(kkday).includes(key)) {
        setKkdayTalkList((prev: any) => [...prev, `[ 킨트 [O] KKDAY [X]] ${key} | ${kint.kkday[key].tour}`])
      } else {
        if (kint.kkday[key].tour !== kkday[key].tour) {
          setKkdayTalkList((prev: any) => [
            ...prev,
            `[투어명] > ${key} | < KINT: ${kint.kkday[key].tour} < KKDAY: ${kkday[key].tour}`,
          ])
        }

        if (kkday[key].tour === "에버") {
          if (kkday[key].extra.search("T-MONEY") !== -1) {// has tmoney
            if (kint.kkday[key].extra.search(kkday[key].extra) === -1) { // but not in kint
              setKkdayTalkList((prev: any) => [
                ...prev,
                `[에버 티머니] > ${key} | < KINT: ${kint.kkday[key].extra} > < KKDAY: ${kkday[key].extra}`,
              ])
            }
          }
        }

        if (kkday[key].people !== kint.kkday[key].people) {
          setKkdayTalkList((prev: any) => [
            ...prev,
            `[명수] > ${key} | < KINT: ${kint.kkday[key].people} > | < KKDAY: ${kkday[key].people}`,
          ])
        }

        if (kint.kkday[key].lang !== kkday[key].lang) {
          setKkdayTalkList((prev: any) => [
            ...prev,
            `[언어] > ${key} |  < KINT: ${kint.kkday[key].lang} > | < KKDAY: ${kkday[key].lang} > `,
          ])
        }

        if (kkday[key].tour !== "에버") {
          if (kkday[key].extra !== kint.kkday[key].extra) {
            setKkdayTalkList((prev: any) => [
              ...prev,
              `[옵션] > ${key} | < KINT: ${kint.kkday[key].extra} > | < KKDAY: ${kkday[key].extra} >`,
            ])
          }
        }
      }
    })

    Object.keys(kkday).forEach((key) => {
      if (!Object.keys(kint.kkday).includes(key)) {
        setKkdayTalkList((prev: any) => [...prev, `[킨트 [X] KKDAY [O]] > ${key} | ${kkday[key].tour}`])
      }
    })
  }

  const checkTrazy = () => {
    const ids = Object.keys(trazy ?? {})

    setTotalBookingKint((prev: any) => ({
      ...prev,
      trazy: ids.length,
    }))

    Object.keys(kint.trazy).forEach((key) => {
      if (kint.trazyDuplicated.includes(key)) {
        setTrazyTalkList((prev: any) => [...prev, `[중복예약] ${key} |  ${kint.trazy[key].tour}`])
      }

      if (!Object.keys(trazy).includes(key)) {
        setTrazyTalkList((prev: any) => [...prev, `[KINT [0] TRAZY [X]] > ${key} | ${kint.trazy[key].tour}`])
      } else {
        if (kint.trazy[key].tour !== trazy[key].tour) {
          setTrazyTalkList((prev: any) => [
            ...prev,
            `[투어명] > ${key} | < KINT: ${kint.trazy[key].tour} > | < TRAZY: ${trazy[key].tour} >`,
          ])
        }

        if (kint.trazy[key].people !== trazy[key].people) {
          setTrazyTalkList((prev: any) => [
            ...prev,
            `[명수] > ${key} | < KINT: ${kint.trazy[key].people} > | < TRAZY: ${trazy[key].people} >`,
          ])
        }

        if (kint.trazy[key].extra !== trazy[key].extra) {
          setTrazyTalkList((prev: any) => [
            ...prev,
            `[옵션] > ${key} | < KINT: ${kint.trazy[key].extra} > | < TRAZY: ${trazy[key].extra} > `,
          ])
        }


        if (kint.trazy[key].lang !== trazy[key].lang) {
          setTrazyTalkList((prev: any) => [
            ...prev,
            `[언어] > ${key} | < KINT: ${kint.trazy[key].lang} > | < TRAZY: ${trazy[key].lang} > `,
          ])
        }

        if (kint.trazy[key].pickup !== trazy[key].pickup) {
          setTrazyTalkList((prev: any) => [
            ...prev,
            `[픽업 장소] > ${key} | < KINT: ${kint.trazy[key].pickup} > | < TRAZY: ${trazy[key].pickup} > `,
          ])
        }
      }
    })

    Object.keys(trazy).forEach((key) => {
      if (!Object.keys(kint.trazy).includes(key)) {
        setTrazyTalkList((prev: any) => [...prev, `[킨트 [X] TRAZY [O]] > ${key} | ${trazy[key].tour}`])
      }
    })
  }
  const checkViator = () => {
    const ids = Object.keys(viator ?? {})

    setTotalBookingKint((prev: any) => ({
      ...prev,
      viator: ids.length,
    }))

    Object.keys(kint.viator).forEach((key) => {
      if (kint.viatorDuplicated.includes(key)) {
        setViatorTalkList((prev: any) => [...prev, `[중복예약] ${key} |  ${kint.viator[key].tour}`])
      }

      if (!Object.keys(viator).includes(key)) {
        setViatorTalkList((prev: any) => [...prev, `[KINT [0] viator [X]] > ${key} | ${kint.viator[key].tour}`])
      } else {
        if (kint.viator[key].tour !== viator[key].tour) {
          setViatorTalkList((prev: any) => [
            ...prev,
            `[투어명] > ${key} | < KINT: ${kint.viator[key].tour} > | < VIATOR: ${viator[key].tour}>(${viator[key].origin.productName}/${viator[key].origin.option}) >`,
          ])
        }

        if (kint.viator[key].people !== viator[key].people) {
          setViatorTalkList((prev: any) => [
            ...prev,
            `[명수] > ${key} | < KINT: ${kint.viator[key].people} > | < VIATOR: ${viator[key].people} >`,
          ])
        }

        if (kint.viator[key].extra !== viator[key].extra) {
          setViatorTalkList((prev: any) => [
            ...prev,
            `[옵션] > ${key} | < KINT: ${kint.viator[key].extra} > | < VIATOR: ${viator[key].extra} > `,
          ])
        }

        if (kint.viator[key].pickup !== viator[key].pickup) {
          setViatorTalkList((prev: any) => [
            ...prev,
            `[픽업 장소] > ${key} | < KINT: ${kint.viator[key].pickup} > | < VIATOR: ${viator[key].pickup} > `,
          ])
        }

        if (kint.viator[key].lang !== viator[key].lang) {
          setViatorTalkList((prev: any) => [
            ...prev,
            `[언어] > ${key} | < KINT: ${kint.viator[key].lang} > | < VIATOR: ${viator[key].lang} > `,
          ])
        }

        if (kint.viator[key].phone < 5) {
          setViatorTalkList((prev: any) => [
            ...prev,
            `[연락처] > ${key} | < KINT: ${kint.viator[key].phone} > | < VIATOR: ${viator[key].phone} > `,
          ])
        }
      }
    })

    Object.keys(viator).forEach((key) => {
      if (!Object.keys(kint.viator).includes(key)) {
        setViatorTalkList((prev: any) => [...prev, `[킨트 [X] VIATOR [O]] > ${key} | ${viator[key].tour}`])
      }
    })
  }

  return (
      <Box
          style={{
            display: "flex",
            gap: 16,
            flexDirection: "column",
          }}
      >
        <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: 16,
            }}
        >
          <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                gap: 16,
                flex: 1,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
          >
            <Typography variant="h6">KINT Reservation</Typography>
            <FileUploadButton onChange={handleKintReservation}/>
          </Box>
          <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                gap: 16,
                flex: 1,
              }}
          >
            <Typography variant="h6">KLOOK Reservation</Typography>
            <FileUploadButton onChange={handleKlookReservation} multiple/>
          </Box>

          <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                gap: 16,
                flex: 1,
              }}
          >
            <Typography variant="h6">KKDAY Reservation</Typography>
            <FileUploadButton onChange={handleKkdayReservation} multiple/>
          </Box>
          <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                gap: 16,
                flex: 1,
              }}
          >
            <Typography variant="h6">TRAZY Reservation</Typography>
            <FileUploadButton onChange={handleTrazyReservation} multiple/>
          </Box>
          <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                gap: 16,
                flex: 1,
              }}
          >
            <Typography variant="h6">VIATOR Reservation</Typography>
            <FileUploadButton onChange={handleViatorReservation} multiple/>
          </Box>
        </Box>
        <Box>
          <Button
              onClick={() => {
                if (!kint) {
                  alert("Kint 파일을 업로드 해주세요")
                  return
                }
                checkKlook()
                checkKkday()
                checkTrazy()
                checkViator()
              }}
          >
            Submit
          </Button>
        </Box>

        <Box>
          <TableContainer component={Paper}>
            <Table sx={{minWidth: 650}} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>KLOOK</TableCell>
                  <TableCell>KKDAY</TableCell>
                  <TableCell>TRAZY</TableCell>
                  <TableCell>VIATOR</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow sx={{"&:last-child td, &:last-child th": {border: 0}}}>
                  <TableCell>KINT</TableCell>
                  <TableCell>{ids.klook.length}</TableCell>
                  <TableCell>{ids.kkday.length}</TableCell>
                  <TableCell>{ids.trazy.length}</TableCell>
                  <TableCell>{ids.viator.length}</TableCell>
                </TableRow>
                <TableRow sx={{"&:last-child td, &:last-child th": {border: 0}}}>
                  <TableCell>Agency</TableCell>
                  <TableCell>{totalBookingKint.klook}</TableCell>
                  <TableCell>{totalBookingKint.kkday}</TableCell>
                  <TableCell>{totalBookingKint.trazy}</TableCell>
                  <TableCell>{totalBookingKint.viator}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box>
          <List sx={{width: "100%", bgcolor: "background.paper", p: 4}}>
            {klookTalkList.map((value: any, index: number) => (
                <ListItem
                    key={value}
                    disableGutters
                    secondaryAction={
                      <IconButton
                          aria-label="comment"
                          onClick={() =>
                              setKlookTalkList((prev: any) => prev.filter((v: any, i: number) => v !== value))
                          }
                      >
                        <DeleteIcon/>
                      </IconButton>
                    }
                >
                  <ListItemText sx={{whiteSpace: 'pre-wrap'}} primary={value}/>
                </ListItem>
            ))}
            {kkdayTalkList.map((value: any, index: number) => (
                <ListItem
                    key={value}
                    disableGutters
                    secondaryAction={
                      <IconButton
                          aria-label="comment"
                          onClick={() =>
                              setKkdayTalkList((prev: any) => prev.filter((v: any, i: number) => v !== value))
                          }
                      >
                        <DeleteIcon/>
                      </IconButton>
                    }
                >
                  <ListItemText sx={{whiteSpace: 'pre-wrap'}} primary={value}/>
                </ListItem>
            ))}
            {trazyTalkList.map((value: any, index: number) => (
                <ListItem
                    key={value}
                    disableGutters
                    secondaryAction={
                      <IconButton
                          aria-label="comment"
                          onClick={() =>
                              setTrazyTalkList((prev: any) => prev.filter((v: any, i: number) => v !== value))
                          }
                      >
                        <DeleteIcon/>
                      </IconButton>
                    }
                >
                  <ListItemText sx={{whiteSpace: 'pre-wrap'}} primary={value}/>
                </ListItem>
            ))}
            {viatorTalkList.map((value: any, index: number) => (
                <ListItem
                    key={value}
                    disableGutters
                    secondaryAction={
                      <IconButton
                          aria-label="comment"
                          onClick={() =>
                              setViatorTalkList((prev: any) => prev.filter((v: any, i: number) => v !== value))
                          }
                      >
                        <DeleteIcon/>
                      </IconButton>
                    }
                >
                  <ListItemText sx={{whiteSpace: 'pre-wrap'}} primary={value}/>
                </ListItem>
            ))}
          </List>
        </Box>
      </Box>
  )
}

export default DoubleCheck
