import { Box, styled } from "@mui/material"
import { grey } from "@mui/material/colors"

const Puller = styled(Box)(({ theme }: { theme: any }) => ({
    width: 40,
    height: 12,
    backgroundColor: grey[700],
    borderRadius: 9999,
    margin: "0 auto",
}))

export default Puller
