import React, {createContext, useContext} from 'react';
import {Agency} from "../../models/Agency";
import {Nationality} from "../../models/Nationality";
import {Product} from "../../models/Product";
import {Pickup} from "../../models/Pickup";
import {useRead} from "../realtime";


interface BaseContextValue {
    agencies: { [agencyId: string]: Agency } | null,
    nationalities: { [nationId: string]: Nationality } | null,
    products: { [productId: string]: Product } | null,
    pickupGroup: { [area: string]: { [pickupId: string]: Pickup } } | null
}

export const BaseContext = createContext<BaseContextValue>({
    agencies: {},
    nationalities: {},
    products: {},
    pickupGroup: {}
})

export const useBase = () => useContext(BaseContext)
export const BaseProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {

    const {data: agencies} = useRead<{ [agencyId: string]: Agency }>('/agency');
    const {data: nationalities} = useRead<{ [nationId: string]: Nationality }>('/nationality');
    const {data: products} = useRead<{ [productId: string]: Product }>('/product');
    const {data: pickupGroup} = useRead<{ [area: string]: { [pickupId: string]: Pickup } }>(`/pickup`);

    return <BaseContext.Provider value={{agencies, nationalities, products, pickupGroup}}>{children}</BaseContext.Provider>
}