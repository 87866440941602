import React, {useEffect} from 'react';

import Modal from '@mui/material/Modal';
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Paper from "@mui/material/Paper";
import Grid from '@mui/material/Grid';
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Switch from '@mui/material/Switch';
import CircularProgress from "@mui/material/CircularProgress";
import {FormControl, FormControlLabel, FormLabel, RadioGroup, Radio, FormGroup} from "@mui/material";


import {useRead} from "../hooks/realtime";
import {removeRealtime, updateRealtime} from "../hooks/firebase";
import {User} from "../models/User";
import TextField from "@mui/material/TextField";
import Checkbox from '@mui/material/Checkbox';
import Stack from "@mui/material/Stack";
import EditIcon from "@mui/icons-material/Edit";
import {Product} from "../models/Product";
import Autocomplete from "@mui/material/Autocomplete";


export default function (props: { id: string, onClose: (update: boolean) => void }) {
  const {id, onClose} = props;
  const ref = `user/${id}`;
  const {data: user, update, updates, save, setData, loading, setLoading} = useRead<User>(ref);
  const {data: products} = useRead<{ [key: string]: Product }>('product');

  useEffect(()=>{
    if(user && user.level === 2.5 && !user.driver){
      update('driver', true);
    }
  },[user?.level, user?.license])

  const handleDelete = () => {
    if (!(window?.confirm("삭제하시겠습니까?"))) return;
    setLoading(true);
    removeRealtime(ref)
        .then(() => {
          setLoading(false);
          onClose(true);
        })
        .catch(console.error)
  }

  const handleSave = () => {
    save({level: (user?.level ?? 99) < 4 ? user?.level : 99, waiting: false})
        .then(() => {
          onClose(true);
        })
  }
  const handleChangeBuilder = (prop: string) => (_: React.ChangeEvent<HTMLInputElement>, value: any) => {
    update(prop, value);
  }
  const handleTargetChangeBuilder = (prop: string, middleware: (value: any) => any = f => f) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = middleware(e.target.value);
    update(prop, value);
  }


  return (
      <Modal
          open
          onClose={onClose}
      >
        <Box
            sx={(theme) => ({
              width: '80vw',
              maxHeight: '80vh',
              position: 'relative' as 'relative',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            })}
        >

          <Box sx={{
            position: 'fixed',
            display: 'inline-flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            bottom: 0,
            left: '50%',
            transform: 'translate(-50%, 125%)',
            zIndex: 999,
            backgroundColor: 'white',
            borderRadius: 5,
            boxShadow: 2,
            py: 1,
            px: 2
          }}>

            {
              loading
                  ? <CircularProgress color={"primary"}/>
                  : <>
                    <Switch checked={user?.on} onChange={
                      (_, value) => {
                        const updatable = value ? {
                              level: 2,
                              on: true
                            }
                            : {
                              level: 3,
                              on: false,
                              messageIds: null
                            }
                        updates(updatable);
                      }
                    }/>
                    <IconButton onClick={handleSave}>
                      <EditIcon/>
                    </IconButton>
                    <IconButton onClick={handleDelete}>
                      <DeleteIcon/>
                    </IconButton>
                  </>
            }

          </Box>

          <Paper
              sx={(theme) => ({
                padding: '32px 24px',
              })}
          >
            <Grid
                container
                spacing={2}
            >
              <Grid
                  item
                  xs={12}
                  sx={{
                    position: 'relative',
                  }}
              >
                <Box
                    sx={{
                      position: 'relative',
                      maxHeight: '75vh',
                      overflowY: 'auto',
                    }}
                >

                  <Box
                      sx={(theme) => ({
                        borderRadius: 3,
                        backgroundColor: theme.palette.background.default,
                        p: 4
                      })}
                  >

                    <Grid
                        container
                        spacing={4}
                        component={'form'}
                    >
                      <Grid
                          item
                          xs={2}
                      >
                        <Typography fontWeight={'bold'}>
                          Area
                        </Typography>
                      </Grid>
                      <Grid
                          item
                          xs={10}
                      >
                        <FormControl>
                          <RadioGroup
                              row
                              name="position"
                              value={user?.area ?? ''}
                              onChange={handleChangeBuilder('area')}
                          >
                            <FormControlLabel
                                value="Seoul"
                                control={<Radio/>}
                                label="Seoul"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="Busan"
                                control={<Radio/>}
                                label="Busan"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="Tokyo"
                                control={<Radio/>}
                                label="Tokyo"
                                labelPlacement="end"
                            />
                          </RadioGroup>
                        </FormControl>

                      </Grid>

                      <Grid
                          item
                          xs={12}
                      >
                        <Divider/>
                      </Grid>

                      <Grid
                          item
                          xs={2}
                      >
                        <Typography fontWeight={'bold'}>
                          Info
                        </Typography>
                      </Grid>
                      <Grid
                          item
                          xs={5}
                      >
                        <TextField
                            fullWidth
                            label={'Name'}
                            value={user?.name ?? ''}
                            onChange={handleTargetChangeBuilder('name')}
                            InputLabelProps={{shrink: true}}
                            sx={{backgroundColor: 'white'}}
                        />

                      </Grid>
                      <Grid
                          item
                          xs={5}
                      >
                        <TextField
                            fullWidth
                            label={'English Name'}
                            value={user?.nameEn ?? ''}
                            onChange={handleTargetChangeBuilder('nameEn')}
                            InputLabelProps={{shrink: true}}
                            sx={{backgroundColor: 'white'}}
                        />

                      </Grid>
                      <Grid
                          item
                          xs={2}
                      >
                        <Typography fontWeight={'bold'}>
                        </Typography>
                      </Grid>
                      <Grid
                          item
                          xs={5}
                      >
                        <TextField
                            fullWidth
                            label={'Email'}
                            value={user?.email ?? ''}
                            onChange={handleTargetChangeBuilder('email')}
                            InputLabelProps={{shrink: true}}
                            sx={{backgroundColor: 'white'}}
                        />

                      </Grid>
                      <Grid
                          item
                          xs={5}
                      >
                        <TextField
                            fullWidth
                            label={'Tel'}
                            value={user?.tel ?? ''}
                            onChange={handleTargetChangeBuilder('tel')}
                            InputLabelProps={{shrink: true}}
                            sx={{backgroundColor: 'white'}}
                        />

                      </Grid>


                      <Grid
                          item
                          xs={2}
                      >
                      </Grid>

                      <Grid
                          item
                          xs={5}
                      >
                        <FormGroup
                            sx={{
                              display: 'flex',
                              flexDirection: 'row'
                            }}
                        >
                          <FormControlLabel control={<Switch checked={user?.license ?? false}
                                                             onChange={handleChangeBuilder('license')}/>}
                                            label="관통사"/>
                          <FormControlLabel control={<Switch checked={user?.driver ?? false}
                                                             onChange={handleChangeBuilder('driver')}/>}
                                            label="운전면허"/>
                        </FormGroup>
                      </Grid>

                      <Grid
                          item
                          xs={5}
                      >
                        <Stack
                            direction={'row'}
                            gap={2}
                        >
                          <FormGroup
                              sx={{
                                display: 'flex',
                                flexDirection: 'row'
                              }}
                          >
                            <FormControlLabel
                                control={<Checkbox checked={user?.english ?? false}
                                                   onChange={handleChangeBuilder('english')}/>}
                                label="English"/>
                            <FormControlLabel
                                control={<Checkbox checked={user?.chinese ?? false}
                                                   onChange={handleChangeBuilder('chinese')}/>}
                                label="Chinese"/>
                            <FormControlLabel control={<Checkbox checked={user?.korean ?? false}
                                                                 onChange={handleChangeBuilder('korean')}/>}
                                              label="Korean"/>
                            <FormControlLabel
                                control={<Checkbox checked={user?.japanese ?? false}
                                                   onChange={handleChangeBuilder('japanese')}/>}
                                label="Japanese"/>
                          </FormGroup>
                          <TextField
                              variant={'standard'}
                              value={user?.languages ?? ''}
                              placeholder={'other languages'}
                              onChange={handleTargetChangeBuilder('languages')}
                          />
                        </Stack>
                      </Grid>

                      <Grid
                          item
                          xs={12}
                      >
                        <Divider/>
                      </Grid>

                      <Grid
                        item
                        xs={2}
                      >
                        <Typography fontWeight={'bold'}>
                          Management
                        </Typography>
                      </Grid>

                      <Grid item xs={2}>
                        <TextField
                          variant={'outlined'}
                          type={'number'}
                          label={'수습'}
                          value={user?.apprenticeships ?? 0}
                          onChange={handleTargetChangeBuilder('apprenticeships', (v:string)=> {
                            const parsed = Number.parseInt(v);
                            if(!Number.isSafeInteger(parsed)) return 0;
                            return parsed;
                          })}
                        />
                      </Grid>
                      <Grid item xs={10}>
                      </Grid>
                      <Grid
                          item
                          xs={12}
                      >
                        <Divider/>
                      </Grid>

                      <Grid
                          item
                          xs={2}
                      >
                        <Typography fontWeight={'bold'}>
                          Type
                        </Typography>
                      </Grid>
                      <Grid
                          item
                          xs={10}
                      >
                        <FormControl>
                          <RadioGroup
                              row
                              value={
                                (user?.level ?? 99) <= 1 ? 'Operator'
                                    : (user?.level ?? 99) === 1.25 ? 'CS'
                                        : (user?.level ?? 99) === 1.5 ? 'Outsource'
                                            : (user?.level ?? 99) === 2 ? 'Guide'
                                                : (user?.level ?? 99) === 2.5 ? 'Driver'
                                                    : ''
                              }
                              onChange={(_, value) => {
                                if (user?.on) {
                                  handleChangeBuilder('level')(_,
                                      value === 'Operator' ? 1
                                          : value === 'CS' ? 1.25
                                              : value === 'Outsource' ? 1.5
                                                  : value === 'Driver' ? 2.5
                                                      : value === 'Guide' ? 2
                                                          : 99);
                                }
                              }}
                          >
                            <FormControlLabel
                                value={'Guide'}
                                control={<Radio/>}
                                label="Guide"
                                labelPlacement="end"
                                disabled={!user?.on}
                            />
                            <FormControlLabel
                                value={'Driver'}
                                control={<Radio/>}
                                label="Driver"
                                labelPlacement="end"
                                disabled={!user?.on}
                            />
                            <FormControlLabel
                                value={'Outsource'}
                                control={<Radio/>}
                                label="Outsource"
                                labelPlacement="end"
                                disabled={!user?.on}
                            />
                            <FormControlLabel
                                value={'CS'}
                                control={<Radio/>}
                                label="CS(Not Promotion)"
                                labelPlacement="end"
                                disabled={!user?.on}
                            />
                            <FormControlLabel
                                value={'Operator'}
                                control={<Radio/>}
                                label="Operator"
                                labelPlacement="end"
                                disabled={!user?.on}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      {
                        user?.level === 1.5 // outsource
                            ? (
                                <>
                                  <Grid
                                      item
                                      xs={2}
                                  >
                                    <Typography fontWeight={'bold'}>
                                      Managing Products
                                    </Typography>

                                  </Grid>
                                  <Grid item xs={10}>
                                    <Autocomplete
                                        multiple
                                        options={
                                          Object.values(products ?? {}).map(({
                                                                               id,
                                                                               name,
                                                                               category,
                                                                               area
                                                                             }) => ({
                                            id,
                                            name: `${name} (${category} - ${area})`
                                          }))
                                        }
                                        value={
                                          (user?.managingProducts ?? [])
                                              .filter(id => products?.[id])
                                              .map<{ id: string, name: string }>((id) => {
                                                const product = products?.[id];
                                                return product
                                                    ? {
                                                      id,
                                                      name: `${product.name} (${product.category} - ${product.area})`
                                                    }
                                                    : {id: '', name: ''};
                                              })
                                        }
                                        onChange={(_, managingProducts) => {
                                          const values = managingProducts.filter(p => !!p?.id).map((({id}) => id));
                                          update('managingProducts', values);
                                        }}
                                        isOptionEqualToValue={(option: { id: string, name: string }, value: {
                                          id: string,
                                          name: string
                                        }) => option.id === value.id}
                                        getOptionLabel={(option: { id: string, name: string }) => `${option.name}`}
                                        sx={{backgroundColor: 'white'}}
                                        renderInput={
                                          (params) => (
                                              <TextField
                                                  {...params}
                                                  fullWidth
                                                  label={'Products'}
                                                  InputLabelProps={{shrink: true}}
                                              />
                                          )
                                        }
                                    />
                                  </Grid>
                                </>
                            )
                            : null
                      }

                    </Grid>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Modal>
  )
}