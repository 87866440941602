/* eslint-disable */
import { useContext, useEffect, useState } from "react"
import { onSnapshot, query, where } from "firebase/firestore"
import { AuthContext } from "../auth"
import { queryFireStore } from "../firebase"
import { collectionWithBaseFireStore } from "./firebase"

export default function UnreadCount() {
    const { user } = useContext(AuthContext) as any
    const [unReadMaps, setUnReadMaps] = useState(new Map())
    const [unReadCount, setUnReadCount] = useState(0)

    useEffect(() => {
        if (!user) return

        const unsubs: any[] = []
        ;(async () => {
            const docs = await queryFireStore("chats", [[`participants.${user.uid}`, "!=", null]])

            docs.forEach((doc: any) => {
                const q = query(
                    collectionWithBaseFireStore(["chats", doc.id, "messages"]),
                    where(`readStatus.${user.uid}`, "==", false)
                )

                const unsub = onSnapshot(q, (snapshot) => {
                    setUnReadMaps((prev) => {
                        const next = new Map(prev)
                        next.set(doc.id, snapshot.docs.length)
                        return next
                    })
                })

                unsubs.push(unsub)
            })
        })()
        return () => {
            if (unsubs.length > 0) unsubs.forEach((unsub) => unsub())
        }
    }, [user])

    useEffect(() => {
        setUnReadCount(Array.from(unReadMaps.values()).reduce((acc, cur) => acc + cur, 0))
    }, [unReadMaps])

    return { unReadCount }
}
