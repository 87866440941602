import {Box, Button, CircularProgress, SwipeableDrawer, SwipeableDrawerProps, Typography} from "@mui/material"
import { red } from "@mui/material/colors"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import React, { ChangeEvent, Dispatch, SetStateAction, useContext, useState } from "react"
import FolderIcon from "@mui/icons-material/Folder"
import SendIcon from "@mui/icons-material/Send"
import { useParams } from "react-router-dom"
import { v4 as uuidv4 } from "uuid"
import Puller from "../../ui/Puller"
import { IParticipant } from "../../../types/chat"
import { AuthContext } from "../../../hooks/auth"
import { sendMessage } from "../../../hooks/chat"
import { uploadFileToStorage } from "../../../hooks/chat/firebase"

interface Props extends SwipeableDrawerProps {
    participants: IParticipant[]
    setOpen: Dispatch<SetStateAction<boolean>>
}

export default function ImageUploadSwiper({ setOpen, participants, ...props }: Props) {
    const [files, setFiles] = useState<File[]>([])
    const [isUploading, setIsUploading] = useState(false)

    const [progresses, setProgresses] = useState<number[]>([]);
    const [key, setNewKey] = useState<string>(new Date().toString());

    const { operator } = useContext(AuthContext)
    const { chatId } = useParams() as { chatId: string }

    async function onUpload() {
        if (files.length === 0) return
        setIsUploading(true)

        const fileUrls = await uploadFilesToStorage(files)
        const message = {
            type: "image",
            files: fileUrls,
        }

        await sendMessage(chatId, participants, operator!, message)
        setNewKey(new Date().toString());
        setProgresses([]);
        setFiles([])
        setOpen(false)
        setIsUploading(false)
    }


    async function uploadFilesToStorage(files: File[]) {
        return await Promise.all(files.map((file, idx) => uploadFileToStorage(`chats/${chatId}/${uuidv4()}`, file, (newProgress, transferred, total) => {
            setProgresses((progresses) => {
                const newProgresses = [...progresses]
                newProgresses[idx] = newProgress
                return newProgresses
            });
        }).catch((e) => {
            alert('파일 업로드 중 에러가 발생했습니다.' + `\n${e.toString()}`)
            return '';
        })));
    }

    function changeFile(e: ChangeEvent<HTMLInputElement>) {
        if (e.target.files instanceof FileList) {
            setFiles([...files, ...e.target.files])
        }
    }

    function disableSwipeOnChildren(e: any) {
        e.stopPropagation()
    }

    return (
        <SwipeableDrawer {...props}>
            <Box
                sx={{
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                    py: 2,
                }}
            >
                <Puller />
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "12px",
                    px: 2.5,
                }}
                onTouchStart={disableSwipeOnChildren}
                onMouseDown={disableSwipeOnChildren}
            >
                <Box
                    sx={{
                        display: "flex",
                        columnGap: "12px",
                        alignItems: "center",
                        justifyContent: "flex-end",
                    }}
                >
                    <Button
                        component="label"
                        variant="contained"
                        disabled={isUploading}
                        startIcon={isUploading ? <CircularProgress size={20} color="inherit" /> : <FolderIcon />}
                        sx={{
                            background: red[500],
                        }}
                    >
                        Folder
                        <input
                            key={key}
                            type="file"
                            id="file"
                            multiple
                            style={{ display: "none" }}
                            accept={"image/*"}
                            onChange={changeFile}
                        />
                    </Button>
                    <Button
                        variant="contained"
                        disabled={isUploading}
                        startIcon={isUploading ? <CircularProgress size={20} color="inherit" /> : <SendIcon />}
                        onClick={onUpload}
                    >
                        Send
                    </Button>
                </Box>
                <Box
                    sx={{
                        overflowX: "scroll",
                        display: "flex",
                        columnGap: "4px",
                    }}
                >
                    {files.map((file,idx) => (
                        <Box
                            sx={{
                                position: "relative",
                                border: "1px solid  #e2e2e2",
                            }}
                        >
                            <img
                                src={URL.createObjectURL(file)}
                                style={{
                                    width: "200px",
                                    height: "200px",
                                    objectFit: "cover",
                                }}
                                alt="image"
                            />
                            <DeleteForeverIcon
                                fontSize="large"
                                sx={{
                                    position: "absolute",
                                    top: 4,
                                    right: 4,
                                    color: red[500],
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    const filterFiles = files.filter((f) => f !== file)
                                    setFiles(filterFiles)
                                }}
                            />
                            {
                                progresses[idx] !== undefined
                                    ? <Box sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '200px',
                                        height: '200px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        background: 'rgba(0,0,0,0.65)'
                                    }}>
                                        <Typography color={'white'}>
                                            {Math.round(progresses[idx])}%
                                        </Typography>
                                    </Box>
                                    : null
                            }
                        </Box>
                    ))}
                </Box>
            </Box>
        </SwipeableDrawer>
    )
}
