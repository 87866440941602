import { useContext, useEffect, useState } from "react"
import * as React from "react"
import Box from "@mui/material/Box"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Select from "@mui/material/Select"
import Divider from "@mui/material/Divider"
import MenuItem from "@mui/material/MenuItem"

import { AuthContext } from "../../hooks/auth"

import dayjs, { Dayjs } from "dayjs"
import { subscribeToOwnChats } from "../../hooks/chat"
import ChatRoom from "../../components/chat/chats/ChatRoom"
import { Space } from "../../components/ui/Space"
import Footer from "../../components/chat/layout/Footer"

const ALL = "ALL"
export default function Chats() {
    const { operator } = useContext(AuthContext)
    const [chats, setChats] = useState<any>({})
    const [tab, setTab] = useState<string>(ALL)
    const [date, setDate] = useState<string>(dayjs().format("YYYY-MM-DD"))
    const [product, setProduct] = useState<string | null>(null)

    useEffect(() => {
        if (!operator) return

        const unsub = subscribeToOwnChats(operator.id, (querySnapshot) => {
            let chats: any = {}
            querySnapshot.forEach(async (doc: any) => {
                const chat = doc.data()
                chats[doc.id] = {
                    ...chat,
                    title: chat.title,
                }
            })

            setChats(chats)
        })

        return () => {
            if (unsub) unsub()
        }
    }, [operator])

    const aggregated = Object.entries(chats).reduce(
        (result, [key, chat]: [string, any]) => {
            const category = chat.category
            if (!result[category]) result[category] = {}
            result[category][chat.id] = chat
            result[ALL][chat.id] = chat

            if (chat.tour && chat.tour.date) {
                const date = chat.tour.date
                const product = chat.tour.productId
                if (!result["_date"][date]) result["_date"][date] = {}
                if (!result["_date"][date][product]) result["_date"][date][product] = {}
                result["_date"][date][product][chat.id] = chat
            }

            return result
        },
        { [ALL]: {}, _date: {} } as { [type: string]: { [chatId: string]: any } }
    )

    const tabs = Object.keys(aggregated).sort((a, b) => (a > b ? 1 : 0))
    const dateOptions = Object.keys(aggregated["_date"])
    const productOptions = Object.keys(aggregated["_date"][date] ?? {})

    return (
        <>
            <Space height={20} />

            <Tabs
                sx={{
                    px: 4,
                }}
                variant={"scrollable"}
                scrollButtons={"auto"}
                value={tab}
                onChange={(_, newTab) => {
                    setTab(newTab)
                }}
            >
                {tabs
                    .filter((t) => !t.includes("_"))
                    .map((t) => {
                        return <Tab value={t} key={t} label={t} />
                    })}
            </Tabs>
            <Divider />

            <Space height={20} />

            {tab.toUpperCase() === "TEAM" || tab.toUpperCase() === "TOUR" ? (
                <Box display={"flex"} justifyContent={"space-evenly"} mx={4.5} mb={3} gap={2}>
                    <Select
                        fullWidth
                        variant={"standard"}
                        value={date}
                        onChange={(e) => {
                            setDate(e.target.value)
                        }}
                    >
                        {dateOptions.map((d) => (
                            <MenuItem value={d}>{d}</MenuItem>
                        ))}
                    </Select>
                    <Select
                        fullWidth
                        variant={"standard"}
                        value={product}
                        onChange={(e) => {
                            setProduct(e.target.value)
                        }}
                    >
                        {productOptions.map((p) => (
                            <MenuItem value={p}>{p}</MenuItem>
                        ))}
                    </Select>
                </Box>
            ) : null}

            <Box display="flex" flexDirection="column" gap="12px" px={4} pb={12}>
                {operator &&
                    Object.keys(aggregated[tab] ?? [])
                        .sort((aId, bId) => {
                            const chatA :any = aggregated[tab][aId]
                            const chatB :any = aggregated[tab][bId]
                            return chatA.updatedAt?.seconds > chatB.updatedAt?.seconds ? -1 : 0
                        })
                        .filter((chatId) => {
                            const chat = aggregated[tab][chatId]
                            return (
                                (tab.toUpperCase() !== "TEAM" && tab.toUpperCase() !== "TOUR") ||
                                (chat.tour?.date === date && (product === null || chat.tour?.productId === product))
                            )
                        })
                        .map((chatId, index) => {
                            const chat = aggregated[tab][chatId]
                            return <ChatRoom key={chatId} chatMeta={chat} />
                        })}
            </Box>
            <Box
                sx={{
                    position: "fixed",
                    bottom: 0,
                    width: "100%",
                }}
            >
                <Footer />
            </Box>
        </>
    )
}
