import * as XLSX from "xlsx"

const IN_OPTIONS: any = [
    'Daegu E-World Cherry Blossom Day Tour  (from Busan) | Korea',
    'Korea DMZ Day Tour: Visit Gimpo, Paju, Cheorwon Demilitarized Zone, Descendants of the Sun Filming Location | South Korea',

    'Strawberry Picking Day Tour from Seoul: Nami Island & Garden of Morning Calm',
    'Strawberry Picking Day Tour from Seoul: Nami Island, Petite France, Italian Village & Garden of Morning Calm',
    'Pocheon Art Valley, Apple Farm Experience + Pocheon Luge World(autumn) / Strawberry Picking + Herb Island(winter) Day Tour from Seoul',
    'Pocheon Art Valley, Strawberry / Apple picking, and Herb Island One Day Tour from Seoul',
    'Pocheon Day Tour: Pocheon Art Valley, Strawberry/Apple Picking & Herb Island | Gyeonggi-do, South Korea',
    '[Departure from Seoul] 1-Day Tour: Pocheon Art Valley, Strawberry/Apple Picking & Herb Island | Gyeonggi-do, South Korea',
    '2023 Jinhae Cherry Blossom Festival (Jinhae Gunhangje) | Depart from Busan (Day Tour)',
    'Korea DMZ Tour | Visit Gimpo / Paju / Cheorwon Demilitarized Zone, <Descendants of the Sun> filming location Day tour',
    'Korea DMZ Day Tour: Visit Gimpo, Paju, Cheorwon Demilitarized Zone, Descendants of the Sun Filming Location | South Korea',
    'Day Tour from Seoul: Nami Island, Petite France, Italian Village, Rail Bike, and Garden of Morning Calm | Korea',
    'Day Tour from Seoul: Nami Island, Petite France,  Italian Village, rail bike, and Garden of Morning Calm',
    'Nami Island / Alpaca World / Petite France & Italian Village / Rail Bike / Garden of Morning Calm / Legoland Korea Day Tour from Seoul',
    'Nami Island / Alpaca World / Petite France & Italian Village / Rail Bike / Garden of Morning Calm / Legoland / Alpaca World / Painters show | Korea Day Tour from Seoul',
    'Nami Island / Alpaca World / Petite France & Italian Village / Rail Bike / Garden of Morning Calm / Legoland / Painters show | Korea Day Tour from Seoul',
    'Day Tour from Seoul: Nami Island / Alpaca World / Petite France & Italian Village / Gangchon Rail Bike / Garden of Morning Calm / LEGOLAND / The PAINTERS Show | South Korea',
    'Day Tour from Seoul: Nami Island / Alpaca World / Petite France & Italian Village / Gangchon Rail Bike / Garden of Morning Calm | South Korea',
    '[Winter Only] Nami Island / Garden of Morning Calm(Light Festival) / Alpaca World / Petite France / Railbike',
    'Legoland & Gangchon Rail Bike or Alpaca World 1-Day Tour from Seoul | South Korea',
    'Korean Folk Village + Suwon Hwaseong One Day Tour',
    'Korean Folk Village & Suwon Hwaseong One-Day Tour Departing from Seoul',
    'Winter Suburb Day Tour from Seoul | Organic strawberry picking, Namiseom Island, The Garden of Morning Calm, rail bike, Eobi Valley , Alps Village (Departure from Seoul)',
    '[Winter Only] Nami Island, Garden of Morning Calm (Light Festival), Alpaca World, Petite France & Railbike | South Korea',
    '[2023 Maple Leaves Season] Naejangsan National Park: 1-Day Tour (Departure from Seoul) | South Korea',

    'Busan Day Tour | Taejongdae + Gamcheon Culture Village + Huinnyeoul Culture Village+ Haedongyonggungsa Temple or Yacht Tour',
    'Busan Day Tour | Taejongdae + Gamcheon Culture Village + Songdo Skywalk + Haedongyonggungsa Temple or Yacht Tour',
    'Busan One Day City Tour | Yacht Experience Optional',
    'Busan One Day City Tour | Yacht, Haeundae Sky Capsule, Beach Train',
    'Day Tour from Busan: Gyeongju UNESCO World Heritage Site | South Korea',

    'Legoland 1-Day Tour from Seoul (Ticket + Transfer) | South Korea',
    '2024 Gwangyang Plum Blossom Day Tour from Seoul/Busan: Gwangyang Maehwa Festival & Jeonju Hanok Village in Jeollanam-do | South Korea',
    '2024 Jinhae Cherry Blossom Festival (Jinhae Gunhangje) Day Tour | Depart from Busan',
    'Blueline Park Sky Capsule & Chasing Cherry Blossom and Spring Flowers Day Tour from Busan | South Korea',
    '2024 Jinhae Cherry Blossom Festival (Jinhae Gunhangje) – Departing From Seoul',
    'Jeonju Hanok Village Day Tour | Seoul Departure',
    'Day Tour from Busan: Blue Line Park Sky Capsule + Gyeongju UNESCO World Heritage Day Tour',
    'Mt. Seorak & Naksansa Temple 1-Day Tour | Gangwon-do, South Korea',

    'Max Out Busan | Highlight Photo Spots One Day Tour',
    'Seoul Outskirts Tour | Organic Strawberry Picking, Nami Island, Morning Calm Arboretum, Rail Bike, Alps Village (Departing from Seoul)',
    '2024 Cherry Blossom & Spring Flowers Spots Day Tour | from Seoul'
]

const SKI_TOUR: any = ["엘리시안", "남이엘리시안", "남이비발디", "지산", "에덴"]

const EVERLAND: any = [
    '[KKday Exclusive] Everland Theme Park: Admission Ticket + Private Round-Trip Transfer from Seoul | South Korea',
    'KKday Only | Everland Theme Park Tickets with Round-Trip Transfer from Seoul',
    'Everland Theme Park Tickets with Round-Trip Transfer from Seoul',
    '[KKday Exclusive] Everland Theme Park: Admission Ticket + Round Trip Transportation from Seoul | South Korea',
]

const TOUR_NAME: any = {
    '2024 Jinhae Cherry Blossom Festival: Jinhae Gunhangje Day Tour from Busan | South Korea': '부산진해',
    'Gyeongju Cherry Blossom Day Tour from Busan | South Korea': '경주벚꽃',
    'Everland Theme Park Tickets with Round-Trip Transfer from Seoul': '에버',
    'KKday Only | Everland Theme Park Tickets with Round-Trip Transfer from Seoul': '에버',
    '[KKday Exclusive] Everland Theme Park: Admission Ticket + Private Round-Trip Transfer from Seoul | South Korea': '에버',
    '[KKday Exclusive] Everland Theme Park: Admission Ticket + Round Trip Transportation from Seoul | South Korea': '에버',

    'Gyeongsang-do 1-Day Tour: Autumn Foliage Sightseeing in Busan, Daegu, Gyeongju, and Gyeongsang (Departure from Busan) | South Korea': '단풍랜덤부산',
    'Gyeongbuk Autumn Maple Day Tour from Busan: Bomun Tourist Complex & Gyeongju Historic Area': '단풍랜덤경주',
    'Gyeongbuk Autumn Maple Day Tour from Busan: Bomun Tourist Complex and Gyeongju Historic Area': '단풍랜덤경주',
    'Seoul Walking Tour: Gwangjang Market Unique Food Tour | South Korea': '광장시장',

    'Mt. Seorak，Naksansa Temple One Day Tour': '설낙',
    'Mt. Seorak & Naksansa Temple One-Day Tour': '설낙',

    'Nami Island & Mountain Seoraksan Day Tour from Seoul': '남설',
    'Gangwon-do 1-Day Tour: Nami Island & Seoraksan Mountain (Departure from Seoul) | South Korea': '남설',
    'Gangwon Day Tour: Alpaca World, Gubongsan Mountain Observatory Café Street & Jade Garden': '알파카',
    'Gangwon Day Tour: Alpaca World, Gubongsan Mountain Observatory Café Street & SPACE1 Hyundai Premium Outlet': '알파카',
    'Gangwon Day Tour: Alpaca World, Gubongsan Mountain Observatory Café Street & Jade Garden | South Korea': '알파카',
    'Nami Island Round-Trip Transportation from Seoul': '남이섬셔틀',
    'Nami Island Round-trip Transportation from Seoul | South Korea': '남이섬셔틀',
    'Korea Ski Tour | Elysian Gangchon Ski Resort One Day Tour': '엘리시안',
    'Elysian Gangchon Ski Resort One Day Tour with Basic Ski Lesson | Korea': '엘리시안',
    'Elysian Gangchon Ski Resort & Nami Island One Day Tour': '남이엘리시안',
    'Gangwon 1-Day Tour: Elysian Gangchon Ski Resort & Nami Island | South Korea': '남이엘리시안',
    'Nami Island and Vivaldi Ski Resort Day Tour from Seoul': '남이비발디',
    'Vivaldi Park Winter Ski and Nami Island Day Tour from Seoul': '남이비발디',
    'Gyeonggi-do Day Tour: Vivaldi Park Ski World and Nami Island (Departure from Seoul) | South Korea': '남이비발디',
    'Korea Ski Tour | Jisan Forest Ski Resort One Day Tour': '지산',
    'Jisan Forest Winter Ski Day Tour from Seoul | Korea': '지산',
    'Jisan Forest Winter Ski Day Tour from Seoul  | Korea': '지산',
    'Busan Day Tour: Haedong Yonggungsa Temple, Gamcheon Culture Village & Taejongdae': '태감송해',
    'Busan Day Tour | Taejongdae + Gamcheon Culture Village + Huinnyeoul Culture Village+ Haedongyonggungsa Temple': '태감송해',
    'Geoje Island Day Tour from Busan: Windy Hill, Oedo Island, and Maemiseong': '외도',
    'Geoje Island Day Tour from Busan: Windy Hill, Oedo Island, and Maemiseong | South Korea': '외도',
    'Geoje Island Day Tour from Busan: Windy Hill, Oedo Island, and Geoje Panorama Cable Car | South Korea': '외도',


    'Day Tour from Busan: Tongyeong Jungang Market, Hallyeosudo Cable Car, Skyline Luge & Yi Sun-Sin Park': '통영',
    '[Departure from Busan] 1-Day Tour: Tongyeong Jungang Market, Hallyeosudo Cable Car, Skyline Luge & Yi Sun-Sin Park | Gyeongsangnam-do, South Korea': '통영',
    'Eden Valley Winter Ski Day Tour from Busan | Korea': '에덴',
    'Korea Day Tour | Nami Island ・ Garden of MorningCalm (from Seoul)': '남아',
    'Gyeonggi Day Tour: Petite France & Italian Village & Nami Island': '남쁘',
    'Gyeonggi Day Tour: Petite France & Nami Island (Drop-off at the PCR Testing Center)': '남쁘',
    'Day Tour from Seoul: Nami Island, Petite France,  Italian Village and Garden of Morning Calm': '남쁘아',


    'Day Tour from Seoul: Nami Island, Petite France, and Garden of Morning Calm': '남쁘아',
    'Gyeonggi Day Tour: Nami, Gangchon Rail Bike, The Garden of Morning Calm': '레남아',
    'Chuncheon Day Tour from Seoul: Gangchon Rail Bike, Nami Island, Petite France': '레남쁘',
    'Chuncheon Day Tour from Seoul: Gangchon Rail Bike, Nami Island, Petite France,  Italian Village': '레남쁘',
    'Nami Island + Petite France + Rail Bike': '레남쁘',
    'Chuncheon Day Tour from Seoul: Gangchon Rail Bike, Nami Island, Petite France,  Italian Village and The Garden of Morning Calm': '쁘남레아',
    'Chuncheon Day Tour from Seoul: Gangchon Rail Bike, Nami Island, Petite France, and The Garden of Morning Calm': '쁘남레아',
    'Hyvaa Finland + Nami Island + Rail Bike': '휘남레',
    'Gangwon Day Tour: Hyvaa Finland +Nami Isalnd+ Rail bike': '휘남레',
    'Nami Island + Hyvaa Finland + Gangchon Rail Bike': '휘남레',
    'Gangwon Day Tour: Alpaca World／Hyvaa Finland +Nami Isalnd+ Rail bike': '알남레',

    'Gangwon Day Tour: Alpaca World, Hoengseong Luge, Museum San | South Korea': '알루산',
    'Gangwon Day Tour:  Alpaca World, Hoengseong Luge, Museum San | South Korea': '알루산',

    'Jirisan Mountain Autumn Maple Day Tour | Busan Departure': '지리산',
    'Jirisan Mountain Autumn Maple Day Tour from Busan': '지리산',
    'Daedunsan Mountain Autumn Day Tour (Departure from Seoul) | South Korea': '대둔산',

    'Daegu Palgongsan Natural Park Autumn Day Tour from Busan': '가을팔공산',
    'Jeollabuk-do Autumn Day Tour: Naejangsan National Park (Departure from Busan) | South Korea': '부산내장산',
    'Jeolla-buk Do Autumn Day Tour: Naejangsan National Park from Busan': '부산내장산',

    'Breathtaking Autumn｜Seoraksan National Park One Day Tour from Seoul': '설악산단풍',

    'Seoul｜Memorable Autumn Foliage Random Day Tour': '단풍랜덤',
    'Seoul｜Memorable Autumn Hunting Day Tour': '단풍랜덤',
    'Memorable Autumn Hunting Day Tour | Seoul': '단풍랜덤',

    'Seoul & Incheon: Autumn Instagrammable Day Tour | South Korea': '인스타가을',
    'Seoul & Incheon: Autumn Instagrammable Day Tour | Korea': '인스타가을',

    'Hwadam Botanic Garden + The Stay Healing Park + Koo House Museum One Day Tour': '화구스(EG)',
    'Jeonju Hanok Village Day Tour | Seoul Departure': '전주',

    'Depart from Seoul｜Lego Land + Gangchon Railbike One-day Tour': '레고레일',
    'New attractions in the suburbs of Seoul｜Legoland Legoland, Gangchon Rail Bike (from Seoul)': '레고레일',
    'LEGOLAND & Gangchon Rail Bike One-Day Tour from Seoul | South Korea': '레고레일',
    'Legoland & Gangchon Rail Bike 1-Day Tour from Seoul | South Korea': '레고레일',

    'Maple Leaves Sightseeing Day Tour: Odaesan National Park & Woljeongsa Temple (Departure from Seoul) | Gangwon-do, South Korea': '오대산',

    '2023 Spring｜Gwangyang Plum Blossom Festival and Gurye Dogwood Festival One-day Tour in Jeollanam-do (Departure from Seoul)': '서울광양구례',
    'Gwang-yang Maehwa & Gurye Sansuyu Festival Day Tour From Busan': '부산광양구례',

    '2023 Cherry Blossom Season | Jinhae One Day Tour From Busan': '부산진해',
    '2023 Jinhae Cherry Blossom Festival (Jinhae Gunhangje) - Depart from Busan (Day)': '부산진해',

    '2023 Cherry Blossom Season │ Bus Day Tour from Busan': '부산벚꽃랜덤',
    '2023 Spring Season │ Busan Cherry Blossom Random Day Tour': '부산벚꽃랜덤',
    '2023 Spring season | Seoul Cherry Blossom Random Day Tour': '서울벚꽃랜덤',

    '2023 Spring Season | Taean Tulip Festival and Strawberry Picking Day Tour (Departure from Seoul)': '태안딸기',
    'KPOP BTS Fans Day Tour | Hwarangdae Station, Hakdong Park, Aunt\'s Cafeteria | Depart from Seoul': 'BTS',

    'The Suncheon Bay International Garden Expo Tour (from Busan)': '순천만',


    '2023 Jinhae Cherry Blossom Festival (Jinhae Gunhangje) | Depart from Seoul (Day Tour)': '서울진해',
    'Romantic Jinhae Cherry Blossom Watching Tour from Seoul': '서울진해',
    'K-POP | SEOUL FESTA 2023 Opening Ceremony + Gyeongbokgung Palace Guard Changing Tour + Photography Service': '서울페스타',
    '[Free Lotte Duty Free Coupon Worth $30] Lotte World All Night Party | Seoul': '롯데올나잇',

    '2024 Jinhae Cherry Blossom Festival (Departure from Seoul) | South Korea': '서울진해',
    '2024 Chasing Cherry Blossom & Spring Flowers Day Tour from Seoul | South Korea': '벚꽃랜덤(서울)',

    'Pocheon Day Tour: Pocheon Art Valley, Strawberry Picking & Herb Island | Gyeonggi-do, South Korea': '포천딸기',
}

const TOUR_OPTIONS: any = {
    'A - 2: Organic strawberry picking + Nami Island + Morning Calm Arboretum [One-Day tour]': '딸남아',
    'Seoul Cherry Blossom Random Day Tour': '벚꽃랜덤(서울)',
    '[KTO-Family Exclusive] D. Haedong Yonggungsa Temple + Songjeong Beach Train + Songdo Beach + Gamcheon Culture Village': '송감송해',
    '[Course C] Blueline Park Beach Train + Gamcheon Culture Village + Songdo Skywalk + Haedong Yonggungsa Temple': '송감송해',
    '[Course E] Yacht Tour + Blueline Park Sky Capsule + Gamcheon Culture Village + Haedong Yonggungsa +Cheongsapo': '캡슐요트',
    'Package A-1 - Nami Island + Petite France + Italian Village': '남쁘',
    'Package A - Nami Island + Petite France + Italian Village': '남쁘',
    'Package A-2 - Nami Island + Petite France + Italian Village + Painters show': '남쁘페',
    'Package B - Nami Island + Gangchon Rail Bike + Petite France + Italian Village': '레남쁘',
    'Package C - Nami Island + Petite France + Italian Village + The Garden of Morning Calm': '남쁘아',
    'Package D - Nami Island + The Garden of Morning Calm': '남아',
    'Package E - Nami Island + Gangchon Rail Bike + The Garden of Morning Calm': '레남아',
    'Package F - Nami Island + Petite France + Italian Village + Gangchon Rail Bike + The Garden of Morning Calm': '쁘남레아',
    'Package G - Alpaca World + Nami Island + The Garden of Morning Calm': '알남아',
    'Package H - Alpaca World + Nami Island': '알남',
    'Package H -  Alpaca World + Nami Island': '알남',
    'Package I - Alpaca World + Nami Island + Gangchon Rail Bike': '알남레',
    'Package J - Lego land + Nami Island': '레고남이',

    'Package A - Nami Island + Petite France + Italian Village + The Garden of Morning Calm': '남쁘아',
    'Package B - Nami Island + Petite France + Italian Village': '남쁘',
    'Package C - Nami Island + The Garden of Morning Calm': '남아',
    'Package D - Nami Island + Gangchon Rail Bike + Petite France + Italian Village': '레남쁘',
    'Package G - Nami Island + Alpaca World + Gangchon Rail Bike': '알남레',
    'Package G - Alpaca World + Nami Island + Gangchon Rail Bike': '알남레',
    'Package H - Alpaca World + Nami Island + The Garden of Morning Calm': '알남아',

    'A: Nami Island + Morning Calm(Light Festival) + Petite France&Italian Village': '남쁘아',
    'A: Nami Island, Morning Calm (Light Festival), Petite France & Italian Village': '남쁘아',
    'B: Nami Island + Morning Calm(Light Festival)': '남아',
    'B: Nami Island, Morning Calm (Light Festival)': '남아',
    'C: Nami Island + Morning Calm(Light Festival) + Railbike': '레남아',
    'C: Nami Island, Morning Calm (Light Festival) & Railbike': '레남아',
    'D: Nami Island + Morning Calm(Light Festival) + Railbike + Petite France&Italian Village': '쁘남레아',
    'D: Nami Island, Morning Calm (Light Festival), Railbike, Petite France & Italian Village': '쁘남레아',
    'E: Nami Island + Morning Calm(Light Festival) + Alpaca World': '알남아',
    'E: Nami Island, Morning Calm(Light Festival) & Alpaca World': '알남아',
    'F: Alpaca World + Gangchon Rail Bike + Nami Island + Garden of Morning Calm (Starlight Festival)': '알레남아',


    'Package I -  Alpaca World + Nami Island': '알남',
    'Package J -  Lego land + Nami Island': '레고남이',
    'Legoland Tickets & Round Trip Transfers': '레고랜드',

    'Package K - Nami Island + Petite France + Italian Village + Painters show': '남쁘페',

    'A : 유기농 딸기 따기 + 남이섬 + 아침 고요 수목원 일일투어': '딸남아',
    'A: [Day tour] Organic strawberry picking + Namiseom Island + The Garden of Morning Calm': '딸남아',
    'A - 1: Organic strawberry picking + Nami Island + Morning Calm Arboretum day tour': '딸남아',
    'A - 2: Organic strawberry picking + Nami Island + Morning Calm Arboretum day tour': '딸남아',
    'A - 1: Organic strawberry picking + Nami Island + Morning Calm Arboretum [One-Day tour]': '딸남아',
    'B - 1: Organic Strawberry Picking + Nami Island + Rail Bike + Morning Calm Arboretum Day Tour': '딸남레아',
    'B - 1: Organic Strawberry Picking + Nami Island + Rail Bike + Morning Calm Arboretum [One-Day tour]': '딸남레아',
    'B - 2: Organic strawberry picking + Nami Island + rail bike + Morning Calm Arboretum day tour': '딸남레아',
    'B: [Day tour] Organic strawberry picking + Namiseom Island + rail bike + The Garden of Morning Calm': '딸남레아',
    'C: [Day tour] Organic strawberry picking + Namiseom Island + rail bike': '딸남레',
    'C: Organic strawberry picking + Namiseom Island + rail bike [One-Day tour]': '딸남레',
    'D: [Day tour] Organic strawberry picking + snow sledding + Eobi Valley': '딸썰어',
    'D: Organic strawberry picking + snow sledding + Eobi Valley [One-Day tour]': '딸썰어',
    'E: [Day tour] Organic strawberry picking +Namiseom Island + Eobi Valley': '딸남어',
    'E: Organic strawberry picking +Namiseom Island + Eobi Valley [One-Day tour]': '딸남어',
    'F: [Day tour] Organic strawberry picking + Cheongyang Alps Village': '딸알프스',
    'G : Organic strawberry picking + Nami Island + Alpaca World + Morning Calm Arboretum [One-Day tour]': '알딸남아',

    'Gwangyang Day Tour from Seoul: Plum Blossom Festival and Jeonju Hanok Village': '광양전주',

    'LEGOLAND & Gangchon Rail Bike Day Tour': '레고레일',
    'LEGOLAND + Alpaca World Day Tour': '알레고',

    'Korean Folk Village + Suwon Hwaseong One Day Tour': '수원화성투어(EG)',
    'Suwon Hwaseong + Korean Folk Village Night Festival One Day Tour': '야간민속촌',

    '패키지 A - 남이섬 + 쁘띠프랑스 + 이탈리아 마을 + 아침고요수목원': '남쁘아',
    '패키지 B - 남이섬 + 쁘띠프랑스 + 이탈리아 마을': '남쁘',
    'Package 2C - Nami Island + The Garden of Morning Calm': '남아',
    '패키지 D - 남이섬 + 강촌레일바이크 + 쁘띠프랑스 + 이탈리아 마을': '레남쁘',
    '패키지 E - 남이섬 + 강촌 레일바이크 + 아침고요수목원': '레남아',
    'Package 2F - Nami Island + Petite France + Italian Village + Gangchon Rail Bike + The Garden of Morning Calm': '쁘남레아',
    'Package 2G - Nami Island + Alpaca World + Gangchon Rail Bike': '알남레',

    'Pocheon Art Valley + Strawberry Picking + Herb Island （11/1 ~ 1/31）': '포천딸기',
    'Pocheon Art Valley + Strawberry Picking + Herb Island': '포천딸기',
    '[Promotion] Pocheon Art Valley + Strawberry Picking + Herb Island': '포천딸기',
    'Pocheon Art Valley + Strawberry Picking + Herb Island(11/01~03/17)': '포천딸기',
    'A. Pocheon Art Valley + Strawberry Picking + Herb Island(11/01~03/17)': '포천딸기',

    'Pocheon Art Valley + Apple picking / bread & applesauce making + Herb Island': '포천사과',
    'Pocheon Art Valley + Apple Picking/Bread & Applesauce Making + Herb Island': '포천사과',
    'Pocheon Art Valley + Apple Picking + Herb Island': '포천사과',


    '[Hong Kong Tourists Only] Package A - Nami Island + Petite France Village + Little Italy Village + Garden of Morning Calm One Day Tour': '남쁘아',
    '[HK promotion] Taejongdae + Gamcheon Culture Village + Songdo Skywalk + Haedong Yonggungsa Temple': '태감송해',

    '[Course B] Yacht Tour + Gamcheon Culture Village + Haedong Yonggungsa Temple + Gwanganli White Lighthouse': '해감요트',
    '[Course D] Haedong Yonggungsa Temple + Songjeong Beach Train + Songdo Beach + Gamcheon Culture Village': '송감송해',
    'D. Haedong Yonggungsa Temple + Songjeong Beach Train + Songdo Beach + Gamcheon Culture Village': '송감송해',

    '[KTO-Family Exclusive] Jeollanam-do Gwangyang Plum Blossom Festival & Gurye Dogwood Festival One-Day Tour (Departing from Busan)': '광양구례(부산)',

    'Gwangyang Day Tour from Busan: Plum Blossom Festival and Gurye Sansuyu Festival': '광양구례(부산)',

    'Gyeongju UNESCO World Heritage Site Day Tour': '경주',
    'A. Gyeongju UNESCO World Heritage Site Day Tour (24.1.1~09.30)': '경주',
    'Gyeongju UNESCO World Heritage Site Day Tour (October 1–March 31)': '경주',
    '[KTO-Family Exclusive] A. Gyeongju UNESCO World Heritage Site Day Tour (24.1.1~09.30)': '경주',
    'Gyeongju + Haeundae Sky Capsule Day Tour': '경주캡슐',
    'Blue Line Park Sky Capsule + Gyeongju UNESCO World Heritage Day Tour': '경주캡슐',
    'Naejangsan National Park One Day Tour (Departure from Seoul)': '내장산',
    'Naejangsan National Park One Day Tour (Departure from Seoul) - Premium Luxury Bus': '리무진내장산',

    'Everland All-day Tour': '',
    'Everland One-Day Tour': '',
    'Everland Day Tour (12/18~)': '',
    'Everland Day Tour (~12/17)': '',
    'Everland Day Tour (~24/02/29)': '',
    '[5/1~5/31 special offer for a limited time] Everland one-day tour': '',
    '에버랜드 당일투어': '',
    'Everland Day Tour (24/03/01~24/03/31) (24/06/01~24/08/31)': '',
    '[KTO-Family Exclusive] Everland Day Tour (24/03/01~24/03/31) (24/06/01~24/08/31)': '',

    'Everland All-day Tour + KKday T-money Card': 'T-MONEY',
    'Everland One-Day Tour + KKday T-money Transportation Card': 'T-MONEY',
    '에버랜드 당일투어 + KKday T-money 교통카드': 'T-MONEY',
    '[5/1~5/31 special offer for a limited time] Everland One-Day Tour + KKday T-money Transportation Card': 'T-MONEY',
    'Everland day tour + KKday T-money transportation card (12/18~)': 'T-MONEY',
    'Everland day tour + KKday T-money transportation card (~24/02/29)': 'T-MONEY',
    'Everland day tour + KKday T-money transportation card (24/03/01~24/03/31) (24/06/01~24/08/31)': 'T-MONEY',
    'Everland day tour + KKday T-money transportation card (24/04/01~24/05/31) (24/09/01~24/10/31)': 'T-MONEY',

    'Paju DMZ Day Tour': '파주DMZ',
    'Paju DMZ Day Tour (Camp Greaves)': '파주DMZ',
    'Paju DMZ Day Tour (The 3rd Tunnel)': '파주(땅굴)DMZ',
    'Cheorwon DMZ Day Tour': '철원DMZ',
    'Cheorwon DMZ | Demilitarized Zone One Day Tour': '철원DMZ',
    'Gimpo DMZ Day Tour': '김포DMZ',

    '[Hong Kong Tourists Only] Cheorwon DMZ: Demilitarized Zone One-Day Tour': '철원DMZ',
    '[Hong Kong Tourists Only] Paju DMZ: Demilitarized Zone One-Day Tour': '파주DMZ',
    '[Hong Kong Tourists Only] Gimpo DMZ: Demilitarized Zone One-Day Tour': '김포DMZ',

    'Taejongdae + Gamcheon Culture Village + Huinnyeoul Culture Village + Haedong Yonggungsa Temple': '태감송해',
    'Taejongdae + Gamcheon Culture Village + Songdo Skywalk + Haedong Yonggungsa Temple': '태감송해',
    'Yacht Tour + Gamcheon Culture Village + Haedong Yonggungsa Temple + Gwanganli White Lighthouse': '요트',

    '[Course A] Taejongdae + Gamcheon Culture Village + Songdo Skywalk + Haedong Yonggungsa Temple': '태감송해',
    '[KTO-Family Exclusive] A. Busan Day Tour - Taejongdae + Gamcheon Culture Village + Songdo Skywalk + Haedong Yonggungsa Temple': '태감송해',
    '[Course C] Blueline Park Beach Train(Sky Capsule) + Oryukdo Islands Skywalk + Huinnyeoul Culture Village + Gamcheon Culture Village': '감천미포',
    '[KTO-Family Exclusive] C. Busan Day Tour - Blue Line Park Beach Train (Sky Capsule) + Oryukdo Skywalk + Huinnyeoul Culture Village + Gamcheon Culture Village': '감천미포',
    '[Course D] Blueline Park Beach Train(Sky Capsule) + Oryukdo Islands Skywalk + Huinnyeoul Culture Village': '시티미포',


    'A. Ski experience (ski lift ticket not included)': '강습',
    'A : First Timer(Lesson)': '강습',
    'Option A: Vivaldi Park Skiing (Lesson) & Nami Island': '강습',

    'B. Ski experience (ski lift pass included)': '강습 + LIFT',
    'B : Beginner(Lesson+Lift)': '강습 + LIFT',
    'B : Beginner(Lesson+Lift 8h)': '강습 + LIFT',
    'Option B: Vivaldi Park Skiing (Lesson + Lift Pass) & Nami Island': '강습 + LIFT',
    'B: Ski+Nami (Lesson+Lift)': '강습 + LIFT',

    'C : Sightseeing Only': '관광',
    'Option C: Vivaldi Park Sightseeing + Nami Island (December 20, 2023 to February 29, 2024)': '관광',

    'D. Sled experience (December 20–February 29)': '썰매',
    'D : Sled(12/20-2/29)': '썰매',
    'Option D: Vivaldi Park Sledding (Snowy Land) + Nami Island (December 20, 2023 to February 29, 2024)': '썰매',

    'Ski (Lift Pass included)': '강습 + LIFT',
    'Nami + Ski (Lift Pass included)': '강습 + LIFT',
    'Ski Experience and Nami Island Package (with Ski Lift Pass)': '강습 + LIFT',
    'Ski Package': '강습 + LIFT',

    'Ski (No Lift Pass)': '강습',
    'Nami + Ski (No Lift Pass)': '강습',
    'Ski Experience and Nami Island Package (without Ski Lift Pass)': '강습',

    'Sightseeing only': '관광',
    'Nami + Sightseeing only': '관광',
    'Vivaldi Park and Nami Island Sightseeing Package (with Cable Car)': '관광',
    'Transportation Only Package Package': '관광',

    'Sled (12/23~)': '썰매',
    'Snowyland and Nami Island Package (Dec 23~)': '썰매',
    'Sled Package': '썰매',
    'Sled': '썰매',

    'Ski (inc moving walk)': '강습 + MOVING',
    'A: Ski (Lesson+Moving walk)': '강습 + MOVING',
    'Ski (inc lift+moving walk)': '강습 + MOVING + LIFT',
    'B: Ski (Lesson+Lift+Moving Walk)': '강습 + MOVING + LIFT',
    'C: Sled': '썰매',

    'A: Ski (Lesson+Lift x1 Ride)': 'SKI 강습 + LIFT (1 Time)1',
    'B. Snowboard (Lesson+Lift x1 Ride)': 'SNOWBOARD 강습 + LIFT (1 Time)1',
    'B. Snowboard (Lesson)': 'SNOWBOARD 강습 + LIFT (1 Time)1',
    'A. Ski Package': '강습 + LIFT (1 Time)',
    'B: Sled': '썰매',
    'C: Sightseeing only': '관광',
    'D: Sightseeing only': '관광',


    '【Day】Jinhae Cherry Blossom Tour': '부산진해',
    '[Course B] Blueline Park Sky Capsule + Gamcheon Culture Village + Oryukdo Skywalk + Huinnyeoul Culture Village': '감천미포',
    '[Day] Jinhae Cherry Blossom Tour': '부산진해',
    '【Day Cherry Blossom】Jinhae Cherry Blossom Viewing One Day Tour': '부산진해',
    '[KTO-Family Exclusive] 【Day Cherry Blossom】Jinhae Cherry Blossom Viewing One Day Tour': '부산진해',
    '[Night] Jinhae Cherry Blossom Tour': '부산진해(야간)',
    '[KTO-Family Exclusive] 【Night Cherry Blossom】Jinhae Cherry Blossom One-day Tour': '부산진해(야간)',
    '【Night】Jinhae Cherry Blossom Tour': '부산진해(야간)',
    '[KTO-Family Exclusive] A. Taejongdae + Gamcheon Culture Village + Songdo Skywalk + Haedong Yonggungsa Temple': '태감송해',
    'Jeonju Hanok Village Day Tour': '전주',
    'Nami Island round trip shuttle bus': '남이섬셔틀',
    'Jinhae Cherry Blossom One-day Tour': '서울진해',
    'B. Haeundae Blue Line Park & Random Attractions (Departing from Busan)': '캡슐벚꽃',

    'Gyeongju Cherry Blossom Day Tour from Busan': '경주벚꽃',
    'Highlight Photo Spots One Day Tour': '특공대(부산)',
    'Package A - Nami Island + Petite France & Italian Village': '남쁘',
    'A. Romantic Random Cherry Blossom Chasing & Spring Flowers One-day Tour (Departing from Busan)': '벚꽃랜덤(부산)',
    'Daegu E-World Day Tour - Course B (Cherry Blossom Tour)': '이월드벚꽃',
    'Course C] Blueline Park Beach Train + Gamcheon Culture Village + Songdo Skywalk + Haedong Yonggungsa Temple': '송감송해',
    '[KTO-Family Exclusive] Blue Line Park Sky Capsule + Gyeongju UNESCO World Heritage Day Tour': '경주캡슐',
    'Package C - Nami Island + Petite France & Italian Village + The Garden of Morning Calm': '남쁘아',
    'Package B - Nami Island + Gangchon Rail Bike + Petite France & Italian Village': '레남쁘',
    'Paju DMZ | Demilitarized Zone One Day Tour "Third Tunnel"': '파주(땅굴) DMZ',
    'Mt. Seorak & Naksansa Temple One-Day Tour': '설낙',
    'Package F - Nami Island + Petite France & Italian Village + Gangchon Rail Bike + The Garden of Morning Calm': '쁘남레아',
    '[KTO-Family Exclusive] C. Blueline Park Beach Train + Gamcheon Culture Village + Songdo Skywalk + Haedong Yonggungsa Temple': '송감송해',
    '[ Special Discount ] Package I - Alpaca World + Nami Island + Gangchon Rail Bike': '알남레',
    'Package J - Alpaca World + Nami Island + Samaksan Cable Car': '알남삼'
}

export const parseKkdayReservationFile = async (files: FileList, findProductNameAndOption: (productPossible: string, optionPossible?: string) => { product: string, option?: string } | null) => {
    let parsedData: any[] = [];
    for (let i = 0; i < files.length; i++) {
        parsedData.push(...await parseFileData(files[i]))
    }
    const kkdayNotRegistered: any = []
    const kkday: any = {}
    parsedData.forEach((data) => {
        if (!data["Booking no."]) return
        let status = data["Order Status"]?.toString().trim() || ""
        if (status === "Canceled") return
        let bookingId = data["Booking no."]?.toString().trim() || ""
        let tour = data["Product's Name"]?.toString().trim() || ""
        let option = data["Package Name"]?.toString().trim() || ""
        let firstName = data["Passport First Name (English)"]?.toString().trim() || ""
        let lastName = data["Passport Surname Name (English)"]?.toString().trim() || ""
        let number = parseInt(data["Quantity"])
        let lang = data["Tour Language"]?.toString().trim() || ""
        let pickup = data["Pick-up location"]?.toString().trim() || ""
        let pickupTime = data["Shuttle service time"]?.toString().trim().split("~") || []
        let pickup2 = data["Specification 1"]?.toString().trim() || ""
        let pickup3 = data["Specification 2"]?.toString().trim() || ""

        let name = firstName + " " + lastName

        let pickups = [...pickupTime, pickup2, pickup3]
        let timeSet = [
            '7:00', '7:10', '7:20', '7:30', '7:40',
            '8:00', '8:10', '8:20', '8:30', '8:40',
            '9:00', '9:10', '9:20', '9:30', '9:40',
            '10:00', '10:10', '10:20', '10:30', '10:40'
        ]

        pickupTime = ["", ""]

        for (let i = 0; i < pickups.length; i++) {
            for (let j = 0; j < timeSet.length; j++) {
                if (pickups[i].search(timeSet[j]) !== -1) {
                    pickupTime = ["1", timeSet[j]]
                    break
                }
            }
        }

        pickups = [pickup, pickup2, pickup3]

        for (let i = 0; i < pickups.length; i++) {
            const p = pickups[i]
            if (p.search("Yejang") !== -1) {
                pickup = "Yejang"
            } else if (p.search("Dongdaemun") !== -1) {
                pickup = "Dongdaemun"
            } else if (p.search("Hongik") !== -1) {
                pickup = "Hongdae"
            } else if (p.search("KTX") !== -1) {
                pickup = "Busan Station"
            } else if (p.search("Seomyun") !== -1) {
                pickup = "Seomyun"
            } else if (p.search("Haeundae") !== -1) {
                pickup = "Haeundae"
            }
        }

        if (lang.search("zh") !== -1) {
            lang = "중국어"
        }else if(lang.search('ko') !== -1){
            lang = '한국어'
        }else if(lang.search('jp') !== -1){
            lang = '일본어'
        }
        else {
            lang = "영어"
        }

        let extra = ""
        if (pickup3.search("snow sledding") !== -1) {
            extra = `SLED ${number}`
        }


        try {
            const found = findProductNameAndOption(tour, option);
            if (found) {
                kkday[bookingId] = {
                    tour: found.product,
                    name,
                    people: number,
                    lang,
                    pickupTime: pickupTime[1],
                    extra: option && option.toLowerCase() !== 'ignore' && option.toLowerCase() !== 'no option' && found.option ? `${found.option}${number}` : extra,
                    option: option && option.toLowerCase() !== 'ignore' && option.toLowerCase() !== 'no option' && found.option ? `${found.option}${number}` : extra,
                }
            } else if (IN_OPTIONS.includes(tour)) {
                try {
                    kkday[bookingId] = {
                        tour: TOUR_OPTIONS[option] || `투어: ${tour} 옵션: ${option}`,
                        name,
                        people: number,
                        pickupTime: pickupTime[1],
                        lang,
                        extra,
                    }
                } catch (e) {
                    kkday[bookingId] = {
                        tour: `투어: ${tour} 옵션: ${option}`,
                        name,
                        people: number,
                        pickupTime: pickupTime[1],
                        lang,
                        extra,
                    }

                    kkdayNotRegistered.push(option)
                }
            } else if (SKI_TOUR.includes(TOUR_NAME[tour]) || EVERLAND.includes(tour)) {
                kkday[bookingId] = {
                    tour: TOUR_NAME[tour] || `투어: ${tour} 옵션: ${option}`,
                    name,
                    people: number,
                    pickupTime: pickupTime[1],
                    lang,
                    extra: `${TOUR_OPTIONS[option] || option}${number}`,
                }
            } else {
                kkday[bookingId] = {
                    tour: TOUR_NAME[tour] || `투어: ${tour} 옵션: ${option}`,
                    name,
                    people: number,
                    pickupTime: pickupTime[1],
                    lang,
                    extra,
                }
            }
        } catch (e) {
            kkday[bookingId] = {
                tour: `투어 ${tour} 옵션: ${option}`,
                name,
                people: number,
                pickupTime: pickupTime[1],
                lang,
                extra,
            }

            kkdayNotRegistered.push(`${tour} ${option}`)
        }
    })

    return {
        kkday,
        kkdayNotRegistered,
    }
}

const parseFileData = async (file: any) => {
    const binary = await file.arrayBuffer()
    const workbook = XLSX.read(binary, {type: "array"})
    const sheets = workbook.Sheets
    const sheetNames = workbook.SheetNames

    const firstSheet = sheets[sheetNames[0]]
    const data = XLSX.utils.sheet_to_json(firstSheet, {range: 1}) as any[]
    return data
}
