import * as XLSX from "xlsx";
import {
  AgencyFileDatum,
  KintFileDatum,
  KKdayAggregation,
  KKdayFileDatum, ViatorAggregation, ViatorFileDatum,
} from "./types";


class ViatorDatum {
  rows: ViatorFileDatum[];
  refunded: ViatorFileDatum[];
  reservation: ViatorFileDatum[];
  representativeRow: ViatorFileDatum;

  constructor(rows: ViatorFileDatum[]) {
    this.rows = rows;
    this.refunded = rows.filter(({price}) => price < 0);
    this.reservation = rows.filter(({price}) => price >= 0);
    this.representativeRow = this.reservation[0] ?? this.rows[0];
  }

  get people(): number {
    return 0;
  }

  get price(): number {
    return this.rows.map(r => r.price).reduce((a, b) => a + b, 0);
  }

  toAgencyDatum(): AgencyFileDatum {
    return ({
      tour: this.representativeRow.tour,
      option: this.representativeRow.option,
      date: this.representativeRow.date,
      agency: 'VI',
      agencyCode: this.representativeRow.agencyCode,
      people: this.people,
      price: this.price,
      unitPrice: this.price,
    })
  }

}


function cleanString(data?: any): string {
  return data?.toString().trim() || ''
}

function toNumber(numberLike?: string | number) {
  if (typeof numberLike === 'number') return numberLike;
  return Number(cleanString(numberLike) || 0);
}


export default async function parseViatorFile(file: Blob | File): Promise<AgencyFileDatum[]> {
  const parsed = await parseFileData(file);
  const rows = parsed.filter((raw)=>!!raw['__EMPTY_1'] && raw['__EMPTY_1'] !== 'ARRIVAL DATE'&& raw['__EMPTY_2'] !== '--').map((raw) => {
    const agency = 'VI';
    const agencyCode = 'BR-'+cleanString(raw['__EMPTY']);
    const date = formatDate(raw['__EMPTY_1']);
    const tour = cleanString(raw['__EMPTY_3']);
    const option = cleanString(raw['__EMPTY_9'])
    const price = toNumber(raw['__EMPTY_6']);
    return ({
      agency,
      agencyCode,
      date,
      tour,
      option,
      price
    })
  })


  const kkdayAggregationByAgencyCode = rows.reduce((result, row) => {
    if (!(row.agencyCode in result)) {
      result[row.agencyCode] = []
    }
    result[row.agencyCode].push(row);
    return result;
  }, {} as ViatorAggregation);

  return Object.entries(kkdayAggregationByAgencyCode)
    .map(([code, rows]) => {
      if (rows.length === 1) return [code, rows];
      const refunded = rows.filter(({price}) => price < 0)
      const others = rows.filter(({price}) => price >= 0);
      const refundedTotalOriginalPrice = refunded.map((row) => row.price).reduce((a, b) => a + b, 0)
      const othersTotalOriginalPrice = others.map((row) => row.price).reduce((a, b) => a + b, 0)
      if (refundedTotalOriginalPrice + othersTotalOriginalPrice === 0) return [code, []];
      return [code, rows];
    })
    .filter(([_, rows]) => rows.length > 0)
    .map(([key, rows]) => new ViatorDatum(rows as ViatorFileDatum[]).toAgencyDatum())
}


const parseFileData = async (file: Blob | File) => {
  const binary = await file.arrayBuffer()
  const workbook = XLSX.read(binary, {type: "array", raw: true})
  const sheets = workbook.Sheets
  const sheetNames = workbook.SheetNames

  const firstSheet = sheets[sheetNames[0]]
  const data = XLSX.utils.sheet_to_json(firstSheet, {range: 1}) as any[]
  return data
}


function formatDate(ddmmyyyy: string) {
  const matches = /(\d{2})\/(\d{2})\/(\d{4})/gi.exec(ddmmyyyy);
  const yyyy = matches?.[3] ?? '';
  const mm = matches?.[1] ?? '';
  const dd = matches?.[2] ?? '';
  return `${yyyy}-${mm}-${dd}`;
}
