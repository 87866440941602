import React, {useState} from 'react';


import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from '@mui/material/IconButton';
import Switch from "@mui/material/Switch";
import Checkbox from '@mui/material/Checkbox';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from "@mui/material/CardContent";

import CircularProgress from '@mui/material/CircularProgress';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';

import {useRead} from '../hooks/realtime';
import {readRealtime, removeRealtime, setRealtime} from "../hooks/firebase";
import {Agency} from "../models/Agency";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from '@mui/icons-material/Close';
import PlusIcon from '@mui/icons-material/PlusOne';


export default function (props: { onClose: (update: boolean) => void }) {
    const {onClose} = props;
    const [agency, setAgency] = useState<Agency>({
        key: '',
        code: '',
        name: '',
        domain: '',
        isEmbedded: false,
        categoryRegex: {
            reservation: '',
            update: '',
            cancel: '',
            review: '',
            ignore: ''
        }
    });

    const update = (prop: string, value: any) => {
        setAgency((a) => {
            if (!a) return a;
            return {...a, [prop]: value};
        })
    }


    const handleSave = () => {
        const key = agency.name.toLowerCase().replace(/\s/gi, '');
        const ref = `agency/${key}`;
        readRealtime(ref)
            .then((v) => {
                if (v) {
                    return alert('Already exists. Please check name.');
                }
                return setRealtime(ref, {...agency, key,})
            })
            .then((v) => {
                onClose(true);
            })
            .catch((e) => alert('Fail on save'));

    }


    const handleChangeBuilder = (prop: string) => (_: any, value: any) => {
        update(prop, value);
    }
    const handleTargetChangeBuilder = (prop: string, middleware: (value: any) => any = f => f) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = middleware(e.target.value);
        update(prop, value);
    }

    return (
        <Modal
            open
            onClose={onClose}
        >
            <Box
                sx={(theme) => ({
                    width: '80vw',
                    maxHeight: '80vh',
                    position: 'relative' as 'relative',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                })}
            >


                <Box sx={{
                    position: 'fixed',
                    display: 'inline-flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    bottom: 0,
                    left: '50%',
                    transform: 'translate(-50%, 125%)',
                    zIndex: 999,
                    backgroundColor: 'white',
                    borderRadius: 5,
                    boxShadow: 2,
                    py: 1,
                    px: 2
                }}>

                    <Switch checked={agency?.isEmbedded}
                            onChange={handleChangeBuilder('isEmbedded')}/>
                    <IconButton onClick={handleSave}>
                        <EditIcon/>
                    </IconButton>
                    <IconButton onClick={() => onClose(true)}>
                        <CloseIcon/>
                    </IconButton>

                </Box>

                <Paper
                    sx={(theme) => ({
                        padding: '32px 24px',
                    })}
                >
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xs={12}
                            sx={{
                                position: 'relative',
                            }}
                        >
                            <Box
                                sx={{
                                    position: 'relative',
                                    maxHeight: '75vh',
                                    overflowY: 'auto',
                                }}
                            >

                                <Box
                                    sx={(theme) => ({
                                        borderRadius: 3,
                                        backgroundColor: theme.palette.background.default,
                                        p: 4
                                    })}
                                >

                                    <Grid
                                        container
                                        spacing={4}
                                        component={'form'}
                                    >
                                        <Grid
                                            item
                                            xs={2}
                                        >
                                            <Typography fontWeight={'bold'}>
                                                Name / Code
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={5}
                                        >
                                            <TextField
                                                fullWidth
                                                label={'name'}
                                                value={agency?.name ?? ''}
                                                onChange={handleTargetChangeBuilder('name')}
                                                InputLabelProps={{shrink: true}}
                                                sx={{backgroundColor: 'white'}}
                                            />

                                        </Grid>
                                        <Grid
                                            item
                                            xs={5}
                                        >
                                            <TextField
                                                fullWidth
                                                label={'code'}
                                                value={agency?.code ?? ''}
                                                onChange={handleTargetChangeBuilder('code')}
                                                InputLabelProps={{shrink: true}}
                                                sx={{backgroundColor: 'white'}}
                                            />

                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                        >
                                            <Divider/>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={2}
                                        >
                                            <Typography fontWeight={'bold'}>
                                                Logics
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={10}
                                        >
                                            <TextField
                                                fullWidth
                                                label={'Domain'}
                                                value={agency?.domain ?? ''}
                                                onChange={handleTargetChangeBuilder('domain')}
                                                InputLabelProps={{shrink: true}}
                                                sx={{backgroundColor: 'white'}}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={2}
                                        />
                                        <Grid
                                            item
                                            xs={10}
                                        >
                                            <TextField
                                                fullWidth
                                                label={'Reservation Subject'}
                                                value={agency?.categoryRegex?.reservation ?? ''}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    handleChangeBuilder('categoryRegex')(e, {
                                                        ...(agency?.categoryRegex ?? {}),
                                                        reservation: value
                                                    })
                                                }}
                                                InputLabelProps={{shrink: true}}
                                                sx={{backgroundColor: 'white'}}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={2}
                                        />
                                        <Grid
                                            item
                                            xs={10}
                                        >
                                            <TextField
                                                fullWidth
                                                label={'Update Subject'}
                                                value={agency?.categoryRegex?.update ?? ''}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    handleChangeBuilder('categoryRegex')(e, {
                                                        ...(agency?.categoryRegex ?? {}),
                                                        update: value
                                                    })
                                                }}
                                                InputLabelProps={{shrink: true}}
                                                sx={{backgroundColor: 'white'}}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={2}
                                        />
                                        <Grid
                                            item
                                            xs={10}
                                        >
                                            <TextField
                                                fullWidth
                                                label={'Cancel Subject'}
                                                value={agency?.categoryRegex?.cancel ?? ''}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    handleChangeBuilder('categoryRegex')(e, {
                                                        ...(agency?.categoryRegex ?? {}),
                                                        cancel: value
                                                    })
                                                }}
                                                InputLabelProps={{shrink: true}}
                                                sx={{backgroundColor: 'white'}}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={2}
                                        />
                                        <Grid
                                            item
                                            xs={10}
                                        >
                                            <TextField
                                                fullWidth
                                                label={'Review Subject'}
                                                value={agency?.categoryRegex?.review ?? ''}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    handleChangeBuilder('categoryRegex')(e, {
                                                        ...(agency?.categoryRegex ?? {}),
                                                        review: value
                                                    })
                                                }}
                                                InputLabelProps={{shrink: true}}
                                                sx={{backgroundColor: 'white'}}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={2}
                                        />
                                        <Grid
                                            item
                                            xs={10}
                                        >
                                            <TextField
                                                fullWidth
                                                label={'Ignore Subject'}
                                                value={agency?.categoryRegex?.ignore ?? ''}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    handleChangeBuilder('categoryRegex')(e, {
                                                        ...(agency?.categoryRegex ?? {}),
                                                        ignore: value
                                                    })
                                                }}
                                                InputLabelProps={{shrink: true}}
                                                sx={{backgroundColor: 'white'}}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </Modal>
    )
}