import * as XLSX from "xlsx";
import {
    AgencyFileDatum,
    KintFileDatum,
    KKdayAggregation,
    KKdayFileDatum,
} from "./types";


class KKDayDatum {
    rows: KKdayFileDatum[];
    refunded: KKdayFileDatum[];
    reservation: KKdayFileDatum[];
    representativeRow: KKdayFileDatum;

    constructor(rows: KKdayFileDatum[]) {
        this.rows = rows;
        this.refunded = rows.filter(({type}) => type.toLowerCase().includes('취소 환불'));
        this.reservation = rows.filter(({type}) => !type.toLowerCase().includes('취소 환불'));
        this.representativeRow = this.reservation[0] ?? this.rows[0];
    }

    get reservationAdult(): number {
        return this.reservation.map(({adult}) => adult).reduce((a, b) => a + b, 0);
    }

    get refundAdult(): number {
        return this.refunded.map(({adult}) => adult).reduce((a, b) => a + b, 0);
    }

    get adult(): number {
        return this.reservationAdult - this.refundAdult;
    }


    get reservationKid(): number {
        return this.reservation.map(({kid}) => kid).reduce((a, b) => a + b, 0);
    }

    get refundKid(): number {
        return this.refunded.map(({kid}) => kid).reduce((a, b) => a + b, 0);
    }

    get kid(): number {
        return this.reservationKid - this.refundKid;
    }

    get reservationInfant(): number {
        return this.reservation.map(({infant}) => infant).reduce((a, b) => a + b, 0);
    }

    get refundInfant(): number {
        return this.refunded.map(({infant}) => infant).reduce((a, b) => a + b, 0);
    }

    get infant(): number {
        return this.reservationInfant - this.refundInfant;
    }

    get people(): number {
        return this.adult + this.kid + this.infant;
    }

    get price(): number {
        return this.rows.map(r => r.price).reduce((a, b) => a + b, 0);
    }

    toAgencyDatum(): AgencyFileDatum {
        const people = this.adult + this.kid + this.infant;
        return ({
            tour: this.representativeRow.tour,
            option: this.representativeRow.option,
            date: this.representativeRow.date,
            agency: 'KK',
            agencyCode: this.representativeRow.agencyCode,
            people: people,
            price: this.price,
            unitPrice: this.price/people,
        })
    }

}


function cleanString(data?:any):string{
    return data?.toString().trim() || ''
}

function toNumber(numberLike?:string|number){
    if(typeof numberLike === 'number') return numberLike;
    return Number(cleanString(numberLike) || 0);
}


export default async function parseKKdayFile(file:Blob|File):Promise<AgencyFileDatum[]> {
    const parsed = await parseFileData(file);
    const rows =  parsed.map((raw)=>{
        const agency = 'KK';
        const type = cleanString(raw['종류']);
        const agencyCode = cleanString(raw['예약번호']);
        const date = cleanString(raw['출발날짜']?.split(' ')?.at(0) ?? '')
        const tour = cleanString(raw['상품명']);
        const option = cleanString(raw['상품 이름']);
        let adult = toNumber(raw['대인']);
        const kid = toNumber(raw['소인']);
        const infant = toNumber(raw['어린이']);
        if(adult + kid + infant === 0 && raw['결제 건수/구매 수량'] && typeof raw['결제 건수/구매 수량'] ==='string' && raw['결제 건수/구매 수량'].includes('/')){
            adult = toNumber(raw['결제 건수/구매 수량'].split('/').at(-1) ?? '0')
        }

        const price = toNumber(raw['공급가']);
        return({
            type,
            agency,
            agencyCode,
            date,
            tour,
            option,
            adult,
            kid,
            infant,
            price
        })
    })


    const kkdayAggregationByAgencyCode = rows.reduce((result, row) => {
        if (!(row.agencyCode in result)) {
            result[row.agencyCode] = []
        }
        result[row.agencyCode].push(row);
        return result;
    }, {} as KKdayAggregation);

    return Object.entries(kkdayAggregationByAgencyCode)
        .map(([code, rows]) => {
            if (rows.length === 1) return [code, rows];
            const refunded = rows.filter(({type}) => type.toLowerCase().includes('refund'))
            const others = rows.filter(({type}) => !type.toLowerCase().includes('refund'));
            const refundedTotalOriginalPrice = refunded.map((row) => row.price).reduce((a, b) => a + b, 0)
            const othersTotalOriginalPrice = others.map((row) => row.price).reduce((a, b) => a + b, 0)
            if (refundedTotalOriginalPrice + othersTotalOriginalPrice === 0) return [code, []];
            return [code, rows];
        })
        .filter(([_, rows]) => rows.length > 0)
        .map(([key, rows]) => new KKDayDatum(rows as KKdayFileDatum[]).toAgencyDatum())
}


const parseFileData = async (file: Blob | File) => {
    const binary = await file.arrayBuffer()
    const workbook = XLSX.read(binary, {type: "array", raw:true})
    const sheets = workbook.Sheets
    const sheetNames = workbook.SheetNames

    const firstSheet = sheets[sheetNames[0]]
    const data = XLSX.utils.sheet_to_json(firstSheet, {range: 1}) as any[]
    return data
}


