import {
    QueryDocumentSnapshot,
    QuerySnapshot,
    Timestamp,
    serverTimestamp,
    getDocs,
    limit,
    onSnapshot,
    orderBy,
    query,
    updateDoc,
    where,
} from "firebase/firestore"
import {
    callFunction,
    collectionWithBaseFireStore, docWithBaseFireStore,
    getDocData,
    listenWhereFireStore,
    setDocData,
    updateDocData,
} from "./firebase"
import { deleteTxFromIndexedDB } from "./indexedDB"
import { IChatMeta, ICreateChatParams, IGuide, IParticipant, IOperator, IUser } from "../../types/chat"
import { User } from "firebase/auth"
import { useEffect, useRef, useState } from "react"

export const recallChat = async ({
    category,
    participants,
    cId,
    title,
    tour,
}: ICreateChatParams): Promise<IChatMeta> => {
    const chat: IChatMeta = await callFunction("recallChat", {
        category,
        participants,
        cId,
        title,
        tour,
    })

    return chat
}

export const getParticipants = async (chatId: string): Promise<{ [id: string]: IParticipant }> => {
    const { participants } = (await getDocData(["chats", chatId])) as IChatMeta
    return participants
}

export const updateReadStatusMessage = async (chatId: string, uid: string) => {
    const ref = collectionWithBaseFireStore(["chats", chatId, "messages"])
    const querySnapshot = await getDocs(query(ref, where("readStatus." + uid, "==", false)))

    querySnapshot.forEach((doc: QueryDocumentSnapshot<unknown>) => {
        updateDoc(doc.ref, { [`readStatus.${uid}`]: true })
    })

    const chatRef = docWithBaseFireStore(['chats', chatId]);
    const readAt = new Date().toString();
    updateDoc(chatRef, {[`readDate.${uid}`]: readAt}).catch(console.error);
}

export const sendMessage = async (
    chatId: string,
    participants: IParticipant[],
    sender: Pick<IUser, "id" | "name" | "nameEn" | "level" | "raw">,
    message: {
        type: string
        text?: string
        files?: string[]
    } = {
        type: "text",
        text: "",
        files: [],
    },
    reply?: {
        id: string
        text: string
    }
) => {
    let data = {}
    const readStatus = participants
        .map((p) => p.id)
        .reduce((r: any, p: any) => {
            return {
                ...r,
                [p]: p === sender.id,
            }
        }, {})
    switch (message.type) {
        case "text": {
            data = {
                text: message.text,
                readStatus,
            }
            break
        }
        case "reply": {
            data = {
                text: message.text,
                reply,
                readStatus,
            }
            break
        }
        case "image": {
            data = {
                files: message.files,
                readStatus,
            }
            break
        }
        case "exit": {
            data = {
                text: `${sender.name} left the room`,
            }
            break
        }
        case "enter": {
            if (!Array.isArray(participants) || participants.length === 0) return
            const text =
                participants.length > 1
                    ? `${participants.at(0)?.nameEn} and ${participants.length - 1} others have entered the room.`
                    : `${participants?.at(0)?.nameEn} entered the room.`
            data = {
                text,
            }
            break
        }
    }

    await setDocData(["chats", chatId, "messages"], {
        type: message.type,
        date: serverTimestamp(),
        sender: {
            type: sender.level > 1 ? "guide" : "operator",
            name: sender.name,
            nameEn: sender.nameEn,
            id: sender.id,
            photoURL: JSON.parse(sender.raw).photoURL ?? "",
        },
        ...data,
    })

    await updateDocData(["chats", chatId], {
        updatedAt: serverTimestamp()
    })
}

export const exitChat = async (chatId: string, user: IUser) => {
    // TODO: functions, deleteChat() 이름 변경 필요
    await callFunction("deleteChat", { docId: chatId, uid: user.id })
    // deleteTxFromIndexedDB(chatId)
    const message = {
        type: "exit",
        text: `${user.name}님이 나가셨습니다.`,
    }

    await sendMessage(chatId, [], user, message)
}

export const subscribeToOwnChats = (uid: string, callback: (snapshot: QuerySnapshot<any>) => void) => {
    return listenWhereFireStore(["chats"], [[`participants.${uid}.id`, "==", uid]], callback)
}

export const subscribeAllChats = (callback: (snapshot: QuerySnapshot<any>) => void) => {
    return listenWhereFireStore(["chats"], [], callback)
}

export const subscribeToUnreadMessages = (
    chatId: string,
    uid: string,
    callback: (snapshot: QuerySnapshot<any>) => void
) => {
    return listenWhereFireStore(["chats", chatId, "messages"], [[`readStatus.${uid}`, "==", false]], callback)
}

export const subscribeToLastMessage = (chatId: string, callback: (snapshot: QuerySnapshot<any>) => void) => {
    return onSnapshot(
        query(collectionWithBaseFireStore(["chats", chatId, "messages"]), orderBy("date", "desc"), limit(1)),
        callback
    )
}

export const subscribeToNewMessages = (
    chatId: string,
    lastMessageDate: Date,
    callback: (snapshot: QuerySnapshot<any>) => void
) => {
    return onSnapshot(
        query(
            collectionWithBaseFireStore(["chats", chatId, "messages"]),
            orderBy("date", "asc"),
            where("date", ">", lastMessageDate)
        ),
        callback
    )
}

export const useLongPress = (ms = 300) => {
    const [startLongPress, setStartLongPress] = useState(false)
    const [targetEl, setTargetEl] = useState<HTMLDivElement | null>(null)
    const ref = useRef<HTMLDivElement | null>(null)
    const [timer, setTimer] = useState<NodeJS.Timer | null>(null)

    const onLongPress = (e: any) => {
        if (e.currentTarget) {
            const timer = setTimeout(() => {
                setStartLongPress(true)
            }, ms)
            setTimer(timer)
        }
    }

    const onLeaveLongPress = (e: any) => {
        if (timer) clearTimeout(timer)
    }

    const onClose = () => {
        setStartLongPress(false)
        timer && clearTimeout(timer)
        setTimer(null)
    }
    useEffect(() => {
        if (startLongPress && ref.current) {
            setTargetEl(ref.current)
        } else {
            setTargetEl(null)
        }
    }, [startLongPress])

    return {
        ref,
        timer,
        targetEl,
        startLongPress,
        onLongPress,
        onLeaveLongPress,
        onClose,
    }
}
