import {Badge, Box, ButtonBase, CircularProgress, Divider, Popover, Typography} from "@mui/material"
import React, { useContext, useState } from "react"
import { useChatContext } from "../../../context/chat"
import ReplyIcon from "@mui/icons-material/Reply"
import TranslateIcon from "@mui/icons-material/Translate"
import ImageList from "./ImageList"
import { useLongPress } from "../../../hooks/chat"
import FavoriteIcon from "@mui/icons-material/Favorite"
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { blue, deepPurple, red } from "@mui/material/colors"
import { useParams } from "react-router-dom"
import { AuthContext } from "../../../hooks/auth"
import { updateDocData } from "../../../hooks/chat/firebase"
import { IParticipant } from "../../../types/chat"
import Button from "@mui/material/Button";

//todo indexedDb
//import { updateTxToIndexedDB } from "../../../hooks/chat/indexedDB"

type IEmotion = {
    like: Pick<IParticipant, "id" | "name" | "nameEn">[]
    favorite: Pick<IParticipant, "id" | "name" | "nameEn">[]
    check: Pick<IParticipant, "id" | "name" | "nameEn">[]
}
export default function Message({
    message,
    isMe,
    showTime,
    isStart,
}: {
    message: any
    isMe: boolean
    showTime: boolean
    isStart: boolean
}) {
    const [translate, setTranslate] = useState(false)
    const { state: chatState, dispatch: chatDispatch } = useChatContext()
    const {
        ref: messageRef,
        targetEl: messageEl,
        onLongPress,
        onClose: closePopover,
        onLeaveLongPress,
    } = useLongPress(300)
    const { chatId } = useParams()
    const { operator } = useContext(AuthContext)

    const replyMessage = () => {
        chatDispatch({
            type: "SET_REPLY",
            payload: {
                id: message.id,
                text: message.text ? message.text : "Image",
                sender: message.sender,
            },
        })
    }
    const onTranslate = () => {
        setTranslate((t) => !t)
    }

    const onScrollMessage = (messageId: string) => {
        const msgEl = document.getElementById(messageId)
        if (!msgEl) return
        msgEl.scrollIntoView({ behavior: "auto", block: "center" })
        msgEl.style.animation = "vibration 0.6s"
        setTimeout(() => {
            msgEl.style.animation = ""
        }, 1000)
    }

    const updateEmotion = (emotion: "like" | "favorite" | "check") => {
        // needs chatId, messageId
        let paths = ["chats", chatId, "messages", message.id]
        let data: IEmotion = {
            like: [],
            favorite: [],
            check: [],
        }
        const emotionData = {
            id: operator!.id,
            nameEn: operator!.nameEn,
            name: operator!.name,
        }

        if (message.hasOwnProperty("emotion")) {
            data = message.emotion
        }

        const filtered = data[emotion].filter((d) => d.id !== emotionData.id)
        const isExist = filtered.length !== data[emotion].length
        data[emotion] = isExist ? filtered : [...data[emotion], emotionData]
        updateDocData(paths, {
            emotion: data,
        })

        closePopover()
    }
    if (!message) return null
    return (
        <Box>
            <Box
                id={message.id}
                display="flex"
                flexDirection={isMe ? "row-reverse" : "row"}
                alignItems="end"
                columnGap="6px"
                // onMouseDown={onLongPress}
                // onTouchStart={onLongPress}
                // onMouseLeave={onLeaveLongPress}
                // onMouseUp={onLeaveLongPress}
                // onTouchEnd={onLeaveLongPress}
            >
                <Box
                    ref={messageRef}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap:1,
                    }}
                >

                    {(message.type === "text" || message.type === "reply" || message.text) && (
                        <Box
                            display="flex"
                            columnGap="8px"
                            alignItems="flex-end"
                            flexDirection={isMe ? "row-reverse" : "row"}
                            onClick={replyMessage}
                        >
                            <Box
                                sx={(theme) => ({
                                    display: "flex",
                                    flexDirection: "column",
                                    rowGap: "8px",
                                    position: "relative",
                                    padding: "8px 12px",

                                    minHeight: "40px",
                                    minWidth: "fit-content",

                                    wordBreak: "break-word",
                                    whiteSpace: "pre-wrap",

                                    background: isMe ? theme.palette.primary.main : "#fff",
                                    color: isMe ? "#fff" : "#000",

                                    fontSize: "14px",
                                    fontWeight: 500,

                                    ...(isStart
                                        ? {
                                              borderRadius: isMe ? "16px 0 16px 16px" : "0px 16px 16px 16px",
                                          }
                                        : {
                                              borderRadius: "16px",
                                          }),
                                })}
                            >
                                {message.type === "reply" && (
                                    <>
                                        <Box
                                            sx={{
                                                cursor: "pointer",
                                            }}
                                            onClick={() => onScrollMessage(message.reply.id)}
                                        >
                                            <Typography fontSize={14} color={isMe ? "#fff" : "#000"} fontWeight={600}>
                                                Reply to {message.reply.sender.name}
                                            </Typography>
                                            <Typography fontSize={12} color={isMe ? "#f1f2f6" : "#888"}>
                                                {message.reply.text?.split("\n")[0].concat("...")}
                                            </Typography>
                                        </Box>
                                        <Divider />
                                    </>
                                )}
                                <RenderText text={message.text}/>
                                <RenderAction text={message.text}/>
                                {message.translation && translate ? (
                                    <Box>
                                        <Divider sx={{ mb: 1 }} />
                                        {message.translation.en ? (
                                            <Box sx={{ mb: 1.5 }}>
                                                <Typography
                                                    fontWeight={"bold"}
                                                    fontSize={12}
                                                    color={isMe ? "#f1f2f6" : "#888"}
                                                >
                                                    English
                                                </Typography>
                                                <Typography fontSize={12}>{message.translation.en}</Typography>
                                            </Box>
                                        ) : null}
                                        {message.translation.tw ? (
                                            <Box sx={{ mb: 1.5 }}>
                                                <Typography
                                                    fontWeight={"bold"}
                                                    fontSize={12}
                                                    color={isMe ? "#f1f2f6" : "#888"}
                                                >
                                                    繁體中文
                                                </Typography>
                                                <Typography fontSize={12}>{message.translation.tw}</Typography>
                                            </Box>
                                        ) : null}
                                        {message.translation.cn ? (
                                            <Box sx={{ mb: 0.5 }}>
                                                <Typography
                                                    fontWeight={"bold"}
                                                    fontSize={12}
                                                    color={isMe ? "#f1f2f6" : "#888"}
                                                >
                                                    简体中文
                                                </Typography>
                                                <Typography fontSize={12}>{message.translation.cn}</Typography>
                                            </Box>
                                        ) : null}
                                    </Box>
                                ) : null}
                            </Box>
                        </Box>
                    )}

                    {message.type === "image" && <ImageList imgUrls={message.files} />}
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "end",
                        marginLeft: isMe ? "auto" : 0,
                        marginRight: !isMe ? "auto" : 0,
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: isMe ? "row-reverse" : "row",
                            gap: 0.5,
                        }}
                    >
                        {message.translation ? (
                            <ButtonBase onClick={onTranslate}>
                                <TranslateIcon
                                    sx={{
                                        transform: isMe ? "rotateY(180deg)" : "rotateY(0)",
                                        alignSelf: isMe ? "flex-end" : "flex-start",
                                        color: chatState.reply.id === message.id ? "#000" : "#bdbdbd",
                                        cursor: "pointer",
                                        fontSize: "20px",
                                    }}
                                />
                            </ButtonBase>
                        ) : null}

                        {message.files && Object.values(message.files).length > 0 ? (
                            <ButtonBase onClick={replyMessage}>
                                <ReplyIcon
                                    sx={{
                                        transform: isMe ? "rotateY(180deg)" : "rotateY(0)",
                                        color: chatState.reply.id === message.id ? "#000" : "#bdbdbd",
                                        cursor: "pointer",
                                        fontSize: "20px",
                                    }}
                                />
                            </ButtonBase>
                        ) : null}
                    </Box>
                    {showTime && (
                        <Typography variant="caption" color="#444" whiteSpace="nowrap">
                            {new Date(message.date?.seconds * 1000)
                                .toLocaleTimeString()
                                .split(":")
                                .slice(0, 2)
                                .join(":")}
                        </Typography>
                    )}
                </Box>
                <Popover
                    open={Boolean(messageEl)}
                    onClose={closePopover}
                    anchorEl={messageRef.current}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    sx={{
                        top: "4px",
                    }}
                >
                    <Box
                        sx={{
                            padding: "4px 2px",
                            display: "flex",
                            gap: "4px",
                        }}
                    >
                        <ButtonBase onClick={() => updateEmotion("like")}>
                            <FavoriteIcon sx={{ color: red[500], fontSize: "20px" }} />
                        </ButtonBase>
                        <ButtonBase onClick={() => updateEmotion("favorite")}>
                            <ThumbUpAltIcon sx={{ color: deepPurple[400], fontSize: "20px" }} />
                        </ButtonBase>
                        <ButtonBase onClick={() => updateEmotion("check")}>
                            <CheckCircleIcon sx={{ color: blue[500], fontSize: "20px" }} />
                        </ButtonBase>
                    </Box>
                </Popover>
            </Box>
            {message.emotion && (
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                        width: "fit-content",
                        marginLeft: isMe ? "auto" : 0,
                    }}
                >
                    {message.emotion.like.length > 0 && (
                        <Badge
                            badgeContent={message.emotion.like.length}
                            max={10}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            sx={{
                                "& span": {
                                    fontSize: "10px",
                                },
                            }}
                        >
                            <FavoriteIcon
                                sx={{
                                    fontSize: "18px",
                                }}
                            />
                        </Badge>
                    )}
                    {message.emotion.favorite.length > 0 && (
                        <Badge
                            badgeContent={message.emotion.like.length}
                            max={10}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            sx={{
                                "& span": {
                                    fontSize: "10px",
                                },
                            }}
                        >
                            <ThumbUpAltIcon
                                sx={{
                                    fontSize: "18px",
                                }}
                            />
                        </Badge>
                    )}
                    {message.emotion.check.length > 0 && (
                        <Badge
                            badgeContent={message.emotion.like.length}
                            max={10}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            sx={{
                                "& span": {
                                    fontSize: "10px",
                                },
                            }}
                        >
                            <CheckCircleIcon
                                sx={{
                                    fontSize: "18px",
                                }}
                            />
                        </Badge>
                    )}
                </Box>
            )}
        </Box>
    )
}

function RenderText(props: { text: string }) {
    const text = props.text;
    return (
        <div>{(renderLinkText(removeAction(text)))}</div>
    );

}


function RenderAction(props: { text: string }) {
    const text = props.text;
    const [loading, setLoading] = useState<boolean>(false);

    const createPromotionChat = async (e: any) => {
        e.preventDefault();
        e.stopPropagation();

    }

    const returns = [];
    if (detectActionSpecialOffer(text)) {
        returns.push(
            <Button color={'inherit'} key={'/specialoffer'} variant={'outlined'} onClick={createPromotionChat}>Click For Special Offer</Button>
        )
    }

    return loading
        ? <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
            <CircularProgress size={20} color="inherit"/>
        </Box>
        : (
            <>
                {
                    returns.map(m => m)
                }
            </>
        )
}

function removeAction(text: string) {
    const actionLinkRegexp = /\[:(specialoffer)\]/gi
    return text.replace(actionLinkRegexp, "");
}

function detectActionSpecialOffer(text: string) {
    const actionLinkRegexp = /\[:(specialoffer)\]/gi
    return text.match(actionLinkRegexp);
}


function renderLinkText(text: string) {
    const protocolUrlRegex = /(\b(?:https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    const simpleUrlRegex = /(\b(?:(?:https?|ftp|file):\/\/)?[-A-Z0-9+&@#\/%?=~_|!:,.;]+\.(?:com|net|org|edu|gov|mil|info|biz|name|museum|asia|jobs|mobi|aero|travel|coop|pro|tv|cc|me|co|io|tech|xyz|club|ai|ph|tw|cn|sg|id|jp|ca|us|eu|vn|kr|fr|de|it|ru|br|au|mx|nl|es|se|no|fi))/ig;

    const urlRegex = text.match(protocolUrlRegex) ? protocolUrlRegex : simpleUrlRegex;

    const parts = text.split(urlRegex);
    return parts.map((part, index) =>
        urlRegex.test(part) ? (
            <a style={{color: 'inherit'}} href={part.startsWith('http') ? part : `https://${part}`} key={index} target="_blank" rel="noopener noreferrer">
                {part}
            </a>
        ) : (
            part
        )
    );
}

