import React, {useCallback, useEffect, useState} from "react";
import {DateRange, DateRangePicker, SingleInputDateRangeField} from "@mui/x-date-pickers-pro";
import {useQueryDocs} from "../../../hooks/firestore";
import {Reservation} from "../../../models/Reservation";
import {useRead, useReadPropValueStartEndAt} from "../../../hooks/realtime";
import {Product} from "../../../models/Product";
import {Pickup} from "../../../models/Pickup";
import dayjs, {Dayjs} from "dayjs";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import {
  DataGridPro,
  DataGridProProps,
  GridColDef,
  gridFilteredDescendantCountLookupSelector,
  gridFilterModelSelector,
  GridHeaderFilterCellProps,
  GridRenderCellParams,
  GridRowSelectionModel,
  GridToolbarQuickFilter,
  useGridApiContext,
  useGridSelector,
  GridEditCellProps
} from "@mui/x-data-grid-pro";
import CircularProgress from "@mui/material/CircularProgress";
import Button, {ButtonProps} from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {readWithKeyStartEndAt} from "../../../hooks/firebase";
import {Operation, Team, Tour} from "../../../models/Operation";
import writeXlsxFile from "write-excel-file";
import {InputLabel, Select, SelectChangeEvent} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import {Agency} from "../../../models/Agency";
import {User} from "../../../models/User";
import {Balance} from "../../../models/Balance";
import Typography from "@mui/material/Typography";


type ReservationRow = {
  id: string,
  date: string,
  product: string,
  people: number,
  adult: number,
  kid: number,
  infant: number,
  option: string,
  clientName: string,
  agency: string,
  agencyCode: string,
  noShow: boolean,
  reservedAt: string,
  memo: string,
  canceledAt?: string,
  rowGroup?: string,
  teamIdx?: number,
  guide?: string,
};

type ReservationRows = {
  total: ReservationRow[],
  all: ReservationRow[],
  present: ReservationRow[],
  noShow: ReservationRow[],
  cancel: ReservationRow[],
  outsource: ReservationRow[],
}


type ReservationEntity = Partial<Reservation & {
  guide: { name: string, id: string }[],
  teamIdx: number
}>

type Products = { [productId: string]: Product };

type Agencies = { [agencyKeyCode: string]: Agency };
type Users = { [userId: string]: User };


type GuideOperationEntity = {
  guideId: string,
  guideName: string,
  date: string,
  product: string,
  people: number,
  adult: number,
  kid: number,
  count: number | string,
  pickup: string,
  pay: number | string,
  incentive: number | string,
}

type GuideOperationRow = Partial<GuideOperationEntity> & {
  id: string,
  day: string,
  guideId: string,
  guideName: string,
  groupPath: string[]
  cardExpenditure: string | number,
  cardIncome: string | number,
  cashExpenditure: string | number,
  cashIncome: string | number,
  memo?: string
}

const TABS: Array<keyof ReservationRows> = ['all', 'present', 'noShow', 'cancel', 'total']


function DateRangePickerValue(props: { dateRange: DateRange<Dayjs>, onChange: (dr: DateRange<Dayjs>) => void }) {
  const {dateRange: value, onChange: setValue} = props;
  return (

    <DateRangePicker
      format={'YY-MM-DD'}
      slots={{field: SingleInputDateRangeField}}
      value={value}
      onChange={(newValue) => setValue(newValue)}
    />
  );
}

export default function Reconciliation() {
  const [dateRange, setDateRange] = React.useState<DateRange<Dayjs>>(getThisWeekRange());
  const [tempDateRange, setTempDateRange] = React.useState<DateRange<Dayjs>>(dateRange);
  const formatDateRanges = dateRange.map(djs => (djs ?? dayjs()).format('YYYY-MM-DD'));
  const [loadKey, setLoadKey] = useState<string>(Date.now() + '');
  const [tab, setTab] = useState<typeof TABS[0]>(TABS[0]);
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const [operations, setOperations] = useState<{ [formattedDate: string]: Operation } | null>(null);
  const {
    data: _reservations = [], setData: setReservations, loading
  } = useQueryDocs<Reservation>('reservation', [['date', ">=", formatDateRanges[0]!], ['date', '<=', formatDateRanges[1]!]], formatDateRanges.join('-') + loadKey)

  const {
    data: balances, save,
  } = useReadPropValueStartEndAt<Balance>('account', 'date', formatDateRanges[0], formatDateRanges[1], loadKey);

  const {data: products,} = useRead<Products>('/product');
  const {data: agencies,} = useRead<{ [agencyId: string]: Agency }>('/agency');
  const {data: users,} = useRead<{ [userId: string]: User }>('/user');
  const agencyMap = Object.fromEntries(Object.entries(agencies ?? {}).map(([_, agency]) => [agency.code, agency]));
  const {data: pickups,} = useRead<{ [area: string]: { [pickupName: string]: Pickup } }>('/pickup');
  const {data: notes, setData: setNotes,} = useRead<string[]>(`note/${dateRange[0]?.format('YYYY-MM-DD')}`);

  const aggregation = aggregateReservations(_reservations ?? [], products ?? {}, agencyMap ?? {}, operations ?? {});

  const reservationRows = aggregation[tab];

  const productNames = reservationRows.reduce((s, r) => {
    s.add(r.product);
    return s;
  }, new Set<string>());
  const selectedReservationRows = aggregation.total.filter((r) => rowSelectionModel.includes(r.id));
  const ProductFilter = filterGen([...productNames.values()].sort((a, b) => a > b ? 1 : -1));

  const defaultColumns: GridColDef[] = [
    {field: 'date', headerName: 'DATE', minWidth: 100, align: 'center', headerAlign: 'center'},
    {field: 'product', headerName: 'PRODUCT', minWidth: 200, align: 'center', headerAlign: 'center',},
    {field: 'guide', headerName: 'GUIDE', minWidth: 200, align: 'center', headerAlign: 'center'},
    {field: 'people', headerName: 'PEOPLE', minWidth: 100, align: 'center', headerAlign: 'center'},
    {field: 'agencyCode', headerName: 'AGENCY CODE', minWidth: 50, align: 'center', headerAlign: 'center'},
    {field: 'agency', headerName: 'AGENCY', minWidth: 50, align: 'center', headerAlign: 'center'},
    {field: 'clientName', headerName: 'NAME', minWidth: 200, align: 'center', headerAlign: 'center'},
    {field: 'option', headerName: 'OPTION', minWidth: 200, align: 'center', headerAlign: 'center'},
    {field: 'teamIdx', headerName: 'TEAM', minWidth: 200, align: 'center', headerAlign: 'center'},
    {field: 'reservedAt', headerName: 'RESERVED', minWidth: 100, align: 'center', headerAlign: 'center'},
    {field: 'memo', headerName: 'RESERVED', minWidth: 100, align: 'center', headerAlign: 'center'},
  ];


  const allColumn: GridColDef[] = [
    {field: 'date', headerName: 'DATE', minWidth: 100, align: 'center', headerAlign: 'center'},
    {field: 'product', headerName: 'PRODUCT', minWidth: 200, align: 'center', headerAlign: 'center',},
    {field: 'canceledAt', headerName: 'Cancel', minWidth: 100, align: 'center', headerAlign: 'center'},
    {field: 'guide', headerName: 'GUIDE', minWidth: 200, align: 'center', headerAlign: 'center'},
    {field: 'people', headerName: 'PEOPLE', minWidth: 100, align: 'center', headerAlign: 'center'},
    {field: 'agencyCode', headerName: 'AGENCY CODE', minWidth: 50, align: 'center', headerAlign: 'center'},
    {field: 'agency', headerName: 'AGENCY', minWidth: 50, align: 'center', headerAlign: 'center'},
    {field: 'clientName', headerName: 'NAME', minWidth: 200, align: 'center', headerAlign: 'center'},
    {field: 'option', headerName: 'OPTION', minWidth: 200, align: 'center', headerAlign: 'center'},
    {field: 'teamIdx', headerName: 'TEAM', minWidth: 200, align: 'center', headerAlign: 'center'},
    {field: 'reservedAt', headerName: 'RESERVED', minWidth: 100, align: 'center', headerAlign: 'center'},
    {field: 'memo', headerName: 'RESERVED', minWidth: 100, align: 'center', headerAlign: 'center'},

  ];

  const hasCancel = ['cancel', 'total'].includes(tab);
  const columns = hasCancel ? allColumn : defaultColumns

  const handleTempDateRange = (dr: DateRange<Dayjs>) => {
    setTempDateRange(dr);
  }
  const handleConfirmDateRange = () => {
    setDateRange(tempDateRange);
  }

  useEffect(() => {
    readWithKeyStartEndAt<Operation>('/operation', formatDateRanges[0], formatDateRanges[1])
      .then((operations) => {
        setOperations(operations);
      });
  }, formatDateRanges)

  return (
    <Box>
      <Grid container>
        <Grid xs={12}>
          <Box
            p={1}
          >
            <Card
              sx={{}}
            >
              <CardContent sx={(theme) => ({
                display: 'flex',
                justifyContent: 'space-between',
                color: theme.palette.text.primary,
                padding: '8px !important',
              })}>
                <Box>
                  <Tabs onChange={(_, value) => setTab(value)} value={tab}>
                    {
                      TABS.map((tab) =>
                        (<Tab key={tab} label={`${tab.toUpperCase()}(${aggregation[tab].length})`} value={tab}/>)
                      )
                    }
                  </Tabs>
                </Box>
                <Box
                  display={'flex'}
                  gap={1}
                >
                  <DateRangePickerValue onChange={handleTempDateRange} dateRange={dateRange}/>
                  {
                    loading ?
                      <CircularProgress/>
                      : <Button onClick={handleConfirmDateRange} variant={'contained'}
                                disabled={dateRange.map(d => d?.format('YYYY-MM-DD')).join() === tempDateRange.map(d => d?.format('YYYY-MM-DD')).join()}>
                        Confirm
                      </Button>

                  }
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Grid>
        <Grid xs={6}>
          <Box
            p={1}
          >
            <Card>
              <CardContent>

                <Box
                  sx={{height: 'calc(75vh)'}}
                >
                  <DataGridPro
                    hideFooter
                    slots={{
                      toolbar: (...props) => (
                        <Box sx={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-between',
                          pt: 2,
                          pb: 1,
                          px: 2
                        }}>
                          <GridToolbarQuickFilter
                            {...props}
                            fullWidth
                          />
                          <TourReservationList dateRanges={formatDateRanges}
                                               reservations={selectedReservationRows}/>
                        </Box>
                      )
                    }}
                    rows={reservationRows}
                    columns={columns}
                    checkboxSelection
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                      setRowSelectionModel(newRowSelectionModel)
                    }}
                    rowSelectionModel={rowSelectionModel}
                    unstable_headerFilters
                  />
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Grid>
        <Grid xs={6}>
          <Box
            p={1}
          >
            <Box
              sx={{height: '75vh'}}
            >
              <CalcReservations
                hasCancel={hasCancel}
                formattedDateRange={(formatDateRanges as [string, string])}
                reservations={selectedReservationRows}
                groupBy={['date', 'agency', 'product']}
              />
            </Box>
          </Box>
        </Grid>
        <Grid container>
          <Grid xs={12}>
            <Card>
              <CardContent>
                <GuideTourList operations={operations ?? {}} products={products ?? {}} users={users ?? {}}
                               balances={balances ?? {}}/>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}


type CalcRows = Omit<Partial<ReservationRow>, 'count' | 'people' | 'rowGroup'> & {
  id: string, count: number, people: number, rowGroup: string
};

function CustomGridTreeDataGroupingCell(props: GridRenderCellParams) {
  const {id, field, rowNode, formattedValue,} = props;
  const apiRef = useGridApiContext();
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector,
  );
  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;

  const handleClick = (event: any) => {
    if (rowNode.type !== 'group') {
      return;
    }

    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };
  return (
    <Box sx={{ml: rowNode.depth * 4}}>
      <div>
        {filteredDescendantCount > 0 ? (
          <Button onClick={handleClick} tabIndex={-1} size="small">
            {">"} {formattedValue}
          </Button>
        ) : (
          <span/>
        )}
      </div>
    </Box>
  );
}

type CalcGroupBy = 'date' | 'agency' | 'product'

function CalcReservations(props: {
  formattedDateRange: [string, string],
  hasCancel?: boolean,
  reservations: ReservationRow[],
  groupBy?: [CalcGroupBy, CalcGroupBy, CalcGroupBy,]
}) {
  const {reservations: _reservations, formattedDateRange, hasCancel, groupBy = ['date', 'agency', 'product']} = props;
  const reservations = _reservations.sort((a, b) => {
    const aDate = dayjs(a.date).toDate();
    const bDate = dayjs(b.date).toDate();
    const aReservedDate = dayjs(a.reservedAt).toDate();
    const bReservedDate = dayjs(b.reservedAt).toDate();
    if (aDate > bDate) return 1;
    if (aDate < bDate) return -1;
    if (aReservedDate > bReservedDate) return 1;
    if (bReservedDate < bReservedDate) return -1;
    return -1;
  })
  const isSameDate = formattedDateRange[0] === formattedDateRange[1]

  // const [sortModel, setSortModel] = React.useState<GridSortModel>([
  //     { field: 'date', sort: 'desc' },
  // ]);


  const [reducedReservationRows, reducedReservationCount, reducedResevationPeople] = reservations.reduce(
    (r, reservationRow) => {
      const [result, counts, peoples] = r;
      const date = reservationRow.date;
      const agency = reservationRow.agency;
      const product = reservationRow.product;
      const groupBase = {
        date,
        agency,
        product,
      }
      const pathes = groupBy.map((_, idx, groupBy) => {
        return groupBy.slice(0, idx + 1).map((target) => groupBase[target]).join(':')
      })

      const people = reservationRow.people;
      const a = groupBase[groupBy[0]];
      const b = groupBase[groupBy[1]];
      const c = groupBase[groupBy[2]];
      const aPath = pathes[0];
      const bPath = pathes[1];
      const cPath = pathes[2];
      if (!result[a]) {
        result[a] = {}
        counts[aPath] = 0;
        peoples[aPath] = 0;
      }
      if (!result[a][b]) {
        result[a][b] = {};
        counts[bPath] = 0;
        peoples[bPath] = 0;
      }
      if (!result[a][b][c]) {
        result[a][b][c] = [];
        counts[cPath] = 0;
        peoples[cPath] = 0;
      }
      result[a][b][c].push(reservationRow);
      counts[aPath] += 1;
      counts[bPath] += 1;
      counts[cPath] += 1;
      peoples[aPath] += people;
      peoples[bPath] += people;
      peoples[cPath] += people;

      return r
    }
    , ([{}, {}, {}] as [{ [a: string]: { [b: string]: { [c: string]: ReservationRow[] } } }, {
      [path: string]: number
    }, { [path: string]: number }]));

  const rows = Object.entries(reducedReservationRows)
    .sort(([a, _], [b, __]) => a > b ? 1 : -1)
    .map(([a, agencyGroupedProducts]) => {
      const aRow: CalcRows = {
        id: a,
        rowGroup: a,
        count: reducedReservationCount[a],
        people: reducedResevationPeople[a],
      }
      const rows = Object.entries(agencyGroupedProducts)
        .map(([b, productGroupedRows]) => {
          const path1 = [a, b].join(':');
          const bRow: CalcRows = {
            id: path1,
            rowGroup: path1,
            people: reducedResevationPeople[path1],
            count: reducedReservationCount[path1],
          }
          const cRows = Object.entries(productGroupedRows)
            .map(([c, rRows]) => {
              const path2: string = [a, b, c].join(':');
              const productRows: CalcRows = {
                id: path2,
                rowGroup: path2,
                count: reducedReservationCount[path2],
                people: reducedResevationPeople[path2],
              }

              const reservationRows = rRows.map((r) => ({
                ...r,
                [groupBy[0]]: a,
                [groupBy[1]]: b,
                [groupBy[2]]: c,
                rowGroup: `${path2}:${r.id}`,
              }) as CalcRows)

              return [productRows, ...reservationRows]
            }).flat(1);
          return [bRow, ...cRows]
        })
        .flat(1)
      return [aRow, ...rows]
    }).flat(1)


  const getTreeDataPath = (row: CalcRows) => {
    return row.rowGroup.split(':')
  }

  const columns: GridColDef[] = [
    {field: 'agency', headerName: 'AGENCY', minWidth: 50, align: 'center', headerAlign: 'center'},
    {field: 'product', headerName: 'PRODUCT', minWidth: 50, align: 'center', headerAlign: 'center'},
    {field: 'agencyCode', headerName: 'AGENCY CODE', minWidth: 50, align: 'center', headerAlign: 'center'},
    {field: 'clientName', headerName: 'NAME', minWidth: 200, align: 'center', headerAlign: 'center'},
    {field: 'people', headerName: 'PEOPLE', minWidth: 100, align: 'center', headerAlign: 'center'},
    {field: 'option', headerName: 'OPTION', minWidth: 200, align: 'center', headerAlign: 'center'},
    {field: 'guide', headerName: 'GUIDE', minWidth: 200, align: 'center', headerAlign: 'center'},
    {field: 'teamIdx', headerName: 'teamIdx', minWidth: 200, align: 'center', headerAlign: 'center'},
    {field: 'reservedAt', headerName: 'Reserved', minWidth: 100, align: 'center', headerAlign: 'center'},
    {field: 'canceledAt', headerName: 'Cancel', minWidth: 100, align: 'center', headerAlign: 'center'},
  ];
  if (!isSameDate) columns.unshift({
    field: 'date',
    headerName: 'DATE',
    minWidth: 100,
    align: 'center',
    headerAlign: 'center'
  });
  if (hasCancel) columns.push({
    field: 'canceledAt',
    headerName: 'Cancel',
    minWidth: 100,
    align: 'center',
    headerAlign: 'center'
  });

  columns.unshift(
    {field: 'count', headerName: 'COUNT', minWidth: 50, align: 'center', headerAlign: 'center'},
  )

  const groupingColDef: DataGridProProps['groupingColDef'] = {
    headerName: 'Group',
    renderCell: (params) => <CustomGridTreeDataGroupingCell {...params}/>
  };

  return (
    <Card>
      <CardContent>
        <Box
          sx={{height: '75vh'}}
        >

          <DataGridPro
            treeData
            hideFooter
            getTreeDataPath={getTreeDataPath}
            rows={rows}
            columns={columns}
            groupingColDef={groupingColDef}
          />
        </Box>
      </CardContent>
    </Card>
  )
}


function toReservationRow(reservation: Reservation, products?: Products | null, agencies?: {
  [agencyId: string]: Agency
} | null, operationReservationEntityMap?: Map<string, ReservationEntity>): ReservationRow {
  const people = reservation.adult + reservation.kid + reservation.infant;
  return ({
    id: reservation.id,
    date: reservation.date,
    product: products?.[reservation.productId]?.name ?? reservation.product,
    people: people,
    adult: reservation.adult,
    kid: reservation.kid,
    infant: reservation.infant,
    option: reservation.option && reservation.option.length > 0 ? reservation.option.map((option) => `${option.option}(${option.people})`).join(', ') : '',
    clientName: maskName(reservation.clientName),
    agency: agencies?.[reservation.agency]?.name ?? reservation.agency,
    agencyCode: reservation.agencyCode,
    noShow: reservation.noShow,
    canceledAt: reservation.canceledAt ? dayjs(reservation.canceledAt).format('YYYY-MM-DD') : undefined,
    reservedAt: dayjs(reservation.reservedAt).format('YYYY-MM-DD hh:mm:ss'),
    guide: operationReservationEntityMap?.get(reservation.id)?.guide?.map(g => g.name).join(', ') ?? '',
    teamIdx: operationReservationEntityMap?.get(reservation.id)?.teamIdx ?? 0,
    memo: reservation.memo ?? ''
  });
}


function aggregateReservations(reservations: Reservation[], products?: Products, agencies?: Agencies, operations?: {
  [date: string]: Operation
} | null) {

  const teamsToReservationEntities
    = (team: Team, teamIdx: number) => {
    const reservations = Object.values(team.reservations ?? {});
    const guides = team.guides;
    const reservationEntities = reservations.map<ReservationEntity>(r => ({
      ...r,
      guide: guides,
      teamIdx: teamIdx + 1
    }));
    return reservationEntities;
  }

  const tourToReservations = (tour: Tour) => {
    const teams = tour.teams ?? {};
    const teamReservationEntitiesList = Object.values(teams).map<ReservationEntity[]>(teamsToReservationEntities);
    return teamReservationEntitiesList.flat(1);
  }

  const operationDateReservationEntityTupleList: [string, ReservationEntity[]][] = Object.entries(operations ?? {})
    .map(([date, operation]) => {
      const tours = operation.tours ?? {};
      const tourReservationEntitiesList = Object.values(tours).map<ReservationEntity[]>(tourToReservations);
      return [date, tourReservationEntitiesList.flat(1)]
    })

  const operationReservationEntities: ReservationEntity[] = operationDateReservationEntityTupleList.map(([_, reservations]) => reservations).flat(1);
  const operationReservationEntityMap = new Map(operationReservationEntities.map(r => [r.id!, r]));

  return reservations.reduce((result, reservation, idx) => {
      const row = toReservationRow(reservation, products, agencies, operationReservationEntityMap);
      const isOutsourced = reservation.id.toUpperCase().startsWith('KTOS');
      if (isOutsourced) {
        result.outsource.push(row)
        return result;
      }

      result.total.push(row)

      if (!reservation.canceledAt) {
        result.all.push(row)
      }

      if (!reservation.noShow && !reservation.canceledAt) {
        result.present.push(row)
      }

      if (reservation.noShow && !reservation.canceledAt) {
        result.noShow.push(row)
      }

      if (reservation.canceledAt) {
        result.cancel.push(row)
      }
      return result;
    },
    {
      total: [],
      all: [],
      present: [],
      noShow: [],
      cancel: [],
      outsource: [],
    } as ReservationRows
  )
}

const getDefaultFilter = (field: string) => ({
  field, operator:
    'isAnyOf'
});

const filterGen = (productNames: string[]) => function AdminFilter(props: GridHeaderFilterCellProps) {
  const {colDef} = props;
  const apiRef = useGridApiContext();
  const filterModel = useGridSelector(apiRef, gridFilterModelSelector);
  const currentFieldFilters = React.useMemo(
    () => filterModel.items?.filter(({field}) => field === colDef.field),
    [colDef.field, filterModel.items],
  );


  const handleChange = React.useCallback(
    (event: SelectChangeEvent) => {
      if (!event.target.value) {
        if (currentFieldFilters[0]) {
          apiRef.current.deleteFilterItem(currentFieldFilters[0]);
        }
        return;
      }
      apiRef.current.upsertFilterItem({
        ...(currentFieldFilters[0] ?? getDefaultFilter(colDef.field)),
        value: event.target.value,
      });
    },
    [apiRef, colDef.field, currentFieldFilters],
  );

  const value = currentFieldFilters[0]?.value ?? [];
  const label = 'is any of';

  return (
    <FormControl variant="standard" sx={{m: 1, minWidth: 120}} fullWidth>
      <InputLabel id="select-is-admin-label">Select</InputLabel>
      <Select
        labelId="select-is-admin-label"
        id="select-is-admin"
        multiple
        value={value}
        onChange={handleChange}
        label={'Select'}
      >
        {
          productNames.map(p => (
            <MenuItem key={p} value={p}>{p}</MenuItem>
          ))
        }

      </Select>
    </FormControl>
  );
}


function TourReservationList(props: { dateRanges: string[], reservations: ReservationRow[] }) {
  const {reservations} = props;
  const [loading, setLoading] = useState(false);
  const [startAt, endAt] = props.dateRanges;
  const reservationMap = new Map((reservations ?? []).map((r) => [r.id, r]));
  const schema = [
    {
      column: 'Date',
      value: (r: any) => r.date,
    },
    {
      column: 'Reservation Date',
      value: (r: any) => {
        return r.reservedAt
      },
    },
    {
      column: 'Agency',
      value: (r: any) => r.agency,
    },
    {
      column: 'Agency Code',
      value: (r: any) => r.agencyCode,
    },
    {
      column: 'Product',
      value: (r: any) => r.product,
    },
    {
      column: 'Team',
      value: (r: any) => r.teamIdx,
      type: Number
    },
    {
      column: 'Guide',
      value: (r: any) => r.guide,
    },
    {
      column: 'Client Name',
      value: (r: any) => r.clientName,
    },
    {
      column: 'People',
      value: (r: any) => r.people,
      type: Number,
    },
    {
      column: 'Adult',
      value: (r: any) => r.adult,
      type: Number,
    },
    {
      column: 'Kid',
      value: (r: any) => r.kid,
      type: Number,
    },
    {
      column: 'Infant',
      value: (r: any) => r.infant,
      type: Number,
    },
    {
      column: 'Option',
      value: (r: any) => r.option,
    },
    {
      column: 'Memo',
      value: (r: any) => r.memo,
    },

  ]

  const handleClick = useCallback(() => {
    const download = async () => {
      setLoading(true);
      await writeXlsxFile(reservations, {
        schema,
        fileName: `kint2-reservations.xlsx`
      }).then(() => {
      })
    }

    download()
      .catch((e) => {
        console.error(e)
        alert('Fail on download excel');
      })
      .finally(() => setLoading(false));
  }, [startAt, endAt, setLoading, reservationMap]);

  return (
    <Button
      disabled={reservations.length === 0}
      sx={{height: '100%',}}
      onClick={handleClick}
      variant={"outlined"}
    >
      {
        loading ? <CircularProgress size={32}/>
          : 'DOWNLOAD EXCEL'
      }
    </Button>
    // <Card>
    //     <CardContent>
    //         <DataGridPro columns={columns} rows={rows} slots={{toolbar: CustomToolbar}}/>
    //     </CardContent>
    // </Card>
  )
}

type Operations = { [date: string]: Operation };

type Balances = { [id: string]: Balance };

type Count = { count: number, people: number, adult: number, kid: number, infant: number };

type GuideTourEntity = {
  guideId: string,
  guideName: string,
  date: string, product: string, present: Count, noShow: Count, total: Count, pickupSet: Set<string>,
  productId: string
};


function GuideTourList(props: { operations: Operations, products: Products, users: Users, balances: Balances }) {
  const {operations, products, users, balances} = props;
  const [rowUpdates, setRowUpdates] = useState<{ [rowId: string]: GuideOperationRow }>({});
  const handleRowChange = (newRow: GuideOperationRow) => {
    setRowUpdates((updates) => ({...updates, [newRow.id]: newRow}));
    return newRow;
  };

  const sortedBalances = Object.entries(balances).reduce((result, [id, balance]) => {
    const guideId = balance.writer.split(')').at(-1) ?? "";
    const date = balance.date;
    balance.identifier = id;
    if (!result[date]) {
      result[date] = {}
    }

    if (!result[date][guideId]) {
      result[date][guideId] = []
    }

    result[date][guideId].push(balance);
    return result;
  }, {} as { [date: string]: { [id: string]: Balance[] } })

  const tourGuideReducedReservationTuples = Object.entries(operations).map(([date, {tours}]) => {
    const tourList = Object.values(tours ?? {});
    return tourList.map((tour) => {
      const product = products?.[tour.productId]?.name ?? tour.product;
      const teams = Object.values(tour.teams ?? {})
      return teams.map<[string, GuideTourEntity][]>((team, teamIdx) => {
        const guides = team.guides
        const reducedReservations = Object.values(team.reservations ?? {}).reduce((result, reservation) => {
            const adult = reservation.adult;
            const kid = reservation.kid;
            const infant = reservation.infant;
            const people = adult + kid + infant;
            if (reservation.noShow) {
              result.noShow.adult += adult;
              result.noShow.kid += kid;
              result.noShow.infant += infant;
              result.noShow.people += people;
              result.noShow.count += 1;
            } else {
              result.present.adult += adult;
              result.present.kid += kid;
              result.present.infant += infant;
              result.present.people += people;
              result.present.count += 1;
            }
            result.total.adult += adult;
            result.total.kid += kid;
            result.total.infant += infant;
            result.total.people += people;
            result.total.count += 1;
            result.pickupSet.add(reservation.pickupPlace);
            return result;
          }, ({
            date,
            product,
            present: {count: 0, people: 0, adult: 0, kid: 0, infant: 0},
            noShow: {count: 0, people: 0, adult: 0, kid: 0, infant: 0},
            total: {count: 0, people: 0, adult: 0, kid: 0, infant: 0},
            pickupSet: new Set<string>(),
            productId: tour.productId,
          } as GuideTourEntity)
        )
        return guides?.map(g => [g.id, {...reducedReservations, guideName: g.name,}]) ?? []
      }).flat(1);
    }).flat(1)
  }).flat(1)

  const reduced = tourGuideReducedReservationTuples.reduce((result, [guideId, entity]) => {
    if (!result[guideId]) result[guideId] = [];
    result[guideId].push(entity);
    return result;
  }, ({} as { [guideId: string]: GuideTourEntity[] }))

  const sorted = Object.fromEntries(Object.entries(reduced).map(([guideId, entities]) => [guideId, entities.sort((a, b) => a.date > b.date ? 1 : -1)]));
  const guideRows: GuideOperationRow[] = [];
  const rows: GuideOperationRow[] = Object.entries(sorted)
    .sort(([_, aEntities], [__, bEntities]) => {
      if (aEntities.length > bEntities.length) return -1;
      if (aEntities.length < bEntities.length) return 1;
      if (aEntities[0].date > bEntities[0].date) return 1;
      if (aEntities[0].date < bEntities[0].date) return -1;
      return 0
    })
    .map<GuideOperationRow[]>(([guideId, entities]) => {
      const guide = users?.[guideId]
      const guideName = `${guide?.name ?? entities.at(0)?.guideName}(${guide?.nameEn ?? guideId})`;
      const dates = entities.map((entity) => entity.date)
      const product = entities.map((entity) => entity.product).join(', ')
      const count = entities.length;
      const balancesMap: Map<string, GuideOperationRow> = new Map();
      const guideIdentifier = guideName + (guide?.email?.split('@').at(0) ?? '');
      const entityRows = entities.map<GuideOperationRow>((e) => {
        const id = guideId + e.date;
        const pickupOrders = Object.values(products[e.productId]?.chat?.pickup ?? {}).sort((a, b) => (a.order ?? 9999) > (b.order ?? 9999) ? 1 : -1).map(p => p.place)
        const filteredPickupOrders = pickupOrders.filter((p) => e.pickupSet.has(p));
        const balanceRows = sortedBalances[e.date]?.[guideId]?.map((balance, idx) => {
          return {
            id: balance.identifier,
            guideId: '',
            guideName: '',
            date: '',
            day: '',
            count: '',
            product: '',
            pay: '',
            incentive: '',
            groupPath: [guideIdentifier, e.date, balance.identifier],
            cardExpenditure: (balance?.card ?? 0) < 0 ? balance.card + '' : '',
            cardIncome: (balance?.card ?? 0) > 0 ? balance.card + '' : '',
            cashExpenditure: (balance?.cash ?? 0) < 0 ? balance.cash + '' : '',
            cashIncome: (balance?.cash ?? 0) > 0 ? balance.cash + '' : '',
            memo: balance.detail
          }
        }) ?? []

        const guideSum = balanceRows.reduce((result, balance) => {
          balancesMap.set(balance.id, balance);
          if (balance.cardExpenditure)
            result.cardExpenditure += Number.parseInt(balance.cardExpenditure);
          if (balance.cardIncome)
            result.cardIncome += Number.parseInt(balance.cardIncome);
          if (balance.cashExpenditure)
            result.cashExpenditure += Number.parseInt(balance.cashExpenditure);
          if (balance.cashIncome)
            result.cashIncome += Number.parseInt(balance.cashIncome);

          return result;
        }, {cardExpenditure: 0, cardIncome: 0, cashExpenditure: 0, cashIncome: 0} as {
          cardExpenditure: number,
          cardIncome: number,
          cashExpenditure: number,
          cashIncome: number
        });
        return (rowUpdates[id]
          ? {...rowUpdates[id]}
          : {
            ...e,
            ...e.total,
            id,
            guideId,
            guideName,
            date: e.date.split('-').at(-1),
            day: ['일', '월', '화', '수', '목', '금', '토'][dayjs(e.date).day()],
            pickup: filteredPickupOrders?.[0] ?? '',
            groupPath: [guideIdentifier, e.date],
            pay: 0,
            incentive: 0,
            cardExpenditure: guideSum.cardExpenditure,
            cardIncome: guideSum.cardIncome,
            cashExpenditure: guideSum.cashExpenditure,
            cashIncome: guideSum.cashIncome,
          })
      })
      const balancesRow = [...balancesMap.values()];
      const totalPay = entityRows.map((e) => e.pay).reduce((a, b) => Number.parseInt((a ?? 0) + '') + Number.parseInt((b ?? 0) + ''));
      const totalIncentive = entityRows.map((e) => e.incentive).reduce((a, b) => Number.parseInt((a ?? 0) + '') + Number.parseInt((b ?? 0) + ''));
      const totalCardExpenditure = ([...balancesRow.map(b => b.cardExpenditure ? Number.parseInt(b.cardExpenditure + '') : 0), 0]).reduce((a, b) => a + b);
      const totalCardIncome = ([...balancesRow.map(b => b.cardIncome ? Number.parseInt(b.cardIncome + '') : 0), 0]).reduce((a, b) => a + b);
      const totalCashExpenditure = ([...balancesRow.map(b => b.cashExpenditure ? Number.parseInt(b.cashExpenditure + '') : 0), 0]).reduce((a, b) => a + b);
      const totalCashIncome = ([...balancesRow.map(b => b.cashIncome ? Number.parseInt(b.cashIncome + '') : 0), 0]).reduce((a, b) => a + b);
      const guideRow: GuideOperationRow = {
        id: guideId,
        guideId,
        guideName,
        date: dates.map((d: string) => d.split('-').at(-1)).join(', '),
        day: dates.map((d: string) => ['일', '월', '화', '수', '목', '금', '토'][dayjs(d).day()]).join(', '),
        count,
        product,
        groupPath: [guideIdentifier],
        pay: totalPay,
        incentive: totalIncentive,
        cardExpenditure: totalCardExpenditure,
        cardIncome: totalCardIncome,
        cashExpenditure: totalCashExpenditure,
        cashIncome: totalCashIncome
      };
      guideRows.push(guideRow);

      return [guideRow, ...entityRows, ...balancesRow];
    }).flat(1);


  const getRowStyle = (priority: number, value: number) => {
    switch (priority) {
      case 1:
        return {
          fontSize: '16px',
          color: 'white',
          backgroundColor: value === 0 ? undefined : value > 0 ? 'rgba(0,0,255, 0.5)' : 'rgba(255,0,0, 0.5)'
        };
      case 2:
        return {
          fontSize: '16px',
          backgroundColor: value === 0 ? undefined : value > 0 ? 'rgba(0,0,255, 0.1)' : 'rgba(255,0,0, 0.1)'
        };
      case 3:
        return {
          fontSize: '16px',
          backgroundColor: value === 0 ? undefined : value > 0 ? 'rgba(0,0,255, 0.0)' : 'rgba(255,0,0, 0.0)'
        };
      default:
        return {};
    }
  };

  const columns: GridColDef[] = [
    {field: 'date', headerName: 'DATE', minWidth: 200, align: 'left', headerAlign: 'center'},
    {field: 'day', headerName: 'DAY', minWidth: 200, align: 'left', headerAlign: 'center'},
    {field: 'product', headerName: 'PRODUCT', minWidth: 300, align: 'left', headerAlign: 'center'},
    {field: 'count', headerName: 'COUNT', minWidth: 50, align: 'center', headerAlign: 'center'},
    {field: 'people', headerName: 'PEOPLE', minWidth: 50, align: 'center', headerAlign: 'center'},
    {field: 'adult', headerName: 'ADULT', minWidth: 50, align: 'center', headerAlign: 'center'},
    {field: 'kid', headerName: 'KID', minWidth: 50, align: 'center', headerAlign: 'center'},
    {field: 'infant', headerName: 'INFANT', minWidth: 50, align: 'center', headerAlign: 'center'},
    {field: 'pickup', headerName: 'PICK UP', minWidth: 300, align: 'left', headerAlign: 'center'},
    {
      field: 'incentive', headerName: 'INCENTIVE', minWidth: 100, align: 'left', headerAlign: 'center', editable: true,
      valueParser: (value: any) => {
        const newVar = typeof value === 'string' ? Number.parseInt(value) : value ?? 0;
        return newVar;
      },
    },
    {
      field: 'pay', headerName: 'PAY', minWidth: 100, align: 'left', headerAlign: 'center', editable: true,
      valueParser: (value: any) => {
        const newVar = typeof value === 'string' ? Number.parseInt(value) : value ?? 0;
        return newVar;
      },
    },

    {
      field: 'cardExpenditure',
      headerName: 'CARD EXP',
      minWidth: 100,
      align: 'left',
      headerAlign: 'center',
      editable: true,
      valueParser: (value: any) => {
        const newVar = typeof value === 'string' ? Number.parseInt(value) : value ?? 0;
        return newVar;
      },
      renderCell: (prop) => {
        const value = prop.row.cardExpenditure;
        const newVar = typeof value === 'string' ? Number.parseInt(value || '0') : value ?? 0;
        return <Typography
          sx={
            {
              ...getRowStyle(prop.row.groupPath?.length ?? 0, prop.row.cardExpenditure)
            }
          }

          color={
            prop.row.cardExpenditure > 0
              ? '#1577BA'
              : prop.row.cardExpenditure < 0
                ? '#FF4C41'
                : 'inherit'
          }>
          {
            newVar !== 0
              ?
              newVar.toLocaleString()
              : ''
          }
        </Typography>
      },
    },
    {
      field: 'cardIncome', headerName: 'CARD IN', minWidth: 100, align: 'left', headerAlign: 'center', editable: true,
      valueParser: (value: any) => {
        const newVar = typeof value === 'string' ? Number.parseInt(value) : value ?? 0;
        return newVar;
      },
      renderCell: (prop) => {
        const value = prop.row.cardIncome;
        const newVar = typeof value === 'string' ? Number.parseInt(value || '0') : value ?? 0;
        return <Typography
          sx={
            {
              ...getRowStyle(prop.row.groupPath?.length ?? 0, prop.row.cardIncome)
            }
          }
          color={
            prop.row.cardIncome > 0
              ? '#1577BA'
              : prop.row.cardIncome < 0
                ? '#FF4C41'
                : 'inherit'
          }>
          {
            newVar !== 0
              ?
              newVar.toLocaleString()
              : ''
          }
        </Typography>
      },
    },

    {
      field: 'cashExpenditure',
      headerName: 'CASH EXP',
      minWidth: 100,
      align: 'left',
      headerAlign: 'center',
      editable: true,
      valueParser: (value: any) => {
        const newVar = typeof value === 'string' ? Number.parseInt(value) : value ?? 0;
        return newVar;
      },
      renderCell: (prop) => {
        const value = prop.row.cashExpenditure;
        const newVar = typeof value === 'string' ? Number.parseInt(value || '0') : value ?? 0;
        return <Typography
          sx={
            {
              ...getRowStyle(prop.row.groupPath?.length ?? 0, prop.row.cashExpenditure)
            }
          }
          color={
            prop.row.cashExpenditure > 0
              ? '#1577BA'
              : prop.row.cashExpenditure < 0
                ? '#FF4C41'
                : 'inherit'
          }>
          {
            newVar !== 0
              ?
              newVar.toLocaleString()
              : ''
          }
        </Typography>
      },
    },
    {
      field: 'cashIncome', headerName: 'CASH IN', minWidth: 100, align: 'left', headerAlign: 'center', editable: true,
      renderCell: (prop) => {
        const value = prop.row.cashIncome;
        const newVar = typeof value === 'string' ? Number.parseInt(value || '0') : value ?? 0;
        return <Typography
          sx={
            {
              ...getRowStyle(prop.row.groupPath?.length ?? 0, prop.row.cashIncome)
            }
          }
          color={
            prop.row.cashIncome > 0
              ? '#1577BA'
              : prop.row.cashIncome < 0
                ? '#FF4C41'
                : 'inherit'
          }>
          {
            newVar !== 0
              ?
              newVar.toLocaleString()
              : ''
          }
        </Typography>
      },
    },
    {field: 'memo', headerName: 'MEMO', minWidth: 400, align: 'left', headerAlign: 'center'},

  ]
  return <DataGridPro
    treeData
    rows={rows}
    columns={columns}
    getTreeDataPath={(r) => r.groupPath}
    processRowUpdate={handleRowChange}
    onProcessRowUpdateError={console.error}
  />;
}


function maskName(inputString: string): string {
  const name = inputString.replace(/\(M[ISR]{1,4}\)/, '');
  const thirdLength: number = Math.ceil(name.length / 3);
  const start: number = thirdLength;
  const end: number = 2 * thirdLength;

  return (
    name.substring(0, start) +
    "*".repeat(end - start) +
    name.substring(end)
  );
}

function getThisWeekRange(): [Dayjs, Dayjs] {
  const today = new Date();
  const dayOfWeek = today.getDay(); // 일요일: 0, 월요일: 1, ..., 토요일: 6

  const monday = new Date(today);
  monday.setDate(today.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1));

  const sunday = new Date(today);
  sunday.setDate(today.getDate() + (dayOfWeek === 0 ? 0 : 7 - dayOfWeek));

  return [dayjs(monday), dayjs(sunday)];
}