import * as XLSX from "xlsx"

const SKI_TOUR = ['엘리시안', '남이엘리시안', '남이비발디', '지산', '에덴']
const IN_OPTION: string[] = [
  'Chasing Cherry Blossom / Spring Flower Day Tour from Busan',
  'Jinhae Cherry Blossom Festival Day Tour from Seoul & Busan',
  'Cherry Blossom Random Tour',
  'Nami Island / Petite France / Garden of Morning Calm / Rail Bike / Alpaca Tour',
  'Nami Island / Petite France / Garden of Morning Calm / Rail Bike / Alpaca World / Legoland',
  'Nami Island, Petite France & Italian Village, Garden of Morning Calm, and Gangchon Rail Bike Day Tour from Seoul',
  'Strawberry Picking + Nami Island / Railbike / Garden of Morning Calm Day Tour from Seoul', 'Nami Island, Gangchon Rail Bike and More',
  'Winter Strawberry Picking & Nami Island Day Tour from Seoul',
  'Eobi Ice Valley & Nami & Strawberry Day Tour from Seoul',
  'Nami Island / Alpaca / Railbike / Petite France / Morning Calm Tour',
  'Hyvaa Finland, Nami Island, Gangchon Rail Bike and More', 'Gwangyang Maehwa and Gurye Sansuyu Festival Day Tour',
  'Jinhae Cherry Blossom Festival Tour from Seoul and Busan',
  'DMZ Day Tour from Seoul',
  'DMZ Tour',
  'Paju/Gimpo/Cheorwon DMZ Day Tour from Seoul',
  'Paju/Cheorwon/Gimpo DMZ One Day Tour from Seoul',
  'Paju/Cheorwon/Gimpo DMZ Day Tour from Seoul',
  'Busan City Day Tour',
  'Korea\'s Seasonal Local Festival Tour From Busan',
  'East Busan Day Tour with Optional Yacht Experience',
  'Busan One Day City Tour: Haeundae Sky Capsule, Yacht',
  'Korea Folk Village / Suwon Hwaseong Day Tour from Seoul (EG Tour)',
  'DMZ(Demilitarized Zone) Tour',
  'Naejangsan National Park Autumn Maple Day Tour from Seoul',
  'Hwadam Botanic Garden & Nami Island Spring Flowers Day Tour',
  'Mt. Seorak / Naksansa Temple Day Tour from Seoul',
  'Gyeongju UNESCO World Heritage Day Tour from Busan',
  'Pocheon Apple/Strawberry Farm Day Tour from Seoul',
  'Legoland Korea Round-Trip Transfers with Admission Ticket',
  'Nami Island & Strawberry Picking Day Tour from Seoul',
  'Gwangyang Plum Blossom (Maehwa) Festival from Seoul / Busan',
  'Busan One Day City Tour: Haeundae Sky Capsule, Gamcheon, Yacht',
  'Alpaca World, Suspension Bridge & Luge Day Tour from Seoul',

  'Pocheon Art Valley & Strawberry / Apple Picking Day Tour from Seoul',
  'Blueline Park Sky Capsule & Chasing Cherry Blossom and Spring Flowers Day Tour from Busan | South Korea',
  'Gyeongju Unesco Site & Sky Capsule Day Tour from Busan',
  'Hwadam Botanic Garden & Nami Island Spring Flowers Day Tour'
]

const TOUR_NAME: any = {
  'DMZ(Demilitarized Zone) Tour': '파주(캠프그리브즈) DMZ',

  'Everland Full Day Trip with Admission and Transfers from Seoul': '에버',
  'Everland Full Day Tour with Admission and Transfers from Seoul': '에버',
  'Everland One Day Tour with Admission & Transportation from Seoul': '에버',
  'Everland Day Tour with Early/Late Return from Seoul': '에버',

  'Nami Island & Mt. Seorak Day Tour by KTOURSTORY': '남설',
  'Nami Island & Mt. Seorak One Day Tour': '남설',
  'Nami Island & Mt. Seorak One Day Tour from Seoul': '남설',
  'Alpaca World, Gubongsan Mountain Observatory Café Street, and Hyundai Premium Outlet Space Day Tour from Seoul': '알파카',
  'Alpaca World, Gubongsan Mountain Observatory Cafe Street, and Jade Garden Day Tour from Seoul': '알파카',
  'Alpaca World / Luge Activity / Museum SAN / Jade Garden / Gubongsan Cafe Street': '알파카',
  'Alpaca World / Luge Ride / Museum SAN One Day Tour from Seoul': '알루산',

  'Nami Island Round-Trip Shuttle Bus from Seoul': '남이섬셔틀',
  'Seoul - Nami Island Shuttle Bus': '남이섬셔틀',
  'Shared Round-Trip Shuttle Bus between Seoul and Nami Island': '남이섬셔틀',


  'Nami Island & Elysian Gangchon Winter Ski Day Tour from Seoul': '남이엘리시안',
  'Nami Island & Elysian Gangchon Winter Ski  Day Tour from Seoul': '남이엘리시안',
  'Nami Island & Vivaldi Park Winter Ski Day Tour from Seoul': '남이비발디',
  'Elysian Gangchon Winter Ski Day Tour From Seoul': '엘리시안',


  'B : Strawberry Farm + Pocheon Art Valley + Herb Island [~06/30]': '포천딸기',
  'Pocheon Art Valley, Strawberry picking, and Herb Island One Day Tour': '포천딸기',
  'A : Strawberry Farm + Pocheon Art Valley + Herb Island [~03/17]': '포천딸기',
  'Pocheon Apple/Strawberry Farm Day Tour from Seoul(EG Tour)': '포천사과',
  'Pocheon Apple Farm Day Tour from Seoul(EG Tour)': '포천사과',
  'Jisan Forest Winter Ski Day Tour from Seoul': '지산',

  'Seoraksan National Park Autumn Maple Day Tour from Seoul': '설악산단풍',

  'Autumn Instagrammable Tour from Seoul': '인스타가을',
  'Memorable Autumn Hunting Day Tour from Seoul': '단풍랜덤',
  'Memorable Autumn Hunting Day Tour from Busan': '단풍랜덤부산',
  'Gyeongju Autumn Foliage Day Tour from Busan': '단풍랜덤경주',

  'Daegu E-World Day Tour with Cherry Blossom Option from Busan': '이월드벚꽃',


  'Gyeongju Unesco Site & Sky Capsule Day월 Tour from Busan': '경주캡슐',
  'Max Out Busan: Highlight Photo Spots Day Tour with Sky Capsule': '특공대(부산)',


  'Mountain Jiri Scenic Autumn Day Tour from Busan': '지리산',

  'Busan East Coast Cultural Day Tour': '태감송해',
  'Oedo Island Tour from Busan: Maemiseong Castle, Windy Hill, Cruise': '외도',
  'Oedo Island Tour from Busan: Windy Hill ,Geoje Panorama Cable Car': '외도',
  'Oedo Paradise Island Tour from Busan : Windy Hill ,Geoje Cable Car': '외도',

  'Yeongnam Alps Gondola Autumn Foliage Day Tour from Busan': '영남알프스',

  'Tongyeong Day Tour with Cable Car from Busan': '통영',
  'Tongyeong Day Tour with Cable Car & Luge': '통영',
  'Serene Seaside Tongyeong Tour from Busan:  Jungang Market, Cable Car': '통영',
  'Busan Eden Valley Ski Day Tour (Lesson & Rental)': '에덴',

  'Gyeongju Cherry Blossom One day Tour from Busan': '경주벚꽃',
  'King Cherry Blossoms Day Tour from Seoul': '겹벚꽃(서울)',
  'Chasing Cherry Blossom & Spring Flowers Day Tour from Seoul': '벚꽃랜덤(서울)',
  'Taean Tulip Festival & Strawberry Picking Day Tour from Seoul': '태안딸기',

  'Odaesan National Park Autumn Maple Day Tour from Seoul': '오대산',
  'Palgongsan Natural Park Autumn Maple Day Tour from Busan': '가을팔공산',
  'Daedunsan Autumn Maple Day Tour from Seoul': '대둔산',
  'Naejangsan National Park Autumn Maple Day Tour from Busan': '부산내장산',

  'Seoul BTS Fan Day Tour': 'BTS',
  'BTS Fan ARMY Must Visit One Day Tour from Seoul': 'BTS',
  'BTS Fan ARMY Must Visit Day Tour from Seoul': 'BTS',

  'Seoul Gwangjang Market Unique Food Tour': '광장시장',

  'K-drama [ Itaewon Class ] Walking Tour': '이태원클래스',

  'Hwadam Forest Healing Package Day Tour from Seoul(EG Tour)': '화구스(EG)',
  'Yangpyeong Day Tour from Seoul': '양평',


  'Jeonju Hanok Village Day Tour from Seoul': '전주',

  'BLACKPINK \'BLINK\' Fan Day Tour from Seoul': '블랙핑크',

  'MBC STUDIO One Day Tour from Seoul': 'MBC 스튜디오',
  'MBC STUDIO One Day Tour from SeoulMBC Studio Tour + Drama Rehearsal Visit 20% off': 'MBC 스튜디오(드라마 리허설)',
  '[Lotte Duty Free] All Night Party in Lotte World with DJ Performance': '롯데올나잇',
  'Max Out Seoul: Must Visit Highlights One Day Tour': '특공대(서울)'
}

const TOUR_OPTIONS: any = {


  'Hwadam Botanic Garden + Starfield Shopping Mall + Zoolung Zoolung': '화주스',

  'A : Nami Island + Petite France + Italian Village': '남쁘',
  'B : Nami Island + Petite France + Italian Village + Gangchon Rail Bike': '레남쁘',
  'C : Nami Island + Petite France + Italian Village + The Garden of Morning Calm': '남쁘아',
  'D : Nami Island + Petite France + Italian Village + Painters Show': '남쁘페',
  'E : Nami Island + The Garden of Morning Calm': '남아',
  'F : Nami Island + The Garden of Morning Calm + Gangchon Rail Bike': '레남아',
  'G : Nami Island + The Garden of Morning Calm + Petite France + Italian Village + Gangchon Rail Bike': '쁘남레아',
  'H : Alpaca World + Nami Island': '알남',
  'I :  Alpaca World + Nami Island + Gangchon Rail Bike': '알남레',
  'J : Alpaca World + Nami Island + The Garden of Morning Calm': '알남아',
  'K : Nami Island + Legoland': '레고남이',

  'A : Nami + Petite France + Italian Village': '남쁘',
  'A-2 : Nami + Petite France + Italian Village + Painters Show': '남쁘페',
  'B : Nami + Petite France + Italian Village + Railbike': '레남쁘',
  'C-1 : Nami + Petite France + Italian Village + Morning Calm': '남쁘아',
  'C-2 : (Winter)Nami + Petite France + Italian Village + Morning Calm': '남쁘아',
  'D-1 : Nami + Morning Calm': '남아',
  'D-2 : (Winter)Nami  + Morning Calm': '남아',
  'E-2 : (Winter)Nami  + Railbike + Morning Calm': '레남아',
  'E-1 : Nami + Railbike + Morning Calm': '레남아',
  'F-2 : (Winter)Nami + Petite France + Italian Village + Railbike + Morning Calm': '쁘남레아',
  'F-1 : Nami + Petite France + Italian Village + Railbike + Morning Calm': '쁘남레아',
  'G-1 : Alpaca World + Nami + Morning Calm': '알남아',
  'G-2 : (Winter)Alpaca World + Nami + Morning Calm': '알남아',
  'H : Alpaca World + Nami': '알남',
  'I : Alpaca World + Nami + Railbike': '알남레',
  'J : Legoland + Nami': '레고남이',
  'J : Alpaca World + Nami + Mt. Samak Cable Car': '알남삼',


  'K : Legoland + Alpaca World': '알레고',
  'K : (Winter)Alpaca World + Railbike + Nami + Morning Calm': '알레남아',

  'Autumn Mt. Seorak Maple(10/16~10/26)': '설악산단풍',
  'Mt. Seorak + Naksansa Temple': '설낙',

  'A : Day Tour [Regular 43-seater Bus]': '내장산',
  'B : Day Tour [19-Seater Premium Limousine Bus]': '리무진내장산',

  'A-1: (Winter)Strawberry Picking + Nami + Morning Calm': '딸남아',
  'A-2: Strawberry Picking + Nami + Morning Calm': '딸남아',
  'B-1: (Winter)Strawberry Picking + Nami + Railbike + Morning Calm': '딸남레아',
  'B-2: Strawberry Picking + Nami + Railbike + Morning Calm': '딸남레아',
  'C: Strawberry Picking + Nami + Railbike': '딸남레',
  'D: Strawberry Picking + Sled + Eobi Valley': '딸썰어',
  'E: Strawberry Picking + Nami + Eobi Valley': '딸남어',
  'F: Strawberry Picking + Alps Snow Village': '딸알프스',
  'G : Strawberry Picking + Alpaca World + Nami + Morning Calm': '알딸남아',

  'Legoland (Shuttle Bus + Admission)': '레고랜드',
  'Legoland (Shuttle Bus + Admission + Guide) + Gangchon Railbike': '레고레일',

  'Chasing Cherry Blossom & Spring Flower Tour': '벚꽃랜덤(부산)',
  'Chasing Cherry Blossom & Spring Flower Tour + Blueline Sky Capsule': '캡슐벚꽃',
  '[HK Promotion] Day Tour [Departing from Busan]': '부산진해',
  'Day Tour [Departing from Busan]': '부산진해',

  'Day Tour [Departing from Seoul]': '서울진해',
  '[HK Promotion] Day Tour [Departing from Seoul]': '서울진해',


  'Nami Island + The Garden of Morning Calm': '남아',
  'Nami Island + Petite France + Italian Village': '남쁘',
  'Nami Island + Petite France': '남쁘',
  'Nami Island + Petite France + Italian Village + Painters Show': '남쁘페',
  'Nami Island + Petite France + Italian Village + The Garden of Morning Calm': '남쁘아',
  'Nami Island + The Garden of Morning Calm + Petite France': '남쁘아',
  'Nami Island + Petite France + The Garden of Morning Calm': '남쁘아',
  'Nami Island + The Garden of Morning Calm + Gangchon Rail Bike': '레남아',
  'Nami Island + Petite France + Italian Village + Gangchon Rail Bike': '레남쁘',
  'Nami Island + Petite France + Rail Bike': '레남쁘',
  'Nami Island + The Garden of Morning Calm + Petite France + Italian Village + Gangchon Rail Bike': '쁘남레아',
  'Hyvaa Finland + Nami Island + Rail Bike': '휘남레',
  'Nami Island + Alpaca World + Gangchon Rail Bike': '알남레',
  'Alpaca World + Nami Island + Gangchon Rail Bike': '알남레',
  'Alpaca World + Nami Island + The Garden of Morning Calm': '알남아',
  'Alpaca World + Nami Island': '알남',

  'Hyvaa Finland + Nami Island + Gangchon Rail Bike': '휘남레',
  'Nami Island + Hyvaa Finland + Gangchon Rail Bike': '휘남레',

  'Strawberry Picking + Nami Island + Gangchon Railbike': '딸남레',
  'Strawberry Picking + Nami Island + The Garden of Morning Calm': '딸남아',
  'Nami Island + The Garden of Morning Calm + Strawberry Picking': '딸남아',
  'Strawberry Picking + Nami Island + Railbike+ Garden of Morning Calm': '딸남레아',

  'Hwadam Botanic Garden + Nami Island + Gangchon Railbike': '화남레',

  '[Oneday] Gimpo': '김포 DMZ',
  'Gimpo DMZ Day Tour': '김포 DMZ',
  'Paju DMZ Day Tour': '파주 DMZ',
  'Paju DMZ Day Tour(Camp Greaves)': '파주 DMZ',
  'Paju DMZ Day Tour(The 3rd Tunnel)': '파주(땅굴) DMZ',
  'Cheorwon DMZ Day Tour': '철원 DMZ',
  '[Oneday] Cheorwon': '철원 DMZ',

  'Korean Folk Village & Suwon Hwaseong Tour': '수원화성투어(EG)',
  'Korean Folk Village Night Festival & Suwon Hwaseong': '야간민속촌',

  'A : Strawberry Farm + Pocheon Art Valley + Herb Island [~03/17]': '포천딸기',
  'B : Strawberry Farm + Pocheon Art Valley + Herb Island [~06/30]': '포천딸기',

  'A: Taejongdae + Gamcheon + Haedong Yonggungsa + Songdo Skywalk': '태감송해',

  'D: Yacht Tour + Haedong Yonggungsa + Gamcheon + Gwanganli Beach': '해감요트',


  'B: Blueline Park Sky Capsule + Gamcheon + Oryukdo Skywalk + Huinnyeoul Village': '감천미포',
  'C: Blueline Park Beach Train + Gamcheon + Haedong Yonggungsa + Songdo Skywalk': '송감송해',

  'Suncheon Bay International Garden Expo Tour[5th Jun - 31st Oct]': '순천만',

  'Gyeongju One Day Tour (1/1~9/30)': '경주',
  'Gyeongju One Day Tour + Haeundae Sky Capsule': '경주캡슐',

  'B: Beginner(Lesson + Lift 8-hour Pass)': '강습 + LIFT',
  'B: Ski+Nami (Lesson+Lift)': '강습 + LIFT',
  'B: Beginner(Lesson+Lift)': '강습 + LIFT',
  'Vivaldi Ski Resort + Nami Island · Ski (Lesson+Lift)': '강습 + LIFT',
  'Vivaldi Ski Resort & Nami Island · Ski (Lesson+Lift)': '강습 + LIFT',
  'Vivaldi Ski Resort & Zoolung Zoolung & Starfield · Ski (Lesson+Lift)': '강습 + LIFT',

  'A: First Timer(Lesson)': '강습',
  'A: Ski+Nami (Lesson)': '강습',
  'Vivaldi Ski Resort + Nami Island · Ski (Lesson)': '강습',
  'Vivaldi Ski Resort & Nami Island · Ski (Lesson)': '강습',

  'C: Sightseeing Only': '관광',
  'Vivaldi Ski Resort & Nami Island · Ski (Sightseeing)': '관광',

  'Sledding Tour': '썰매',
  'Nami & Sled': '썰매',
  'Snowy Land(sled) +Nami': '썰매',
  'Sled': '썰매',
  'Snow sled': '썰매',
  'C: Sled': '썰매',
  'D: Sled': '썰매',
  'Vivaldi Ski Resort & Nami Island · Snowy Land (Sled)': '썰매',

  'A: Ski (Lesson + Lift Single Ride)': 'SKI 강습 + LIFT (1 Time)1',
  'B: Snowboard (Lesson + Lift Single Ride)': 'SNOWBOARD 강습 + LIFT (1 Time)1',
  'C: Sightseeing only': '관광',
  'D: Sightseeing only': '관광',

  'A: Ski (Lesson + Moving walk)': '강습 + MOVING',
  'B: Ski (Lesson + Lift + Moving walk)': '강습 + MOVING + LIFT',

  'Gwang-yang Maehwa & Gurye Sansuyu Festival(From Seoul)': '서울광양구례',
  'Gwang-yang Maehwa & Gurye Sansuyu Festival(From Busan)': '부산광양구례',

  'Gwang-yang Maehwa + Jeonju Hanok Village Day Tour [Departing from Seoul]': '광양전주(서울)',
  'Gwang-yang Maehwa + Gurye Sansuyu Festival [Departing from Busan]': '광양구례(부산)',


  'Seoul Cherry Blossom Random Tour': '서울벚꽃랜덤',
  'Busan Cherry Blossom Random Tour': '부산벚꽃랜덤',
  '[3/27-4/12] Busan Cherry Blossom City Tour': '부산벚꽃랜덤',

  'Jinhae Day Tour from Seoul': '서울진해',
  'Jinhae Day Tour from Busan': '부산진해',
  'Jinhae Night Tour from Busan': '부산진해(야간)',
  'Night Tour [Departing from Busan]': '부산진해(야간)',

  'Alpaca World + Sogeum Mountain Suspension Bridge + Hoengseong Luge': '알출루',
  'A. Romantic Random Cherry Blossom Chasing & Spring Flowers One-day Tour (Departing from Busan)': '벚꽃랜덤(부산)',
  'Gyeongju Unesco World Heritage Site + Busan Sky Capsule': '경주캡슐',
  'E: Yacht Tour + Blueline Sky Capsule + Gamcheon + Haedong Yonggungsa +Cheongsapo': '캡슐요트'
}

export const parseKlookReservationFile = async (files: FileList, findProductNameAndOption: (productPossible: string, optionPossible?: string) => {
  product: string,
  option?: string
} | null) => {
  let parsedData: any[] = [];
  for (let i = 0; i < files.length; i++) {
    parsedData.push(...await parseFileData(files[i]))
  }
  const klook: any = {}
  const names: any = []
  const klookNotRegistered: any = []
  parsedData.forEach((data) => {
    let bookingId = data["Booking Reference ID"]?.toString().trim() || ""
    if (!bookingId) return;
    let tour = data["Activity Name"]?.toString().trim() || ""
    let option = data["Package Name"]?.toString().trim() || ""
    let info = data["Extra Info"]?.toString().trim() || ""
    let name = data["Traveler Name"]?.toString().toLowerCase().trim() || ""
    let number = parseInt(data["Num of Units"])

    let date = data["Participation Time"]?.toString().trim() || ""

    const child = ["Child (3-12)", "Child(3-12)", "Child(3-13)"]


    names.push(name)

    let pickup = ""

    if (info.search("Hongik") !== -1) {
      pickup = "Hongdae"
    } else if (info.search("Myeongdong") !== -1) {
      pickup = "Myungdong"
    } else if (info.search("Yejang") !== -1) {
      pickup = "Myungdong"
    } else if (info.search("Dongdaemun")) {
      pickup = "Dongdaemoon"
    } else {
      pickup = "Unknown"
    }

    let lang = ""
    if (info.toLowerCase().search("english,chinese") !== -1 || info.toLowerCase().search("chinese,english") !== -1) {
      lang = "중국어,영어"
    } else if (info.toLowerCase().search("chinese") !== -1) {
      lang = "중국어"
    } else if (info.toLowerCase().search("english,korean") !== -1 || info.toLowerCase().search("korean,english") !== -1) {
      lang = "중국어,영어"
    } else if (info.toLowerCase().search("chinese,korean") !== -1 || info.toLowerCase().search("korean,chinese") !== -1) {
      lang = "중국어,영어"
    } else if (info.toLowerCase().search("korean") !== -1) {
      lang = "한국어"
    } else if (info.toLowerCase().search("english,japanese") !== -1 || info.toLowerCase().search("japanese,english") !== -1) {
      lang = "중국어,영어"
    } else if (info.toLowerCase().search("japanese") !== -1) {
      lang = "일본어"
    } else {
      lang = "영어"
    }

    let extra = ""
    if (info.search("Private") !== -1) {
      extra = "PV5000"
    }

    let stroller = "X"
    if (info.search("YES") !== -1 || info.search("Yes") !== -1) {
      stroller = "유모차"
    }

    if (option.search("Painters Show") !== -1) {
      extra = `페인터즈${number}`
    }

    if (info.search("Photoshoot") !== -1) {
      const match = /Photoshoot.+:.+(\d{1,2})/gi.exec(info)
      extra = `PHOTOSHOOT${match?.[1]}`
    }


    // const unitName = data["Unit name"]?.toString().trim() || ""
    // if (unitName.search("Capsule") !== -1) {
    //     extra = `CAPSULE${number}`
    // }

    try {
      const found = findProductNameAndOption(tour, option);
      if (found) {
        klook[bookingId] = {
          tour: found.product,
          name,
          note: "note",
          people: number,
          lang,
          extra: option && option.toLowerCase() !== 'ignore' && option.toLowerCase() !== 'no option' && found.option ? `${found.option}${number}` : extra,
          option: option && option.toLowerCase() !== 'ignore' && option.toLowerCase() !== 'no option' && found.option ? `${found.option}${number}` : extra,
          stroller,
        }
      } else if (IN_OPTION.includes(tour)) {
        try {
          klook[bookingId] = {
            tour: TOUR_OPTIONS[option] ?? `투어: ${tour} 옵션: ${option}`,
            name,
            note: "note",
            people: number,
            lang,
            extra,
            stroller,
          }
        } catch (e) {
          klook[bookingId] = {
            tour: `투어: ${tour} 옵션: ${option}`,
            option,
            name,
            note: "note",
            people: number,
            lang,
            extra,
            stroller,
          }
        }
      } else if (SKI_TOUR.includes(TOUR_OPTIONS[tour])) {
        klook[bookingId] = {
          tour: TOUR_NAME[tour] ?? `투어: ${tour} 옵션: ${option}`,
          name,
          note: "note",
          people: number,
          lang,
          option: TOUR_OPTIONS[option] + number,
          extra: TOUR_OPTIONS[option] + number,
          stroller,
        }
      } else if (TOUR_NAME[tour]) {
        klook[bookingId] = {
          tour: TOUR_NAME[tour] ?? `투어: ${tour} 옵션: ${option}`,
          name,
          note: "note",
          lang,
          people: number,
          extra,
          stroller,
        }
      } else {
        klook[bookingId] = {
          tour: `투어: ${tour} 옵션: ${option}`,
          name,
          note: "note",
          people: number,
          lang,
          extra,
          stroller,
        }
        klookNotRegistered.push(`${tour} ${option}`)
      }
    } catch (e) {
      klook[bookingId] = {
        tour: `투어: ${tour} 옵션: ${option}`,
        name,
        note: "note",
        people: number,
        lang,
        extra,
        stroller,
      }
      klookNotRegistered.push(`${tour} ${option}`)
    }

    // KLOOK_notRegistered.txt 파일 없음
  })

  return {
    klook,
    klookNotRegistered,
    names,
  }
}

const parseFileData = async (file: any) => {
  const binary = await file.arrayBuffer()
  const workbook = XLSX.read(binary, {type: "array"})
  const sheets = workbook.Sheets
  const sheetNames = workbook.SheetNames

  const firstSheet = sheets[sheetNames[0]]
  const data = XLSX.utils.sheet_to_json(firstSheet) as any[];


  const aggregated = data.reduce((result, cursor, idx, array) => {
    if (!cursor['Booking Reference ID']) {
      let lastValid = null;
      for (let i = 1; i < idx + 1 && (!lastValid || !lastValid['Booking Reference ID']); i++) {
        lastValid = array[idx - i];
      }
      if (cursor['Unit name']?.toLowerCase().includes('photoshoot')) {
        result[lastValid['Booking Reference ID']]['Extra Info'] += '\n' + cursor['Unit name'] + ': ' + cursor['Num of Units']; // photoshoot은 예외처리
      } else {
        result[lastValid['Booking Reference ID']]['Num of Units'] = Number.parseInt(result[lastValid['Booking Reference ID']]['Num of Units']) + Number.parseInt(cursor['Num of Units']);
      }
    } else if (result[cursor['Booking Reference ID']]) {
      if (cursor['Unit name']?.toLowerCase().includes('photoshoot')) {
        result[cursor['Booking Reference ID']]['Extra Info'] += '\n' + cursor['Unit name'] + ': ' + cursor['Num of Units']; // photoshoot은 예외처리
      } else {
        result[cursor['Booking Reference ID']]['Num of Units'] = Number.parseInt(result[cursor['Booking Reference ID']]['Num of Units']) + Number.parseInt(cursor['Num of Units']);
      }
    } else {
      result[cursor['Booking Reference ID']] = cursor;
    }
    return result;
  }, {} as any)

  return Object.values<any>(aggregated)
}
