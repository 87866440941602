import React, {useState} from 'react';

import dayjs from "dayjs";

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from '@mui/material/IconButton';
import Switch from "@mui/material/Switch";
import Checkbox from '@mui/material/Checkbox';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from "@mui/material/CardContent";

import CircularProgress from '@mui/material/CircularProgress';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';

import {useRead} from '../hooks/realtime';
import {pushRealtime, readRealtime, removeRealtime, setRealtime} from "../hooks/firebase";
import {Balance} from '../models/Balance';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from '@mui/icons-material/Close';
import PlusIcon from '@mui/icons-material/PlusOne';
import {useAuth} from "../hooks/auth";
import {DatePicker} from "@mui/x-date-pickers";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";


export default function (props: { id: string, balance: Balance, onSave: (id: string, balance: Balance) => void, onDelete: (id: string) => void, onClose: (update: boolean) => void }) {
    const {auth} = useAuth();
    const {id, balance: _balance, onSave, onDelete, onClose} = props;
    const [balance, setBalance] = useState<Balance>(_balance);

    const update = (prop: string, value: any) => {
        setBalance((b: Balance) => {
            if (!b) return b;
            return {...b, [prop]: value, updatedAt: new Date().getTime()};
        })
    }

    const handleSave = () => {
        onSave(id, balance);
    }
    const handleDelete = () => {
        onDelete(id);
    }
    const handleChangeBuilder = (prop: string) => (_: any, value: any) => {
        update(prop, value);
    }
    const handleTargetChangeBuilder = (prop: string, middleware: (value: any) => any = f => f) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = middleware(e.target.value);
        update(prop, value);
    }


    return (
        <Modal
            open
            onClose={onClose}
        >
            <Box
                sx={(theme) => ({
                    width: '80vw',
                    maxHeight: '80vh',
                    position: 'relative' as 'relative',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                })}
            >


                <Box sx={{
                    position: 'fixed',
                    display: 'inline-flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    bottom: 0,
                    left: '50%',
                    transform: 'translate(-50%, 125%)',
                    zIndex: 999,
                    backgroundColor: 'white',
                    borderRadius: 5,
                    boxShadow: 2,
                    py: 1,
                    px: 2
                }}>

                    <IconButton onClick={handleSave}>
                        <EditIcon/>
                    </IconButton>
                    <IconButton onClick={handleDelete}>
                        <DeleteIcon/>
                    </IconButton>
                    <IconButton onClick={() => onClose(true)}>
                        <CloseIcon/>
                    </IconButton>
                </Box>

                <Paper
                    sx={(theme) => ({
                        padding: '32px 24px',
                    })}
                >
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xs={12}
                            sx={{
                                position: 'relative',
                            }}
                        >
                            <Box
                                sx={{
                                    position: 'relative',
                                    maxHeight: '75vh',
                                    overflowY: 'auto',
                                }}
                            >

                                <Box
                                    sx={(theme) => ({
                                        borderRadius: 3,
                                        backgroundColor: theme.palette.background.default,
                                        p: 4
                                    })}
                                >

                                    <Grid
                                        container
                                        spacing={4}
                                        component={'form'}
                                    >

                                        <Grid
                                            item
                                            xs={2}
                                        >
                                            <Typography fontWeight={'bold'}>
                                                Date/Currency
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={5}
                                        >
                                            <DatePicker
                                                format={'YYYY-MM-DD'}
                                                value={dayjs(balance?.date)}
                                                onChange={(date) => {
                                                    if (date) {
                                                        setBalance({
                                                            ...balance,
                                                            date: dayjs(date).format('YYYY-MM-DD')
                                                        })
                                                    }
                                                }}
                                                slotProps={{
                                                    // inputAdornment: {
                                                    //     sx:{display:'none'}
                                                    // },
                                                    textField: {
                                                        fullWidth: true,
                                                        sx: {backgroundColor: 'white'},
                                                        label: 'Date',
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Select
                                                fullWidth
                                                variant={'outlined'}
                                                onChange={(e) => {
                                                    setBalance({
                                                        ...balance,
                                                        currency: e.target.value ?? 'KRW'
                                                    })
                                                }}
                                                value={balance?.currency}
                                                sx={{
                                                    '&::before': {
                                                        display: 'none'
                                                    }
                                                }}
                                            >
                                                <MenuItem value={'KRW'}>
                                                    KRW(₩)
                                                </MenuItem>
                                                <MenuItem value={'JPY'}>
                                                    JPY(¥)
                                                </MenuItem>
                                            </Select>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                        >
                                            <Divider/>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={2}
                                        >
                                            <Typography fontWeight={'bold'}>
                                                Card
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={5}
                                        >
                                            <TextField
                                                fullWidth
                                                label={'Card Income'}
                                                value={balance?.card && balance.card >= 0 ? balance.card : ''}
                                                type={"number"}
                                                onChange={(e) => {
                                                    let value = Number(e.target.value);
                                                    value = value > 0 ? value : -value;
                                                    handleChangeBuilder('card')(e, value)
                                                    handleChangeBuilder('cash')(e, null)
                                                }}
                                                InputLabelProps={{shrink: true}}
                                                sx={{backgroundColor: 'white'}}
                                            />

                                        </Grid>
                                        <Grid
                                            item
                                            xs={5}
                                        >
                                            <TextField
                                                fullWidth
                                                label={'Card Expenditure'}
                                                value={balance?.card && balance.card <= 0 ? balance.card : ''}
                                                type={"number"}
                                                onChange={(e) => {
                                                    let value = Number(e.target.value);
                                                    value = value > 0 ? -value : value;
                                                    handleChangeBuilder('card')(e, value)
                                                    handleChangeBuilder('cash')(e, null)
                                                }}
                                                InputLabelProps={{shrink: true}}
                                                sx={{backgroundColor: 'white'}}
                                            />

                                        </Grid>
                                        <Grid
                                            item
                                            xs={2}
                                        >
                                            <Typography fontWeight={'bold'}>
                                                Cash
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={5}
                                        >
                                            <TextField
                                                fullWidth
                                                label={'Cash Income'}
                                                value={balance?.cash && balance.cash >= 0 ? balance.cash : ''}
                                                type={"number"}
                                                onChange={(e) => {
                                                    let value = Number(e.target.value);
                                                    value = value > 0 ? value : -value;
                                                    handleChangeBuilder('cash')(e, value)
                                                    handleChangeBuilder('card')(e, null)
                                                }}
                                                InputLabelProps={{shrink: true}}
                                                sx={{backgroundColor: 'white'}}
                                            />

                                        </Grid>
                                        <Grid
                                            item
                                            xs={5}
                                        >
                                            <TextField
                                                fullWidth
                                                label={'Cash Expenditure'}
                                                value={balance?.cash && balance.cash <= 0 ? balance.cash : ''}
                                                type={"number"}
                                                onChange={(e) => {
                                                    let value = Number(e.target.value);
                                                    value = value > 0 ? -value : value;
                                                    handleChangeBuilder('cash')(e, value)
                                                    handleChangeBuilder('card')(e, null)
                                                }}
                                                InputLabelProps={{shrink: true}}
                                                sx={{backgroundColor: 'white'}}
                                            />

                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                        >
                                            <Divider/>
                                        </Grid>


                                        <Grid
                                            item
                                            xs={2}
                                        >
                                            <Typography fontWeight={'bold'}>
                                                Detail
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={10}
                                        >
                                            <TextField
                                                fullWidth
                                                label={'title'}
                                                value={balance?.title ?? ''}
                                                onChange={handleTargetChangeBuilder('title')}
                                                InputLabelProps={{shrink: true}}
                                                sx={{backgroundColor: 'white'}}
                                            />

                                        </Grid>
                                        <Grid
                                            item
                                            xs={2}
                                        />
                                        <Grid
                                            item
                                            xs={10}
                                        >
                                            <TextField
                                                fullWidth
                                                multiline
                                                minRows={3}
                                                label={'detail'}
                                                value={balance?.detail ?? ''}
                                                onChange={handleTargetChangeBuilder('detail')}
                                                InputLabelProps={{shrink: true}}
                                                sx={{backgroundColor: 'white'}}
                                            />

                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </Modal>
    )
}