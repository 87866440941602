export default async function copy(text:string, onCopy?:(text:string)=>void):Promise<void> {
  try {
    await copyToClipboard(text);
  } catch (e) {
    prompt('아래 정보를 복사해주세요', text);
  }
  onCopy?.(text);
};

export async function copyToClipboard(text:string){
  try {
    await navigator.clipboard.writeText(text);
  } catch (e) {
    let textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed¬';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    textArea.setSelectionRange?.(0, 99999);
    document.execCommand('copy');
    document.body.removeChild(textArea);
  }
}