import React from "react"
import ReactDOM from "react-dom/client"

// PWA
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"
import reportWebVitals from "./reportWebVitals"

import App from "./App"
import theme from "./theme"
import CssBaseline from "@mui/material/CssBaseline"
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs"
import {ThemeProvider} from "@mui/material/styles"
import {AuthProvider} from "./hooks/auth"
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider"
import {SnackbarProvider} from "notistack"
import {DrawerContextProvider} from "./context/drawer"
import {BaseProvider} from "./hooks/base";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <ThemeProvider theme={theme}>
        <CssBaseline/>
        <AuthProvider>
            <BaseProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DrawerContextProvider>
                        <SnackbarProvider maxSnack={3}>
                            <App/>
                        </SnackbarProvider>
                    </DrawerContextProvider>
                </LocalizationProvider>
            </BaseProvider>
        </AuthProvider>
    </ThemeProvider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()
reportWebVitals(console.log)