import React, {useState} from "react";
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import PlusIcon from "@mui/icons-material/PlusOne";
import {useRead} from "../../hooks/realtime";
import {escapeEmail} from "../../utils/textUtils";


export default function () {
    const [loadKey, setLoadKey] = useState<string>(Date.now + '');
    const {
        data,
        update,
        updates,
        setData,
        put,
        save
    } = useRead<{ [key: string]: string }>('spam', loadKey);
    return (
        <Box>
            <Stack
                gap={1}
            >
                <Box
                    display={'flex'}
                    flexDirection={'row-reverse'}
                    pb={2}
                >
                    <Button variant={'contained'} onClick={() => {
                        const updates = Object.fromEntries(Object.values(data ?? {}).filter(email=>email && email.length >0).map((email) => [escapeEmail(email), email]))
                        put(updates)
                            .then(() => {
                                setLoadKey(Date.now()+'');
                            })
                            .catch((e) => {
                                alert('Error on save')
                            });
                    }}>
                        Save
                    </Button>
                </Box>
                <Button
                    fullWidth
                    style={{
                        backgroundColor: 'white',
                    }}
                    variant={'outlined'}
                    color={'primary'}
                    onClick={(e) => {
                        const newArray = ['',...(Object.values(data ?? {}) ?? [])];
                        setData(Object.fromEntries(newArray.map((s, i) => [i + '', s])));
                    }}
                >
                    <PlusIcon/>
                </Button>
                {
                    (Object.values(data ?? {})).map((p, i, array) => (
                        <TextField
                            key={'spam' + i}
                            fullWidth
                            style={{
                                backgroundColor: 'white'
                            }}
                            value={p}
                            onChange={(e) => {
                                const value = e.target.value;
                                const newArray = [...array]
                                newArray[i] = value;
                                setData(Object.fromEntries(newArray.map((s, i) => [i + '', s])));
                            }}
                        />
                    ))
                }
            </Stack>
        </Box>
    )
}