import * as XLSX from "xlsx"

const IN_OPTIONS: any = {
  "48881P135": {Nami: "딸남어", Sled: "딸썰어"},
  "48881P65": {Nami: "레고남이", Railbike: "레고레일"},
  "48881P41": {"Railbike only": "딸남레", Garden: "딸남레아"},
  "48881P2": {"Without Yacht": "태감송해", "With Yacht": "해감요트", _: '태감송해'},
  "48881P165": {
    "All Inclusive": "알레남아",
    "with Garden": "알남아",
    "with Railbike": "알남레",
    "Alpaca+Nami": "알남",
  },
}

const TOUR_NAME: any = {
  "48881P173": "알레고",
  "48881P172": "김포 DMZ",
  "48881P170": "감천미포",
  "48881P169": "파주 DMZ",
  "48881P167": "광장시장",
  "48881P160": "남이섬셔틀",
  "48881P138": "BTS",
  "48881P134": "딸남아",
  "48881P27": "해감요트",
  "48881P109": "철원 DMZ",
  "48881P100": "이월드",
  "48881P93": "포천딸기",
  "48881P43": "경주",
  "48881P39": "지산",
  "48881P37": "에덴",
  "48881P9": "남쁘",
  "48881P82": "수원화성투어(EG)",
  "48881P61": "알루산",
  "48881P60": "남설",
  "48881P6": "전주",
  "48881P59": "남아",
  "48881P5": "남쁘아",
  "48881P40": "남이비발디",
  "48881P4": "통영",
  "48881P30": "엘리시안",
  "48881P29": "남이엘리시안",
  "48881P20": "에버",
  "48881P19": "외도",
  "48881P13": "설낙",
  "48881P12": "쁘남레아",
  "48881P11": "레남아",
  "48881P10": "레남쁘",
  '48881P95': '부산진해',
  '48881P99': '경주벚꽃',
  '48881P94': '서울진해',
  '48881P97': '벚꽃랜덤(서울)',
  '48881P171': '송감송해'
}

export const parseViatorReservationFile = async (files: FileList, findProductNameAndOption: (productPossible: string, optionPossible?: string) => {
  product: string,
  option?: string
} | null) => {
  let parsedData: any[] = [];
  for (let i = 0; i < files.length; i++) {
    parsedData.push(...await parseFileData(files[i]))
  }
  const viatorNotRegistered: any = []
  const viator: any = {}
  parsedData.filter((data) => data['Status'] !== 'Cancelled').forEach((data) => {
    let bookingId = data["Booking Reference"]?.toString().trim() || ""
    let tour = data["Product Code"]?.toString().trim() || ""
    let productName = data['Product Name']?.toString().trim() || ''
    let option = data["Tour Grade Title"]?.toString().trim() || ""
    let number = parseInt(data["Number of Passengers"])
    let phone = data["Lead traveler Contact Info"]?.toString().trim() || ""
    let language = data["Tour Language"]?.toString().trim() || ""


    let extra = ""
    let pickup = ""

    let lang = ""
    if (language.toLowerCase().search("chinese") !== -1) {
      lang = "중국어"
    } else if (language.toLowerCase().search("korean") !== -1) {
      lang = "한국어"
    } else if (language.toLowerCase().search("japanese") !== -1) {
      lang = "일본어"
    } else {
      lang = "영어"
    }


    if (option.search("Hongik") !== -1 || option.search('å¸¸è§_å¼çå¤§å­¦ç«åºå') !== -1) {
      pickup = "Hongdae"
    } else if (option.search("Myeongdong") !== -1 || option.search('ëªë') !== -1) {
      pickup = "Myungdong"
    } else if (option.search("Dongdaemun") !== -1 || option.search("DDP") !== -1 || option.search("DHCP") !== -1) {
      pickup = "Dongdaemoon"
    } else if (option.search("Jamsil") !== -1) {
      pickup = "Jamsil"
    } else if (option.search("Jongno") !== -1 || option.search("Gwangjang") !== -1) {
      pickup = "Jongno"
    } else if (
        option.search("Busan Station") !== -1 ||
        option.search("Busan train") !== -1 ||
        option.search("KTX") !== -1 ||
        option.search("釜山站") !== -1
    ) {
      pickup = "Busan Station"
    } else if (option.search("Seomyeon") !== -1 || option.search("서면역") !== -1) {
      pickup = "Seomyun"
    } else if (option.search("Haeundae") !== -1 || option.search('海云台') !== -1 || option.search('í´ì´ëì­ìì ì¶ë°') !== -1 || option.search('æµ·é²å°é§åºç') !== -1) {
      pickup = "Haeundae"
    } else {
      pickup = option;
    }

    if (option.search("Sky Capsule") !== -1) {
      extra = `CAPSULE ${number}`
    }

    try {
      const found = findProductNameAndOption(tour, option) || findProductNameAndOption(`${tour}(${productName})`, option);
      if (found) {
        viator[bookingId] = {
          tour: found.product,
          people: number,
          pickup,
          lang,
          extra: option && option.toLowerCase() !== 'ignore' && option.toLowerCase() !== 'no option' && found.option ? `${found.option}${number}` : extra,
          option: option && option.toLowerCase() !== 'ignore' && option.toLowerCase() !== 'no option' && found.option ? `${found.option}${number}` : extra,
          origin: {
            productName:tour, option,
          }
        }
      } else if (Object.keys(IN_OPTIONS).includes(tour)) {
        try {
          const options = Object.keys(IN_OPTIONS[tour])
          let title = IN_OPTIONS[tour]?._ || '';
          for (let i = 0; i < options.length; i++) {
            if (option.toString().includes(options[i])) {
              title = IN_OPTIONS[tour][options[i]]
              break
            }
          }
          viator[bookingId] = {
            tour: title || `투어: ${tour}(${productName}) 옵션: ${option}`,
            people: number,
            pickup,
            extra,
            lang,
            phone, origin: {
              productName:tour, option,
            }
          }
        } catch (e) {
          viator[bookingId] = {
            tour: `투어: ${tour}(${productName}) 옵션: ${option}`,
            people: number,
            pickup,
            extra,
            lang,
            phone, origin: {
              productName:tour, option,
            }
          }
          viatorNotRegistered.push(`투어: ${tour}(${productName}) 옵션: ${option}`,)
        }
      } else {
        viator[bookingId] = {
          tour: TOUR_NAME[tour] || `투어: ${tour}(${productName}) 옵션: ${option}`,
          people: number,
          pickup,
          extra,
          lang,
          phone, origin: {
            productName:tour, option,
          }
        }
      }
    } catch (e) {
      viator[bookingId] = {
        tour: `투어: ${tour}(${productName}) 옵션: ${option}`,
        people: number,
        pickup,
        extra,
        lang,
        phone, origin: {
          productName:tour, option,
        }
      }

      viatorNotRegistered.push(`투어: ${tour}(${productName}) 옵션: ${option}`,)
    }
  })
  return {
    viator,
    viatorNotRegistered,
  }
}

const parseFileData = async (file: any) => {
  const binary = await file.arrayBuffer()
  const workbook = XLSX.read(binary, {type: "array"})
  const sheets = workbook.Sheets
  const sheetNames = workbook.SheetNames

  const firstSheet = sheets[sheetNames[0]]
  const data = XLSX.utils.sheet_to_json(firstSheet) as any[]
  return data
}
