import * as XLSX from "xlsx";
import {
  AgencyFileDatum, CtripAggregation, CtripFileDatum,
  KintFileDatum,
} from "./types";


class CTripDatum {
  rows: CtripFileDatum[];
  refunded: CtripFileDatum[];
  reservation: CtripFileDatum[];
  representativeRow: CtripFileDatum;

  constructor(rows: CtripFileDatum[]) {
    this.rows = rows;
    this.refunded = rows.filter(({price}) => price < 0);
    this.reservation = rows.filter(({price}) => price >= 0);
    this.representativeRow = this.reservation.filter((reservation) => reservation.number > 0).at(0) ?? this.rows[0];
  }

  get people(): number {
    return this.representativeRow.number;
  }

  get price(): number {
    return this.rows.map(r => r.price).reduce((a, b) => a + b, 0);
  }

  toAgencyDatum(): AgencyFileDatum {
    return ({
      tour: this.representativeRow.tour,
      option: this.representativeRow.option,
      date: this.representativeRow.date,
      agency: 'TPC',
      agencyCode: this.representativeRow.agencyCode,
      people: this.representativeRow.number,
      price: this.price,
      unitPrice: this.price / (this.representativeRow.number ?? 0),
    })
  }

}


function cleanString(data?: any): string {
  return data?.toString().trim() || ''
}

function toNumber(numberLike?: string | number) {
  if (typeof numberLike === 'number') return numberLike;
  return Number(cleanString(numberLike) || 0);
}


export default async function parseCtripFile(file: Blob | File): Promise<AgencyFileDatum[]> {
  const parsed = await parseFileData(file);
  const rows = parsed.map((raw) => {
    const agency = 'TPC';
    const agencyCode = cleanString(raw['트립닷컴 예약번호']);
    const date = formatDate(raw['예약일'])
    const tour = cleanString(raw['상품 정보']);
    const option = cleanString(raw['리소스 이름']);
    const number = toNumber(raw['이용한 수량'])
    const price = toNumber(raw['정산 금액']);
    return ({
      agency,
      agencyCode,
      date,
      tour,
      option,
      number,
      price
    })
  })


  const aggregationByAgencyCode = rows.reduce((result, row) => {
    if (!(row.agencyCode in result)) {
      result[row.agencyCode] = []
    }
    result[row.agencyCode].push(row);
    return result;
  }, {} as CtripAggregation);

  return Object.entries(aggregationByAgencyCode)
    .map(([code, rows]) => {
      if (rows.length === 1) return [code, rows];
      const refunded = rows.filter(({price}) => price < 0)
      const others = rows.filter(({price}) => price >= 0);
      const refundedTotalOriginalPrice = refunded.map((row) => row.price).reduce((a, b) => a + b, 0)
      const othersTotalOriginalPrice = others.map((row) => row.price).reduce((a, b) => a + b, 0)
      if (refundedTotalOriginalPrice + othersTotalOriginalPrice === 0) return [code, []];
      return [code, rows];
    })
    .filter(([_, rows]) => rows.length > 0)
    .map(([key, rows]) => new CTripDatum(rows as CtripFileDatum[]).toAgencyDatum())
}


const parseFileData = async (file: Blob | File) => {
  const binary = await file.arrayBuffer()
  const workbook = XLSX.read(binary, {type: "array", raw: true})
  const sheets = workbook.Sheets
  const sheetNames = workbook.SheetNames

  const secondaSheet = sheets[sheetNames[1]]
  const data = XLSX.utils.sheet_to_json(secondaSheet, {range: 0}) as any[]
  return data
}


function formatDate(yyyymmdd:string){
  const matches = /\d{4}.\d{2}.\d{2}/gi.exec(yyyymmdd);
  const yyyy = matches?.[1] ?? '';
  const mm = matches?.[2] ?? '';
  const dd = matches?.[3] ?? '';
  return `${yyyy}-${mm}-${dd}`;
}