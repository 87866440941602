import { Box } from "@mui/material"
import User from "./User"
import TabPanel from "../../ui/TabPanel"
import {IOperator, IUser} from "../../../types/chat"

interface Props {
    index: number
    value: number
    users: IUser[]
    participants: IUser[]
    handleToggleSelectUser: (user: IUser) => void
}

export default function TabPanelUser({ index, value, participants, users, handleToggleSelectUser }: Props) {
    return (
        <>
            <TabPanel index={index} value={value}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                    }}
                >
                    {users.map((user: any, index: number) => (
                        <>
                            <User
                                key={index}
                                isSelected={!!participants.find((participant) => participant.id === user.id)}
                                user={user}
                                onClick={() => handleToggleSelectUser(user)}
                            />
                        </>
                    ))}
                </Box>
            </TabPanel>
        </>
    )
}
