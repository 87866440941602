import * as XLSX from "xlsx";
import product from "../../settings/product";
import {KintPriceDatum} from "./types";

function cleanString(data?: any): string {
  return data?.toString().trim() || ''
}

function toNumber(numberLike?: string | number) {
  if (typeof numberLike === 'number') return numberLike;
  return Number(cleanString(numberLike) || 0) || 0;
}

const parseFileData = async (file: Blob | File) => {
  const binary = await file.arrayBuffer()
  const workbook = XLSX.read(binary, {type: "array"})
  const sheets = workbook.Sheets
  const sheetNames = workbook.SheetNames.slice(0, 3);
  return sheetNames.map((sheetName) => XLSX.utils.sheet_to_json(sheets[sheetName]) as any[]).flat(1)
}


export async function parseKintPriceFile(file: Blob | File): Promise<KintPriceDatum[]> {
  const parsed = await parseFileData(file);
  const generated: { [productName: string]: KintPriceDatum } = {};
  let cursor: KintPriceDatum | null = null;
  for (let i = 0; i < parsed.length; i++) {
    const row = parsed[i];
    const tour = row['투어명'];
    if (tour) {
      if (cursor) {
        generated[cursor.product] = (cursor);
      }
      cursor = {
        product: row['투어명'].replace(/\n/gi, ' '),
        guide: 0,
        driver: 0,
        direct: 0,
        klook: {adult: 0, kid: 0},
        ctrip: {adult: 0, kid: 0},
        others: {adult: 0, kid: 0},
        viator: {adult: 0, kid: 0},
        othersUSD: {adult: 0, kid: 0},
      };
    }
    if (!cursor) continue;
    if (row['가이드 일급']) {
      cursor['guide'] = toNumber(row['가이드 일급']);
    }
    if (row['운전만 일급']) {
      cursor['driver'] = toNumber(row['운전만 일급']);
    }
    if (row['Klook'] && row['__EMPTY']) {
      cursor['klook']['kid'] = toNumber(row['__EMPTY']);
    }
    if (row['Klook']) {
      cursor['klook']['adult'] = toNumber(row['Klook']) || cursor['klook']['kid']; // 뒷자리에 들어간 경우가 있음
    }
    const othersKey = Object.keys(row).find(key => key.startsWith('기타 Agency'));
    if (othersKey && row[othersKey]) {
      cursor['others']['adult'] = toNumber(row[othersKey]);
    }
    if (othersKey && row[othersKey] && row['__EMPTY_1']) {
      cursor['others']['kid'] = toNumber(row['__EMPTY_1']);
    }
    if (row['현장결제\n' + '금액']) cursor['direct'] = toNumber(row['현장결제\n' + '금액']);
  }
  return Object.values(generated);
}
