import React, {createContext, useContext, useState} from "react";

interface SearchContextValue {
    keyword: string | null
    setKeyword: (keyword: string | null) => void
}

export const createSearchContext = () => {
    const SearchContext = createContext<SearchContextValue>({
        keyword: null,
        setKeyword: (f: string | null) => {
        }
    })

    const useSearch = () => useContext(SearchContext)

    const SearchProvider: React.FC<{ children: React.ReactNode, keyword: string | null, setKeyword: (f: string | null) => void }> =
        ({children, keyword, setKeyword}) => {
            return <SearchContext.Provider value={{keyword, setKeyword: (keyword: string | null) => setKeyword(keyword)}}>
                {children}
            </SearchContext.Provider>
        }


    return ({
        SearchContext,
        SearchProvider,
        useSearch
    })
}
