import React, {useState} from 'react';

import Box from '@mui/material/Box';
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Agency from "./agency";
import Spam from "./spam";
import Pickups from "./pickups";
import Product from "./product";
import User from "./user";
import {useAuth} from "../../hooks/auth";

const TABS = [
    {label:'spam', partial:Spam},
    {label:'pickups', partial:Pickups},
    {label:'agency', partial:Agency},
    {label:'product', partial:Product},
    {label:'user', partial:User},
];
export default function () {
    const [tabIdx, setTabIdx] = useState(0);
    const Partial = TABS[tabIdx].partial;

    return (
        <>
            <Stack
                sx={{
                    px:8
                }}
                gap={4}
            >
                <Tabs value={tabIdx} onChange={(_, tab)=>setTabIdx(tab)}>
                    {
                        TABS.map((tab, idx)=>(
                            <Tab key={tab.label} label={tab.label.toUpperCase()} value={idx}/>
                        ))
                    }
                </Tabs>
                <Partial/>
            </Stack>
        </>
    )
}