import * as XLSX from "xlsx"

const IN_OPTIONS: any = [
    "Nami Island + Garden of Morning Calm / Petite France + Italian Village 1 Day Tour",
    "Nami Island + Gangchon Rail Bike + Garden of Morning Calm / Petite France + Italian Village 1 Day Tour",
    "Alpaca World + Nami Island (+ Rail Bike/ Garden of Morning Calm) 1 Day Tour - from Seoul",
    "LEGOLAND & Gangchon Rail Bike/Nami Island/Alpaca World 1 Day Tour",
    "Paju / Gimpo / Cheorwon DMZ 1 Day Tour - from Seoul",
    "Busan City 1 Day Tour (+ Yacht Tour/Sky Capsule/Beach Train option)",
    "Busan City 1 Day Tour (+ Sky Capsule/Beach Train option)",
    "Busan City 1 Day Tour (+ Yacht Tour/Sky Capsule option)",
    "Korean Folk Village & Suwon Hwaseong Fortress 1 Day Tour - from Seoul",
    "Korea Autumn/Fall Foliage 1 Day Tour to Mountains & National Parks - from Seoul (Oct 10~Nov 15)",
    "Korea Autumn/Fall Foliage 1 Day Tour to Mountains & National Parks - from Busan (Oct 23~Nov 15)",
    "Strawberry Picking + Winter Sled/Nami Island + Eobi Valley 1 Day Tour - from Seoul (Dec 18~Feb 28)",
    "Gyeongju 1 Day Tour (+ Busan Sky Capsule option) from Busan",
    "Seoul Vicinity: Nami Island + Garden of Morning Calm + Strawberry Picking (+ Alpaca World Option)",


    'Seoul Vicinity: Nami Island + Gangchon Rail Bike + Strawberry Picking (+ Garden of Morning Calm) 1 Day Tour',
    'Spring Special: Jinhae Cherry Blossom 1 Day Tour - from Seoul/Busan (Mar 22~Apr 7)',
    'Seoul Vicinity: Nami Island + Garden of Morning Calm + Strawberry Picking (+ Alpaca World Option) 1 Day Tour',
    'Seoul Vicinity: Pocheon Art Valley + Pocheon Herb Island + Strawberry/Apple Farm 1 Day Afternoon Tour',
    'Spring Special: Jinhae Cherry Blossom 1 Day Tour - from Seoul/Busan (Mar 22~Apr 10)'
]

const TOUR_NAME: any = {
    'Spring Special: Daegu E-World Cherry Blossom Tour - from Busan (Mar 25~Apr 7': "이월드벚꽃",
    'Busan Highlights: Must-Visit Photo Spots 1 Day Tour': '특공대(부산)',
    'Spring Special: Cherry Blossom 1 Day Tour (Busan ↔ Vicinities, Mar 25~Apr 7)': '벚꽃랜덤(부산)',
    'Spring Special: Cherry Blossom 1 Day Tour (Seoul ↔ Vicinities, Mar 29~Apr 14)': '벚꽃랜덤(서울)',
    "Seoul Vicinity: Nami Island + Petite France + Italian Village + Garden of Morning Calm Day Tour": "남쁘아",
    "Seoul Vicinity Tour 5 in 1: Nami Island + Petite France + Italian Village + Rail Park + Garden of Morning Calm": "쁘남레아",
    "Seoraksan National Park & Naksansa Temple 1 Day Tour": "설낙",
    "Seoraksan National Park & Nami Island 1 Day Tour": "남설",
    "Everland Full Day Package": "에버",
    "Nami Island Shuttle Bus From Seoul": "남이섬셔틀",
    "Jeonju Hanok Village 1 Day Join-in Tour": "전주",
    "ARMY Tour: Explore Seoul Through the Eyes of BTS": "BTS",
    "Alpaca World + Hoengseong Luge + Museum SAN 1 Day Tour - from Seoul (Aug~Nov)": "알루산",

    "Tongyeong 1 Day Small Group Tour (from Busan)": "통영",
    "Oedo Island Botanical Garden 1 Day Tour from Busan": "외도",
    "Daegu E-World & 83 Tower Observatory 1 Day Tour - from Busan": "이월드",

    "Strawberry Picking + Cheongyang Alps Village (+ Snow Sledding option) 1 Day Tour - from Seoul (Jan 2~Feb 29)":
        "딸알프스",

    "Seoul Forest + Incheon Grand Park + Haneul Park Autumn/Fall Foliage 1 Day Tour - from Seoul (Oct 21~31)":
        "인스타가을",

    "Korea Autumn/Fall Foliage 1 Day Tour (Seoul ↔ Vicinities, Oct 21~31)": "단풍랜덤",
    "Korea Autumn/Fall Foliage 1 Day Tour (Busan ↔ Vicinities, Oct 28~Nov 15)": "단풍랜덤부산",
    "Yeongnam Alps Gondola Autumn/Fall Foliage 1 Day Tour - from Busan (Oct 30~Nov 15)": "영남알프스",

    "Lotte Duty Free All-Night Party in Lotte World (Oct 14 & 21)": "롯데올나잇",
}

const TOUR_OPTIONS: any = {
    'Nami + Rail Bike + Garden of Morning Calm': '레남아',
    'Nami Island + Gangchon Rail Bike + Strawberry Picking': '딸남레',
    "Strawberry + Nami Island + Garden of Morning Calm": "딸남아",
    "Strawberry + Nami Island + Garden of Morning Calm + Alpaca World": "알딸남아",
    'Nami Island + Garden of Morning Calm': '남아',
    'Nami Island + Petite France + Italian Village': '남쁘',

    "Alpaca World + Nami Island + Rail Bike + Garden of Morning Calm": "알레남아",
    "Alpaca World + Nami Island + Rail Bike": "알남레",
    "Alpaca World + Nami Island + Garden of Morning Calm": "알남아",
    "Alpaca World + Nami Island": "알남",

    "Nami + Rail Bike + Petite France + Italian Village": "레남쁘",


    "LEGOLAND & Gangchon Rail Bike": "레고레일",
    "LEGOLAND & Nami Island": "레고남이",
    "LEGOLAND & Alpaca World": "알레고",

    "DMZ 1 Day Tour in Paju: 3rd Tunnel": "파주(땅굴)DMZ",
    "Paju DMZ Tour: Camp Greaves": "파주DMZ",
    "Gimpo DMZ Tour": "김포DMZ",
    "DMZ 1 Day Tour in Cheorwon": "철원DMZ",

    "Strawberry Picking + Winter Sled + Eobi Valley": "딸썰어",
    "Strawberry Picking + Nami Island + Eobi Valley": "딸남어",

    "Gyeongju 1 Day Tour": "경주",
    "Gyeongju + Busan Sky Capsule 1 Day Tour": "경주캡슐",

    "Course A": "태감송해",
    "Course B": "해감요트",
    "Course C": "감천미포",
    "Course D": "시티미포",
    'Course C - From Busan Station': '감천미포',

    Odaesan: "오대산",
    Naejangsan: "내장산",
    Seoraksan: "설악산단풍",
    Daedunsan: "대둔산",

    "Evening Festival Tour": "야간민속촌",
    "Day Tour": "수원화성투어(EG)",
    'Pocheon + Strawberry Farm': '포천딸기'
}

const JINHAE_OPTIONS: any = {
    '(Morning) From Busan': '부산진해',
    '(Afternoon) From Busan': '부산진해(야간)',
    'From Seoul': '서울진해',
}

export const parseTrazyReservationFile = async (files: FileList, findProductNameAndOption: (productPossible: string, optionPossible?: string) => { product: string, option?: string } | null) => {
    let parsedData: any[] = [];
    for (let i = 0; i < files.length; i++) {
        parsedData.push(...await parseFileData(files[i]))
    }
    const trazyNotRegistered: any = []
    const trazy: any = {}
    parsedData.forEach((data) => {
        let bookingId = data["Reservation Code"]?.toString().trim() || ""
        let tour = data["Activity"]?.toString().trim() || ""
        let option = data["Option Name"]?.toString().trim() || ""
        let requiredInfo = data["Required Information"]?.toString().trim() || ""
        let number = parseInt(data["Quantity"])

        let extra = ""
        let pickup = ""

        let lang = "영어"
        // if (requiredInfo.toLowerCase().search("chinese") !== -1) {
        //     lang = "중국어"
        // } else if (requiredInfo.toLowerCase().search("korean") !== -1) {
        //     lang = "한국어"
        // } else if (requiredInfo.toLowerCase().search("japanese") !== -1) {
        //     lang = "일본어"
        // } else {
        //     lang = "영어"
        // }

        if (option.search("Hongik") !== -1) {
            pickup = "Hongdae"
        } else if (option.search("Myeongdong") !== -1) {
            pickup = "Myungdong"
        } else if (option.search("Dongdaemun") !== -1) {
            pickup = "Dongdaemoon"
        } else if (option.search("Jamsil") !== -1) {
            pickup = "Jamsil"
        } else if (option.toLowerCase().search("busan station") !== -1 || option.toLowerCase().search("busan train") !== -1) {
            pickup = "Busan Station"
        } else if (option.search("Seomyeon") !== -1) {
            pickup = "Seomyun"
        } else if (option.search("Haeundae") !== -1) {
            pickup = "Haeundae"
        }

        // if (option.search("Course C") !== -1) {
        //     extra = `CAPSULE ${number}`
        // }

        try {

            const found = findProductNameAndOption(tour, option);
            if (found) {
                trazy[bookingId] = {
                    tour: found.product,
                    people: number,
                    pickup,
                    lang,
                    extra: option && option.toLowerCase() !== 'ignore' && option.toLowerCase() !== 'no option' && found.option ? `${found.option}${number}` : extra,
                    option: option && option.toLowerCase() !== 'ignore' && option.toLowerCase() !== 'no option' && found.option ? `${found.option}${number}` : extra,
                }
            }

            else if (IN_OPTIONS.includes(tour)) {
                try {
                    let title =
                        tour.toLowerCase().includes('jinhae')
                            ? Object.entries(JINHAE_OPTIONS).filter(([key]) => option.includes(key))[0]?.[1]
                            : Object.entries(TOUR_OPTIONS).filter(([key]) => option.includes(key))[0]?.[1];

                    trazy[bookingId] = {
                        tour: title || `투어: ${tour} 옵션: ${option}`,
                        people: number,
                        pickup,
                        lang,
                        extra,
                    }
                } catch (e) {
                    console.log(e);
                    trazy[bookingId] = {
                        tour: `투어: ${tour} 옵션: ${option}`,
                        people: number,
                        pickup,
                        lang,
                        extra,
                    }
                    trazyNotRegistered.push(option)
                }
            } else {
                trazy[bookingId] = {
                    tour: TOUR_NAME[tour] || `투어: ${tour} 옵션: ${option}`,
                    people: number,
                    pickup,
                    lang,
                    extra,
                }
            }
        } catch (e) {
            trazy[bookingId] = {
                tour: `투어: ${tour} 옵션: ${option}`,
                people: number,
                pickup,
                lang,
                extra,
            }

            trazyNotRegistered.push(`${tour} ${option}`)
        }
    })

    // TRAZY_notRegistered.txt 파일이 없음

    return {
        trazy,
        trazyNotRegistered,
    }
}

const parseFileData = async (file: any) => {
    const binary = await file.arrayBuffer()
    const workbook = XLSX.read(binary, {type: "array"})
    const sheets = workbook.Sheets
    const sheetNames = workbook.SheetNames

    const firstSheet = sheets[sheetNames[0]]
    const data = XLSX.utils.sheet_to_json(firstSheet) as any[]
    return data
}
