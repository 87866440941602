import React, {useLayoutEffect} from 'react';
import {useAuth} from "../hooks/auth";
import Button from '@mui/material/Button';

export default function Auth() {
    const authContext = useAuth();
    return (
        !(authContext.user?.email)
            ? (
                <Button
                    sx={{
                        fontSize: '1.125rem',
                        fontWeight:'medium',
                    }}
                    onClick={authContext.login}
                    disabled={!!authContext.user}
                    color={'inherit'}
                >
                    Login
                </Button>
            )
            : (
                <Button
                    sx={{
                        fontSize: '1.125rem',
                        fontWeight:'medium'
                    }}
                    onClick={authContext.logout}
                    disabled={!authContext.user}
                    color={'inherit'}
                >
                    {authContext.user?.email}
                </Button>
            )

    )
}