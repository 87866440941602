import React, {useCallback, useState} from 'react';

import Box from '@mui/material/Box';
import Chip from "@mui/material/Chip";
import {
    DataGridPro, GridActionsCellItem, GridColDef, GridRowSelectionModel, GridToolbarQuickFilter
} from "@mui/x-data-grid-pro";

import {Balance} from '../../models/Balance';
import {useRead, useReadPropValueStartEndAt} from "../../hooks/realtime";

import {GridEventListener} from "@mui/x-data-grid/models/events";
import Button from "@mui/material/Button";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import ButtonBase from "@mui/material/ButtonBase";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import dayjs, {Dayjs} from "dayjs";
import {DateRange, DateRangePicker, SingleInputDateRangeField} from "@mui/x-date-pickers-pro";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import DateTabs from "../../components/DateTabs";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from '@mui/icons-material/Close';
import CreateAccountModal from "../../partials/CreateAccountModal";
import StarIcon from "@mui/icons-material/Star";
import StarOutlinedIcon from "@mui/icons-material/StarOutline";
import {callFunction, getCurrentUser, readWithKeyStartEndAt, removeRealtime} from "../../hooks/firebase";
import Modal from "@mui/material/Modal";
import {Operation, Tour} from "../../models/Operation";
import {downloadObjectAsJson} from "../../utils/utils";
import {User} from "../../models/User";
import CircularProgress from "@mui/material/CircularProgress";
import Switch from "@mui/material/Switch";
import {Reservation} from "../../models/Reservation";
import writeXlsxFile from "write-excel-file";
import AccountModal from "../../partials/AccountModal";
import {useAuth} from "../../hooks/auth";
import CardHeader from "@mui/material/CardHeader";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";


type BalanceRow = {
    id: string,
    type: 'CARD' | 'CASH'
    card: number,
    cash: number,
    category: string,
    currency: string,
    date: string,
    detail: string,
    identifier: string,
    timestamp: string,
    title: string,
    writer: string,
    area: string


}

function DateRangePickerValue(props: { dateRange: DateRange<Dayjs>, onChange: (dr: DateRange<Dayjs>) => void }) {
    const {dateRange: value, onChange: setValue} = props;
    return (

        <DateRangePicker
            format={'YY-MM-DD'}
            slots={{field: SingleInputDateRangeField}}
            value={value}
            onChange={(newValue) => setValue(newValue)}
        />
    );
}

export default function () {
    const [selectedBalanceRow, setSelectedBalanceRow] = useState<BalanceRow | null>(null);
    const [dateRange, setDateRange] = React.useState<DateRange<Dayjs>>([dayjs(), dayjs()]);
    const [tempDateRange, setTempDateRange] = React.useState<DateRange<Dayjs>>([dayjs(), dayjs()]);
    const formatDateRanges = dateRange.map(djs => djs?.format('YYYY-MM-DD'));
    const [edit, setEdit] = useState<boolean>(true);
    const [balanceId, setBalanceId] = useState<string | null>(null);
    const [loadKey, setLoadKey] = useState<string>(Date.now() + '');
    const [newBalance, setNewBalance] = useState<boolean>(false);
    const [tab, setTab] = useState<'all' | 'seoul' | 'busan' | 'tokyo'>('all');

    const {
        data: balances,
        loading,
        save,
    } = useReadPropValueStartEndAt<Balance>('account', 'date', formatDateRanges[0], formatDateRanges[1], loadKey);
    const {
        data: users,
    } = useRead<{ [uId: string]: User }>('user', loadKey);

    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);

    const handleDateRange = (dr: DateRange<Dayjs>) => {
        setDateRange(dr);
    }
    const handleTempDateRange = (dr: DateRange<Dayjs>) => {
        setTempDateRange(dr);
    }
    const handleConfirmDateRange = () => {
        setDateRange(tempDateRange);
    }
    const handlePreDate = () => {
        setDateRange([dateRange[0]?.subtract(1, 'day') ?? dayjs(), dateRange[1]?.subtract(1, 'day') ?? dayjs()]);
    }

    const handleNextDate = () => {
        setDateRange([dateRange[0]?.add(1, 'day') ?? dayjs(), dateRange[1]?.add(1, 'day') ?? dayjs()]);
    }

    const handleDelete = (id: string | number) => {
        if (!window.confirm('회계 내역을 삭제하시겠습니까?')) return false;
        const ref = `account/${id}`;
        removeRealtime(ref).catch(console.error);
        setLoadKey(Date.now() + '');
        return true;
    }

    const handleEditMode = (_: any, checked: boolean) => {
        setEdit(checked);
    }

    const handleSelectRow = (selection: any) => {
        setSelectedBalanceRow(selection.row ?? null);
    }

    const balanceRow = Object.entries(balances ?? {}).map<BalanceRow>(([id, balance], idx) => {
        const writerId = balance.writer.split(')')[1];
        const writer = users?.[writerId] as any;
        const writerName = writer ? `${writer.name}(${writer.nameEn})` : /\((.+)\).+/.exec(balance.writer)?.[1] ?? balance.writer;
        return {
            id: id,
            type: balance.card ? 'CARD' : 'CASH',
            card: balance.card ?? 0,
            cash: balance.cash ?? 0,
            category: balance.category,
            currency: balance.currency,
            date: balance.date,
            detail: balance.detail,
            identifier: balance.identifier,
            timestamp: new Date(balance.timestamp ?? balance.date).toLocaleString(),
            createdAt: new Date(balance.createdAt ?? balance.date).toLocaleString(),
            updatedAt: new Date(balance.updatedAt ?? balance.timestamp).toLocaleString(),
            title: balance.title,
            writer: writerName,
            area: balance.area ?? writer?.area ?? 'Seoul'
            // writer: /\((.+)\).+/.exec(balance.writer)?.[1] ?? balance.writer
            // writer: balance.writer
        }
    }).filter((b) => {
        return tab === 'all' || b.area.toLowerCase().includes(tab)
    });


    const jpyBalanceRow = balanceRow.filter(b => b.currency.toLowerCase() === 'jpy')
    const krwBalanceRow = balanceRow.filter(b => b.currency.toLowerCase() === 'krw')


    const balanceColumn: GridColDef[] = [
        {
            field: 'writer',
            headerName: 'Writer',
            minWidth: 100,
            align: 'center',
            headerAlign: 'center',
            editable: false
        },
        {
            field: 'date',
            headerName: 'Date',
            minWidth: 100,
            align: 'center',
            headerAlign: 'center',
            editable: false
        },
        {
            field: 'type',
            headerName: 'Type',
            minWidth: 100,
            align: 'center',
            headerAlign: 'center',
            editable: true
        },
        {
            field: 'currency',
            headerName: 'Currency',
            minWidth: 100,
            align: 'center',
            headerAlign: 'center',
            editable: true
        },
        {
            field: 'card',
            headerName: 'Card',
            minWidth: 150,
            align: 'center',
            headerAlign: 'center',
            renderCell: (prop) => {
                return <Typography color={
                    prop.row.card > 0
                        ? '#1577BA'
                        : prop.row.card < 0
                            ? '#FF4C41'
                            : 'inherit'
                }>
                    {prop.row.card.toLocaleString()}
                </Typography>
            },
            editable: true
        },
        {
            field: 'cash',
            headerName: 'Cash',
            minWidth: 150,
            align: 'center',
            headerAlign: 'center',
            renderCell: (prop) => {
                return <Typography color={
                    prop.row.cash > 0
                        ? '#1577BA'
                        : prop.row.cash < 0
                            ? '#FF4C41'
                            : 'inherit'
                }>
                    {prop.row.cash.toLocaleString()}</Typography>
            },
            editable: true
        },
        {
            field: 'title',
            headerName: 'Title',
            minWidth: 200,
            align: 'center',
            headerAlign: 'center',
            editable: true
        },
        {
            field: 'detail',
            headerName: 'Detail',
            minWidth: 400,
            align: 'center',
            headerAlign: 'center',
            editable: true
        },
        {
            field: 'updatedAt',
            headerName: 'Update',
            minWidth: 300,
            align: 'center',
            headerAlign: 'center',
            editable: false
        },

        {
            field: 'createdAt',
            headerName: 'Create',
            minWidth: 100,
            align: 'center',
            headerAlign: 'center',
            editable: false
        },

        {
            field: 'actions',
            type: 'actions',
            headerName: '',
            minWidth: 100,
            align: 'center',
            headerAlign: 'center',
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<DeleteIcon/>}
                    onClick={() => handleDelete(params.id)}
                    label={'Delete'}
                />
            ]
        },
    ]


    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    px: 8
                }}
            >
                <Box sx={{
                    position: 'fixed',
                    display: 'inline-flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    bottom: 0,
                    left: '50%',
                    transform: 'translate(-50%, -1em)',
                    zIndex: 999,
                    backgroundColor: 'white',
                    borderRadius: 5,
                    boxShadow: 2,
                    py: 2,
                    px: 3
                }}>


                    {
                        rowSelectionModel.length > 0
                            ?
                            <ButtonBase
                                onClick={() => {
                                    setRowSelectionModel([])
                                }}
                            >
                                <Typography variant={'h6'} color={'primary'}>
                                    {balanceRow.filter((r) => rowSelectionModel.includes(r.id)).reduce((result, a) => result + a.card + a.cash, 0).toLocaleString()}
                                </Typography>
                            </ButtonBase>
                            : <Button
                                fullWidth
                                variant={'text'}
                                size={'large'}
                                startIcon={<FiberNewIcon/>}
                                onClick={() => setNewBalance(true)}
                            >
                                새 내역
                            </Button>
                    }
                </Box>
                <>
                    <ButtonBase
                        disableTouchRipple
                        onClick={handlePreDate}
                        sx={{
                            position: 'fixed',
                            top: '50%',
                            left: '0.5em',
                            transform: 'translateY(-50%)',
                            zIndex: 1,
                        }}
                    >
                        <ArrowLeftIcon
                            sx={{
                                width: '2em',
                                height: '2em'
                            }}
                        />
                    </ButtonBase>
                    <ButtonBase
                        disableTouchRipple
                        onClick={handleNextDate}
                        sx={{
                            position: 'fixed',
                            top: '50%',
                            right: '0.5em',
                            transform: 'translateY(-50%)',
                            zIndex: 1,
                        }}
                    >
                        <ArrowRightIcon
                            sx={{
                                width: '2em',
                                height: '2em'
                            }}
                        />
                    </ButtonBase>
                </>

                {/*<Stack*/}
                {/*    direction={'row-reverse'}*/}
                {/*    gap={2}*/}
                {/*>*/}
                {/*    <Button variant={'outlined'} onClick={handlePull}*/}
                {/*            disabled={formatDateRanges[0] !== formatDateRanges[1]}>*/}
                {/*        Pull*/}
                {/*    </Button>*/}
                {/*</Stack>*/}
                <Card
                    sx={{
                        display: 'inline-block',
                        flex: 0
                    }}
                >
                    <CardContent sx={(theme) => ({
                        display: 'flex',
                        justifyContent: 'space-between',
                        color: theme.palette.text.primary,
                        padding: '8px !important',
                    })}>
                        <DateTabs date={dateRange[0]?.toDate() ?? null} onChange={(date) => {
                            setDateRange([dayjs(date), dayjs(date)]);
                            setTempDateRange([dayjs(date), dayjs(date)]);
                        }}/>

                        <Box
                            display={'flex'}
                            gap={1}
                        >
                            <DateRangePickerValue onChange={handleTempDateRange} dateRange={dateRange}/>
                            {
                                loading ?
                                    <CircularProgress/>
                                    : <Button onClick={handleConfirmDateRange} variant={'contained'}
                                              disabled={dateRange.map(d => d?.format('YYYY-MM-DD')).join() === tempDateRange.map(d => d?.format('YYYY-MM-DD')).join()}>
                                        Confirm
                                    </Button>

                            }
                        </Box>
                    </CardContent>
                </Card>


                <Tabs value={tab} onChange={(_, t) => setTab(t)}>
                    <Tab value={'all'} label={'ALL'}/>
                    <Tab value={'seoul'} label={'SEOUL'}/>
                    <Tab value={'busan'} label={'BUSAN'}/>
                    <Tab value={'tokyo'} label={'TOKYO'}/>
                </Tabs>

                {
                    krwBalanceRow.length > 0
                        ? (
                            <Card>
                                <CardHeader title={'KRW'}/>
                                <CardContent>
                                    <Grid
                                        container
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            sm={4}
                                        >
                                            <Box
                                                height={'100%'}
                                                display={"flex"}
                                                alignItems={'center'}
                                                justifyContent={'center'}
                                                flexDirection={'column'}
                                            >
                                                <Typography textAlign={'center'} variant={'h4'} gutterBottom>
                                                    {formatDateRanges[0] === formatDateRanges[1] ? formatDateRanges[0] : `${formatDateRanges[0]} ~ ${formatDateRanges[1]}`}
                                                </Typography>
                                                <Typography textAlign={'center'} variant={'h3'} color={'primary'}
                                                            fontWeight={'bold'}>
                                                    {
                                                        krwBalanceRow.reduce((result, a) => {
                                                            return result + a.card + a.cash
                                                        }, 0)
                                                            .toLocaleString()
                                                    }
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={4}
                                        >
                                            <Stack
                                                gap={2}
                                            >
                                                <Box>
                                                    <Typography textAlign={'center'} variant={'h5'} gutterBottom>
                                                        CARD
                                                    </Typography>
                                                    <Typography textAlign={'center'} variant={'h4'} color={'primary'}>
                                                        {
                                                            krwBalanceRow.reduce((result, a) => {
                                                                return result + (a.type === 'CARD' ? a.card + a.cash : 0)
                                                            }, 0).toLocaleString()
                                                        }
                                                    </Typography>
                                                </Box>
                                                <Grid
                                                    container
                                                >
                                                    <Grid
                                                        item
                                                        xs={6}
                                                    >
                                                        <Typography textAlign={'center'} variant={'h6'} gutterBottom>
                                                            INCOME
                                                        </Typography>
                                                        <Typography textAlign={'center'} variant={'h5'} color={'primary'}>
                                                            {
                                                                krwBalanceRow.reduce((result, a) => {
                                                                    return result + (a.type === 'CARD' && (a.card + a.cash) > 0 ? (a.card + a.cash) : 0)
                                                                }, 0).toLocaleString()
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={6}
                                                    >
                                                        <Typography textAlign={'center'} variant={'h6'} gutterBottom>
                                                            EXPENDITURE
                                                        </Typography>
                                                        <Typography textAlign={'center'} variant={'h5'} color={'error'}>
                                                            {
                                                                krwBalanceRow.reduce((result, a) => {
                                                                    return result + (a.type === 'CARD' && (a.card + a.cash) < 0 ? (a.card + a.cash) : 0)
                                                                }, 0).toLocaleString()
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Stack>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={4}
                                        >
                                            <Stack
                                                gap={2}
                                            >
                                                <Box>
                                                    <Typography textAlign={'center'} variant={'h5'} gutterBottom>
                                                        CASH
                                                    </Typography>
                                                    <Typography textAlign={'center'} variant={'h4'} color={'primary'}>
                                                        {
                                                            krwBalanceRow.reduce((result, a) => {
                                                                return result + (a.type === 'CASH' ? (a.card + a.cash) : 0)
                                                            }, 0).toLocaleString()
                                                        }
                                                    </Typography>
                                                </Box>
                                                <Grid
                                                    container
                                                >
                                                    <Grid
                                                        item
                                                        xs={6}
                                                    >
                                                        <Typography textAlign={'center'} variant={'h6'} gutterBottom>
                                                            INCOME
                                                        </Typography>
                                                        <Typography textAlign={'center'} variant={'h5'} color={'primary'}>
                                                            {
                                                                krwBalanceRow.reduce((result, a) => {
                                                                    return result + (a.type === 'CASH' && (a.card + a.cash) > 0 ? (a.card + a.cash) : 0)
                                                                }, 0).toLocaleString()
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={6}
                                                    >
                                                        <Typography textAlign={'center'} variant={'h6'} gutterBottom>
                                                            EXPENDITURE
                                                        </Typography>
                                                        <Typography textAlign={'center'} variant={'h5'} color={'error'}>
                                                            {
                                                                krwBalanceRow.reduce((result, a) => {
                                                                    return result + (a.type === 'CASH' && (a.card + a.cash) < 0 ? (a.card + a.cash) : 0)
                                                                }, 0).toLocaleString()
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>)
                        : null
                }

                {
                    jpyBalanceRow.length > 0
                        ? (<Card>
                            <CardHeader title={'JPY'}/>
                            <CardContent>
                                <Grid
                                    container
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sm={4}
                                    >
                                        <Box
                                            height={'100%'}
                                            display={"flex"}
                                            alignItems={'center'}
                                            justifyContent={'center'}
                                            flexDirection={'column'}
                                        >
                                            <Typography textAlign={'center'} variant={'h4'} gutterBottom>
                                                {formatDateRanges[0] === formatDateRanges[1] ? formatDateRanges[0] : `${formatDateRanges[0]} ~ ${formatDateRanges[1]}`}
                                            </Typography>
                                            <Typography textAlign={'center'} variant={'h3'} color={'primary'}
                                                        fontWeight={'bold'}>
                                                {
                                                    jpyBalanceRow.reduce((result, a) => {
                                                        return result + a.card + a.cash
                                                    }, 0)
                                                        .toLocaleString()
                                                }
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={4}
                                    >
                                        <Stack
                                            gap={2}
                                        >
                                            <Box>
                                                <Typography textAlign={'center'} variant={'h5'} gutterBottom>
                                                    CARD
                                                </Typography>
                                                <Typography textAlign={'center'} variant={'h4'} color={'primary'}>
                                                    {
                                                        jpyBalanceRow.reduce((result, a) => {
                                                            return result + (a.type === 'CARD' ? a.card + a.cash : 0)
                                                        }, 0).toLocaleString()
                                                    }
                                                </Typography>
                                            </Box>
                                            <Grid
                                                container
                                            >
                                                <Grid
                                                    item
                                                    xs={6}
                                                >
                                                    <Typography textAlign={'center'} variant={'h6'} gutterBottom>
                                                        INCOME
                                                    </Typography>
                                                    <Typography textAlign={'center'} variant={'h5'} color={'primary'}>
                                                        {
                                                            jpyBalanceRow.reduce((result, a) => {
                                                                return result + (a.type === 'CARD' && (a.card + a.cash) > 0 ? (a.card + a.cash) : 0)
                                                            }, 0).toLocaleString()
                                                        }
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                >
                                                    <Typography textAlign={'center'} variant={'h6'} gutterBottom>
                                                        EXPENDITURE
                                                    </Typography>
                                                    <Typography textAlign={'center'} variant={'h5'} color={'error'}>
                                                        {
                                                            jpyBalanceRow.reduce((result, a) => {
                                                                return result + (a.type === 'CARD' && (a.card + a.cash) < 0 ? (a.card + a.cash) : 0)
                                                            }, 0).toLocaleString()
                                                        }
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Stack>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={4}
                                    >
                                        <Stack
                                            gap={2}
                                        >
                                            <Box>
                                                <Typography textAlign={'center'} variant={'h5'} gutterBottom>
                                                    CASH
                                                </Typography>
                                                <Typography textAlign={'center'} variant={'h4'} color={'primary'}>
                                                    {
                                                        jpyBalanceRow.reduce((result, a) => {
                                                            return result + (a.type === 'CASH' ? (a.card + a.cash) : 0)
                                                        }, 0).toLocaleString()
                                                    }
                                                </Typography>
                                            </Box>
                                            <Grid
                                                container
                                            >
                                                <Grid
                                                    item
                                                    xs={6}
                                                >
                                                    <Typography textAlign={'center'} variant={'h6'} gutterBottom>
                                                        INCOME
                                                    </Typography>
                                                    <Typography textAlign={'center'} variant={'h5'} color={'primary'}>
                                                        {
                                                            jpyBalanceRow.reduce((result, a) => {
                                                                return result + (a.type === 'CASH' && (a.card + a.cash) > 0 ? (a.card + a.cash) : 0)
                                                            }, 0).toLocaleString()
                                                        }
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                >
                                                    <Typography textAlign={'center'} variant={'h6'} gutterBottom>
                                                        EXPENDITURE
                                                    </Typography>
                                                    <Typography textAlign={'center'} variant={'h5'} color={'error'}>
                                                        {
                                                            jpyBalanceRow.reduce((result, a) => {
                                                                return result + (a.type === 'CASH' && (a.card + a.cash) < 0 ? (a.card + a.cash) : 0)
                                                            }, 0).toLocaleString()
                                                        }
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>)
                        : null
                }
                <Stack flexDirection={'row-reverse'} gap={0.5}>
                    <BalanceList dateRanges={formatDateRanges} balanceRows={balanceRow}/>
                    <Switch onChange={handleEditMode} checked={edit}/>
                </Stack>
                <Card>

                    <CardContent>
                        <Box
                            sx={
                                edit
                                    ? {height: '80vh', mt: 2,}
                                    : {mt: 2,}
                            }
                        >
                            <DataGridPro
                                sx={{color: '#2a2f37'}}
                                slots={{
                                    toolbar: (...props) => (
                                        <Box sx={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            pt: 2,
                                            pb: 1,
                                            px: 2
                                        }}>
                                            {/*<Switch onChange={handleEditMode} checked={edit}/>*/}
                                            <GridToolbarQuickFilter
                                                {...props}
                                                fullWidth
                                            />
                                        </Box>
                                    )
                                }}

                                rows={balanceRow}
                                columns={balanceColumn}
                                onRowClick={handleSelectRow}
                                checkboxSelection
                                disableRowSelectionOnClick
                                onRowSelectionModelChange={(newRowSelectionModel) => {
                                    setRowSelectionModel(newRowSelectionModel);
                                }}
                                rowSelectionModel={rowSelectionModel}
                                processRowUpdate={async (nr, or) => {
                                    const card = Number.parseInt(nr.card ?? '0');
                                    const cash = Number.parseInt(nr.cash ?? '0');
                                    const updates = {
                                        [nr.id]: {
                                            card: card,
                                            cash: cash,
                                            category: or.category,
                                            currency: or.currency,
                                            date: or.date,
                                            detail: nr.detail,
                                            identifier: nr.identifier,
                                            timestamp: new Date().getTime(),
                                            updatedAt: new Date().getTime(),
                                            createdAt: nr.createdAt,
                                            title: nr.title,
                                            writer: nr.writer
                                        }
                                    };
                                    try {
                                        await save(updates)
                                        return nr;
                                    } catch (e) {
                                        return or
                                    }
                                }}
                                onProcessRowUpdateError={console.error}
                            />
                        </Box>

                    </CardContent>
                </Card>
            </Box>
            {

                newBalance
                    ? <CreateAccountModal
                        onClose={(update) => {
                            if (update) setLoadKey(Date.now() + '')
                            setNewBalance(false);
                        }}
                    />
                    : null
            }
            {

                selectedBalanceRow
                    ? <AccountModal
                        id={selectedBalanceRow.id}
                        balance={balances![selectedBalanceRow.id]}
                        onSave={(id: string, nr: Balance) => {
                            save({
                                [id]: {
                                    ...nr,
                                    timestamp: new Date().getTime(),
                                    updatedAt: new Date().getTime(),
                                }
                            })
                                .then(() => {
                                    setSelectedBalanceRow(null);
                                    setLoadKey(Date.now() + '')
                                })
                                .catch(() => {
                                    alert('업데이트에 실패했습니다.')
                                })
                        }}
                        onDelete={(id) => {
                            if (handleDelete(id)) {
                                setSelectedBalanceRow(null);
                                setLoadKey(Date.now() + '')
                            }
                        }}
                        onClose={(update) => {
                            if (update) setLoadKey(Date.now() + '')
                            setSelectedBalanceRow(null);
                        }}
                    />
                    : null
            }
        </>
    )
}


function BalanceList(props: { dateRanges: (string | undefined)[], balanceRows: BalanceRow[] }) {
    const [loading, setLoading] = useState(false);
    const [startAt, endAt] = props.dateRanges;

    const schema = [
        {
            column: 'Writer',
            value: (r: any) => r.writer,
        },
        {
            column: 'Date',
            value: (r: any) => r.date,
        },
        {
            column: 'Type',
            value: (r: any) => r.type,
        },
        {
            column: 'Card',
            value: (r: any) => r.card,
        },
        {
            column: 'Cash',
            value: (r: any) => r.cash,
        },
        {
            column: 'Title',
            value: (r: any) => r.title,
        },
        {
            column: 'Detail',
            value: (r: any) => r.detail,
        },
        {
            column: 'Updated',
            value: (r: any) => r.updatedAt,
        },
        {
            column: 'Created',
            value: (r: any) => r.createdAt,
        },

    ]

    const handleClick = useCallback(() => {
        const download = async () => {
            setLoading(true);

            await writeXlsxFile(props.balanceRows, {
                schema,
                fileName: `kint2-balance.xlsx`
            }).then(() => {
            })
        }

        download()
            .catch((e) => {
                console.error(e)
                alert('Fail on download excel');
            })
            .finally(() => setLoading(false));
    }, [startAt, endAt, setLoading, props.balanceRows]);

    return (
        <Button onClick={handleClick} variant={"outlined"}>
            {
                loading ? <CircularProgress size={32}/>
                    : 'EXCEL'
            }
        </Button>
        // <Card>
        //     <CardContent>
        //         <DataGridPro columns={columns} rows={rows} slots={{toolbar: CustomToolbar}}/>
        //     </CardContent>
        // </Card>
    )
}