import * as XLSX from "xlsx";
import {AgencyFileDatum} from "./types";

function cleanString(data?: any): string {
  return data?.toString().trim() || ''
}

function toNumber(numberLike?: string | number) {
  if (typeof numberLike === 'number') return numberLike;
  return Number(cleanString(numberLike) || 0);
}


export default async function parseCVFiles(file: Blob | File): Promise<AgencyFileDatum[]> {
  const parsed = await parseFileData(file);
  return parsed
    .filter(raw=>(raw['Status'] !== 'Canceled' || raw['Net price'] !== '0USD'))
    .map((raw) => {
      const agency = 'CV'
      const agencyCode = cleanString(raw['I.D.']).replace('A', '');
      const tour = cleanString(raw['Product']);
      const option = cleanString(raw['Selected option']);
      const date = formatDate(cleanString(raw['Travel date']));
      const people = parseQuantity(raw['People']);
      const price = parsePrice(raw['Net price']);

      return ({
        tour,
        option,
        date,
        agency,
        agencyCode,
        people,
        price,
        unitPrice: price / people,
      });
    })
}


const parseFileData = async (file: Blob | File) => {
  const binary = await file.arrayBuffer()
  const workbook = XLSX.read(binary, {type: "array", raw: true})
  const sheets = workbook.Sheets
  const sheetNames = workbook.SheetNames

  const firstSheet = sheets[sheetNames[0]]
  return XLSX.utils.sheet_to_json(firstSheet, {range: 1}) as any[];
}


function formatDate(ddmmyyyy: string) {
  const matches = /(\d{2})\/(\d{2})\/(\d{4})/gi.exec(ddmmyyyy);
  const yyyy = matches?.[3] ?? ''
  const mm = matches?.[2] ?? ''
  const dd = matches?.[1] ?? '';
  return `${yyyy}-${mm}-${dd}`;
}

/**
 * 3 - adulti:2
 * 1
 * Person (3 yrs~) 1
 * @param quantity
 */

function parseQuantity(quantity: string) {
  const matches = /(\d+) -/gi.exec(quantity);
  if (!matches?.[1]) return 0;
  return Number.parseInt(matches[1]);
}

function parsePrice(price:string){
  const matches = /(\d+(.\d+))USD/.exec(price);
  if(!matches?.[1])return 0;
  return Number(matches[1]);
}