import { Badge, Box, ButtonBase, Typography } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Unsubscribe } from "firebase/auth"
import CloseIcon from "@mui/icons-material/Close"
import { grey, red } from "@mui/material/colors"
import CircularProgress from "@mui/material/CircularProgress"
import { exitChat, subscribeToLastMessage, subscribeToUnreadMessages } from "../../../hooks/chat"
import { AuthContext } from "../../../hooks/auth"
import { IChatMeta } from "../../../types/chat"
import { convertSenderName } from "../../../utils/chatUtils"

interface Props {
    chatMeta: IChatMeta
}

export default function ChatRoom({ chatMeta }: Props) {
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [unreadCount, setUnreadCount] = useState<number>(0)
    const [latestMessage, setLatestMessage] = useState<any>(null)

    const { operator } = useContext(AuthContext)
    const participantNames = Object.values(chatMeta.participants)
        .filter((participant) => participant.id !== operator?.id)
        .map((participant) => convertSenderName(participant).split("(")[1].split(")")[0])

    const navigate = useNavigate()

    useEffect(() => {
        if (!operator) return
        const unsubs: Unsubscribe[] = []
        unsubs.push(
            subscribeToUnreadMessages(chatMeta.id, operator.id, (querySnapshot: any) => {
                setUnreadCount(querySnapshot.docs.length)
            })
        )
        unsubs.push(
            subscribeToLastMessage(chatMeta.id, (querySnapshot) => {
                querySnapshot.forEach((doc: any) => {
                    if (doc.exists()) {
                        setLatestMessage(doc.data() as any)
                    }
                })
            })
        )
        return () => {
            if (unsubs.length > 0) {
                unsubs.forEach((unsub) => unsub())
            }
        }
    }, [])

    async function handleDeleteChat(e: any) {
        setDeleteLoading(true)
        e.preventDefault()
        e.stopPropagation()
        setDeleteLoading(true)
        await exitChat(chatMeta.id, operator!)
        setDeleteLoading(false)
    }

    return (
        <ButtonBase
            sx={{
                p: 2,
                display: "flex",
                alignItems: "start",
                position: "relative",
                columnGap: 1.5,
                width: "100%",
                maxHeight: "200px",
                overflow: "hidden",
                border: "1px solid #E9F4FF",
                borderRadius: "16px",
                transition: "background .8s",
                background: deleteLoading ? red[100] : "#fff",
                "&:hover": {
                    background: "#e9f4ff",
                },
            }}
            disabled={deleteLoading}
            onClick={() =>
                navigate(chatMeta.id, {
                    relative: "path",
                    state: {
                        chatMeta,
                    },
                })
            }
        >
            <Badge
                color="error"
                badgeContent={unreadCount}
                sx={{
                    "& .MuiBadge-badge": {
                        position: "absolute",
                        right: "8px",
                        top: "10px",
                    },
                }}
                max={50}
            >
                <img
                    src="/logo512_3_0_0.png"
                    style={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "50%",
                    }}
                    alt="image"
                />
            </Badge>
            <ButtonBase
                sx={{
                    position: "absolute",
                    top: 4,
                    right: 4,
                    color: deleteLoading ? grey[500] : red[500],
                }}
                disabled={deleteLoading}
                onClick={handleDeleteChat}
            >
                {deleteLoading && <CircularProgress sx={{ mr: 1, mt: 0.5 }} size={18} color="inherit" />}
                {!deleteLoading && <CloseIcon sx={{ mr: 1 }} />}
            </ButtonBase>
            <Box
                sx={{
                    flex: 1,
                    mt: 1,
                }}
            >
                <Typography
                    sx={{
                        textAlign: "left",
                    }}
                >
                    [{chatMeta.title}]
                </Typography>
                <Typography
                    sx={{
                        fontSize: "18px",
                        color: "var(--black)",
                        fontWeight: 700,
                        textAlign: "left",
                    }}
                >
                    {participantNames.join(", ")}
                </Typography>

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "end",
                        width: "100%",
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: "14px",
                            color: "#4f4f4f",
                            textAlign: "left",
                        }}
                    >
                        {latestMessage?.type === "text" ||
                        latestMessage?.type === "exit" ||
                        latestMessage?.type === "enter"
                            ? latestMessage?.text
                            : latestMessage?.type === "image"
                            ? "Image"
                            : ""}
                    </Typography>
                    <Typography
                        sx={{
                            textAlign: "right",
                            fontSize: "12px",
                            color: "#828282",
                        }}
                    >
                        {latestMessage?.date?.toDate().toLocaleTimeString().split(":").slice(0, 2).join(":")}
                    </Typography>
                </Box>
            </Box>
        </ButtonBase>
    )
}
