export function tokenize(str: string): string[] {
    return str.split(/\s+/g).flatMap(token => token.split(/[{}\[\]\/?.,;:|)*~`!^\-_+<>@#$%&\\=('"]/g)).map(str => str.toLowerCase());
}

export function emailTokenize(email: string): string[] {
    return tokenize(email.split('@')[0]);
}

export function nameTokenize(clientName: string): string[] {
    return tokenize(clientName).filter(token => !token.match(/mr|mrs|miss|mstr/i));
}

export function escapeInvalidCharacter(str: string) {
    return str.replace(/[.,#$\[\]]/g, '');
}

export function escapeEmail(email:string){
    return email.replace(/@|\./g, '_')
}

export function recoverEmail(escaped:string){
    return escaped.replace('_', '@').replace(/_/g, '.');
}

export function simplifyHtml(html: string) {
    const rawBody = (/<body[\s\S]+?<\/body>/gi.exec(html) ?? [html])[0];
    return rawBody
        .replace(/[\t\r\n]/g, '')
        .replace(/<head>[\s\S]*<\/head>/i, '')
        .replace(/<(div|p|br|h|li|ol|form|iframe|img|lable|map|menu|tr|td|th)[^>]*>/gi, '\n')
        .replace(/<!--[\s\S]*?-->/g, '')
        .replace(/<[^>]*>/gi, '')
        .replace(/&nbsp/g, ' ')
        .replace(/&amp/, '\&')
        .replace(/&lt/g, '\<')
        .replace(/&gt/g, '\>')
        .replace(/&quot/g, '"')
        .replace(/&#40/g, '\(')
        .replace(/&#41/g, '\)')
        .replace(/;/g, '')
        .replace(/\n[ ]*/g, '\n')
        .replace(/[\r\n]{2,}/g, '\n')
        .replace(/[ ]{2,}/g, ' ');
}
